import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiPersonalizationsService {
  apiName = 'Default';
  

  getCustomCssByBlockAndId = (block: string, id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: `/api/app/gmi-personalizations/${id}/custom-css`,
      params: { block },
    },
    { apiName: this.apiName,...config });
  

  getPersonalization = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/gmi-personalizations/personalization',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
