<div class="card-content">
	<div class="card-body">
		<ngx-datatable
			rowHeight="auto"
			columnMode="force"
			default
			class="bootstrap core-bootstrap"
			[rows]="contractMissingDocs.items"
			[count]="contractMissingDocs.totalCount"
			[externalPaging]="true"
			[rowClass]="getRowClass"
			[scrollbarH]="false"
			[limit]="page.pageSize"
			[messages]="customMessages"
			[offset]="page.pageNumber"
			[footerHeight]="50"
			(page)="setPage($event)"
			(sort)="onSort($event)"
			[sorts]="defaultSort">
			<ngx-datatable-column
				*ngFor="let col of contractMissingDocsColumns"
				[name]="col.name"
				[prop]="col.prop"
				[pipe]="col.pipe"
				[minWidth]="col.minWidth"
				[maxWidth]="col.maxWidth"
				[cellClass]="col.cellClass"
				[headerClass]="col.headerClass"
				[draggable]="false"
				[resizeable]="false">
			</ngx-datatable-column>
			<ngx-datatable-column
				name=""
				[minWidth]="400"
				[cellClass]="'show800 hideOnFull'"
				[headerClass]="'show800 hideOnFull'"
				[draggable]="false"
				[resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<div *ngIf="!!showIdPog">{{ WL.contracts_IdPog | gmiCustomLocalization | async }}: {{ row.idPog }}</div>
					<div>{{ WL.missingDoc_DocumentDate | gmiCustomLocalization | async }}: {{ row.missingDocDate | gmiDateFormatFromString }}</div>
					<div class="fw-bold">{{ row.missingDocDescription }}</div>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
	</div>
</div>
