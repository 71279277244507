import type { GmiBlocksDto, GmiCoreRecoveryAgenciesDto, GmiCoreSellersDto, GmiNotificationTypeDto, GmiPartnerSearchInputDto, GuidTitleDto, IdTitleDtoInt, IdTitleDtoString } from './models';
import type { IdentityRoleDto } from './volo/abp/identity/models';
import { RestService, Rest } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminRegistersService {
  apiName = 'Default';
  

  getGmiBlocks = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiBlocksDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/gmi-blocks',
    },
    { apiName: this.apiName,...config });
  

  getGmiCoreRecoveryAgencies = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiCoreRecoveryAgenciesDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/gmi-core-recovery-agencies',
    },
    { apiName: this.apiName,...config });
  

  getGmiCoreSellers = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiCoreSellersDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/gmi-core-sellers',
    },
    { apiName: this.apiName,...config });
  

  getGmiPartnerSearchByInput = (input: GmiPartnerSearchInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GuidTitleDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/gmi-partner-search',
      params: { title: input.title, id: input.id },
    },
    { apiName: this.apiName,...config });
  

  getGmiStylesByIdCurrentStyle = (idCurrentStyle: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/gmi-styles',
      params: { idCurrentStyle },
    },
    { apiName: this.apiName,...config });
  

  getGroups = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ListResultDto<IdentityRoleDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/groups',
    },
    { apiName: this.apiName,...config });
  

  getMrtSupportedRenderTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoString[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/mrt-supported-render-types',
    },
    { apiName: this.apiName,...config });
  

  getNotificationTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNotificationTypeDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-registers/notification-types',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
