<div class="card-content">
	<div class="card-body">
		<ngx-datatable
			class="bootstrap core-bootstrap gmi-selectable"
			columnMode="force"
			default
			rowHeight="auto"
			[count]="page.totalElements"
			[externalPaging]="true"
			[footerHeight]="50"
			[limit]="page.pageSize"
			[messages]="customMessages"
			[offset]="page.pageNumber"
			[rows]="userNotifications.items"
			[scrollbarH]="false"
			[sorts]="defaultSort"
			(activate)="openModal($event, content)"
			(page)="setPage($event)"
			(sort)="onSort($event)">
			<ngx-datatable-column
				name=""
				[minWidth]="50"
				[maxWidth]="50"
				[sortable]="false"
				[draggable]="false"
				[resizeable]="false"
				[cellClass]="'grid-right-align'">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<i
						[ngClass]="{
							'font-medium-4': true,
							'icon-envelope-open gmi-grayed-out': !!row.dateRead && searchType === searchTypeEnum.notification,
							'icon-envelope': !!!row.dateRead && searchType === searchTypeEnum.notification,
							'ft-help-circle': searchType === searchTypeEnum.help
						}"></i>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column
				name="{{ WL.gmiNotifications_Title | gmiCustomLocalization | async }}"
				[minWidth]="1000"
				prop="title"
				[draggable]="false"
				[resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<div [ngClass]="{ 'fw-bold': !!!row.dateRead && searchType !== searchTypeEnum.help }">{{ row.title }}</div>
					<div [ngClass]="{ 'gmi-unread-text': !!!row.dateRead && searchType !== searchTypeEnum.help }" [innerHTML]="row.text"></div>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column
				name="{{ WL.general_LabelDateInserted | gmiCustomLocalization | async }}"
				[minWidth]="100"
				prop="dateInserted"
				[draggable]="false"
				[resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<div [ngClass]="{ 'fw-bold': !!!row.dateRead && searchType !== searchTypeEnum.help }">
						{{ row.dateInserted | gmiDateFormatFromString }}
					</div>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column
				name=""
				[minWidth]="50"
				[sortable]="false"
				[cellClass]="'gmi-align-grid-cell-vertical'"
				[draggable]="false"
				[resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<i
						*ngIf="!!row.filesCnt"
						class="icon-paper-clip font-medium-2"
						ngbTooltip="{{ WL.gmiNotifications_HasAttachements | gmiCustomLocalization | async }}"></i>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
	</div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header gmi-modal-header-subtitle">
		<div class="modal-title gmi-display-flex">
			<h3>
				{{ selectedNotification.title }}
			</h3>
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
		</div>
		<div>
			<h5>
				{{ WL.gmiNotifications_NotificationDate | gmiCustomLocalization | async }}: {{ selectedNotification.dateInserted | gmiDateFormatFromString }}
			</h5>
		</div>
	</div>
	<div class="modal-body">
		<form>
			<div class="col-md-12 mb-4 input-group">
				<div class="form-control gmi-div-high" [innerHtml]="selectedNotification.text"></div>
			</div>
		</form>
		<form *ngIf="!!selectedNotification.dataFiles && selectedNotification.dataFiles.length > 0">
			<div class="col-md-12 mb-4 input-group">
				<label for="exampleInputEmail1" class="me-2">{{ WL.general_LabelAttachements | gmiCustomLocalization | async }}</label
				><i
					class="ft-download font-medium-2 gmi-selectable"
					ngbTooltip="{{ WL.general_LabelDownloadAll | gmiCustomLocalization | async }}"
					(click)="downloadAllDataFiles()"></i>
				<div class="input-group" *ngFor="let item of selectedNotification.dataFiles">
					<span class="input-group-text border-end-0" id="basic-addon1">
						<i class="ft-download font-medium-2 gmi-selectable" (click)="downloadDataFile(item.id)"></i>
					</span>
					<input type="text" class="form-control" disabled="true" value="{{ item.title }}" />
				</div>
			</div>
		</form>
		<ngx-spinner
			type="line-scale"
			name="SPINNER_NOTIFICATIONS_MODAL"
			bdColor="rgba(0, 0, 0, 0.8)"
			size="medium"
			color="#ffffff"
			type="line-scale"
			[fullScreen]="true">
			<p style="color: white">{{ WL.general_Loading | gmiCustomLocalization | async }}</p>
		</ngx-spinner>
	</div>
	<div class="modal-footer">
		<div class="pe-xl-2">
			<button
				*ngIf="!!!selectedNotification.dateRead && selectedNotification.typeCode == 'confirmation'"
				type="button"
				class="btn btn-primary"
				(click)="markAsRead()">
				{{ WL.gmiNotifications_MarkAsRead | gmiCustomLocalization | async }}
			</button>
			<i *ngIf="!!selectedNotification.dateRead" class="grid-left-align"
				>{{ WL.gmiNotifications_DateRead | gmiCustomLocalization | async }}: {{ selectedNotification.dateRead | gmiDateFormatFromString }}</i
			>
		</div>
		<button type="button" class="btn btn-primary" (click)="c('Close click')">{{ WL.general_ButtonClose | gmiCustomLocalization | async }}</button>
	</div>
</ng-template>
