import { Injectable } from '@angular/core';
import { IdTitleDtoString } from '@proxy';
import { BehaviorSubject } from 'rxjs';

/**
 * This is the observable for the custom localizations.
 * We get the localizations and store them here, so we dont't have to go to the server.
 */
@Injectable({ providedIn: 'root' })
export class GmiLocalizationService {
	private userCustomLocalization$: BehaviorSubject<IdTitleDtoString[]> = new BehaviorSubject<IdTitleDtoString[]>(null);

	getUserCustomLocalization() {
		return this.userCustomLocalization$.asObservable();
	}

	setUserCustomLocalization(value: string) {
		try {
			let data = JSON.parse(value);
			const dataArray: IdTitleDtoString[] = [];
			Object.keys(data).forEach((key) => dataArray.push({ id: key, title: data[key] }));
			this.userCustomLocalization$.next(dataArray);
		} catch (e) {
			// write an error??
		}
	}
}
