import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CUSTOM_ROUTES: Routes = [
     {
        path: '',
        loadChildren: () => import('../../custom-pages/sisum/custom-pages-sisum.module').then(m => m.CustomPagesModule)
    }
];
