import { IdTitleDtoInt } from '@proxy';

export class Page {
	// The number of elements in the page
	pageSize: number = 10;
	// The total number of elements
	totalElements: number = 0;
	// The total number of pages
	totalPages: number = 0;
	// The current page number
	pageNumber: number = 0;
}

export class GmiGridLocalization {
	totalMessage: string;
	emptyMessage: string;
}

export enum SearchTypeEnum {
	help = 'help',
	notification = 'notification',
}
