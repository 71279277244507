import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AbpOAuthService } from '@abp/ng.oauth';

/**
 * This is the observable for tracking user activity.
 */
@Injectable({ providedIn: 'root' })
export class GmiTokenService {
	// Observable
	private lastApiCall$: BehaviorSubject<number> = new BehaviorSubject(0);

	setLastApiCall() {
		this.lastApiCall$.next(moment().unix());
	}

	getLastApiCall() {
		return this.lastApiCall$.asObservable();
	}

	setTokenExpiration(tokenExp: number) {
		localStorage.setItem('tokenExpire', (tokenExp / 1000 - moment().unix()).toString());
	}

	getTokenExpiration() {
		return localStorage.getItem('tokenExpire');
	}

	tokenCheck(oAuthService: AbpOAuthService) {
		this.getLastApiCall().subscribe((lastApiCall) => {
			let now = moment().unix();
			if (!!lastApiCall && lastApiCall > 0 && lastApiCall + +this.getTokenExpiration() < now) {
				oAuthService.logout().subscribe((response) => {
					window.location.reload();
				});
			}
		});
	}
}
