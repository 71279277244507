import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PipeModule } from 'src/app/shared/pipes/pipe.module';

//COMPONENTS
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { CustomizerComponent } from './customizer/customizer.component';

//DIRECTIVES
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';

// GMI code
import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { GmiSimpleGridCriteriaComponent } from './components/gmi-simple-grid-criteria/gmi-simple-grid-criteria.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GmiSpinner } from './components/gmi-spinner/gmi-spinner.component';
import { GmiEaDocumentsGrid } from './components/gmi-ea-documents-grid/gmi-ea-documents-grid.component';
import { GmiPipesModule } from '../gmiUtils/pipes.module';
import { GmiMissingDocumentationGrid } from './components/gmi-missing-documentation-grid/gmi-missing-documentation-grid.component';
import { GmiNotificationsGrid } from './components/gmi-notifications-grid/gmi-notifications-grid.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GmiFormatDateDirective } from '../gmiUtils/ngbDateInput';
import { GmiReportsModalComponent } from './components/gmi-reports-modal/gmi-reports-modal.component';
import { GmiCookieComponent } from './gmi-cookie/gmi-cookie.component';
import { GmiRequestsModalComponent } from './components/gmi-requests-modal/gmi-requests-modal.component';
import { GmiActions } from './components/gmi-actions/gmi-actions.component';
import { GmiInputTrimDirective, GmiMobilePhoneInputDirective, GmiIbanInputDirective, GmiInputToUpperCaseDirective } from '../gmiUtils/inputs';
import { GmiDatepickerTooltipModule } from './directives/ngb-datepicker-tooltip.directive';
import { GmiContentPagesMessage } from './components/gmi-content-pages-message/gmi-content-pages-message.component';
import { GmiUploadFiles } from './components/gmi-upload-files/gmi-upload-files.component';

import { MatInputModule } from '@angular/material/input';
import { GmiRestrictDateInputDirective } from '../gmiUtils/restrictDataInput';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
	exports: [
		CommonModule,
		FooterComponent,
		NavbarComponent,
		VerticalMenuComponent,
		HorizontalMenuComponent,
		CustomizerComponent,
		SidebarDirective,
		TopMenuDirective,
		NgbModule,
		// GMI
		CoreModule,
		NgbDropdownModule,
		NgxDatatableModule,
		NgxValidateCoreModule,
		GmiSimpleGridCriteriaComponent,
		NgxSpinnerModule,
		GmiSpinner,
		GmiEaDocumentsGrid,
		GmiMissingDocumentationGrid,
		GmiPipesModule,
		GmiNotificationsGrid,
		GmiFormatDateDirective,
		GmiRestrictDateInputDirective,
		GmiReportsModalComponent,
		GmiCookieComponent,
		GmiRequestsModalComponent,
		GmiActions,
		GmiInputTrimDirective,
		GmiContentPagesMessage,
		GmiDatepickerTooltipModule,
		GmiUploadFiles,
		GmiMobilePhoneInputDirective,
		GmiIbanInputDirective,
		GmiInputToUpperCaseDirective,
		MatButtonModule,
		MatStepperModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
	],
	imports: [
		RouterModule,
		CommonModule,
		NgbModule,
		FormsModule,
		OverlayModule,
		ReactiveFormsModule,
		PipeModule,
		// GMI
		CoreModule,
		NgbDropdownModule,
		NgxDatatableModule,
		NgxValidateCoreModule,
		NgxSpinnerModule,
		GmiPipesModule,
		GmiDatepickerTooltipModule,
		MatButtonModule,
		MatStepperModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
	],
	declarations: [
		FooterComponent,
		NavbarComponent,
		VerticalMenuComponent,
		HorizontalMenuComponent,
		CustomizerComponent,
		SidebarLinkDirective,
		SidebarDropdownDirective,
		SidebarAnchorToggleDirective,
		SidebarDirective,
		TopMenuLinkDirective,
		TopMenuDropdownDirective,
		TopMenuAnchorToggleDirective,
		TopMenuDirective,
		// GMI
		GmiSimpleGridCriteriaComponent,
		GmiSpinner,
		GmiEaDocumentsGrid,
		GmiMissingDocumentationGrid,
		GmiNotificationsGrid,
		GmiFormatDateDirective,
		GmiRestrictDateInputDirective,
		GmiReportsModalComponent,
		GmiCookieComponent,
		GmiRequestsModalComponent,
		GmiActions,
		GmiInputTrimDirective,
		GmiContentPagesMessage,
		GmiUploadFiles,
		GmiMobilePhoneInputDirective,
		GmiIbanInputDirective,
		GmiInputToUpperCaseDirective,
	],
})
export class SharedModule {}
