import { ConfigStateService, ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GmiSearchDto, GmiNotificationForUserDto, GmiNotificationDto, GmiNotificationsService } from '@proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import { GmiBase64ToBlob, GmiPrepareGridLocalization } from 'src/app/gmiUtils/functions';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WL } from '../../../const/definitions';
import { GmiNavbarService } from '../../services/gmiNavbar.service';
import * as moment from 'moment';
import { GmiStripHtml } from 'src/app/gmiUtils/pipes';
import { GmiGridLocalization, Page, SearchTypeEnum } from 'src/app/gmiUtils/objects';

@Component({
	selector: 'gmi-notifications-grid',
	templateUrl: './gmi-notifications-grid.component.html',
	styleUrls: ['./gmi-notifications-grid.component.scss'],
	providers: [ListService],
})
export class GmiNotificationsGrid implements OnInit {
	@Input() searchType: SearchTypeEnum = null;
	@Input() searchDto = {} as GmiSearchDto;
	@ViewChild('content') templateRef: TemplateRef<any>;

	searchTypeEnum = SearchTypeEnum;
	// grid
	userNotifications = { items: [], totalCount: 0 } as PagedResultDto<GmiNotificationForUserDto>;

	// notification object
	selectedNotification: GmiNotificationDto;
	defaultSort = [{ prop: 'dateInserted', dir: 'desc' }];
	WL: WL;
	cntNewNotifications: number;
	customMessages = {} as GmiGridLocalization;
	page = new Page();
	currentLanguage = this.configStateService.getDeep('localization.currentCulture').name;

	constructor(
		public readonly list: ListService,
		private gmiNotificationsService: GmiNotificationsService,
		private localizationService: LocalizationService,
		private ngbModal: NgbModal,
		private spinner: NgxSpinnerService,
		private gmiNavbarService: GmiNavbarService,
		private configStateService: ConfigStateService
	) {
		this.list.maxResultCount = 10;
		this.WL = new WL();
	}

	ngOnInit(): void {
		this.gmiNavbarService.getCntUnreadNotifications().subscribe((response) => {
			this.cntNewNotifications = response;
		});

		this.loadGrid();

		if (!!history.state?.idNotification) {
			this.openModalFromHere(history.state.idNotification);
		}
	}

	loadGrid() {
		this.spinner.show();

		if (this.searchType === SearchTypeEnum.help) {
			const eaDocumentsStreamCreator = (query) => {
				this.spinner.show();
				query.skipCount = this.page.pageNumber * this.page.pageSize;
				return this.gmiNotificationsService.getHelpNotificationsForUserByLanguageAndInputAndPage(this.currentLanguage, this.searchDto, query);
			};

			this.list.hookToQuery(eaDocumentsStreamCreator).subscribe((response) => {
				this.userNotifications = response;
				this.page.totalElements = response.totalCount;
				this.page.totalPages = response.totalCount / this.page.pageSize;
				this.userNotifications.items.forEach((x) => {
					x.text = new GmiStripHtml().transform(x.text, 100);
				});
				this.customMessages = GmiPrepareGridLocalization(this.localizationService, this.page);
				this.spinner.hide();
			});
		} else if (this.searchType === SearchTypeEnum.notification) {
			const eaDocumentsStreamCreator = (query) => {
				this.spinner.show();
				query.skipCount = this.page.pageNumber * this.page.pageSize;
				return this.gmiNotificationsService.getNotificationsForUserByLanguageAndInputAndPage(this.currentLanguage, this.searchDto, query);
			};

			this.list.hookToQuery(eaDocumentsStreamCreator).subscribe((response) => {
				this.userNotifications = response;
				this.page.totalElements = response.totalCount;
				this.page.totalPages = response.totalCount / this.page.pageSize;
				this.userNotifications.items.forEach((x) => {
					x.text = new GmiStripHtml().transform(x.text, 100);
				});
				this.customMessages = GmiPrepareGridLocalization(this.localizationService, this.page);
				this.spinner.hide();
			});
		}
	}

	openModalFromHere(idNotification: number) {
		this.gmiNotificationsService.getNotificationByIdAndLanguage(idNotification, this.currentLanguage).subscribe((response) => {
			this.selectedNotification = response;
			this.ngbModal.open(this.templateRef, { size: 'lg' });
			if (!!!response.dateRead && response.typeCode == 'normal') {
				this.gmiNotificationsService.postMarkNotificationAsReadById(idNotification).subscribe((response) => {
					this.gmiNavbarService.setCntUnreadNotifications(this.cntNewNotifications - 1);
					this.loadGrid();
				});
			}
		});
	}

	openModal(event, content) {
		if (event.type == 'click') {
			event.cellElement.blur();
			this.gmiNotificationsService.getNotificationByIdAndLanguage(event.row.id, this.currentLanguage).subscribe((response) => {
				this.selectedNotification = response;
				this.ngbModal.open(content, { size: 'lg' });
				if (!!!event.row.dateRead && this.selectedNotification.typeCode != 'confirmation') {
					this.gmiNotificationsService.postMarkNotificationAsReadById(this.selectedNotification.id).subscribe((response) => {
						this.gmiNavbarService.setCntUnreadNotifications(this.cntNewNotifications - 1);
						this.loadGrid();
					});
				}
			});
		}
	}

	markAsRead() {
		this.gmiNotificationsService.postMarkNotificationAsReadById(this.selectedNotification.id).subscribe((response) => {
			if (response.isError) {
				Swal.fire({
					icon: 'error',
					title: response.errorMsg,
					showConfirmButton: false,
				});
			} else {
				this.Toast.fire({
					icon: 'success',
					title: this.localizationService.instant(WL.GmiNotifications_MarkedAsRead),
				}).then((result) => {
					this.selectedNotification.dateRead = moment().format();
					this.gmiNavbarService.setCntUnreadNotifications(this.cntNewNotifications - 1);
					this.loadGrid();
				});
			}
		});
	}

	downloadDataFile(id) {
		this.spinner.show('SPINNER_NOTIFICATIONS_MODAL');
		this.gmiNotificationsService.getNotificationSingleFileByIdNotificationAndIdFile(this.selectedNotification.id, id).subscribe((response) => {
			const imageBlob = GmiBase64ToBlob(response.content, response.mimeType);
			const imageFile = new File([imageBlob], response.fileName, { type: response.mimeType });
			this.spinner.hide('SPINNER_NOTIFICATIONS_MODAL');
			saveAs(imageFile);
		});
	}

	downloadAllDataFiles() {
		this.spinner.show('SPINNER_NOTIFICATIONS_MODAL');
		this.gmiNotificationsService.getNotificationAllFilesByIdNotification(this.selectedNotification.id).subscribe((response) => {
			const imageBlob = GmiBase64ToBlob(response.content, response.mimeType);
			const imageFile = new File([imageBlob], response.fileName, { type: response.mimeType });
			this.spinner.hide('SPINNER_NOTIFICATIONS_MODAL');
			saveAs(imageFile);
		});
	}

	onSort(event) {
		this.searchDto.orderBy = event.column.prop;
		this.searchDto.orderAscending = !!event.newValue && event.newValue == 'asc';
		this.loadGrid();
	}

	setPage(pageInfo) {
		this.page.pageNumber = pageInfo.offset;
		this.loadGrid();
	}

	filterResults(obj: any) {
		this.page.pageNumber = 0;
		this.searchDto = obj;
		this.loadGrid();
	}

	Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000,
		timerProgressBar: true,
	});

	closeModal() {
		this.ngbModal.dismissAll();
	}
}
