import type { GmiCoreInsuranceDto, GmiLesseeDocDeliveryChannelDto, IdTitleDtoString, IdTitleDtoStringExtra } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiRegistersService {
  apiName = 'Default';
  

  getGmiCoreCountries = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoString[]>({
      method: 'GET',
      url: '/api/app/gmi-registers/gmi-core-countries',
    },
    { apiName: this.apiName,...config });
  

  getGmiCoreInsurances = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiCoreInsuranceDto[]>({
      method: 'GET',
      url: '/api/app/gmi-registers/gmi-core-insurances',
    },
    { apiName: this.apiName,...config });
  

  getGmiCoreZipCodes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoStringExtra[]>({
      method: 'GET',
      url: '/api/app/gmi-registers/gmi-core-zip-codes',
    },
    { apiName: this.apiName,...config });
  

  getGmiCoreZipCodesSi = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoStringExtra[]>({
      method: 'GET',
      url: '/api/app/gmi-registers/gmi-core-zip-codes-si',
    },
    { apiName: this.apiName,...config });
  

  getGmiDocumentDeliveryChannels = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiLesseeDocDeliveryChannelDto[]>({
      method: 'GET',
      url: '/api/app/gmi-registers/gmi-document-delivery-channels',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
