import { Routes, RouterModule } from '@angular/router';
// GMI code
import { WP, WC } from '../../const/definitions';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/dashboard',
	},
	{
		path: 'calculation',
		loadChildren: () => import('../../gmi-calculation-fast/gmi-calculation-fast.module').then((m) => m.GmiCalculationFastModule),
	},
	{
		path: 'dashboard',
		loadChildren: () => import('../../gmi-dashboard/gmi-dashboard.module').then((m) => m.GmiDashboardModule),
	},
	{
		path: 'contracts',
		data:{
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Contracts,
			gmiMenuPolicy: ["isLessee"],
			gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
		},
		children: [
			{
				path: '',
				loadChildren: () => import('../../gmi-contracts/gmi-contracts.module').then((m) => m.GmiContractModule),
				data:{
					requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Contracts,
					gmiMenuPolicy: ["isLessee"],
					gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
				}
			},
			{
				path: 'contract',
				loadChildren: () => import('../../gmi-contract-dashboard/gmi-contract-dashboard.module').then((m) => m.GmiContractDashboardModule),
				data:{
					requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Contracts,
					gmiMenuPolicy: ["isLessee"],
					gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
				}

			},
		]
	},
	{
		path: 'payments',
		data:{
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Payments,
			gmiMenuPolicy: ["isLessee"],
			gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
		},
		children: [
			{
				path: '',
				loadChildren: () => import('../../gmi-payments/gmi-payments.module').then((m) => m.GmiPaymentsModule),
				data:{
					requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Payments,
					gmiMenuPolicy: ["isLessee"],
					gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
				}
			},
			{
				path: 'payment',
				loadChildren: () => import('../../gmi-payments-distributions/gmi-payments-distributions.module').then((m) => m.GmiPaymentsDistributionsModule),
				data:{
					requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Payments,
					gmiMenuPolicy: ["isLessee"],
					gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
				}
			},
		]
	},
	{
		path: 'profile',
		loadChildren: () => import('../../gmi-profile/gmi-profile.module').then((m) => m.GmiProfileModule),
	},
	{
		path: 'message',
		loadChildren: () => import('../../gmi-messaging/gmi-messaging.module').then((m) => m.GmiMessagingModule),
	},
	{
		path: 'missingDocuments',
		loadChildren: () => import('../../gmi-missing-documentation/gmi-missing-documentation.module').then((m) => m.GmiMissingDocumentationModule),
		data:{
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_MissingDocumentations,
			gmiMenuPolicy: ["isLessee"],
			gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
		}
	},
	{
		path: 'notification',
		loadChildren: () => import('../../gmi-notification/gmi-notification.module').then((m) => m.GmiNotificationModule),
	},
	{
		path: 'partner',
		loadChildren: () => import('../../gmi-partner/gmi-partner.module').then((m) => m.GmiPartnerModule),
		data: {
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Partner,
		}
	},
	{
		path: 'eaDocuments',
		loadChildren: () => import('../../gmi-ea-documents/gmi-ea-documents.module').then((m) => m.GmiEaDocumentsModule),
		data:{
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_EADocuments,
			gmiMenuPolicy: ["isLessee"],
			gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
		}
	},
	{
		path: 'overduePayments',
		loadChildren: () => import('../../gmi-overdue-payments/gmi-overdue-payments.module').then((m) => m.GmiOverduePaymentsModule),
		data: {
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_OverduePayments,
			gmiMenuPolicy: ["isLessee"],
			gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync]
		},
	},
	{
		path: 'help',
		loadChildren: () => import('../../gmi-help/gmi-help.module').then((m) => m.GmiHelpModule),
		data: {
			gmiMenuPolicy: ["showHelpMenu"]
		},
	},
	{
		path: 'insuranceClaims',
		loadChildren: () => import('../../gmi-insurance-claims/gmi-insurance-claims.module').then((m) => m.GmiInsClaimsModule),
		data: {
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_InsuranceClaims,
			gmiMenuPolicy: ["isLessee", "showInsuranceClaimsMenu"],
			gmiModulePolicy: [WC.GmiLicenceModules_DamageClaims]
		},
	},
	{
		path: 'stockFunding',
		loadChildren: () => import('../../gmi-stock-funding/gmi-stock-funding.module').then((m) => m.GmiStockFundingModule),
		data: {
			requiredPolicy: WP.PublicStockFundingGroup_PublicStockFunding,
			gmiMenuPolicy: ["isStockFunding"],
			gmiModulePolicy: [WC.GmiLicenceModules_StockFunding]
		},
	},
	{
		path: 'requests',
		loadChildren: () => import('../../gmi-requests/gmi-requests.module').then((m) => m.GmiRequestsModule),
		data: {
			requiredPolicy: WP.PublicRequestsGroup_PublicRequests
		},
	},
];
