import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GmiTokenService } from '../shared/services/gmiToken.service';

@Injectable()
export class GmiInterceptor implements HttpInterceptor {
	constructor(private gmiTokenService: GmiTokenService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.endsWith('connect/token')) {
			this.gmiTokenService.setLastApiCall();
		}
		return next.handle(req);
	}
}
