import type { BaseTypeEnum } from '../../../base-type-enum.enum';
import type { IdTitleDtoString, IdTitleIntWithDecimalDto, IdTitleWithDecimalDto } from '../../../models';

export interface BaseSettings {
  brutAmount: DecimalSetting;
  netAmount: DecimalSetting;
  loanAmount: DecimalSetting;
  calculationAmount: DecimalSetting;
  rInterestAmount: DecimalSetting;
}

export interface CalcTemplateObjectDto {
  id: number;
  name?: string;
  usedVehicle: boolean;
  howOldMax?: number;
  howOldMin?: number;
}

export interface CalculationSettingsDto {
  base: BaseTypeEnum;
  taxRate: SelectSettingsIntWithDecimal;
  baseSettings: BaseSettings;
  additionalObjectsAmount: DecimalSetting;
  newPurchaseValue: DecimalSetting;
  exchangeRate: SelectSettings;
  financingPeriod: SelectSettingsIntWithDecimal;
  paymentDynamic: SelectSettingsWithDecimal;
  subsidy: PercentAmountWithSelect;
  paymentAtStart: PercentAmountWithSelect;
  bail: PercentAmountWithSelect;
  downpayment: PercentAmountWithSelect;
  yearlyKmAmount: DecimalSetting;
  residual: PercentAmountWithSelect;
  interestRate: InterestRate;
  desiredEOM: DecimalSetting;
  beginEnd: SelectSettings;
  additionalServices: DecimalSetting;
  manipulativeCosts: PercentAmountWithSelect;
  claimInsuranceCosts: PercentAmountWithSelect;
  propertyInsuranceCosts: PercentAmountWithSelect;
  financeInsuranceCosts: PercentAmountWithSelect;
  extraCosts: PercentAmountWithSelect;
  moratorium: SelectSettingsWithDecimal;
  estimatedKm: DecimalSetting;
  installments: Installments;
  firstInstallment: FirstInstallment;
  lastInstallmentBuyout: LastInstallmentBuyout;
  docDateCreateType: SelectSettingsIntWithDecimal;
  multipleCalculation: MultipleCalculation;
  leaseSettings: LeaseSettings;
  fullPrice: DecimalSetting;
  engineCapacity: DecimalSetting;
  premiumRateFactor: DecimalSetting;
  currentKm: DecimalSetting;
  fictiveCosts: FictiveCost[];
  extraData: ExtraData;
  dependency: DependencySettings;
}

export interface CommonSettings {
  visible: boolean;
  enabled: boolean;
}

export interface DecimalSetting extends CommonSettings {
  min?: number;
  max?: number;
  default?: number;
  fixedValue: boolean;
  mandatory: boolean;
}

export interface DependencySettings {
  script?: string;
  customMessage?: string;
  allowCalculation: boolean;
}

export interface ExtraData {
  idFConditions: number;
  interestRateZero: boolean;
  disableMultiplePeriodCalculation: boolean;
  subsidyFullInterestRate: boolean;
  paymentsAtStartCalculate: number;
  bailSubtractFromResidualValue: boolean;
  residualValueFromDefinition: boolean;
  canConvertExchangeRate: boolean;
  totalAmountOfKilometers?: number;
  pricePerKilometer?: number;
  manipulativeCostsMaxEom: boolean;
  hasFullLease: boolean;
  moratoriumIrFromContract: boolean;
  moratoriumIr: number;
  moratoriumIrType?: string;
  leasingType?: string;
  coreEquipTypeId?: string;
  idGrupe?: string;
  tipIzracunaRobresti?: string;
}

export interface FictiveCost extends PercentAmountWithSelect {
  id: number;
  name?: string;
  repeatYearly: boolean;
  costType?: string;
}

export interface FirstInstallment {
  enabled: boolean;
  delayPeriod?: string;
  delayValue: number;
}

export interface Installments extends DecimalSetting {
  percentOf: number;
}

export interface InterestRate {
  index: SelectSettingsWithDecimal;
  margin: DecimalSetting;
  marginSelect: SelectSettingsWithDecimal;
  combined: DecimalSetting;
  limitTotalIr: boolean;
  maxEom: boolean;
}

export interface LastInstallmentBuyout {
  enabled: boolean;
  visible: boolean;
  value: boolean;
}

export interface LeaseSettings {
  nacinLeas?: string;
  tipKnjizenja?: string;
  leasKred?: string;
  dobrocno: boolean;
  davN?: string;
  davO?: string;
  davM?: string;
  davR?: string;
  davB?: string;
  finbruto: boolean;
  odstejVar: boolean;
  diskR: boolean;
  opcImaobr: boolean;
  nacinMs: number;
  imaOpcijo: boolean;
  dniZap: number;
  imaOststr: boolean;
  eomNeto: boolean;
  olNaNacinFl: boolean;
  imaRobresti: boolean;
  tipIzracunaRobresti?: string;
  eomZero: boolean;
  eomNpmZero: boolean;
  idDavStPlanpZeroTax?: string;
  manipulativeCostsTaxRate: number;
  manipulativeCostsTaxCode?: string;
}

export interface MultipleCalculation {
  maxCalculations: number;
  minPeriod: number;
  maxOds: number;
  minOdsPeriod: number;
}

export interface PercentAmountWithSelect {
  percentOf: number;
  percent: DecimalSetting;
  amount: DecimalSetting;
  select: SelectSettingsWithDecimal;
}

export interface SelectSettings extends CommonSettings {
  default?: string;
  values: IdTitleDtoString[];
}

export interface SelectSettingsIntWithDecimal extends CommonSettings {
  default?: number;
  values: IdTitleIntWithDecimalDto[];
}

export interface SelectSettingsWithDecimal extends CommonSettings {
  default?: string;
  values: IdTitleWithDecimalDto[];
}
