import type { GmiTwoFactorType } from './gmi-two-factor-type.enum';
import type { PagedResultDto } from '@abp/ng.core';
import type { RegisterExtraDataType } from './register-extra-data-type.enum';

export interface AmPlanData {
  datum_dok?: string;
  claim_type_desc?: string;
  zap_obr: number;
  debit: number;
  dat_zap?: string;
  davek: number;
  obresti: number;
  robresti: number;
  id_terj?: string;
}

export interface AmortizationPlanRecord {
  claimType?: string;
  datumDok?: string;
  zapObr: number;
  debit: number;
  datZap?: string;
  davek: number;
  obresti: number;
  robresti: number;
  idTerj?: string;
}

export interface AmountPercent {
  valueAmount?: number;
  valuePercent?: number;
  valueSelect?: string;
}

export interface AmountPercentValue {
  amount: number;
  percent: number;
}

export interface BrutNetValue {
  brut: number;
  net: number;
}

export interface CalculationFastStep1Dto {
  customerLegalTypeId: string;
  objectId?: number;
  firstRegistrationDate?: string;
  ddvIncluded?: boolean;
  finConditionId?: number;
}

export interface CalculationFastStep21DataDto extends CalculationFastStep1Dto {
  objectId: number;
  finConditionId: number;
}

export interface CalculationFastStep2DataDto {
  brutAmount?: number;
  netAmount?: number;
  loanAmount?: number;
  calculationAmount?: number;
  financingAmount?: number;
  rInterestAmount?: number;
  equipmentTaxRate?: string;
  leasingTaxRate?: string;
  taxRate?: number;
  newPurchaseValue?: number;
  premiumRateFactor?: number;
  exchangeRate?: string;
  financingPeriod: number;
  paymentDynamic?: string;
  subsidy: AmountPercent;
  paymentAtStart: AmountPercent;
  bail: AmountPercent;
  downpayment: AmountPercent;
  yearlyKmAmount?: number;
  residualValue: AmountPercent;
  interestRate: IrData;
  desiredEOM?: number;
  beginEnd?: string;
  additionalServicesAmount?: number;
  manipulativeCosts: AmountPercent;
  claimInsuranceCosts: AmountPercent;
  propertyInsuranceCosts: AmountPercent;
  financeInsuranceCosts: AmountPercent;
  extraCosts: AmountPercent;
  moratorium?: number;
  fullPrice?: number;
  engineCapacity?: number;
  currentKm?: number;
  buyoutOnLastInstallment?: boolean;
  docDateCreateType?: number;
  docDate?: string;
  creditWithCosts: boolean;
  mpcInstallment?: number;
  fictiveAmountPercent: FictiveAmountPercent[];
}

export interface CalculationFastStep2Dto {
  step1Data: CalculationFastStep21DataDto;
  step2Data: CalculationFastStep2DataDto;
}

export interface CalculationResultDto {
  installment: BrutNetValue;
  residualValue: BrutNetValue;
  downpayment: BrutNetValue;
  bail: BrutNetValue;
  price: BrutNetValue;
  manipulativeCosts: BrutNetValue;
  paymentsAtStart: BrutNetValue;
  inputPrice: number;
  contractAmount: number;
  financeAmount: number;
  interests: number;
  interestsMargin: number;
  taxBaseValue: number;
  vat: number;
  claimAmount: number;
  interestRateVat: number;
  interestRateActual: number;
  interestRateEffective: number;
  paymentsPerYear: number;
  claimInsuranceCosts: AmountPercentValue;
  remarksBl?: string;
  exRateWarning?: string;
  irWarning?: string;
  commission?: number;
  downpaymentPercent?: number;
  rInterests?: number;
  showRInterests: boolean;
  eomTooHigh: boolean;
  residualValuePercent?: number;
  paymentsAtStartPercent?: number;
  beginDate?: string;
  buyoutDate?: string;
  buyoutOnLastInstallment: boolean;
  docDateCreateType?: number;
  newPurchaseValue?: number;
  premiumRateFactor?: number;
  financeInsuranceCosts: AmountPercentValue;
  propertyInsuranceCosts: AmountPercentValue;
  extraCosts: AmountPercentValue;
  commissionIrInput?: number;
  amPlan: AmPlanData[];
  fictiveCosts: FictiveCostsData[];
  residualValuesAssetBased: ResidualValueData[];
  financeInterestRate: number;
  moratorium: number;
  financingCost: number;
  priceTwo: number;
  installmentCount: number;
  additionalServices: number;
  residualValueTwo: number;
  rebate: number;
  irIndexValue: number;
  gmiCalculationErrors: GmiCalculationErrors[];
  amortizationPlanRecords: AmortizationPlanRecord[];
}

export interface ContractAmPlanDto extends grp_PublicContractLesseeAmPlanTable1 {
}

export interface ContractDebtDto extends grp_PublicContractLesseeDebtTable0 {
}

export interface ContractDetailDto extends grp_PublicContractLesseeDetailTable0 {
}

export interface ContractGuarantorDto extends grp_PublicContractLesseeGuarantorsTable1 {
}

export interface ContractHeaderDto extends grp_PublicContractLesseeHeaderTable0 {
}

export interface ContractInterestRateDto extends grp_PublicContractLesseeInterestRateTable0 {
}

export interface ContractsDto extends grp_PublicContractsLesseeSearchTable1 {
}

export interface DefaultReturnData {
  id?: string;
  isError: boolean;
  errorMsg?: string;
  extraData?: string;
}

export interface DocumentContentsStringDto {
  fileName?: string;
  mimeType?: string;
  content?: string;
}

export interface ExternalFunctionsDto {
  id?: string;
  sqlCode?: string;
  inactive: boolean;
  lastChangeId: string;
  lastChangeComment: string;
  lastChangeDate?: string;
}

export interface FictiveAmountPercent extends AmountPercent {
  id: number;
}

export interface FictiveCostsData extends AmountPercent {
  fictiveCostType?: string;
}

export interface FileContentsDto {
  fileName?: string;
  mimeType?: string;
  content: number[];
  extension?: string;
}

export interface GetStockFundingDropDownDataDto {
  stockFundTemplateDropDown: IdTitleDtoInt[];
  templateLesseeType: GetTemplateLesseeTypeDto[];
  idMake: IdTitleDtoString[];
  codeMake: IdTitleDtoString[];
}

export interface GetTemplateLesseeTypeDto {
  id: number;
  tittle?: string;
  coreTax: number;
}

export interface GmiAdminMailTableDto {
  id: number;
  sentTo?: string;
  sent: boolean;
  subject?: string;
  dateInserted?: string;
}

export interface GmiAdminPartnersDto {
  id?: string;
  isVendor: boolean;
  isVendorPseudo: boolean;
  isLessee: boolean;
  isInsurance: boolean;
  isStockFunding: boolean;
  idKupca?: string;
  idInsurance?: string;
  idRecoveryAgency?: string;
  title: string;
  address?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  email?: string;
  idStyle: number;
  unit?: string;
  idCommission: number;
  comment?: string;
  readOnly: boolean;
  dateModified?: string;
  inactive: boolean;
}

export interface GmiBlock {
  id: number;
  blockCode?: string;
  blockTitle?: string;
  personalizationType?: number;
  gmiPersonalizations: GmiPersonalization[];
  gmiReports: GmiReport[];
  gmiRequestDefinitionsPages: GmiRequestDefinitionsPage[];
  personalizationTypeNavigation: GmiPersonalizationType;
}

export interface GmiBlockSearchDto {
  searchCriteria?: string;
  blockId?: number;
  orderBy?: string;
  orderAscending: boolean;
  showInactive: boolean;
}

export interface GmiBlocksDto {
  id: number;
  blockCode?: string;
  blockTitle?: string;
  personalizationType: number;
}

export interface GmiCalculationErrors {
  fieldId?: string;
  errorMsg?: string;
  percentToBig?: boolean;
  amountToBig?: boolean;
  limit?: number;
}

export interface GmiCheckClaimsInputDto {
  damageDate?: string;
  chassisNumb?: string;
  insuranceCase?: string;
}

export interface GmiCoreInsurance {
  idZav?: string;
  naziv?: string;
  ulica?: string;
  idPoste?: string;
  telefon?: string;
  idKupca?: string;
  opomba?: string;
  inactive: boolean;
  gmiInsuranceClaims: GmiInsuranceClaim[];
  gmiPartners: GmiPartner[];
  id?: string;
}

export interface GmiCoreInsuranceDto {
  id?: string;
  naziv?: string;
  inactive: boolean;
}

export interface GmiCoreLesseeDocDeliveryChannel {
  id: number;
  opis?: string;
  pKontaktVlogaSifra?: string;
  obvezenEmail: boolean;
  gmiPartners: GmiPartner[];
}

export interface GmiCoreRecoveryAgenciesDto {
  id?: string;
  opis?: string;
  inactive: boolean;
}

export interface GmiCoreRecoveryAgency {
  idAgenc?: string;
  opis?: string;
  idKupca?: string;
  opravljaTelefonskoIzterjavo: boolean;
  interno: boolean;
  inactive: boolean;
  gmiPartners: GmiPartner[];
  id?: string;
}

export interface GmiCoreSeller {
  idSeller: number;
  vendorCoreId?: string;
  vendorName?: string;
  vendorAddress?: string;
  sellerCoreId?: string;
  sellerName?: string;
  inactive: boolean;
  idProd1?: string;
  getsCommission: boolean;
  phone?: string;
  email?: string;
  gsm?: string;
  gmiUsers: GmiUser[];
  id: number;
}

export interface GmiCoreSellersDto {
  id: number;
  sellerName?: string;
  idProd1?: string;
}

export interface GmiCustomTranslation {
  id: number;
  title?: string;
  textEn?: string;
  textSl?: string;
  textHr?: string;
  textSrC?: string;
  textSrL?: string;
  textMk?: string;
  inactive: boolean;
}

export interface GmiCustomTranslationsSearchParamsDto {
  searchCriteria?: string;
  showInactive: boolean;
  orderBy?: string;
  orderAscending: boolean;
}

export interface GmiEaDocumentDto extends grp_PublicEaDocumentsLesseeSearchTable1 {
}

export interface GmiExternalFunctionResultsDto {
  id?: string;
  key?: string;
  value?: string;
}

export interface GmiFavorite {
  id: number;
  userId?: string;
  linkName?: string;
  linkUrl?: string;
  linkOrder: number;
  user: GmiUser;
}

export interface GmiFavoritesDto {
  id: number;
  linkName?: string;
  linkUrl?: string;
  linkOrder: number;
}

export interface GmiForgotPasswordDto {
  username?: string;
  email?: string;
}

export interface GmiForgotPasswordResponseDto {
  mustEnterUsername: boolean;
  mustEnterEmail: boolean;
  maskedEmail?: string;
  errorMessage?: string;
}

export interface GmiGetInsuranceClaimsDto extends grp_PublicInsuranceClaimsSearchTable1 {
}

export interface GmiGetStockFundingDto extends grp_PublicStockFundingSearchTable1 {
}

export interface GmiInsuranceClaim {
  id: number;
  dateInserted?: string;
  idKupca?: string;
  damageDate?: string;
  insuranceDate?: string;
  chassisNumb?: string;
  insuranceAmount?: number;
  insuranceCase?: string;
  pdfApproval?: string;
  idZav?: string;
  pdfIdKupca?: string;
  userId?: string;
  idZavNavigation: GmiCoreInsurance;
}

export interface GmiInsuranceClaimsFormDto {
  id: number;
  dateInserted?: string;
  idKupca?: string;
  damageDate?: string;
  insuranceDate?: string;
  chassisNumb?: string;
  insuranceAmount?: number;
  insuranceCase?: string;
  status?: string;
  idZav?: string;
  pdfIdKupca?: string;
  pdfApproval?: string;
  pdfExists: boolean;
  isError: boolean;
  errorMsg?: string;
  insuranceStatus?: string;
  statusTxt?: string;
}

export interface GmiInsuranceClaimsSettingsDto {
  companyText?: string;
  errorText?: string;
}

export interface GmiJobHistSearchParamsDto {
  from?: string;
  to?: string;
  searchCriteria?: string;
  orderBy?: string;
  orderAscending: boolean;
}

export interface GmiLesseeDocDeliveryChannelDto {
  id: number;
  title?: string;
  mailMandatory: boolean;
}

export interface GmiLicenceInfoDto {
  companyName?: string;
  coreContractQuota: number;
  currentCoreContracts: number;
  offerQuota: number;
  currentOffers: number;
  expiresOn?: string;
  modules: string[];
  messages: string[];
  dbVersion?: string;
  html?: string;
  pluginModules: string[];
}

export interface GmiLoginDto {
  username: string;
  password: string;
  twoFactorCode?: string;
}

export interface GmiLoginReturnDto {
  isError: boolean;
  errorMsg: string;
  mustRegister: boolean;
  mustChangePassword: boolean;
  mustEnterTwoFactor: boolean;
  twoFactorSetup: GmiTwoFactorType;
  id?: string;
  email?: string;
  token?: string;
  emailConfirmationSent: boolean;
  twoFactorEmailSent: boolean;
}

export interface GmiMailAttachmentDto {
  id?: string;
  gmiMailId?: string;
  name?: string;
}

export interface GmiMailDto {
  id: number;
  sentFrom?: string;
  sentTo?: string;
  sentCc?: string;
  sentBcc?: string;
  subject?: string;
  body?: string;
  sent: boolean;
  dateSent?: string;
  error?: string;
  dateError?: string;
  dateInserted?: string;
  gmiMailAttachments: GmiMailAttachmentDto[];
}

export interface GmiMailSearchParamsDto {
  dateInsertedFrom?: string;
  dateInsertedTo?: string;
  searchCriteria?: string;
  userMail?: string;
}

export interface GmiMessageConversation {
  id: number;
  msgSubject?: string;
  msgStatus?: string;
  msgCategory?: string;
  msgConversationDeleted: boolean;
  gmiMessageDataFiles: GmiMessageDataFile[];
  gmiMessageTexts: GmiMessageText[];
}

export interface GmiMessageConversationDataDto extends grp_GetMessageConversationForUserTable1 {
}

export interface GmiMessageConversationText {
  id: number;
  msgText?: string;
  msgSender?: string;
  msgDate?: string;
  isSender: boolean;
}

export interface GmiMessageConversationsDto {
  id: number;
  msgSubject?: string;
  msgStatus?: string;
  msgCategory?: string;
  msgText?: string;
  msgConversationDeleted: boolean;
  msgRecipients: string[];
  texts: GmiMessageConversationText[];
}

export interface GmiMessageDataFile {
  id: number;
  idConversation: number;
  idText: number;
  fileName?: string;
  content: number[];
  mimeType?: string;
  idConversationNavigation: GmiMessageConversation;
  idTextNavigation: GmiMessageText;
}

export interface GmiMessageStatus {
  id: number;
  msgUser?: string;
  msgIsSender: boolean;
  dateRead?: string;
  gmiMessageTextId: number;
  gmiMessageText: GmiMessageText;
}

export interface GmiMessageText {
  id: number;
  msgText?: string;
  dateInserted?: string;
  msgTextDeleted: boolean;
  gmiMessageConversationId?: number;
  gmiMessageConversation: GmiMessageConversation;
  gmiMessageDataFiles: GmiMessageDataFile[];
  gmiMessageStatuses: GmiMessageStatus[];
}

export interface GmiMessageTextsDto {
  idConversation: number;
  msgText?: string;
}

export interface GmiNavbarDto {
  cntUnreadMessages: number;
  cntMissingDocs: number;
  cntNewDocuments: number;
  cntNewNotifications: number;
  userName?: string;
  email?: string;
  actualUserName?: string;
}

export interface GmiNotification {
  id: number;
  dateValidFrom?: string;
  dateValidTo?: string;
  title?: string;
  text?: string;
  inactive: boolean;
  dateInserted?: string;
  dateModified?: string;
  userModified?: string;
  type: number;
  textSl?: string;
  textHr?: string;
  textSrC?: string;
  textSrL?: string;
  textMk?: string;
  gmiNotificationDataFiles: GmiNotificationDataFile[];
  gmiNotificationReads: GmiNotificationRead[];
  gmiNotificationRecipients: GmiNotificationRecipient[];
  typeNavigation: GmiNotificationType;
}

export interface GmiNotificationAdminDto {
  id: number;
  title: string;
  text?: string;
  dateValidFrom?: string;
  dateValidTo?: string;
  dateModified?: string;
  dateInserted?: string;
  inactive: boolean;
  userModified?: string;
  userModifiedName?: string;
  type: number;
  typeName?: string;
  alreadyRead: boolean;
  recipientPartner: string[];
  recipientRole: string[];
  existingFiles: IdTitleDtoInt[];
  newFiles: UploadFileDto[];
  deleteFiles: number[];
  textSl?: string;
  textHr?: string;
  textSrC?: string;
  textSrL?: string;
  textMk?: string;
}

export interface GmiNotificationAllDto extends grp_AdminNotificationsTable1 {
}

export interface GmiNotificationDataFile {
  id: number;
  idNotification: number;
  fileName?: string;
  content: number[];
  mimeType?: string;
  idNotificationNavigation: GmiNotification;
}

export interface GmiNotificationDto {
  id: number;
  title?: string;
  text?: string;
  dateRead?: string;
  dateInserted?: string;
  typeCode?: string;
  dataFiles: IdTitleDtoInt[];
}

export interface GmiNotificationForUserDto extends grp_PublicNotificationsSearchTable1 {
}

export interface GmiNotificationRead {
  id: number;
  idNotification: number;
  userId?: string;
  dateRead?: string;
  idNotificationNavigation: GmiNotification;
  user: GmiUser;
}

export interface GmiNotificationRecipient {
  id: number;
  idNotification: number;
  userOrGroupId?: string;
  idNotificationNavigation: GmiNotification;
}

export interface GmiNotificationType {
  id: number;
  typeCode?: string;
  typeName?: string;
  gmiNotifications: GmiNotification[];
}

export interface GmiNotificationTypeDto {
  id: number;
  typeCode?: string;
  typeName?: string;
}

export interface GmiNotificationsSearchParamsDto {
  searchCriteria?: string;
  typeId?: number;
  showInactive: boolean;
  orderBy?: string;
  orderAscending: boolean;
}

export interface GmiPagedResultDto<T> extends PagedResultDto<T> {
  hiddenFields: string[];
}

export interface GmiPartner {
  id?: string;
  isVendor: boolean;
  isVendorPseudo: boolean;
  isLessee: boolean;
  isInsurance: boolean;
  isStockFunding: boolean;
  idKupca?: string;
  idInsurance?: string;
  idRecoveryAgency?: string;
  title?: string;
  address?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  email?: string;
  unit?: string;
  idCommission?: number;
  comment?: string;
  readOnly: boolean;
  docDeliveryChannelId?: number;
  docDeliveryNotificationEmail?: string;
  docDeliveryNotifyByEmail: boolean;
  docDeliveryChannelCustomMsg?: string;
  dateModified?: string;
  inactive: boolean;
  idStyle: number;
  docDeliveryChannel: GmiCoreLesseeDocDeliveryChannel;
  gmiUsers: GmiUser[];
  idInsuranceNavigation: GmiCoreInsurance;
  idRecoveryAgencyNavigation: GmiCoreRecoveryAgency;
  idStyleNavigation: GmiStyle;
}

export interface GmiPartnerSearchInputDto {
  title?: string;
  id: string[];
}

export interface GmiPartnersSearchDto extends grp_AdminGmiPartnersSearchTable1 {
}

export interface GmiPartnersSearchParamsDto extends GmiSearchDto {
  isVendor: boolean;
  isVendorPseudo: boolean;
  isLessee: boolean;
  isInsurance: boolean;
  isStockFunding: boolean;
  isRecoveryAgency: boolean;
}

export interface GmiPartnersSelectDto extends grp_GetPartnersForSelectTable0 {
}

export interface GmiPasswordChangeDto {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface GmiPersonalization {
  id: number;
  blockId?: number;
  typeId: number;
  title?: string;
  sqlCondition?: string;
  definition?: string;
  sortOrder: number;
  inactive: boolean;
  block: GmiBlock;
}

export interface GmiPersonalizationBlockAndTypeDto {
  blockCode?: string;
  blockTitle?: string;
  personalizationType: number;
  personalizationTypeName?: string;
  personalizationTypeLabel?: string;
  sqlConditionFieldMandatory: boolean;
  sqlConditionFieldVisible: boolean;
  personalizationDefFieldMandatory: boolean;
  personalizationDefFieldVisible: boolean;
  blockId: number;
}

export interface GmiPersonalizationDto {
  id: number;
  blockId?: number;
  typeId: number;
  title: string;
  sqlCondition?: string;
  definition?: string;
  sortOrder: number;
  inactive: boolean;
}

export interface GmiPersonalizationSearchDto extends grp_AdminGmiPersonalizationsSearchTable1 {
}

export interface GmiPersonalizationType {
  id: number;
  personalizationTypeName?: string;
  personalizationTypeLabel?: string;
  sqlConditionFieldMandatory: boolean;
  sqlConditionFieldVisible: boolean;
  personalizationDefFieldMandatory: boolean;
  personalizationDefFieldVisible: boolean;
  gmiBlocks: GmiBlock[];
}

export interface GmiPersonalizationsSearchDto extends GmiBlockSearchDto {
  idType?: number;
}

export interface GmiProfileDataDto {
  gmiProfileDto: GmiProfileDto;
  gmiProfileDocumentDeliveryDto: GmiProfileDocumentDeliveryDto;
}

export interface GmiProfileDocumentDeliveryDto {
  docDeliveryChannelId?: number;
  docDeliveryNotificationEmail?: string;
  docDeliveryNotifyByEmail: boolean;
  docDeliveryChannelCustomMsg?: string;
}

export interface GmiProfileDto {
  email?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  title?: string;
}

export interface GmiRegisterDataDto {
  isError: boolean;
  email?: string;
  username?: string;
  type: RegisterExtraDataType;
}

export interface GmiRegistrationDto {
  username: string;
  email: string;
  id: string;
  type: RegisterExtraDataType;
}

export interface GmiReport {
  id: number;
  blockId: number;
  title?: string;
  description?: string;
  inactive: boolean;
  sortOrder: number;
  sqlCondition?: string;
  reportType?: string;
  renderType?: string;
  novaReportName?: string;
  novaReportKeySql?: string;
  content: number[];
  block: GmiBlock;
}

export interface GmiReportArchiveDto {
  id: number;
  title?: string;
  description?: string;
  time?: string;
}

export interface GmiReportDto {
  id: number;
  blockId: number;
  title: string;
  description: string;
  inactive: boolean;
  sortOrder: number;
  sqlCondition?: string;
  reportType?: string;
  renderType?: string;
  novaReportName?: string;
  novaReportKeySql?: string;
  content: number[];
  reportMimeType?: string;
}

export interface GmiReportMailDto {
  reportId: number;
  id?: string;
  to: string;
  cc?: string;
  subject: string;
  body: string;
}

export interface GmiReportsSearchDto extends grp_AdminGmiReportsSearchTable1 {
}

export interface GmiRequest {
  id: number;
  idRequestDefinition: number;
  requestData?: string;
  userId?: string;
  dateInserted?: string;
  gmiId?: string;
  internalId?: string;
  gmiRequestDataFiles: GmiRequestDataFile[];
  idRequestDefinitionNavigation: GmiRequestDefinition;
}

export interface GmiRequestDataFile {
  id: number;
  idRequest: number;
  fileName?: string;
  content: number[];
  mimeType?: string;
  dateInserted?: string;
  idRequestNavigation: GmiRequest;
}

export interface GmiRequestDefinition {
  id: number;
  title?: string;
  requestDefinition?: string;
  requestDestination?: string;
  requestFormat?: string;
  inactive: boolean;
  description?: string;
  idProcess?: string;
  sqlCondition?: string;
  bpmGroup?: string;
  gmiRequestDefinitionsPages: GmiRequestDefinitionsPage[];
  gmiRequests: GmiRequest[];
  idProcessNavigation: GmiWfProcess;
}

export interface GmiRequestDefinitionDto extends GmiRequestDefinition {
  requestPages: number[];
}

export interface GmiRequestDefinitionsDto extends grp_AdminGmiRequestDefinitionsSearchTable1 {
}

export interface GmiRequestDefinitionsPage {
  id: number;
  definitionId: number;
  blockId: number;
  block: GmiBlock;
  definition: GmiRequestDefinition;
}

export interface GmiRequestExecutionDto {
  id: number;
  destination?: string;
  formData: object;
  idCont?: number;
  documents: UploadFileDto[];
}

export interface GmiRequestsSentDto extends grp_AdminGmiRequestsSearchTable1 {
}

export interface GmiSearchDto {
  searchCriteria?: string;
  dateFrom?: string;
  dateTo?: string;
  checkBoxValue: boolean;
  orderBy?: string;
  orderAscending: boolean;
  showInactive: boolean;
}

export interface GmiSearchWithIntIdDto extends GmiSearchDto {
  id?: number;
}

export interface GmiSettingUpdateDto {
  name?: string;
  value?: string;
}

export interface GmiSettingsDto {
  name?: string;
  value?: string;
  description?: string;
  isHtml: boolean;
  isJson: boolean;
  isBoolean: boolean;
  isFromNova: boolean;
}

export interface GmiStockFundingFormDto {
  id: number;
  idAbpUserInserted?: string;
  dateInserted?: string;
  vendor?: string;
  vendorUser?: string;
  idStockFundingTemplate: number;
  valueStockFundingTemplate?: string;
  idMake?: string;
  model?: string;
  vehicleType?: string;
  numberOfKilometers: number;
  priceNet: number;
  priceBrut: number;
  yearManufactured: number;
  chassisNumb?: string;
  status?: string;
  message?: string;
  error?: string;
  dateBegin?: string;
  dateEnd?: string;
}

export interface GmiStyle {
  id: number;
  title?: string;
  favicon?: string;
  faviconType?: string;
  logo?: string;
  logoType?: string;
  loginVertical?: string;
  loginVerticalType?: string;
  loginHorizontal?: string;
  loginHorizontalType?: string;
  colorPrimary?: string;
  colorLight?: string;
  colorGradient1?: string;
  colorGradient2?: string;
  isDefault: boolean;
  inactive: boolean;
  colorSuccess?: string;
  colorInfo?: string;
  colorWarning?: string;
  colorDanger?: string;
  colorMandatory?: string;
  textMenu?: string;
  textNavbar?: string;
  appTitle?: string;
  noInputShading: boolean;
  gmiPartners: GmiPartner[];
}

export interface GmiStyleDto {
  id: number;
  title?: string;
  favicon: UploadFileDto;
  logo: UploadFileDto;
  loginVertical: UploadFileDto;
  loginHorizontal: UploadFileDto;
  colorPrimary?: string;
  colorLight?: string;
  colorSuccess?: string;
  colorInfo?: string;
  colorWarning?: string;
  colorDanger?: string;
  colorMandatory?: string;
  colorGradient1?: string;
  colorGradient2?: string;
  isDefault: boolean;
  inactive: boolean;
  textMenu?: string;
  textNavbar?: string;
  appTitle?: string;
  cssStyle?: string;
  noInputShading: boolean;
}

export interface GmiTwoFactorSettingsDto {
  twoFactorType?: number;
  twoFactorTypes: GmiTwoFactorType[];
  setOnRole: boolean;
}

export interface GmiUser {
  id?: string;
  idAbpUser?: string;
  idPartner?: string;
  idKupca?: string;
  idKupca2?: string;
  idProd?: number;
  title?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  email?: string;
  comment?: string;
  ip?: string;
  firstPage?: string;
  nextPasswordChange?: string;
  isSupport: boolean;
  twoFactorType?: number;
  twoFactorCode?: string;
  registrationDate?: string;
  gmiFavorites: GmiFavorite[];
  gmiNotificationReads: GmiNotificationRead[];
  idPartnerNavigation: GmiPartner;
  idProdNavigation: GmiCoreSeller;
}

export interface GmiUserHistoryAdminDto {
  creationTime?: string;
  action?: string;
  extraData?: string;
}

export interface GmiUserPasswordResetDto {
  idUser: string;
  resetPassword: string;
  resetPasswordConfirm: string;
}

export interface GmiUsersAdminDto {
  id?: string;
  idAbpUser?: string;
  idPartner: string;
  idPartnerTitle?: string;
  idKupca?: string;
  idKupca2?: string;
  idProd?: number;
  title: string;
  phoneNumber?: string;
  mobileNumber?: string;
  email?: string;
  comment?: string;
  ip?: string;
  firstPage?: string;
  nextPasswordChange?: string;
  userName: string;
  password?: string;
  lockoutEnabled: boolean;
  inactive: boolean;
  userRoles: string[];
  emailConfirmed: boolean;
}

export interface GmiUsersHistSearchParamsDto {
  createdTimeFrom?: string;
  createdTimeTo?: string;
}

export interface GmiUsersSearchDto extends grp_AdminGmiUsersSearchTable1 {
}

export interface GmiUsersSearchParamsDto extends GmiSearchDto {
  idPartner?: string;
  idRole?: string;
}

export interface GmiValidateOTPDto {
  id: string;
  iid: string;
  receiver: string;
  otp: string;
}

export interface GmiWfDocument {
  id: number;
  idProcess?: string;
  entityId?: number;
  dateWfStarted?: string;
  dateWfFinished?: string;
  idStatus: number;
  dateLastStatusChange?: string;
  dateLastChange?: string;
  userLastChange?: string;
  userInserted?: string;
  currentComment?: string;
  gmiWfDocumentHistories: GmiWfDocumentHistory[];
  idProcessNavigation: GmiWfProcess;
  idStatusNavigation: GmiWfStatus;
}

export interface GmiWfDocumentChangeDto {
  idProcess: string;
  entityId: number;
  statusCode: string;
  comment?: string;
}

export interface GmiWfDocumentDto {
  idProcess: string;
  entityId: number;
  statusCode: string;
  comment?: string;
}

export interface GmiWfDocumentHistory {
  id: number;
  idDocument: number;
  dateInserted?: string;
  userInserted?: string;
  idStatus: number;
  comment?: string;
  idDocumentNavigation: GmiWfDocument;
  idStatusNavigation: GmiWfStatus;
}

export interface GmiWfProcess {
  id?: string;
  title?: string;
  description?: string;
  inactive: boolean;
  gmiRequestDefinitions: GmiRequestDefinition[];
  gmiWfDocuments: GmiWfDocument[];
}

export interface GmiWfProcessDto {
  id: string;
  title: string;
  description: string;
  inactive: boolean;
}

export interface GmiWfProcessTransitionDataDto {
  idProcess?: string;
  startStatus: number;
  statusTransitions: GmiWfStatusTransitionsDto[];
  statusTransitionsDeleted: number[];
}

export interface GmiWfStatus {
  id: number;
  statusCode?: string;
  title?: string;
  description?: string;
  translation?: string;
  isStart: boolean;
  isEnd: boolean;
  backgroundColor?: string;
  visibleToUser: boolean;
  statusComment?: string;
  inactive: boolean;
  gmiWfDocumentHistories: GmiWfDocumentHistory[];
  gmiWfDocuments: GmiWfDocument[];
  gmiWfStatusTransitionIdStatusNewNavigations: GmiWfStatusTransition[];
  gmiWfStatusTransitionIdStatusOldNavigations: GmiWfStatusTransition[];
}

export interface GmiWfStatusDto {
  id?: number;
  statusCode: string;
  title: string;
  description: string;
  translation?: string;
  isStart: boolean;
  isEnd: boolean;
  backgroundColor?: string;
  visibleToUser: boolean;
  statusComment?: string;
  inactive: boolean;
}

export interface GmiWfStatusTransition {
  id: number;
  idProcess?: string;
  idStatusOld: number;
  idStatusNew: number;
  defaultComment?: string;
  idStatusNewNavigation: GmiWfStatus;
  idStatusOldNavigation: GmiWfStatus;
}

export interface GmiWfStatusTransitionsDto {
  id?: number;
  idStatusOld: number;
  idStatusNew: number;
  defaultComment?: string;
}

export interface GuidTitleDto extends TitleDto {
  id?: string;
}

export interface IdTitleDtoInt extends TitleDto {
  id: number;
}

export interface IdTitleDtoString extends TitleDto {
  id?: string;
}

export interface IdTitleDtoStringExtra extends IdTitleDtoString {
  extraData?: string;
}

export interface IrData {
  irIndex?: string;
  irMargin?: number;
  irMarginSelect?: string;
  irCombined?: number;
}

export interface JobHistoryDto {
  id: number;
  idBackGroundWorker?: string;
  startTime?: string;
  endTime?: string;
  error?: string;
}

export interface JobHistorySearchDto extends grp_AdminGmiJobHistorySearchTable1 {
}

export interface JobsDto {
  id?: string;
  description?: string;
  inactive: boolean;
  nextRunTime?: string;
  timeToNextRuntime: number;
  error?: string;
  fromTimeHours?: string;
  fromTimeMinutes?: string;
  toTimeHours?: string;
  toTimeMinutes?: string;
}

export interface JobsSearchDto extends grp_AdminGmiJobsSearchTable1 {
}

export interface LesseeDashboardContractDto extends grp_PublicDashboardLesseeTable1 {
}

export interface LesseeDashboardDto extends grp_PublicDashboardLesseeTable0 {
  contracts: LesseeDashboardContractDto[];
  hiddenFields: string[];
}

export interface LesseeMissingDocumentsDto extends grp_PublicMissingDocumentationLesseeSearchTable1 {
}

export interface LesseePartnerDataDto extends grp_PublicPartnerLesseeTable0 {
}

export interface LesseePartnerKontaktDto extends grp_PublicPartnerKontaktTable0 {
  hideFields: string[];
}

export interface LesseePartnerTrrDto extends grp_PublicPartnerTrrTable0 {
  hideFields: string[];
}

export interface LogsDto {
  name?: string;
  lastWriteTime?: string;
}

export interface LogsListDto {
  adminLogs: LogsDto[];
  publicLogs: LogsDto[];
}

export interface MenuSettingsDto extends grp_PublicGetMenuSettingsTable0 {
  companyCode?: string;
}

export interface OverduePaymentsDto extends grp_PublicOverduePaymentsLesseeSearchTable1 {
}

export interface OverduePaymentsSummaryDto extends grp_PublicOverduePaymentsSummaryLesseeTable0 {
}

export interface PartnerDataDto {
  taxNumber: string;
  datoOfBirth: string;
}

export interface PartnerReturnDataDto {
  idKupca?: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  eMail?: string;
  address?: string;
  addressZipCode?: string;
  addressCountry?: string;
  documentType?: string;
  documentNumber?: string;
  documentIssuer?: string;
  documentIssueDate?: string;
  documentValidDate?: string;
}

export interface PasswordResetDto {
  email: string;
  token: string;
  password: string;
  passwordRepeat: string;
}

export interface PaymentDistributionListDto extends grp_PublicPaymentDistributionLesseeSearchTable1 {
}

export interface PaymentListDto extends grp_PublicPaymentsLesseeSearchTable1 {
}

export interface PaymentSummaryDto {
  paymentDate?: string;
  bookingDate?: string;
  paymentAmount: number;
  idVal?: string;
}

export interface PeriodPercentPair {
  period: number;
  percent: number;
}

export interface PublicRequestDto extends grp_PublicGetRequestsForPageTable0 {
}

export interface PublicRequestHistoryDto {
  summary: PublicRequestHistorySummaryDto;
  workflow: PublicRequestHistoryWorkflowDto[];
}

export interface PublicRequestHistorySummaryDto extends grp_PublicGmiRequestHistoryTable0 {
}

export interface PublicRequestHistoryWorkflowDto extends grp_PublicGmiRequestHistoryTable1 {
}

export interface QrCodeGeneratorDto {
  qrCode?: string;
  qrKey?: string;
  id?: string;
}

export interface ResidualValueData {
  valueAmount?: number;
  valuePercent?: number;
  periodPercentPairs: PeriodPercentPair[];
}

export interface SisumApplicationData {
  sisumApplicationPortalDataDto: SisumApplicationPortalDataDto;
  step0Data: Step0Data;
  step1Data: Step1Data;
  step2Data: Step2Data;
  step3Data: Step3Data;
}

export interface SisumApplicationPortalDataDto {
  idDataOffer: number;
  username?: string;
  sellerName?: string;
  totalValueAmount: number;
  installmentAmount: number;
  objectDescription?: string;
  sellerEmail?: string;
  sellerIdKupca?: string;
  vendorIdKupca?: string;
}

export interface SisumApplicationPortalInitialDataDto {
  id?: string;
  iid?: string;
  guarantor: boolean;
}

export interface SisumApplicationSavedDataDto {
  applicationId?: string;
  step: number;
  message?: string;
  sisumApplicationData: SisumApplicationData;
}

export interface SisumApplicationStep0Dto {
  applicationId: string;
  step0Data: Step0Data;
  documents: DocumentContentsStringDto[];
}

export interface SisumApplicationStep1Dto {
  applicationId: string;
  step1Data: Step1Data;
}

export interface SisumApplicationStep2Dto {
  applicationId: string;
  step2Data: Step2Data;
}

export interface SisumApplicationStep3Dto {
  applicationId: string;
  step3Data: Step3Data;
}

export interface SisumApplicationStep4Dto {
  applicationId: string;
  documents: DocumentContentsStringDto[];
}

export interface Step0Data {
  partnerRole?: string;
  taxNumber?: string;
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  mobilePhoneCode?: string;
  mobilePhoneConfirmationId?: string;
  eMail?: string;
  emailConfirmationId?: string;
  address?: string;
  addressZipCode?: string;
  addressCountry?: string;
  documentType?: string;
  documentNumber?: string;
  documentIssuer?: string;
  documentIssueDate?: string;
  documentValidDate?: string;
  documentValidForever: boolean;
  setcceSignType?: string;
  hasGuarantor: boolean;
  continueSeller: boolean;
  sellerDocuments: IdTitleDtoInt[];
}

export interface Step1Data {
  mobilePhoneCodeStep1?: string;
  mobilePhoneConfirmationIdStep1?: string;
  placeOfBirth?: string;
  citizenship?: string;
  validDriversLicence: boolean;
  tempResidence?: boolean;
  tempResidenceAddress?: string;
  tempResidencePost?: string;
  tempResidenceCountry?: string;
  mailToPermanentAddress?: boolean;
  gdprSummit: boolean;
  gdprIntermediary: boolean;
}

export interface Step2Data {
  employmentStatus?: string;
  employmentDescription?: string;
  employmentType?: string;
  employmentForever?: boolean;
  employmentDateStart?: string;
  employmentDateEnd?: string;
  employmentPosition?: string;
  employmentEmployerTitle?: string;
  employmentEmployerAddress?: string;
  employmentEmployerPost?: string;
  employmentEmployerCountry?: string;
  pio: boolean;
  pioFunction?: string;
  pioSources?: string;
  pioFinancialStatus?: string;
}

export interface Step3Data {
  paymentType?: string;
  paymentDayInMonth?: number;
  sendDocumentsByMail: boolean;
  trrLocal: boolean;
  trr?: string;
}

export interface TitleDto {
  title?: string;
}

export interface UploadFileDto {
  id?: number;
  fileName?: string;
  content?: string;
  mimeType?: string;
}

export interface UserRequestsDto extends grp_PublicGmiRequestsSearchTable1 {
}

export interface grp_AdminGmiJobHistorySearchTable1 {
  id: number;
  idBackGroundWorker?: string;
  startTime?: string;
  endTime?: string;
  error?: string;
}

export interface grp_AdminGmiJobsSearchTable1 {
  id?: string;
  description?: string;
  inactive?: boolean;
  nextRunTime?: string;
  timeToNextRuntime?: number;
  error?: string;
  fromTime?: string;
  toTime?: string;
}

export interface grp_AdminGmiPartnersSearchTable1 {
  id?: string;
  title?: string;
  datRoj?: string;
  idKupca?: string;
  isVendor?: boolean;
  isVendorPseudo?: boolean;
  isLessee?: boolean;
  isInsurance?: boolean;
  isStockFunding?: boolean;
  isRecoveryAgency?: boolean;
  inactive?: boolean;
}

export interface grp_AdminGmiPersonalizationsSearchTable1 {
  id: number;
  title?: string;
  blockTitle?: string;
  typeId?: number;
  sortOrder?: number;
}

export interface grp_AdminGmiReportsSearchTable1 {
  id: number;
  title?: string;
  description?: string;
  sortOrder?: number;
  inactive?: boolean;
  blockTitle?: string;
}

export interface grp_AdminGmiRequestDefinitionsSearchTable1 {
  id: number;
  title?: string;
  description?: string;
  blockTitle?: string;
}

export interface grp_AdminGmiRequestsSearchTable1 {
  id: number;
  dateInserted?: string;
  title?: string;
  userTitle?: string;
}

export interface grp_AdminGmiUsersSearchTable1 {
  id?: string;
  title?: string;
  email?: string;
  idKupca?: string;
  userName?: string;
  inactive?: boolean;
  emailConfirmed?: boolean;
}

export interface grp_AdminNotificationsTable1 {
  id: number;
  title?: string;
  text?: string;
  dateValidFrom?: string;
  dateValidTo?: string;
  dateModified?: string;
  userModified?: string;
  inactive?: boolean;
  type?: number;
  typeName?: string;
}

export interface grp_AdminWfDocumentHistoryTable0 {
  idDocument?: number;
  dateInserted?: string;
  userInserted?: string;
  statusCode?: string;
  statusTitle?: string;
  statusDescription?: string;
  comment?: string;
}

export interface grp_GetMessageConversationForUserTable1 {
  id: number;
  msgSubject?: string;
  msgStatus?: string;
  msgCategory?: string;
  msgConversationDeleted?: boolean;
  msgUnread?: boolean;
  msgUserIsSender?: boolean;
  originalSender?: string;
  lastMessageDate?: string;
}

export interface grp_GetPartnersForSelectTable0 {
  id?: string;
  title?: string;
}

export interface grp_PublicContractLesseeAmPlanTable1 {
  docID?: string;
  dueDate?: string;
  documentDate?: string;
  net?: number;
  interests?: number;
  margin?: number;
  rInterests?: number;
  tax?: number;
  debit?: number;
  credit?: number;
  balance?: number;
  currency?: string;
  installmentNumber?: number;
  claimType?: string;
  rent?: number;
  other?: number;
  balanceDom?: number;
  remainingPrincipal?: number;
}

export interface grp_PublicContractLesseeDebtTable0 {
  idCont?: number;
  sumExDueClaimsDebt?: number;
  sumExDueClaimsCount?: number;
  sumExFutureDebt?: number;
  sumExFutureInstallments?: number;
  futureCapital?: number;
  presentValue?: number;
  idVal?: string;
  tipKnjizenja?: string;
}

export interface grp_PublicContractLesseeDetailTable0 {
  lesseeTitle?: string;
  ppyrTitle?: string;
  activationDate?: string;
  registrationNumber?: string;
  vin?: string;
  leaseType?: string;
  referenceNumber?: string;
  annex?: string;
  statusDb?: string;
  legalType?: string;
  stockFunding?: boolean;
  bookingType?: string;
  irCalcMode?: string;
  lastIr?: number;
  leasKred?: string;
}

export interface grp_PublicContractLesseeGuarantorsTable1 {
  guarantorName?: string;
  guarantorRole?: string;
}

export interface grp_PublicContractLesseeHeaderTable0 {
  idCont?: number;
  idPog?: string;
  leaseObject?: string;
  activityStatus?: string;
  conclusionDate?: string;
  financedValue?: number;
  currency?: string;
  stockFundingConversionCandidate?: boolean;
  hasEaDocs?: boolean;
  hasMissingDocuments?: boolean;
  percentPayedLobrClaims?: number;
  hasPlanp?: number;
}

export interface grp_PublicContractLesseeInterestRateTable0 {
  irIndexTitle?: string;
  interestRateMargin?: number;
  lastIr?: number;
  lastIrChange?: string;
  actualIr?: number;
  irTolerance?: number;
  irCalcMode?: string;
  reprogamType?: number;
  effectiveIr?: number;
  nextIrChange?: string;
}

export interface grp_PublicContractsLesseeSearchTable1 {
  idCont?: number;
  idPog?: string;
  leaseObject?: string;
  activityStatus?: string;
  conclusionDate?: string;
  financedValue?: number;
  totalDebt?: number;
  currency?: string;
  userRole?: string;
  registrationNumber?: string;
  vin?: string;
  nacinLeas?: string;
}

export interface grp_PublicDashboardLesseeTable0 {
  idKupca?: string;
  vrOsebe?: string;
  exAdvances?: number;
  dateOfPayment?: string;
  znesekPl?: number;
  idVal?: string;
  sumExDueNotPayed?: number;
  sumExDueInstallments?: number;
  sumExDueClaimsDebt?: number;
  sumExDueClaimsCount?: number;
  sumExFutureInstallments?: number;
  sumExFutureDebt?: number;
  sumExFutureCapital?: number;
  totalContracts?: number;
  idValCustom?: string;
}

export interface grp_PublicDashboardLesseeTable1 {
  idCont?: number;
  idPog?: string;
  conclusionDate?: string;
  financedValue?: number;
  currency?: string;
  predNaj?: string;
  percentPayedLobrClaims?: number;
  userRole?: string;
}

export interface grp_PublicEaDocumentsLesseeSearchTable1 {
  id: number;
  idDocument?: number;
  idType?: number;
  type?: string;
  docDesc?: string;
  dateDocument?: string;
  dateRead?: string;
  status?: string;
  archived?: boolean;
  idPog?: string;
  hasFiles?: boolean;
  openPdf?: boolean;
}

export interface grp_PublicGetMenuSettingsTable0 {
  showHelpMenu?: boolean;
  showInsuranceClaimsMenu?: boolean;
  isVendor?: boolean;
  isVendorPseudo?: boolean;
  isLessee?: boolean;
  isInsurance?: boolean;
  isStockFunding?: boolean;
  isRecoveryAgency?: boolean;
  showUserRequests?: boolean;
}

export interface grp_PublicGetRequestsForPageTable0 {
  id: number;
  title?: string;
  requestDefinition?: string;
  sqlCondition?: string;
}

export interface grp_PublicGmiRequestHistoryTable0 {
  id: number;
  dateInserted?: string;
  title?: string;
}

export interface grp_PublicGmiRequestHistoryTable1 {
  id: number;
  title?: string;
  description?: string;
  isStart?: boolean;
  isEnd?: boolean;
  dateInserted?: string;
  comment?: string;
}

export interface grp_PublicGmiRequestsSearchTable1 {
  id: number;
  dateInserted?: string;
  title?: string;
  currentStatus?: string;
  dateLastChange?: string;
}

export interface grp_PublicInsuranceClaimsSearchTable1 {
  id: number;
  dateInserted?: string;
  damageDate?: string;
  chassisNumb?: string;
  insuranceAmount?: number;
  insuranceCase?: string;
  translation?: string;
}

export interface grp_PublicMissingDocumentationLesseeSearchTable1 {
  idDokum?: number;
  idPog?: string;
  missingDocDate?: string;
  missingDocDescription?: string;
}

export interface grp_PublicNotificationsSearchTable1 {
  id: number;
  title?: string;
  text?: string;
  typeCode?: string;
  dateRead?: string;
  dateInserted?: string;
  filesCnt?: number;
}

export interface grp_PublicOverduePaymentsLesseeSearchTable1 {
  idPog?: string;
  dueDate?: string;
  docID?: string;
  installmentNumber?: number;
  claimType?: string;
  debit?: number;
  credit?: number;
  balance?: number;
}

export interface grp_PublicOverduePaymentsSummaryLesseeTable0 {
  totalSumClaims?: number;
  totalContractWithOverdueCount?: number;
  totalOverdueClaimCount?: number;
  overduePaymentSum?: number;
  idVal?: string;
}

export interface grp_PublicPartnerKontaktTable0 {
  idPKontakt?: string;
  opisVloga?: string;
  naziv?: string;
  idKupca?: string;
  idKupcaK?: string;
  email?: string;
  telefon?: string;
  gsm?: string;
  naslov?: string;
  opis?: string;
}

export interface grp_PublicPartnerLesseeTable0 {
  idKupca?: string;
  nazKrKup?: string;
  davStev?: string;
  telefon?: string;
  email?: string;
  ulica?: string;
  idPoste?: string;
  nazivPosta?: string;
  emso?: string;
  gsm?: string;
  fax?: string;
  mesto?: string;
  ulicaSed?: string;
  idPosteSed?: string;
  nazivPosteSed?: string;
  mestoSed?: string;
  vrOsebeSifra?: string;
  datRoj?: string;
}

export interface grp_PublicPartnerTrrTable0 {
  idKupcaTrr?: number;
  idKupca?: string;
  trr?: string;
  idBanke?: string;
  naziv1?: string;
  swift?: string;
  aktivnost?: string;
}

export interface grp_PublicPaymentDistributionLesseeSearchTable1 {
  idPog?: string;
  documentDate?: string;
  docID?: string;
  docDescription?: string;
  idVal?: string;
  creditFC?: number;
  creditNC?: number;
  exRate?: number;
}

export interface grp_PublicPaymentsLesseeSearchTable1 {
  idPlac?: number;
  paymentDate?: string;
  bookingDate?: string;
  paymentAmount?: number;
  advanceAmount?: number;
  idVal?: string;
}

export interface grp_PublicStockFundingSearchTable1 {
  idStockFunding?: number;
  chassisNumb?: string;
  idMake?: string;
  makeNaziv?: string;
  price?: number;
  dateInserted?: string;
  status?: string;
  idStockFundingTemplate?: number;
  stockFundingTemplateNaziv?: string;
  idAbpUserInserted?: string;
  username?: string;
}

export interface GmiExternalUrlValidWithDataDto {
  isValid: boolean;
  externalData?: string;
}

export interface GmiInternalUrlDataDto {
  url?: string;
  parameters: IdTitleDtoString[];
}

export interface GmiInternalUrlDto {
  isValid: boolean;
  url: GmiInternalUrlDataDto;
}

export interface GmiQrDocumentUploadDto {
  isError: boolean;
  id?: string;
  idUrl?: string;
  qrCode?: string;
}

export interface GmiUploadFilesWithCheckDto {
  maliciousContentFound: boolean;
  maliciousContentFile: string[];
  uploadedError: boolean;
  errorMessage?: string;
  uploadedFiles: IdTitleDtoIntExtra[];
}

export interface IdTitleDtoIntExtra extends IdTitleDtoInt {
  extraData: number;
}

export interface IdTitleIntWithDecimalDto extends IdTitleDtoInt {
  value: number;
}

export interface IdTitleWithDecimalDto extends IdTitleDtoString {
  value: number;
}
