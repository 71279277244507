import type { ContractAmPlanDto, ContractDebtDto, ContractDetailDto, ContractGuarantorDto, ContractHeaderDto, ContractInterestRateDto, ContractsDto, GmiPagedResultDto, GmiSearchDto, GmiSearchWithIntIdDto, LesseeDashboardDto, LesseeMissingDocumentsDto, LesseePartnerDataDto, LesseePartnerKontaktDto, LesseePartnerTrrDto, OverduePaymentsDto, OverduePaymentsSummaryDto, PaymentDistributionListDto, PaymentListDto, PaymentSummaryDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiLesseesService {
  apiName = 'Default';
  

  getLesseeContractAmPlanByInputAndPage = (input: GmiSearchWithIntIdDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiPagedResultDto<ContractAmPlanDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-am-plan',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractDebtByIdCont = (idCont: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContractDebtDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-debt',
      params: { idCont },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractDetailsByIdCont = (idCont: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContractDetailDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-details',
      params: { idCont },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractGuarantorsByIdContAndPage = (idCont: number, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ContractGuarantorDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-guarantors',
      params: { idCont, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractHeaderByIdCont = (idCont: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContractHeaderDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-header',
      params: { idCont },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractInterestRateByIdCont = (idCont: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContractInterestRateDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-interest-rate',
      params: { idCont },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractMissingDocumentationByInputAndPage = (input: GmiSearchWithIntIdDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LesseeMissingDocumentsDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contract-missing-documentation',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeContractsByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiPagedResultDto<ContractsDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-contracts',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeDashboardData = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LesseeDashboardDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-dashboard-data',
    },
    { apiName: this.apiName,...config });
  

  getLesseeMissingDocumentationByPage = (page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LesseeMissingDocumentsDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-missing-documentation',
      params: { skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeOverduePaymentsByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<OverduePaymentsDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-overdue-payments',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseeOverduePaymentsSummary = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, OverduePaymentsSummaryDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-overdue-payments-summary',
    },
    { apiName: this.apiName,...config });
  

  getLesseePartnerData = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LesseePartnerDataDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-partner-data',
    },
    { apiName: this.apiName,...config });
  

  getLesseePaymentByIdPlac = (idPlac: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentSummaryDto>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-payment',
      params: { idPlac },
    },
    { apiName: this.apiName,...config });
  

  getLesseePaymentDistributionByInputAndPage = (input: GmiSearchWithIntIdDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PaymentDistributionListDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-payment-distribution',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getLesseePaymentsByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PaymentListDto>>({
      method: 'GET',
      url: '/api/app/gmi-lessees/lessee-payments',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getPartnerKontakt = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LesseePartnerKontaktDto[]>({
      method: 'GET',
      url: '/api/app/gmi-lessees/partner-kontakt',
    },
    { apiName: this.apiName,...config });
  

  getPartnerTrr = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, LesseePartnerTrrDto[]>({
      method: 'GET',
      url: '/api/app/gmi-lessees/partner-trr',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
