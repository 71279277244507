import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CompressImageService } from '../../services/compressImage.service';
import { firstValueFrom } from 'rxjs';
import { LocalizationService } from '@abp/ng.core';
import { WL } from 'src/app/const/definitions';
import { GmiStringFormat } from 'src/app/gmiUtils/pipes';

@Component({
	selector: 'gmi-upload-files',
	templateUrl: './gmi-upload-files.component.html',
	styleUrls: ['./gmi-upload-files.component.scss'],
})
export class GmiUploadFiles {
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef;
	@Input() limitNrFiles: number = 10;
	@Input() compressImage: boolean = true;
	@Input() sizeFileMax = 100000000;
	@Input() get maliciousFile() {
		return this._malFile;
	}
	@Input() set finish(end: boolean) {
		if (end === true) {
			this.reset();
		}
	}
	@Input() get maliciousFileWarning() {
		return this._malFileWarning;
	}
	@Output() confirmClicked: EventEmitter<File[]> = new EventEmitter<File[]>();

	_malFileWarning: boolean = false;
	_malFile: string[] = [];
	labelMaxSize: string;
	files: File[];
	WL: WL;
	labelManual: string;
	enableButtons = true;
	showMaxSizeFileWarning: boolean = false;

	constructor(private imageCompress: CompressImageService, private ref: ChangeDetectorRef, private localizationService: LocalizationService) {}

	ngOnInit(): void {
		this.WL = new WL();
		this.files = [];
		this.labelMaxSize = new GmiStringFormat().transform(this.localizationService.instant(WL.QrFileUpload_MaxSizeFize), this.sizeFileMax / 10000000);
		this.labelManual = new GmiStringFormat().transform(
			this.localizationService.instant(WL.QrFileUpload_Manual),
			this.localizationService.instant(WL.QrFileUpload_BtnAdd),
			this.localizationService.instant(WL.QrFileUpload_BtnConfirm)
		);
	}

	async OnFileUpload(event: any) {
		this.showMaxSizeFileWarning = false;
		var forUpload = event.target.files;
		for (let file of forUpload) {
			if (file.size > this.sizeFileMax) {
				this.showMaxSizeFileWarning = true;
			}
			if (this.files.length < this.limitNrFiles && file.size < this.sizeFileMax) {
				if (this.compressImage && file.type.includes('image')) {
					await firstValueFrom(this.imageCompress.compress(file)).then((r) => {
						this.files.push(r);
						this.ref.detectChanges();
					});
				} else {
					this.files.push(file);
				}
			}
		}
	}

	isUploadedFilesLimit() {
		if (this.limitNrFiles <= this.files.length) {
			return true;
		}
		return false;
	}

	removeFile(i: number) {
		this.fileInput.nativeElement.value = null;
		var file = this.files.splice(i, 1);
		if (this.maliciousFile?.includes(file[0].name)) {
			this.maliciousFile = this.maliciousFile.filter((x) => x !== file[0].name);
			if (this.maliciousFile?.length == 0) {
				this.maliciousFileWarning = false;
			}
		}
		this.ref.detectChanges();
	}

	onConfirmClicked() {
		this.confirmClicked.emit(this.files);
	}

	reset() {
		console.log('reset');
		this.files = [];
	}

	set maliciousFileWarning(value: boolean) {
		this._malFileWarning = value;
	}

	set maliciousFile(file: string[]) {
		this._malFile = file;
	}
}
