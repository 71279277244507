import { NgModule } from '@angular/core';
import { OnlineLocalizations } from './localizations';
import { OnlinePermissions } from './permissions';
import { OnlineSettings } from './settings';
import { OnlineConstants } from './constants';

@NgModule({})
export class WP extends OnlinePermissions {}

export class WS extends OnlineSettings {}

export class WL extends OnlineLocalizations {}

export class WC extends OnlineConstants {}
