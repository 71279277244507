import { DatePipe, DecimalPipe, formatNumber, getCurrencySymbol, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { GmiLocalizationService } from '../shared/services/gmiLocalization.service';
import { Observable, map } from 'rxjs';
import { IdTitleDtoString } from '@proxy';
import { LocalizationService } from '@abp/ng.core';

registerLocaleData(localeDe, 'de');

@Pipe({ name: 'gmiDateFormatFromDate' })
export class GmiDateFormatFromDate implements PipeTransform {
	transform(date: Date): string {
		return new DatePipe('en-US').transform(date, 'dd. MM. yyyy');
	}
}

@Pipe({ name: 'gmiDateFormatFromString' })
export class GmiDateFormatFromString implements PipeTransform {
	transform(date: string): string {
		return new DatePipe('en-US').transform(date, 'dd. MM. yyyy');
	}
}

@Pipe({ name: 'gmiDateAndTimeFormatFromString' })
export class GmiDateAndTimeFormatFromString implements PipeTransform {
	transform(date: string): string {
		return new DatePipe('en-US').transform(date, 'dd. MM. yyyy HH:mm:ss');
	}
}

@Pipe({ name: 'gmiDateFormatServer' })
export class GmiDateFormatServer implements PipeTransform {
	transform(date: Date): string {
		return new DatePipe('en-US').transform(date, 'yyyy-MM-dd');
	}
}

@Pipe({ name: 'gmiDecimalFormat' })
export class GmiDecimalFormat implements PipeTransform {
	transform(val: number): string {
		return new DecimalPipe('de').transform(val, '1.2-2');
	}
}

@Pipe({ name: 'gmiCurrencySymbol' })
export class GmiCurrencySymbol implements PipeTransform {
	transform(code: string, format: 'wide' | 'narrow' = 'narrow', locale?: string): any {
		return getCurrencySymbol(!!code ? code.toUpperCase() : code, format, locale);
	}
}

@Pipe({ name: 'gmiStringFormat' })
export class GmiStringFormat implements PipeTransform {
	transform(val, ...args): string {
		for (let i = 0; i < args.length; i++) {
			val = val.replace('{' + i + '}', args[i]);
		}
		return val;
	}
}

@Pipe({ name: 'gmiStripHtml' })
export class GmiStripHtml implements PipeTransform {
	transform(value: string, length: number): any {
		var cleanText = value.replace(/<.*?>/g, '');
		if (!!length) {
			return cleanText.length > length ? cleanText.slice(0, length - 4) + ' ...' : cleanText;
		}
		return cleanText;
	}
}

@Pipe({ name: 'gmiSubString' })
export class GmiSubString implements PipeTransform {
	transform(value: string, length = 100): any {
		return value.slice(0, length);
	}
}

@Pipe({ name: 'gmiSafe' })
export class GmiSafePipe implements PipeTransform {
	constructor(protected _sanitizer: DomSanitizer) {}
	public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
		switch (type) {
			case 'html':
				return this._sanitizer.bypassSecurityTrustHtml(value);
			case 'style':
				return this._sanitizer.bypassSecurityTrustStyle(value);
			case 'script':
				return this._sanitizer.bypassSecurityTrustScript(value);
			case 'url':
				return this._sanitizer.bypassSecurityTrustUrl(value);
			case 'resourceUrl':
				return this._sanitizer.bypassSecurityTrustResourceUrl(value);
			default:
				throw new Error(`Invalid safe type specified: ${type}`);
		}
	}
}

@Pipe({ name: 'gmiPercentFormat' })
export class GmiPercentFormat implements PipeTransform {
	transform(value: number): string {
		return formatNumber(value, 'de', '1.4') + ' %';
	}
}

@Pipe({ name: 'gmiCustomLocalization' })
export class GmiCustomLocalization implements PipeTransform {
	constructor(protected localizationService: LocalizationService, protected gmiLocalizationService: GmiLocalizationService) {}
	transform(value: string): Observable<string> {
		return this.gmiLocalizationService.getUserCustomLocalization().pipe(
			map((abc: IdTitleDtoString[]) => {
				if (!!!value) {
					return value;
				}
				if (!!value && value.startsWith('::')) {
					value = this.localizationService.instant(value);
				}
				return abc?.find((x) => x.id.trim() == value.trim())?.title || value;
			})
		);
	}
}
