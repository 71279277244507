import type { FileContentsDto, GmiAdminMailTableDto, GmiMailDto, GmiMailSearchParamsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminMailsService {
  apiName = 'Default';
  

  getMailAttachmentByIdMailAndIdAttachment = (idMail: number, idAttachment: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-mails/mail-attachment',
      params: { idMail, idAttachment },
    },
    { apiName: this.apiName,...config });
  

  getMailById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiMailDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-mails/${id}/mail`,
    },
    { apiName: this.apiName,...config });
  

  getMailsByGmiMailSearchParamsDtoAndPagedResultRequestDto = (gmiMailSearchParamsDto: GmiMailSearchParamsDto, pagedResultRequestDto: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiAdminMailTableDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-mails/mails',
      params: { dateInsertedFrom: gmiMailSearchParamsDto.dateInsertedFrom, dateInsertedTo: gmiMailSearchParamsDto.dateInsertedTo, searchCriteria: gmiMailSearchParamsDto.searchCriteria, userMail: gmiMailSearchParamsDto.userMail, skipCount: pagedResultRequestDto.skipCount, maxResultCount: pagedResultRequestDto.maxResultCount },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
