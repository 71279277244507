import type { GmiLicenceInfoDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiLicencesService {
  apiName = 'Default';
  

  getIsModuleEnabledByModuleName = (moduleName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/app/gmi-licences/is-module-enabled',
      params: { moduleName },
    },
    { apiName: this.apiName,...config });
  

  getLicenceInfo = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiLicenceInfoDto>({
      method: 'GET',
      url: '/api/app/gmi-licences/licence-info',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
