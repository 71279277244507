import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { WL, WP } from '../../../const/definitions';
import { GmiReportsService, GmiRequestsService, IdTitleDtoInt, PublicRequestDto } from '@proxy';
import { GmiReportsModalComponent } from '../gmi-reports-modal/gmi-reports-modal.component';
import { GmiRequestsModalComponent } from '../gmi-requests-modal/gmi-requests-modal.component';
import { PermissionService } from '@abp/ng.core';

@Component({
	selector: 'gmi-actions',
	templateUrl: './gmi-actions.component.html',
	styleUrls: ['./gmi-actions.component.scss'],
})
export class GmiActions implements OnInit {
	@Input() block: string;
	@Input() id: string;
	@Input() customSubtitle: string;

	@ViewChild('modalReports') modalReports: GmiReportsModalComponent;
	@ViewChild('modalRequests') modalRequests: GmiRequestsModalComponent;

	reportsSelect: Array<IdTitleDtoInt>;
	requestsSelect: Array<PublicRequestDto>;

	WL: WL;
	WP: WP;
	constructor(
		private gmiReportsService: GmiReportsService,
		private gmiRequestsService: GmiRequestsService,
		private cdr: ChangeDetectorRef,
		private permissionService: PermissionService
	) {
		this.WL = new WL();
		this.WP = new WP();
	}

	ngOnInit(): void {
		if (this.permissionService.getGrantedPolicy(WP.PublicReportsGroup_PublicReports)) {
			this.gmiReportsService.getReportsByBlockIdAndId(this.block, this.id).subscribe((response) => {
				this.reportsSelect = response;
				this.cdr.detectChanges();
			});
		}

		if (this.permissionService.getGrantedPolicy(WP.PublicRequestsGroup_PublicRequests_PublicRequestsInsert)) {
			this.gmiRequestsService.getRequestsByPageAndId(this.block, this.id).subscribe((response) => {
				this.requestsSelect = response;
				this.cdr.detectChanges();
			});
		}
	}

	openReportsModal() {
		this.modalReports.openModal();
	}

	openRequestsModal() {
		this.modalRequests.openModal();
	}
}
