import { ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { GmiLesseesService, GmiSearchWithIntIdDto, LesseeMissingDocumentsDto } from '@proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { GmiDateFormatFromString } from 'src/app/gmiUtils/pipes';
import { WL } from '../../../const/definitions';
import { GmiGridLocalization, Page } from 'src/app/gmiUtils/objects';
import { GmiPrepareGridLocalization } from 'src/app/gmiUtils/functions';

@Component({
	selector: 'gmi-missing-documentation-grid',
	templateUrl: './gmi-missing-documentation-grid.component.html',
	styleUrls: ['./gmi-missing-documentation-grid.component.scss'],
	providers: [ListService],
})
export class GmiMissingDocumentationGrid implements OnInit {
	@Input() searchDto = {} as GmiSearchWithIntIdDto;
	@Input() showIdPog = false;

	// grid
	contractMissingDocs = { items: [], totalCount: 0 } as PagedResultDto<LesseeMissingDocumentsDto>;
	contractMissingDocsColumns = [
		{
			prop: 'idPog',
			name: this.localizationService.instant(WL.Contracts_IdPog),
			minWidth: 150,
			maxWidth: 150,
			cellClass: 'hide800',
			headerClass: 'hide800',
		},
		{
			prop: 'missingDocDate',
			name: this.localizationService.instant(WL.MissingDoc_DocumentDate),
			pipe: new GmiDateFormatFromString(),
			minWidth: 150,
			maxWidth: 150,
			cellClass: 'hide800 grid-right-align',
			headerClass: 'hide800 grid-right-align-header',
		},
		{
			prop: 'missingDocDescription',
			name: this.localizationService.instant(WL.General_LabelDescription),
			minWidth: 400,
			maxWidth: 400,
			cellClass: 'hide800',
			headerClass: 'hide800',
		},
	];
	defaultSort = [{ prop: 'missingDocDate', dir: 'asc' }];
	WL: WL;
	page = new Page();
	customMessages = {} as GmiGridLocalization;

	constructor(
		private localizationService: LocalizationService,
		private gmiLesseesService: GmiLesseesService,
		public readonly list: ListService,
		private spinner: NgxSpinnerService
	) {
		this.list.maxResultCount = 10;
		this.WL = new WL();
	}

	ngOnInit(): void {
		this.loadGrid();
	}

	loadGrid() {
		if (!this.showIdPog) {
			this.contractMissingDocsColumns = this.contractMissingDocsColumns.filter((x) => x.prop != 'idPog');
		}

		const missingDocumentationStream = (query) => {
			this.spinner.show();
			query.skipCount = this.page.pageNumber * this.page.pageSize;
			return this.gmiLesseesService.getLesseeContractMissingDocumentationByInputAndPage(this.searchDto, query);
		};

		this.list.hookToQuery(missingDocumentationStream).subscribe((response) => {
			this.contractMissingDocs = response;
			this.page.totalElements = response.totalCount;
			this.page.totalPages = response.totalCount / this.page.pageSize;
			this.customMessages = GmiPrepareGridLocalization(this.localizationService, this.page);
			this.spinner.hide();
		});
	}

	getRowClass = (row) => {
		if (moment(row.missingDocDate).isBefore(moment())) {
			return 'gmi-warning-row';
		} else {
			return '';
		}
	};

	filterResults(obj: any) {
		this.searchDto = obj;
		this.loadGrid();
	}

	onSort(event: any) {
		this.searchDto.orderBy = event.column.prop;
		this.searchDto.orderAscending = !!event.newValue && event.newValue == 'asc';
	}

	setPage(pageInfo: any) {
		this.page.pageNumber = pageInfo.offset;
		this.loadGrid();
	}
}
