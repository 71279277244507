import type { CalcTemplateObjectDto, CalculationSettingsDto } from './gmi/dtos/calculation/models';
import type { CalculationFastStep1Dto, CalculationFastStep21DataDto, CalculationFastStep2Dto, CalculationResultDto, IdTitleDtoInt, IdTitleDtoString } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiCalculationService {
  apiName = 'Default';
  

  getCalculationFastTemplatesByInput = (input: CalculationFastStep1Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CalcTemplateObjectDto[]>({
      method: 'GET',
      url: '/api/app/gmi-calculation/calculation-fast-templates',
      params: { customerLegalTypeId: input.customerLegalTypeId, objectId: input.objectId, firstRegistrationDate: input.firstRegistrationDate, ddvIncluded: input.ddvIncluded, finConditionId: input.finConditionId },
    },
    { apiName: this.apiName,...config });
  

  getCalculationLegalTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoString[]>({
      method: 'GET',
      url: '/api/app/gmi-calculation/calculation-legal-types',
    },
    { apiName: this.apiName,...config });
  

  getCalculationSettingsByIdObjectAndIdFinCondition = (idObject: number, idFinCondition: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CalculationSettingsDto>({
      method: 'GET',
      url: '/api/app/gmi-calculation/calculation-settings',
      params: { idObject, idFinCondition },
    },
    { apiName: this.apiName,...config });
  

  postCalculateByInput = (input: CalculationFastStep2Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CalculationResultDto>({
      method: 'POST',
      url: '/api/app/gmi-calculation/calculate',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postCalculateDifferentPeriodsByInput = (input: CalculationFastStep2Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/app/gmi-calculation/calculate-different-periods',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postCalculationFastFinConditionsByInput = (input: CalculationFastStep1Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'POST',
      url: '/api/app/gmi-calculation/calculation-fast-fin-conditions',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postCalculationFastFirstStepCheckByInput = (input: CalculationFastStep21DataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CalculationSettingsDto>({
      method: 'POST',
      url: '/api/app/gmi-calculation/calculation-fast-first-step-check',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
