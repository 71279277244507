<form [formGroup]="formSimpleGridCriteria" class="py-1">
  <div class="container gmi-search-container">
    <div class="row">
      <!-- basic search - textbox and 2 buttons -->
      <div [ngClass]="
					!searchExpanded
						? 'col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9'
						: checkBoxVisible
						? 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3'
						: 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5'
				">
        <div class="input-group">
          <span class="input-group-text border-end-0" id="basic-addon1">
            <i class="ft-search fa-lg"></i>
          </span>
          <input #search type="text" class="form-control" id="formSearchCriteria" (keyup.enter)="criteriaChange()"
            formControlName="formSearchCriteria" placeholder="{{ WL.search_Title | gmiCustomLocalization | async }}"
            aria-label="Search" aria-describedby="basic-addon1" />
        </div>
      </div>
      <!-- expanded search - dates and checkbox and expanded buttons -->
      <div *ngIf="searchExpanded"
        [ngClass]="checkBoxVisible ? 'col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2' : 'col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2'">
        <div class="input-group">
          <input gmiDatepickerTooltip gmiFormatDate class="form-control" name="dp" ngbDatepicker #d1="ngbDatepicker"
            id="formSearchDateFromNgb" formControlName="formSearchDateFromNgb"
            placeholder="{{ searchDateFromPlaceholderText }}" gmiRestrictDateInput maxlength="12" />
          <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
      <div *ngIf="searchExpanded"
        [ngClass]="checkBoxVisible ? 'col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2' : 'col-12 col-sm-6 col-md-3 col-lg-3 col-xl-2'">
        <div class="input-group">
          <input gmiDatepickerTooltip gmiFormatDate class="form-control" name="dp" ngbDatepicker #d2="ngbDatepicker"
            id="formSearchDateToNgb" formControlName="formSearchDateToNgb"
            placeholder="{{ searchDateToPlaceholderText }}" gmiRestrictDateInput maxlength="12" />
          <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
      <div *ngIf="checkBoxVisible && searchExpanded" class="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 mt-1">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="formCheckBoxValue" formControlName="formCheckBoxValue" />
          <label class="form-check-label" for="formCheckBoxValue"><span>{{ checkBoxText }}</span></label>
        </div>
      </div>

      <div [ngClass]="
					!searchExpanded
						? 'col-4 col-sm-4 col-md-1 col-lg-1 col-xl-1'
						: checkBoxVisible
						? 'col-4 col-sm-4 col-md-4 col-lg-2 col-xl-1'
						: 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-1'
				">
        <button [disabled]="formSimpleGridCriteria.invalid" type="button" class="btn btn-primary me-1 gmi-button-fill"
          (click)="criteriaChange()" ngbTooltip="{{ WL.search_Search | gmiCustomLocalization | async }}">
          <i class="ft-search font-medium-3 pointer gmi-align-icon"></i>
        </button>
      </div>
      <div [ngClass]="
					!searchExpanded
						? 'col-4 col-sm-4 col-md-1 col-lg-1 col-xl-1'
						: checkBoxVisible
						? 'col-4 col-sm-4 col-md-4 col-lg-2 col-xl-1'
						: 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-1'
				">
        <button type="button" class="btn btn-primary me-1 gmi-button-fill" (click)="criteriaReset()"
          ngbTooltip="{{ WL.search_Reset | gmiCustomLocalization | async }}"
          [disabled]="!!!formSearchCriteria.value && !!!formSearchDateFromNgb.value && !!!formSearchDateToNgb.value && !formCheckBoxValue.value">
          <i class="ft-x-circle font-medium-3 pointer gmi-align-icon"></i>
        </button>
      </div>

      <div [ngClass]="
					!searchExpanded
						? 'col-4 col-sm-4 col-md-1 col-lg-1 col-xl-1'
						: checkBoxVisible
						? 'col-4 col-sm-4 col-md-4 col-lg-2 col-xl-1'
						: 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-1'
				">
        <button type="button" class="btn btn-primary me-1 gmi-button-fill" (click)="searchExpanded = !searchExpanded"
          ngbTooltip="{{ WL.search_More | gmiCustomLocalization | async }}">
          <i class="ft-more-horizontal font-medium-3 pointer gmi-align-icon"></i>
        </button>
      </div>
    </div>
  </div>
</form>