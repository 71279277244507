import { Injectable } from "@angular/core";
import { MenuSettingsDto } from "@proxy";
import { BehaviorSubject } from "rxjs";

/**
 * This is the observable for the gmi partner types.
 */
@Injectable({ providedIn: 'root' })
export class GmiPolicyService {
    partnerMenuSettings$: BehaviorSubject<MenuSettingsDto> = new BehaviorSubject(null);

    constructor(
    ) { }

    setPolicySettings(val){
        this.partnerMenuSettings$.next(val);
    }

    getPolicySettings(){
        return this.partnerMenuSettings$.asObservable();
    }

    checkGmiPolicySetting(req: Array<string>){
        let isOk = false;
        // we use for, because forEach can't be stopped
        this.getPolicySettings().subscribe((response) => {
            let o = Object.keys(response);
            for (let i = 0; i < req.length; i++){
                for (let j = 0; j < o.length; j++){
                    if(req[i] == o[j] && !!response[o[j]]){
                        isOk = true;
                        break;
                    }
                }
            }
        });
        return isOk;
    }
}
