import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
// GMI code
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ROUTES } from './shared/routes/custom-layout.routes';
import { AuthGuard } from './shared/auth/auth-guard.service';

const appRoutes: Routes = [
	// this one is the top page, all others are children
	{ path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
	// this one is for content pages when not loged in - login, forgot password...
	{ path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
	{ path: '', component: ContentLayoutComponent, data: { title: 'custom Views' }, children: CUSTOM_ROUTES },
	{
		path: '**',
		redirectTo: 'error',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }), FormsModule, BrowserModule],
	exports: [RouterModule],
})
export class AppRoutingModule {}
