import type { DefaultReturnData, GmiPartnersSelectDto, GmiUserHistoryAdminDto, GmiUserPasswordResetDto, GmiUsersAdminDto, GmiUsersHistSearchParamsDto, GmiUsersSearchDto, GmiUsersSearchParamsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminUsersService {
  apiName = 'Default';
  

  getImpersonatedUserByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/impersonated-user',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  getPartnersForSelectByInputAndPage = (input: string, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiPartnersSelectDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/partners-for-select',
      params: { input, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getUserDataByAbpByIdAbpUser = (idAbpUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiUsersAdminDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/user-data-by-abp',
      params: { idAbpUser },
    },
    { apiName: this.apiName,...config });
  

  getUserDataByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiUsersAdminDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/user-data',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  getUserDataHistoryByIdUserAndInputAndPage = (idUser: string, input: GmiUsersHistSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiUserHistoryAdminDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/user-data-history',
      params: { idUser, createdTimeFrom: input.createdTimeFrom, createdTimeTo: input.createdTimeTo, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getUsersSearchByInputAndPage = (input: GmiUsersSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiUsersSearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-users/users-search',
      params: { idPartner: input.idPartner, idRole: input.idRole, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  impersonateUserByIdUserAndStopImpersonation = (idUser: string, stopImpersonation: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-users/impersonate-user',
      params: { idUser, stopImpersonation },
    },
    { apiName: this.apiName,...config });
  

  postConfirmUserMailByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-users/confirm-user-mail',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  postInsertNewUserByInput = (input: GmiUsersAdminDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-users/insert-new-user',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postResetUserPasswordByInput = (input: GmiUserPasswordResetDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-users/reset-user-password',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUnconfirmUserMailByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-users/unconfirm-user-mail',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  putActivateUserByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-users/activate-user',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  putDeactivateUserByIdUser = (idUser: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-users/deactivate-user',
      params: { idUser },
    },
    { apiName: this.apiName,...config });
  

  putUpdateUserByInput = (input: GmiUsersAdminDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-users/update-user',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
