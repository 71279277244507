import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminMaintenanceService {
  apiName = 'Default';
  

  postCleanTokens = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/gmi-admin-maintenance/clean-tokens',
    },
    { apiName: this.apiName,...config });
  

  postEncryptTokenByToken = (token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/gmi-admin-maintenance/encrypt-token',
      params: { token },
    },
    { apiName: this.apiName,...config });
  

  restartApplicationByForAdminAndForPublic = (forAdmin: boolean, forPublic: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/gmi-admin-maintenance/restart-application',
      params: { forAdmin, forPublic },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
