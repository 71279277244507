import type { DefaultReturnData, FileContentsDto, GmiEaDocumentDto, GmiSearchWithIntIdDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiEaDocumentsService {
  apiName = 'Default';
  

  getDocumentPdfFileByIdDocument = (idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number[]>({
      method: 'GET',
      url: '/api/app/gmi-ea-documents/document-pdf-file',
      params: { idDocument },
    },
    { apiName: this.apiName,...config });
  

  getDownloadFileByIdDocument = (idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-ea-documents/download-file',
      params: { idDocument },
    },
    { apiName: this.apiName,...config });
  

  getEaDocumentFromBpmByIdDocument = (idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'GET',
      url: '/api/app/gmi-ea-documents/ea-document-from-bpm',
      params: { idDocument },
    },
    { apiName: this.apiName,...config });
  

  getEaDocumentsForContractByPageAndInput = (page: PagedResultRequestDto, input: GmiSearchWithIntIdDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiEaDocumentDto>>({
      method: 'GET',
      url: '/api/app/gmi-ea-documents/ea-documents-for-contract',
      params: { skipCount: page.skipCount, maxResultCount: page.maxResultCount, id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  getEaDocumentsForUserByPageAndInput = (page: PagedResultRequestDto, input: GmiSearchWithIntIdDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiEaDocumentDto>>({
      method: 'GET',
      url: '/api/app/gmi-ea-documents/ea-documents-for-user',
      params: { skipCount: page.skipCount, maxResultCount: page.maxResultCount, id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  postMarkDocumentAsReadByIdDocument = (idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-ea-documents/mark-document-as-read',
      params: { idDocument },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
