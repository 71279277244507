<div class="card-content">
	<div class="card-body">
		<ngx-datatable
			class="bootstrap core-bootstrap gmi-selectable"
			columnMode="force"
			default
			rowHeight="auto"
			[count]="page.totalElements"
			[externalPaging]="true"
			[footerHeight]="50"
			[limit]="page.pageSize"
			[messages]="customMessages"
			[offset]="page.pageNumber"
			[rowClass]="getRowClassForEaDocuments"
			[rows]="contractEaDocuments.items"
			[scrollbarH]="false"
			[sorts]="defaultSort"
			(activate)="handleRowClick($event)"
			(page)="setPage($event)"
			(sort)="onSort($event)">
			<ngx-datatable-column name="" [minWidth]="50" [maxWidth]="50" [sortable]="false" [draggable]="false" [resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<i [ngClass]="{ 'font-medium-4 align-middle': true, 'ft-download': row.hasFiles }"></i>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column
				*ngFor="let col of contractEaDocumentsColumns"
				[name]="col.name"
				[prop]="col.prop"
				[pipe]="col.pipe"
				[minWidth]="col.minWidth"
				[cellClass]="col.cellClass"
				[headerClass]="col.headerClass"
				[draggable]="false"
				[resizeable]="false">
			</ngx-datatable-column>
			<ngx-datatable-column
				name=""
				[minWidth]="300"
				[cellClass]="'show600 hideOnFull'"
				[headerClass]="'show600 hideOnFull'"
				[draggable]="false"
				[resizeable]="false">
				<ng-template let-row="row" ngx-datatable-cell-template>
					<div class="fw-bold">{{ row.docDesc }}</div>
					<div *ngIf="showIdPog">{{ WL.contracts_IdPog | gmiCustomLocalization | async }}: {{ row.idPog }}</div>
					<div>{{ row.docDate | gmiDateFormatFromDate }}</div>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
	</div>
</div>

<ngx-spinner type="line-scale" name="SPINNER_EADOCS" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ffffff" type="line-scale" [fullScreen]="true">
	<p style="color: white">{{ spinnerEaDocsLoadingMessage }}</p>
</ngx-spinner>
