import { ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { GmiEaDocumentDto, GmiSearchWithIntIdDto, GmiEaDocumentsService } from '@proxy';
import { NgxSpinnerService } from 'ngx-spinner';
import { GmiBase64ToBlob, GmiPrepareGridLocalization } from 'src/app/gmiUtils/functions';
import { GmiDateAndTimeFormatFromString, GmiDateFormatFromString } from 'src/app/gmiUtils/pipes';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { WL } from '../../../const/definitions';
import { GmiNavbarService } from '../../services/gmiNavbar.service';
import { GmiGridLocalization, Page } from 'src/app/gmiUtils/objects';

@Component({
	selector: 'gmi-ea-documents-grid',
	templateUrl: './gmi-ea-documents-grid.component.html',
	styleUrls: ['./gmi-ea-documents-grid.component.scss'],
	providers: [ListService],
})
export class GmiEaDocumentsGrid implements OnInit {
	@Input() searchDto = {} as GmiSearchWithIntIdDto;
	@Input() showIdPog = false;

	isLoadingArchived = false;

	// grid
	contractEaDocuments = { items: [], totalCount: 0 } as PagedResultDto<GmiEaDocumentDto>;
	contractEaDocumentsColumns = [
		{ prop: 'type', name: this.localizationService.instant(WL.General_LabelType), minWidth: 150, headerClass: 'hide1400', cellClass: 'hide1400' },
		{ prop: 'docDesc', name: this.localizationService.instant(WL.General_LabelDescription), minWidth: 300, headerClass: 'hide600', cellClass: 'hide600' },
		{ prop: 'idPog', name: this.localizationService.instant(WL.Contracts_IdPog), minWidth: 100, headerClass: 'hide600', cellClass: 'hide600' },
		{
			prop: 'dateDocument',
			name: this.localizationService.instant(WL.EaDocuments_DocumentDate),
			minWidth: 150,
			headerClass: 'hide800 grid-right-align-header',
			cellClass: 'hide800 grid-right-align',
			pipe: new GmiDateFormatFromString(),
		},
		{
			prop: 'dateRead',
			name: this.localizationService.instant(WL.EaDocuments_DocumentDateRead),
			minWidth: 150,
			headerClass: 'hide1300 grid-right-align-header',
			cellClass: 'hide1300 grid-right-align',
			pipe: new GmiDateAndTimeFormatFromString(),
		},
	];
	defaultSort = [{ prop: 'docDate', dir: 'desc' }];
	spinnerEaDocsLoadingMessage = '';
	WL: WL;
	cntNewEaDocuments: number;

	customMessages = {} as GmiGridLocalization;
	page = new Page();

	constructor(
		private localizationService: LocalizationService,
		private gmiEaDocumentsService: GmiEaDocumentsService,
		public readonly list: ListService,
		private spinner: NgxSpinnerService,
		private gmiNavbarService: GmiNavbarService
	) {
		this.list.maxResultCount = 10;
		this.WL = new WL();
	}

	ngOnInit(): void {
		this.loadGrid();

		this.gmiNavbarService.getCntNewEaDocuments().subscribe((response) => {
			this.cntNewEaDocuments = response;
		});
	}

	loadGrid() {
		this.spinner.show();
		if (!this.showIdPog) {
			this.contractEaDocumentsColumns = this.contractEaDocumentsColumns.filter((x) => x.prop != 'idPog');
		}

		const eaDocumentsStreamCreator = (query) => {
			this.spinner.show();
			query.skipCount = this.page.pageNumber * this.page.pageSize;
			return this.gmiEaDocumentsService.getEaDocumentsForUserByPageAndInput(query, this.searchDto);
		};

		this.list.hookToQuery(eaDocumentsStreamCreator).subscribe((response) => {
			this.contractEaDocuments = response;
			this.page.totalElements = response.totalCount;
			this.page.totalPages = response.totalCount / this.page.pageSize;
			this.customMessages = GmiPrepareGridLocalization(this.localizationService, this.page);
			this.spinner.hide();
		});
	}

	getRowClassForEaDocuments = (row) => {
		if (!!!row.dateRead && row.status != 'D') {
			return 'fw-bold';
		}

		if (row.status == 'D') {
			return 'gmi-grayed-out';
		}
		return '';
	};

	handleRowClick(event) {
		if (event.type == 'click') {
			if (event.row.status == 'D') {
				// document is deleted
				Swal.fire({ html: '<p>' + this.localizationService.instant(WL.EaDocuments_DocumentDeleted) + '</p>' });
			} else if (event.row.status == 'A' && !event.row.hasFiles && !this.isLoadingArchived) {
				// document is archived, so the transfer will take some time
				this.isLoadingArchived = true;
				this.spinnerEaDocsLoadingMessage = this.localizationService.instant(WL.EaDocuments_LoadingFromArchive);
				this.spinner.show('SPINNER_EADOCS');
				this.gmiEaDocumentsService.getEaDocumentFromBpmByIdDocument(event.row.idDocument).subscribe((response) => {
					if (!!!response.isError) {
						this.gmiEaDocumentsService.getDownloadFileByIdDocument(event.row.idDocument).subscribe((response1) => {
							const imageBlob = GmiBase64ToBlob(response1.content, response1.mimeType);
							const imageFile = new File([imageBlob], response1.fileName, { type: response1.mimeType });
							saveAs(imageFile);
							this.loadGrid();
							this.spinner.hide('SPINNER_EADOCS');
							this.isLoadingArchived = false;
						});
					} else {
						this.spinner.hide('SPINNER_EADOCS');
						this.isLoadingArchived = false;
						Swal.fire(response.errorMsg);
					}
				});
			} else {
				// file is here, download it
				this.spinner.show();
				this.gmiEaDocumentsService.getDownloadFileByIdDocument(event.row.idDocument).subscribe((response) => {
					if (!!response.fileName) {
						if (!!event.row.dateRead) {
							this.showFile(response);
							this.spinner.hide();
						} else {
							this.gmiEaDocumentsService.postMarkDocumentAsReadByIdDocument(event.row.idDocument).subscribe((response1) => {
								if (!!response1.isError) {
									Swal.fire({ html: '<p>' + this.localizationService.instant(WL.EaDocuments_DocumentDeleted) + '</p>' });
								} else {
									this.gmiNavbarService.setCntNewEaDocuments(this.cntNewEaDocuments - 1);
									this.showFile(response);
									this.loadGrid();
									this.spinner.hide();
								}
							});
						}
					} else {
						Swal.fire({ html: '<p>' + this.localizationService.instant(WL.EaDocuments_OpenDocumentError) + '</p>' });
						this.spinner.hide();
					}
				});
			}
		}
	}

	showFile(response: any) {
		const imageBlob = GmiBase64ToBlob(response.content, response.mimeType);
		const imageFile = new File([imageBlob], response.fileName, { type: response.mimeType });
		saveAs(imageFile);
	}

	filterResults(obj: any) {
		this.page.pageNumber = 0;
		this.loadGrid();
	}

	onSort(event: any) {
		this.searchDto.orderBy = event.column.prop;
		this.searchDto.orderAscending = !!event.newValue && event.newValue == 'asc';
		this.loadGrid();
	}

	setPage(pageInfo: any) {
		this.page.pageNumber = pageInfo.offset;
		this.loadGrid();
	}
}
