import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DefaultReturnData, DocumentContentsStringDto, GmiExternalUrlValidWithDataDto, GmiInternalUrlDataDto, GmiInternalUrlDto, GmiQrDocumentUploadDto, GmiUploadFilesWithCheckDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GmiExternalUrlService {
  apiName = 'Default';
  

  getExternalUrlById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiInternalUrlDto>({
      method: 'GET',
      url: `/api/app/gmi-external-url/${id}/external-url`,
    },
    { apiName: this.apiName,...config });
  

  postConfirmEmailByIdAndIid = (id: string, iid: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/confirm-email`,
      params: { iid },
    },
    { apiName: this.apiName,...config });
  

  postFinishUrlById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/finish-url`,
    },
    { apiName: this.apiName,...config });
  

  postGenerateDocumentUploadQrCodeByIdAndValidForHours = (id: string, validForHours: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiQrDocumentUploadDto>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/generate-document-upload-qr-code`,
      params: { validForHours },
    },
    { apiName: this.apiName,...config });
  

  postGenerateEmailConfirmationUrlByIdAndEmailAndValidForHours = (id: string, email: string, validForHours: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/generate-email-confirmation-url`,
      params: { email, validForHours },
    },
    { apiName: this.apiName,...config });
  

  postGenerateQrDocumentUploadUrlByIdAndValidForHours = (id: string, validForHours: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/generate-qr-document-upload-url`,
      params: { validForHours },
    },
    { apiName: this.apiName,...config });
  

  postInsertRequestFileByIdRequestAndDocumentContents = (idRequest: string, documentContents: DocumentContentsStringDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiUploadFilesWithCheckDto>({
      method: 'POST',
      url: '/api/app/gmi-external-url/insert-request-file',
      params: { idRequest },
      body: documentContents,
    },
    { apiName: this.apiName,...config });
  

  postUpdateDateUsedByIdAndDateUsed = (id: string, dateUsed: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/update-date-used`,
      params: { dateUsed },
    },
    { apiName: this.apiName,...config });
  

  postUpdateDateValidByIdAndDateValid = (id: string, dateValid: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external-url/${id}/update-date-valid`,
      params: { dateValid },
    },
    { apiName: this.apiName,...config });
  

  postValidateInternalUrlByInput = (input: GmiInternalUrlDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiExternalUrlValidWithDataDto>({
      method: 'POST',
      url: '/api/app/gmi-external-url/validate-internal-url',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
