import type { DefaultReturnData, FileContentsDto, GmiNotificationDataFile, GmiNotificationDto, GmiNotificationForUserDto, GmiSearchDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiNotificationsService {
  apiName = 'Default';
  

  getCommercialNotificationsForUser = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNotificationForUserDto>({
      method: 'GET',
      url: '/api/app/gmi-notifications/commercial-notifications-for-user',
    },
    { apiName: this.apiName,...config });
  

  getConfirmableNotificationsForUser = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNotificationDto[]>({
      method: 'GET',
      url: '/api/app/gmi-notifications/confirmable-notifications-for-user',
    },
    { apiName: this.apiName,...config });
  

  getHelpNotificationsForUserByLanguageAndInputAndPage = (language: string, input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiNotificationForUserDto>>({
      method: 'GET',
      url: '/api/app/gmi-notifications/help-notifications-for-user',
      params: { language, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getNotificationAllFilesByIdNotification = (idNotification: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-notifications/notification-all-files',
      params: { idNotification },
    },
    { apiName: this.apiName,...config });
  

  getNotificationByIdAndLanguage = (id: number, language: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNotificationDto>({
      method: 'GET',
      url: `/api/app/gmi-notifications/${id}/notification`,
      params: { language },
    },
    { apiName: this.apiName,...config });
  

  getNotificationSingleFileByIdNotificationAndIdFile = (idNotification: number, idFile: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-notifications/notification-single-file',
      params: { idNotification, idFile },
    },
    { apiName: this.apiName,...config });
  

  getNotificationsForUserByLanguageAndInputAndPage = (language: string, input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiNotificationForUserDto>>({
      method: 'GET',
      url: '/api/app/gmi-notifications/notifications-for-user',
      params: { language, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postMarkNotificationAsReadById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-notifications/${id}/mark-notification-as-read`,
    },
    { apiName: this.apiName,...config });
  

  postUploadNotificationDocumentsByInput = (input: GmiNotificationDataFile, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-notifications/upload-notification-documents',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
