import type { DefaultReturnData, ExternalFunctionsDto, GmiExternalFunctionResultsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminExternalFunctionsService {
  apiName = 'Default';
  

  getExternalFunctionById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ExternalFunctionsDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-external-functions/${id}/external-function`,
    },
    { apiName: this.apiName,...config });
  

  getExternalFunctions = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ExternalFunctionsDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-external-functions/external-functions',
    },
    { apiName: this.apiName,...config });
  

  postTryExecuteExternalFunctionById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiExternalFunctionResultsDto[]>({
      method: 'POST',
      url: `/api/app/gmi-admin-external-functions/${id}/try-execute-external-function`,
    },
    { apiName: this.apiName,...config });
  

  postUpdateExternalFunctionByInput = (input: ExternalFunctionsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-external-functions/update-external-function',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
