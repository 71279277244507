import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PublicRequestDto } from '@proxy';
import { WL } from 'src/app/const/definitions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GmiJsonFormComponent } from '../gmi-json-form/gmi-json-form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalizationService } from '@abp/ng.core';

@Component({
	selector: 'gmi-requests-modal',
	templateUrl: './gmi-requests-modal.component.html',
	styleUrls: ['./gmi-requests-modal.component.scss'],
})
export class GmiRequestsModalComponent implements OnInit {
	@Input() requestsSelect: Array<PublicRequestDto>;
	@Input() idCont: number;
	@Input() subTitle: string;
	@ViewChild('requestModalContent') templateRef: TemplateRef<any>;

	WL: WL;
	selectedRequest: string;

	requestOptionsForm: FormGroup;
	allRequestsToSelect: Array<PublicRequestDto>;
	onRequestChange: any;

	constructor(private ngbModal: NgbModal, private formBuilder: FormBuilder, private localizationService: LocalizationService) {
		this.WL = new WL();
	}

	ngOnInit() {}

	buildForm() {
		this.requestOptionsForm = this.formBuilder.group({
			selectedRequest: [null],
		});

		this.requestOptionsForm.valueChanges.subscribe((event) => {
			this.changeSelectedRequest(event);
		});
	}

	openModal() {
		this.allRequestsToSelect = [{ id: null, title: this.localizationService.instant(WL.General_SelectNoSelection) }, ...this.requestsSelect];
		this.buildForm();
		this.ngbModal.open(this.templateRef, { size: 'lg', backdrop: 'static' });
	}

	closeModal() {
		this.ngbModal.dismissAll();
	}

	changeSelectedRequest(event) {
		if (!!event) {
			this.ngbModal.dismissAll();

			const modalRef = this.ngbModal.open(GmiJsonFormComponent, { size: 'lg', backdrop: 'static' });
			modalRef.componentInstance.subTitle = this.subTitle;
			modalRef.componentInstance.idCont = this.idCont;
			modalRef.componentInstance.selectedRequest = this.requestsSelect.find((x) => x.id == event.selectedRequest);
		}
	}
}
