import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbDateAdapter, NgbDatepickerI18n, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { StoreModule } from "@ngrx/store";
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import * as fromApp from './store/app.reducer';
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { CoreModule } from '@abp/ng.core';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { registerLocale } from '@abp/ng.core/locale';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { RouterModule } from '@angular/router';
import { GmiNgbDateAdapter, NgbDateGmiParserFormatter} from './gmiUtils/ngbDateParser'
import { GmiDatepickerI18n, I18n } from "./gmiInjectable/gmi-datePicker-localization";
import { NgSelectModule } from "@ng-select/ng-select";
import { GmiInterceptor } from "./gmiUtils/interceptor";
import { AuthGuardService } from "./shared/auth/auth-guard.service";

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot(fromApp.appReducer),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    NgxSpinnerModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule.forRoot({
      environment: environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    NgxValidateCoreModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateGmiParserFormatter
    },
    {
      provide: NgbDateAdapter,
      useClass: GmiNgbDateAdapter
    },
    {
      provide: LOCALE_ID,
      useValue: 'EN-us'
    },
    APP_ROUTE_PROVIDER,
    I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: GmiDatepickerI18n,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GmiInterceptor,
      multi: true
    },
    AuthGuardService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
