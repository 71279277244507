import type { DefaultReturnData, FileContentsDto, GmiReportMailDto, IdTitleDtoInt } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiReportsService {
  apiName = 'Default';
  

  getRenderStimulsoftReportByReportIdAndId = (reportId: number, id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: `/api/app/gmi-reports/${id}/render-stimulsoft-report/${reportId}`,
    },
    { apiName: this.apiName,...config });
  

  getReportsByBlockIdAndId = (blockId: string, id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'GET',
      url: `/api/app/gmi-reports/${id}/reports/${blockId}`,
    },
    { apiName: this.apiName,...config });
  

  postSendReportByEmailByInput = (input: GmiReportMailDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-reports/send-report-by-email',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
