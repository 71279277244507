import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WL, WS } from 'src/app/const/definitions';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GmiCustomLocalization } from 'src/app/gmiUtils/pipes';
import { GmiTitlesAndStyles } from 'src/app/gmiInjectable/gmi-titles-and-styles';
import { GmiLanguageService } from '../services/gmiLanguage.service';


@Component({
  selector: 'gmi-cookie',
  templateUrl: './gmi-cookie.component.html',
  styleUrls: ['./gmi-cookie.component.scss'],
  providers: [GmiCustomLocalization],
})
export class GmiCookieComponent implements OnInit {
  currentDate: Date = new Date();

  // GMI code
  WL: WL;
  WS: WS;
  ishidden = false;
  cookieInfoPage = "";
  spinner: any;
  selectedReport: number;
  showRenderDiv: boolean;
  showSendDiv: boolean;
  gmiReportsService: any;
  reportsSelect: any;
  cookie_Message: string = this.localizationService.instant(WL.Cookie_Message);
  cookie_Understand: string = this.localizationService.instant(WL.Cookie_Understand);
  cookie_LearnMore: string =  this.localizationService.instant(WL.Cookie_LearnMore);
  languageChange: boolean = false;


  constructor(
    private configStateService: ConfigStateService,
    private ngbModal: NgbModal, private gmiLanguageService: GmiLanguageService,
    private localizationService: LocalizationService,
    public gmiTitlesAndStyles: GmiTitlesAndStyles,
    private cdr: ChangeDetectorRef,
    private gmiCustomLocalization: GmiCustomLocalization,
  ) {
    this.WL = new WL();
    this.WS = new WS(); 
  }

  ngOnInit(): void { 
    this.customTranslations();
    this.localizationService.languageChange$.subscribe((response) => {
    
      this.customTranslations();
    });
  

    if(!localStorage.getItem('cookieConfirmationDate') || !moment(localStorage.getItem('cookieConfirmationDate'), 'yyyy-MM-DD', true).isValid() || moment(localStorage.getItem('cookieConfirmationDate')).isBefore(moment().subtract(1, 'years').format('yyyy-MM-DD'))){
      localStorage.removeItem('cookieConfirmationDate');
      this.ishidden = false;
    }else{
      this.ishidden = true;
    }
  }

  iUnderstand() {
    localStorage.setItem('cookieConfirmationDate', moment().format('yyyy-MM-DD').toString())
    this.ishidden = true;
  }

  customTranslations(){
		this.gmiCustomLocalization.transform(WL.Cookie_Message).subscribe((response) => {
			this.cookie_Message = response;
		});	

    this.gmiCustomLocalization.transform(WL.Cookie_Understand).subscribe((response) => {
			this.cookie_Understand = response;
		});	

    this.gmiCustomLocalization.transform(WL.Cookie_LearnMore).subscribe((response) => {
			this.cookie_LearnMore = response;
		});	
		this.cdr.detectChanges();
  
  }

  learnMore(content) {
    var cookieInfoPage = this.configStateService.getSetting(WS.ApplicationSettings_GmiApplicationCookieInfoPage);
    if (!cookieInfoPage) {
      this.ngbModal.open(content, { size: 'lg' });
    }
    else {
      window.open(cookieInfoPage.toString(), "_blank")
    }
  }


}

