import type { DefaultReturnData, GmiFavoritesDto, GmiNavbarDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiNavbarsService {
  apiName = 'Default';
  

  endUserImpersonation = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/gmi-navbars/end-user-impersonation',
    },
    { apiName: this.apiName,...config });
  

  getNavbarData = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNavbarDto>({
      method: 'GET',
      url: '/api/app/gmi-navbars/navbar-data',
    },
    { apiName: this.apiName,...config });
  

  getUserFavorites = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiFavoritesDto[]>({
      method: 'GET',
      url: '/api/app/gmi-navbars/user-favorites',
    },
    { apiName: this.apiName,...config });
  

  postAddNewUserFavoriteByInput = (input: GmiFavoritesDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-navbars/add-new-user-favorite',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postDeleteUserFavoriteById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-navbars/${id}/delete-user-favorite`,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
