import type { DefaultReturnData, DocumentContentsStringDto, PartnerDataDto, PartnerReturnDataDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiExternalService {
  apiName = 'Default';
  

  getLoginNotification = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/gmi-external/login-notification',
    },
    { apiName: this.apiName,...config });
  

  getLoginTranslation = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/gmi-external/login-translation',
    },
    { apiName: this.apiName,...config });
  

  getPartnerDataByInput = (input: PartnerDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PartnerReturnDataDto>({
      method: 'GET',
      url: '/api/app/gmi-external/partner-data',
      params: { taxNumber: input.taxNumber, datoOfBirth: input.datoOfBirth },
    },
    { apiName: this.apiName,...config });
  

  getRemoveItemFromCacheByTokenAndName = (token: string, name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/app/gmi-external/remove-item-from-cache',
      params: { token, name },
    },
    { apiName: this.apiName,...config });
  

  postDeleteRequestDocumentByIdAndIdDocument = (id: string, idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-external/${id}/delete-request-document`,
      params: { idDocument },
    },
    { apiName: this.apiName,...config });
  

  postDownloadDocumentByIdAndIdDocument = (id: string, idDocument: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DocumentContentsStringDto>({
      method: 'POST',
      url: `/api/app/gmi-external/${id}/download-document`,
      params: { idDocument },
    },
    { apiName: this.apiName,...config });
  

  postVerifyIbanByInput = (input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/gmi-external/verify-iban',
      params: { input },
    },
    { apiName: this.apiName,...config });
  

  refreshCachePermissionsByTokenAndProviderNameAndProviderKeyAndInput = (token: string, providerName: string, providerKey: string, input: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/gmi-external/refresh-cache-permissions',
      params: { token, providerName, providerKey, input },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
