import type { DefaultReturnData, GmiRequestExecutionDto, GmiSearchDto, PublicRequestDto, PublicRequestHistoryDto, UserRequestsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiRequestsService {
  apiName = 'Default';
  

  getRequestHistoryByRequestId = (requestId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PublicRequestHistoryDto>({
      method: 'GET',
      url: `/api/app/gmi-requests/request-history/${requestId}`,
    },
    { apiName: this.apiName,...config });
  

  getRequestsByPageAndId = (page: string, id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PublicRequestDto[]>({
      method: 'GET',
      url: `/api/app/gmi-requests/${id}/requests`,
      params: { page },
    },
    { apiName: this.apiName,...config });
  

  getRequestsSearchByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<UserRequestsDto>>({
      method: 'GET',
      url: '/api/app/gmi-requests/requests-search',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertRequestByInput = (input: GmiRequestExecutionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-requests/insert-request',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
