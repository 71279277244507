import type { DefaultReturnData, GmiAdminPartnersDto, GmiPartnersSearchDto, GmiPartnersSearchParamsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminPartnersService {
  apiName = 'Default';
  

  getPartnersDataByIdPartner = (idPartner: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiAdminPartnersDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-partners/partners-data',
      params: { idPartner },
    },
    { apiName: this.apiName,...config });
  

  getPartnersSearchByInputAndPage = (input: GmiPartnersSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiPartnersSearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-partners/partners-search',
      params: { isVendor: input.isVendor, isVendorPseudo: input.isVendorPseudo, isLessee: input.isLessee, isInsurance: input.isInsurance, isStockFunding: input.isStockFunding, isRecoveryAgency: input.isRecoveryAgency, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertNewPartnerByInput = (input: GmiAdminPartnersDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-partners/insert-new-partner',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putActivatePartnerByIdPartner = (idPartner: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-partners/activate-partner',
      params: { idPartner },
    },
    { apiName: this.apiName,...config });
  

  putDeactivatePartnerByIdPartner = (idPartner: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-partners/deactivate-partner',
      params: { idPartner },
    },
    { apiName: this.apiName,...config });
  

  putUpdatePartnerByInput = (input: GmiAdminPartnersDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-partners/update-partner',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
