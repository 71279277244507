<div class="mb-2">
	<label>{{ this.labelManual }}</label>
</div>

<label for="file" class="add-files btn btn-secondary" [class.disabled]="isUploadedFilesLimit() || !this.enableButtons">{{
	WL.qrFileUpload_BtnAdd | abpLocalization
}}</label>
<input
	type="file"
	#fileInput
	accept=".xlsx,.xls,.pdf,image/jpeg,image/jpg,image/png;capture=camera"
	id="file"
	name="file"
	multiple
	class="file-upload"
	[disabled]="isUploadedFilesLimit() || !this.enableButtons"
	(change)="OnFileUpload($event)" />
<label *ngIf="isUploadedFilesLimit()" class="ms-2 warning">{{ WL.qrFileUpload_MaximumAllowedNumber | abpLocalization }}</label>
<label *ngIf="showMaxSizeFileWarning" class="ms-2 warning"> {{ this.labelMaxSize }}</label>

<div *ngIf="this.files.length > 0">
	<h5 class="mt-2">{{ WL.qrFileUpload_Uploaded | abpLocalization }}</h5>
	<div *ngFor="let file of this.files; let i = index">
		<div class="uploaded-file" [class.malicious]="this.maliciousFile?.includes(file.name)">
			<label>{{ file.name }}</label>
			<div (click)="removeFile(i)"><i class="fa fa-times"></i></div>
		</div>
	</div>
</div>

<button *ngIf="this.files.length > 0" class="btn btn-primary mt-2" [disabled]="this.files.length == 0 || !this.enableButtons" (click)="this.onConfirmClicked()">
	{{ WL.qrFileUpload_BtnConfirm | abpLocalization }}
</button>
<div class="warning" *ngIf="this.maliciousFile?.length > 0 || this.maliciousFileWarning">{{ WL.qrFileUpload_MaliciousContentFound | abpLocalization }}</div>
