import type { DefaultReturnData, FileContentsDto, GmiBlockSearchDto, GmiReportArchiveDto, GmiReportDto, GmiReportsSearchDto, GmiSearchWithIntIdDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminReportsService {
  apiName = 'Default';
  

  getAllReportsByInputAndPage = (input: GmiBlockSearchDto, page: PagedAndSortedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiReportsSearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-reports/reports',
      params: { searchCriteria: input.searchCriteria, blockId: input.blockId, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, sorting: page.sorting, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getReportArchiveById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-reports/${id}/report-archive`,
    },
    { apiName: this.apiName,...config });
  

  getReportArchiveByInput = (input: GmiSearchWithIntIdDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiReportArchiveDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-reports/report-archive',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  getReportsById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiReportDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-reports/${id}/reports`,
    },
    { apiName: this.apiName,...config });
  

  postInsertReportByInput = (input: GmiReportDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-reports/insert-report',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdateReportByInput = (input: GmiReportDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-reports/update-report',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
