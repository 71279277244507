import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { GmiCheckEmail, GmiDateToString } from './functions';
import { WL } from '../const/definitions';
import { NgbDateGmiParserFormatter } from './ngbDateParser';

/**
 * Password validator
 * @param config
 * @param localizationService
 * @returns validationError array wih all the errors
 */
export function GmiPasswordValidator(config: ConfigStateService, localizationService: LocalizationService): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const pwdErrorsArray = [];

		if (control.value !== null && control.value.length > 0) {
			let pwdMinLength = config.getSetting('Abp.Identity.Password.RequiredLength');
			// password length
			if (control.value.length < parseInt(pwdMinLength)) {
				pwdErrorsArray.push(localizationService.instant(WL.Password_ToShort).replace('{0}', pwdMinLength));
			}

			// password digit
			if (config.getSetting('Abp.Identity.Password.RequireDigit') === 'true' && !/[0-9]/.test(control.value)) {
				pwdErrorsArray.push(localizationService.instant(WL.Password_MustHaveDigit));
			}

			// lower case
			if (config.getSetting('Abp.Identity.Password.RequireLowercase') === 'true' && !/[a-z]/.test(control.value)) {
				pwdErrorsArray.push(localizationService.instant(WL.Password_MustHaveLowerCase));
			}

			// upper case
			if (config.getSetting('Abp.Identity.Password.RequireUppercase') === 'true' && !/[A-Z]/.test(control.value)) {
				pwdErrorsArray.push(localizationService.instant(WL.Password_MusthaveUpperCase));
			}

			// non alpha-numeric
			if (config.getSetting('Abp.Identity.Password.RequireNonAlphanumeric') === 'true' && !/[^a-zA-Z\d\s:]/.test(control.value)) {
				pwdErrorsArray.push(localizationService.instant(WL.Password_MusthaveNonAlphaNum));
			}

			return pwdErrorsArray.length > 0 ? { validationError: { value: pwdErrorsArray } } : null;
		} else {
			return null;
		}
	};
}

/**
 * E-Mail validator
 * @param email
 * @returns validationError with the error message
 */
export function GmiEmailValidator(localizationService: LocalizationService, email: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		return GmiCheckEmail(email) ? null : { validationError: { value: localizationService.instant('LesseeUser:DocDeliveryInvalidEmail') } };
	};
}

export function GmiMustNotMatch(controlName: string, matchingControlName: string) {
	//return (formGroup: AbstractControl): ValidationErrors | null => {
	return (formGroup: FormGroup): ValidationErrors | null => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if (matchingControl.errors && !matchingControl.errors.oldNewPasswordError) {
			// return if another validator has already found an error on the matchingControl
			return;
		}

		// set error on matchingControl if validation fails
		if (control.value === matchingControl.value) {
			matchingControl.setErrors({ oldNewPasswordError: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}

export function GmiMustMatch(controlName: string, matchingControlName: string) {
	//return (formGroup: AbstractControl): ValidationErrors | null => {
	return (formGroup: FormGroup): ValidationErrors | null => {
		const control = formGroup.controls[controlName];
		const matchingControl = formGroup.controls[matchingControlName];

		if (matchingControl.errors && !matchingControl.errors.oldNewPasswordError) {
			// return if another validator has already found an error on the matchingControl
			return;
		}

		// set error on matchingControl if validation fails
		if (control.value.length > 0 && matchingControl.value.length > 0 && control.value !== matchingControl.value) {
			matchingControl.setErrors({ oldNewPasswordError: true });
		} else {
			matchingControl.setErrors(null);
		}
	};
}

export function GmiDateYearValidation() {
	return (control: AbstractControl): ValidationErrors | null => {
		// there are 2 date formats, the xml version (yyyy-mm-dd) and the displayed version (dd. mm. yyyy) and if the date is valid it will be in the first format, if it's not in the second
		if (!!control?.value && control?.value.length == 10) {
			return new NgbDateGmiParserFormatter().parseXml(control.value).year < 1900 ? { validationError: { value: 'error' } } : null;
		} else if (!!control?.value && control?.value.length > 0) {
			return { validationError: { value: 'error' } };
		} else {
			return null;
		}
	};
}

export function GmiLengthValidator(min: number, max: number) {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!!min && !!control?.value && control?.value.length < min) {
			return { validationError: { value: 'error' } };
		} else if (!!max && !!control?.value && control?.value.length > max) {
			return { validationError: { value: 'error' } };
		} else {
			return null;
		}
	};
}
