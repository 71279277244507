import {
	Component,
	Output,
	EventEmitter,
	OnDestroy,
	OnInit,
	AfterViewInit,
	ChangeDetectorRef,
	ViewChild,
	ElementRef,
	ViewChildren,
	QueryList,
	HostListener,
} from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, interval } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, ConfigStateService, LanguageInfo, LocalizationService, SessionStateService } from '@abp/ng.core';
import { GmiFavoritesDto, GmiNavbarsService, GmiNotificationsService, GmiNavbarDto, GmiNotificationDto, GmiPersonalizationsService } from '@proxy';
import { AbpOAuthService } from '@abp/ng.oauth';
import Swal from 'sweetalert2';
import { WL } from '../../const/definitions';
import { GmiNavbarService } from '../services/gmiNavbar.service';
import { GmiLocalizationService } from '../services/gmiLocalization.service';
import { GmiTokenService } from '../services/gmiToken.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	currentLang = 'en';
	selectedLanguageText = 'English';
	selectedLanguageFlag = './assets/img/flags/us.png';
	toggleClass = 'ft-maximize';
	placement = 'bottom-right';
	logoUrl = 'assets/img/logo.png';
	menuPosition = 'Side';
	isSmallScreen = false;
	protected innerWidth: any;
	searchOpenClass = '';
	transparentBGClass = '';
	hideSidebar: boolean = true;
	public isCollapsed = true;
	layoutSub: Subscription;
	configSub: Subscription;

	navbarData: GmiNavbarDto;

	@ViewChild('search') searchElement: ElementRef;
	@ViewChildren('searchResults') searchResults: QueryList<any>;

	@Output()
	toggleHideSidebar = new EventEmitter<object>();

	@Output()
	seachTextEmpty = new EventEmitter<boolean>();

	listItems = [];
	control = new FormControl();

	public config: any = {};

	userFavorites: GmiFavoritesDto[];

	userNotificationsUnconfirmed: GmiNotificationDto[];
	notificationsUnconfirmedQueue = [];

	WL: WL;

	cntNewNotifications$;
	cntNewEaDocuments$;

	constructor(
		private layoutService: LayoutService,
		private router: Router,
		private configService: ConfigService,
		private cdr: ChangeDetectorRef,
		private oAuthService: AbpOAuthService,
		private authService: AuthService,
		private sessionStateService: SessionStateService,
		private configStateService: ConfigStateService,
		private localizationService: LocalizationService,
		private gmiNavbarsService: GmiNavbarsService,
		private gmiNotificationsService: GmiNotificationsService,
		protected configState: ConfigStateService,
		private gmiNavbarService: GmiNavbarService,
		private gmiLocalizationService: GmiLocalizationService,
		private gmiPersonalizationsService: GmiPersonalizationsService,
		private gmiTokenService: GmiTokenService
	) {
		//const browserLang: string = translate.getBrowserLang();
		//translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
		this.config = this.configService.templateConf;
		this.innerWidth = window.innerWidth;

		this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
			this.hideSidebar = !isShow;
		});
		this.WL = new WL();
	}

	ngOnInit() {
		// GMI: this.listItems = LISTITEMS;

		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		} else {
			this.isSmallScreen = false;
		}

		// session/token handling - check every minute if the token needs refreshing
		interval(1 * 60 * 1000).subscribe((res) => {
			this.gmiTokenService.tokenCheck(this.oAuthService);
		});

		// have to set the data in navbar to observable
		this.gmiNavbarsService.getNavbarData().subscribe((response) => {
			if (!!response) {
				this.navbarData = response;
				this.gmiNavbarService.setCntUnreadNotifications(response.cntNewNotifications);
				this.gmiNavbarService.setCntNewEaDocuments(response.cntNewDocuments);
			}
		});

		this.gmiNavbarService.getCntNewEaDocuments().subscribe((response) => {
			this.cntNewNotifications$ = response;
		});
		this.gmiNavbarService.getCntUnreadNotifications().subscribe((response) => {
			this.cntNewEaDocuments$ = response;
		});

		//this.refreshFavorites(); not needed right now
		/* get custom translations */
		this.gmiPersonalizationsService.getPersonalization().subscribe((response) => {
			if(!!response){
				this.gmiLocalizationService.setUserCustomLocalization(response);
			}
		});

		this.cdr.detectChanges();
	}

	ngAfterViewInit() {
		this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
			if (templateConf) {
				this.config = templateConf;
			}
			this.loadLayout();
			this.cdr.markForCheck();
		});

		this.prepareNotifications();
	}

	ngOnDestroy() {
		if (this.layoutSub) {
			this.layoutSub.unsubscribe();
		}
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = event.target.innerWidth;
		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		} else {
			this.isSmallScreen = false;
		}
	}

	loadLayout() {
		if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== '') {
			this.menuPosition = this.config.layout.menuPosition;
		}

		if (this.config.layout.variant === 'Light') {
			this.logoUrl = 'assets/img/logo-dark.png';
		} else {
			this.logoUrl = 'assets/img/logo-dark.png';
		}

		if (this.config.layout.variant === 'Transparent') {
			this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
		} else {
			this.transparentBGClass = '';
		}
	}

	onSearchKey(event: any) {
		if (this.searchResults && this.searchResults.length > 0) {
			this.searchResults.first.host.nativeElement.classList.add('first-active-item');
		}

		if (event.target.value === '') {
			this.seachTextEmpty.emit(true);
		} else {
			this.seachTextEmpty.emit(false);
		}
	}

	removeActiveClass() {
		if (this.searchResults && this.searchResults.length > 0) {
			this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
		}
	}

	onEscEvent() {
		this.control.setValue('');
		this.searchOpenClass = '';
		this.seachTextEmpty.emit(true);
	}

	onEnter() {
		if (this.searchResults && this.searchResults.length > 0) {
			let url = this.searchResults.first.url;
			if (url && url != '') {
				this.control.setValue('');
				this.searchOpenClass = '';
				this.router.navigate([url]);
				this.seachTextEmpty.emit(true);
			}
		}
	}

	redirectTo(value) {
		this.router.navigate([value]);
		this.seachTextEmpty.emit(true);
	}

	ToggleClass() {
		if (this.toggleClass === 'ft-maximize') {
			this.toggleClass = 'ft-minimize';
		} else {
			this.toggleClass = 'ft-maximize';
		}
	}

	toggleSearchOpenClass(display) {
		this.control.setValue('');
		if (display) {
			this.searchOpenClass = 'open';
			setTimeout(() => {
				this.searchElement.nativeElement.focus();
			}, 0);
		} else {
			this.searchOpenClass = '';
		}
		this.seachTextEmpty.emit(true);
	}

	toggleNotificationSidebar() {
		this.layoutService.toggleNotificationSidebar(true);
	}

	toggleSidebar() {
		this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
	}

	// GMI code
	logout() {
		if (!!this.navbarData.actualUserName) {
			this.gmiNavbarsService.endUserImpersonation().subscribe(result => {
				if (result) {
					this.authService.logout().subscribe((response) => {
						window.location.reload();
					});
				}
			});
		} else {
			this.authService.logout().subscribe((response) => {
				window.location.reload();
			});
		}
	}

	// Language implementation:
	// https://docs.abp.io/en/abp/latest/UI/Angular/Component-Replacement

	languages: LanguageInfo[] = this.configStateService.getDeep('localization.languages');

	get defaultLanguage() {
		const language = this.languages.find((lang) => lang.cultureName === this.sessionStateService.getLanguage());
		language.flagIcon = `./assets/img/flags/${language.cultureName}.svg`;
		return language;
	}

	get dropdownLanguages() {
		const languages = this.languages.filter((lang) => lang.cultureName !== this.sessionStateService.getLanguage());
		languages.forEach((lang) => (lang.flagIcon = `./assets/img/flags/${lang.cultureName}.svg`));
		return languages;
	}

	onChangeLang(cultureName: string) {
		this.sessionStateService.setLanguage(cultureName);
		this.configState.refreshAppState();
		window.location.reload();
	}

	// notifications

	showConfirmation(n: number) {
		if (n >= 0) {
			Swal.fire(this.notificationsUnconfirmedQueue[n]).then((result) => {
				if (result.isConfirmed) {
					var notification = this.userNotificationsUnconfirmed.find((x) => x.title == this.notificationsUnconfirmedQueue[n].title);
					this.router.navigate(['notification'], { state: { idNotification: notification.id } });
					return;
				}
				this.showConfirmation(n - 1);
			});
		}
	}

	prepareNotifications() {
		this.gmiNotificationsService.getConfirmableNotificationsForUser().subscribe((response) => {
			this.userNotificationsUnconfirmed = response;

			if (response.length > 0) {
				response.forEach((element) => {
					this.notificationsUnconfirmedQueue.push({
						title: element.title,
						icon: 'info',
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonText: this.localizationService.instant(WL.General_ButtonMore),
						cancelButtonText: this.localizationService.instant(WL.General_ButtonCancel),
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false,
						customClass: {
							confirmButton: 'gmi-swal-btn-confirm',
							cancelButton: 'gmi-swal-btn-cancel',
						},
					});
				});

				this.showConfirmation(this.notificationsUnconfirmedQueue.length - 1);
			}
		});
	}

	// Favourites
	Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000,
		timerProgressBar: true,
	});

	refreshFavorites() {
		this.gmiNavbarsService.getUserFavorites().subscribe((response) => {
			this.userFavorites = response.sort(function (a, b) {
				if (a.linkOrder < b.linkOrder) return -1;
				if (a.linkOrder > b.linkOrder) return 1;
				return 0;
			});
			this.cdr.markForCheck();
		});
	}

	redirectToEaDocs() {
		this.router.navigate(['eaDocuments'], { state: { unread: true } });
	}

	/*
  removeFavorite(id: number) {
    Swal.fire({
      title: this.localizationService.instant('::Favorites:DeleteFavorite'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.gmiNavbarService.postDeleteUserFavoriteById(id).subscribe((response) => {
          if (!response.isError) {
            this.Toast.fire({
              icon: 'success',
              title: this.localizationService.instant('::Favorites:FavoriteHasBeenDeleted')
            })
            this.refreshFavorites();
          }
        });
      }
    })
  }

  addNewFavorite() {
    if (this.userFavorites?.some(x => x.linkUrl == this.router.url)) {
      Swal.fire({
        title: this.localizationService.instant('::Favorites:AlreadyFavorited'),
        icon: 'info',
        showCancelButton: true
      })
    } else {
      Swal.fire({
        title: this.localizationService.instant('::Favorites:DeclareFavoriteName'),
        input: 'text',
        inputValidator: result => !result && this.localizationService.instant('::Favorites:EmptyField')
      }).then((result) => {
        if (!!result && !result.dismiss) {
          Swal.fire({
            title: this.localizationService.instant('::Favorites:DeclareFavoritePosition'),
            input: 'number',
            inputValidator: result => !result && this.localizationService.instant('::Favorites:EmptyField')
          }).then((result1) => {
            if (!!result1 && !result1.dismiss) {
              let newFavorite = {} as GmiFavoritesDto;
              newFavorite.linkName = result.value;
              newFavorite.linkUrl = this.router.url;
              newFavorite.linkOrder = result1.value;
              this.gmiNavbarService.postAddNewUserFavoriteByInput(newFavorite).subscribe((response) => {
                if (!response.isError) {
                  this.Toast.fire({
                    icon: 'success',
                    title: this.localizationService.instant('::Favorites:FavoriteAdded'),

                  })
                  this.refreshFavorites();
                } else {
                  this.Toast.fire({
                    icon: 'error',
                    title: response.errorMsg
                  })
                }
              })
            }
          })
        }
      });
    }
  }*/
}
