import { mapEnumToOptions } from '@abp/ng.core';

export enum GmiTwoFactorType {
  None = 0,
  AuthenticationApplication = 1,
  Email = 2,
  UserChoice = 10,
}

export const gmiTwoFactorTypeOptions = mapEnumToOptions(GmiTwoFactorType);
