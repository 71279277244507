import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES_ALL, ROUTES_HOME, ROUTES_INSURANCE, ROUTES_LESSEE, ROUTES_STOCKFUNDING } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { ConfigStateService, LocalizationService, PermissionService } from '@abp/ng.core';
import { RouteInfo } from './vertical-menu.metadata';
import { GmiTitlesAndStyles } from 'src/app/gmiInjectable/gmi-titles-and-styles';
import { GmiPolicyService } from "../services/gmiPolicy.service";
import { GmiCheckMenuPermissions } from "src/app/gmiUtils/functions";
import { NgxSpinnerService } from "ngx-spinner";
import { WL } from "src/app/const/definitions";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  menuLoaded = false;
  WL: WL;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private localizationService: LocalizationService,
    public gmiTitlesAndStyles: GmiTitlesAndStyles,
    private permissionService: PermissionService,
    private gmiPolicyService: GmiPolicyService,
    private spinner: NgxSpinnerService,
    private configStateService: ConfigStateService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
    this.WL = new WL();
  }

  getGmiRoutes(): RouteInfo[] {
    let gmiRoutes: RouteInfo[] = [];
    return gmiRoutes;
  }

  ngOnInit() {
    this.isDone(); // GMI
  }

  isDone(cnt: number = 0): void {
    if (this.gmiTitlesAndStyles != null && this.gmiTitlesAndStyles?.done) {
      this.cdr.detectChanges();
      return;
    }
    cnt++;
    setTimeout(() => {
      if (this.gmiTitlesAndStyles != null && this.gmiTitlesAndStyles?.done) {
        this.cdr.detectChanges();
        return;
      }
      if (cnt < 50) this.isDone(cnt);
    }, 100);
  }

  ngAfterViewInit() {
    this.spinner.show("MENU_SPINNER");
    this.loadLayout();

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.config.layout.menuPosition === "Top") {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
    }
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu
      
      this.gmiPolicyService.getPolicySettings().subscribe((response) => {
        // first check if the users is in multiple roles
        let multipleRoles = false;

        // if the user is only Lessee and Insurance then he is no in multiple roles
        if ((response.isLessee || response.isInsurance) && (response.isRecoveryAgency || response.isStockFunding || response.isVendor || response.isVendorPseudo)) {
          multipleRoles = true;
        }

        // build the menu structure base on the role(s) the user is in
        let routesLessee = multipleRoles ? ROUTES_LESSEE : ROUTES_LESSEE[0].submenu;
        let routesInsurance = multipleRoles ? ROUTES_INSURANCE : ROUTES_INSURANCE[0].submenu;

        this.menuItems = [...ROUTES_HOME, ...routesLessee, ...routesInsurance, ...ROUTES_STOCKFUNDING, ...ROUTES_ALL]
        this.menuItems = this.menuItems.filter((element) => {
          return GmiCheckMenuPermissions(this.permissionService, this.gmiPolicyService, this.configStateService, element);
        });
  
        this.menuItems.forEach((element0) => {
          element0.submenu = element0.submenu.filter((element1) => {
            return GmiCheckMenuPermissions(this.permissionService, this.gmiPolicyService, this.configStateService, element1);
          })
        });
  
        // GMI: Translate the titles
        this.menuItems.forEach((element0) => {
          if (element0.title.indexOf('::') == 0) element0.title = this.localizationService.instant(element0.title);
          if (!!element0.submenu) {
            element0.submenu.forEach((element1) => {
              if (element1.title.indexOf('::') == 0) element1.title = this.localizationService.instant(element1.title);
            });
          }
        });
  
        this.menuLoaded = true;
        this.cdr.detectChanges();
        this.spinner.hide("MENU_SPINNER");
      });
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
