<div>
	<div class="modal-header">
		<h3 class="modal-title">{{ selectedRequest.title }}</h3>
		<h4 *ngIf="!!this.subTitle">{{ this.subTitle }}</h4>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeDynamicModal()"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="myForm" *ngIf="formDataParsed">
			<div *ngFor="let control of formControls?.controls">
				<!-- text control -->
				<div class="col pb-3 px-4" *ngIf="control.type == 'text'">
					<label [for]="control.name">{{ control.label }}</label>
					<input
						type="text"
						class="form-control"
						[id]="control.name"
						[formControlName]="control.name"
						[ngbTooltip]="control.tooltip"
						placement="bottom-right"
						[ngClass]="{
							'is-valid': myForm.get(control.name).touched && myForm.get(control.name).valid,
							'is-invalid': myForm.get(control.name).touched && myForm.get(control.name).invalid
						}" />
					<div
						*ngIf="!!control.errorMsg && myForm.get(control.name).touched && myForm.get(control.name).invalid"
						class="invalid-tooltip gmi-invalid-tooltip">
						{{ control.errorMsg }}
					</div>
				</div>
				<!-- checkbox control -->
				<div class="custom-control custom-checkbox pb-3 px-4" *ngIf="control.type == 'checkbox'">
					<input type="checkbox" class="custom-control-input" [id]="control.name" [formControlName]="control.name" />
					<label class="custom-control-label" [for]="control.name"
						><span>{{ control.label }}</span>
					</label>
				</div>
				<!-- select control -->
				<div class="pb-3 px-4" *ngIf="control.type == 'select'">
					<ng-select
						[appendTo]="'body'"
						[items]="control.selectOptions"
						[id]="control.name"
						[formControlName]="control.name"
						bindValue="id"
						bindLabel="title">
					</ng-select>
				</div>
				<!-- datepicker control -->
				<div *ngIf="control.type == 'datePicker'" class="col pb-3 px-4">
					<label for="{{ control.name }}">{{ control.label }}</label>
					<div class="input-group">
						<input
							class="form-control"
							name="dp"
							[formControlName]="control.name"
							ngbDatepicker
							#d1="ngbDatepicker"
							container="body"
							gmiDatepickerTooltip
							gmiFormatDate
							gmiRestrictDateInput
							maxlength="12"
							[minDate]="calcDate(control.minDate)"
							[maxDate]="calcDate(control.maxDate)"
							[ngbTooltip]="control.tooltip"
							placement="bottom-right"
							[ngClass]="{
								'is-invalid': myForm.get(control.name).touched && !myForm.get(control.name).valid,
								'is-valid': myForm.get(control.name).touched && myForm.get(control.name).valid
							}" />
						<div
							*ngIf="!!control.errorMsg && myForm.get(control.name).touched && !myForm.get(control.name).valid"
							class="invalid-tooltip gmi-invalid-tooltip-datepicker">
							{{ control.errorMsg }}
						</div>
						<button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
							<i class="fa fa-calendar"></i>
						</button>
					</div>
				</div>
				<div *ngIf="control.type == 'documents'" class="pb-3 px-4">
					<div>
						<input name="addFilesForUpload" type="file" (change)="uploadFile($event)" multiple />
						<ul *ngIf="!!this.selectedFiles">
							<li *ngFor="let item of this.selectedFiles">
								{{ item.name }}
								<i class="fa fa-minus" (click)="removeFile(item.name)"></i>
							</li>
						</ul>
					</div>
				</div>
				<div *ngIf="control.type == 'label'" class="pb-3 px-4">
					<div>
						<div *ngIf="!!control.label">
							<label>{{ control.label }}</label>
						</div>
						<div>
							{{ control.value }}
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="modal-footer">
			<button type="button" class="btn btn-secondary" (click)="closeDynamicModal()">
				{{ WL.general_ButtonClose | abpLocalization }}
			</button>
			<button class="btn btn-danger" (click)="submitForm()" [disabled]="!myForm.valid">
				{{ WL.gmiRequests_SendRequest | abpLocalization }}
			</button>
		</div>
	</div>
</div>
