import { NgModule } from '@angular/core';
import {
	GmiDateFormatFromDate,
	GmiDateFormatFromString,
	GmiDateFormatServer,
	GmiDecimalFormat,
	GmiStringFormat,
	GmiStripHtml,
	GmiCurrencySymbol,
	GmiDateAndTimeFormatFromString,
	GmiSubString,
	GmiSafePipe,
	GmiPercentFormat,
	GmiCustomLocalization,
} from './pipes';

@NgModule({
	declarations: [
		GmiDateFormatFromDate,
		GmiDateFormatFromString,
		GmiDateAndTimeFormatFromString,
		GmiDateFormatServer,
		GmiDecimalFormat,
		GmiCurrencySymbol,
		GmiStringFormat,
		GmiStripHtml,
		GmiSubString,
		GmiSafePipe,
		GmiPercentFormat,
		GmiCustomLocalization,
	],
	imports: [],
	exports: [
		GmiDateFormatFromDate,
		GmiDateFormatFromString,
		GmiDateAndTimeFormatFromString,
		GmiDateFormatServer,
		GmiDecimalFormat,
		GmiCurrencySymbol,
		GmiStringFormat,
		GmiStripHtml,
		GmiSubString,
		GmiSafePipe,
		GmiPercentFormat,
		GmiCustomLocalization,
	],
})
export class GmiPipesModule {}
