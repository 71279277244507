import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'gmi-content-pages-message',
	templateUrl: './gmi-content-pages-message.component.html',
	styleUrls: ['./gmi-content-pages-message.component.scss'],
})
//export class GmiSpinner implements OnInit {
export class GmiContentPagesMessage implements OnInit {
	@Input() alertType: string;
	@Input() alertMessage: string;

	constructor() {}

	ngOnInit(): void {}
}
