import { LocalizationService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WL } from '../const/definitions';

@Injectable()
export class I18n {
	language = 'sl';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class GmiDatepickerI18n extends NgbDatepickerI18n {
	WL: WL;

	I18N_VALUES = {
		weekdays: [
			this.localizationService.instant(WL.Day_MondayShort),
			this.localizationService.instant(WL.Day_TuesdayShort),
			this.localizationService.instant(WL.Day_WednesdayShort),
			this.localizationService.instant(WL.Day_ThursdayShort),
			this.localizationService.instant(WL.Day_FridayShort),
			this.localizationService.instant(WL.Day_SaturdayShort),
			this.localizationService.instant(WL.Day_SundayShort),
		],
		months: [
			this.localizationService.instant(WL.Month_January),
			this.localizationService.instant(WL.Month_February),
			this.localizationService.instant(WL.Month_March),
			this.localizationService.instant(WL.Month_April),
			this.localizationService.instant(WL.Month_May),
			this.localizationService.instant(WL.Month_June),
			this.localizationService.instant(WL.Month_July),
			this.localizationService.instant(WL.Month_August),
			this.localizationService.instant(WL.Month_September),
			this.localizationService.instant(WL.Month_October),
			this.localizationService.instant(WL.Month_November),
			this.localizationService.instant(WL.Month_December),
		],
		weekLabel: 'sem',
		//},
		// other languages you would support
	};

	getWeekdayShortName(weekday: number): string {
		return this.I18N_VALUES.weekdays[weekday - 1];
	}
	constructor(private _i18n: I18n, private localizationService: LocalizationService) {
		super();
		this.WL = new WL();
	}

	getWeekdayLabel(weekday: number): string {
		return this.I18N_VALUES.weekdays[weekday - 1];
	}
	getWeekLabel(): string {
		return this.I18N_VALUES.weekLabel;
	}
	getMonthShortName(month: number): string {
		return this.I18N_VALUES.months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}
