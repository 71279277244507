export class OnlineLocalizations {
  public abpActionLogs_ChangedPasswordAdmin: string = '::AbpActionLogs:ChangedPasswordAdmin';
  public static AbpActionLogs_ChangedPasswordAdmin: string = '::AbpActionLogs:ChangedPasswordAdmin';
  public abpActionLogs_ChangeEmail: string = '::AbpActionLogs:ChangeEmail';
  public static AbpActionLogs_ChangeEmail: string = '::AbpActionLogs:ChangeEmail';
  public abpActionLogs_ChangePassword: string = '::AbpActionLogs:ChangePassword';
  public static AbpActionLogs_ChangePassword: string = '::AbpActionLogs:ChangePassword';
  public abpActionLogs_ChangePhoneNumber: string = '::AbpActionLogs:ChangePhoneNumber';
  public static AbpActionLogs_ChangePhoneNumber: string = '::AbpActionLogs:ChangePhoneNumber';
  public abpActionLogs_ChangeUserName: string = '::AbpActionLogs:ChangeUserName';
  public static AbpActionLogs_ChangeUserName: string = '::AbpActionLogs:ChangeUserName';
  public abpActionLogs_LoginFailed: string = '::AbpActionLogs:LoginFailed';
  public static AbpActionLogs_LoginFailed: string = '::AbpActionLogs:LoginFailed';
  public abpActionLogs_LoginInvalidUserName: string = '::AbpActionLogs:LoginInvalidUserName';
  public static AbpActionLogs_LoginInvalidUserName: string = '::AbpActionLogs:LoginInvalidUserName';
  public abpActionLogs_LoginInvalidUserNameOrPassword: string = '::AbpActionLogs:LoginInvalidUserNameOrPassword';
  public static AbpActionLogs_LoginInvalidUserNameOrPassword: string = '::AbpActionLogs:LoginInvalidUserNameOrPassword';
  public abpActionLogs_LoginLockedout: string = '::AbpActionLogs:LoginLockedout';
  public static AbpActionLogs_LoginLockedout: string = '::AbpActionLogs:LoginLockedout';
  public abpActionLogs_LoginNotAllowed: string = '::AbpActionLogs:LoginNotAllowed';
  public static AbpActionLogs_LoginNotAllowed: string = '::AbpActionLogs:LoginNotAllowed';
  public abpActionLogs_LoginRequiresTwoFactor: string = '::AbpActionLogs:LoginRequiresTwoFactor';
  public static AbpActionLogs_LoginRequiresTwoFactor: string = '::AbpActionLogs:LoginRequiresTwoFactor';
  public abpActionLogs_LoginSucceeded: string = '::AbpActionLogs:LoginSucceeded';
  public static AbpActionLogs_LoginSucceeded: string = '::AbpActionLogs:LoginSucceeded';
  public abpActionLogs_Logout: string = '::AbpActionLogs:Logout';
  public static AbpActionLogs_Logout: string = '::AbpActionLogs:Logout';
  public abpActionLogs_TwoFactorDisabled: string = '::AbpActionLogs:TwoFactorDisabled';
  public static AbpActionLogs_TwoFactorDisabled: string = '::AbpActionLogs:TwoFactorDisabled';
  public abpActionLogs_TwoFactorEnabled: string = '::AbpActionLogs:TwoFactorEnabled';
  public static AbpActionLogs_TwoFactorEnabled: string = '::AbpActionLogs:TwoFactorEnabled';
  public actions: string = '::Actions';
  public static Actions: string = '::Actions';
  public calc_EXRateIndexOutdated: string = '::Calc:EXRateIndexOutdated';
  public static Calc_EXRateIndexOutdated: string = '::Calc:EXRateIndexOutdated';
  public calc_IRIndexRateOutdated: string = '::Calc:IRIndexRateOutdated';
  public static Calc_IRIndexRateOutdated: string = '::Calc:IRIndexRateOutdated';
  public calc_MaxMfEOM: string = '::Calc:MaxMfEOM';
  public static Calc_MaxMfEOM: string = '::Calc:MaxMfEOM';
  public calculation_ErrorAmPlanCouldNotBeGenerated: string = '::Calculation:ErrorAmPlanCouldNotBeGenerated';
  public static Calculation_ErrorAmPlanCouldNotBeGenerated: string = '::Calculation:ErrorAmPlanCouldNotBeGenerated';
  public calculation_ErrorCalcNotOk: string = '::Calculation:ErrorCalcNotOk';
  public static Calculation_ErrorCalcNotOk: string = '::Calculation:ErrorCalcNotOk';
  public calculation_ErrorCostsInCredit: string = '::Calculation:ErrorCostsInCredit';
  public static Calculation_ErrorCostsInCredit: string = '::Calculation:ErrorCostsInCredit';
  public calculation_ErrorEOMCalculation: string = '::Calculation:ErrorEOMCalculation';
  public static Calculation_ErrorEOMCalculation: string = '::Calculation:ErrorEOMCalculation';
  public calculation_ErrorEomIr: string = '::Calculation:ErrorEomIr';
  public static Calculation_ErrorEomIr: string = '::Calculation:ErrorEomIr';
  public calculation_ErrorEomTooHigh: string = '::Calculation:ErrorEomTooHigh';
  public static Calculation_ErrorEomTooHigh: string = '::Calculation:ErrorEomTooHigh';
  public calculation_ErrorInstallmentCalculation: string = '::Calculation:ErrorInstallmentCalculation';
  public static Calculation_ErrorInstallmentCalculation: string = '::Calculation:ErrorInstallmentCalculation';
  public calculation_ErrorMaxMfEOM: string = '::Calculation:ErrorMaxMfEOM';
  public static Calculation_ErrorMaxMfEOM: string = '::Calculation:ErrorMaxMfEOM';
  public calculation_ErrorMissingAssetBasedObjectRvFilter: string = '::Calculation:ErrorMissingAssetBasedObjectRvFilter';
  public static Calculation_ErrorMissingAssetBasedObjectRvFilter: string = '::Calculation:ErrorMissingAssetBasedObjectRvFilter';
  public calculation_ErrorMissingAssetBasedRvDefinition: string = '::Calculation:ErrorMissingAssetBasedRvDefinition';
  public static Calculation_ErrorMissingAssetBasedRvDefinition: string = '::Calculation:ErrorMissingAssetBasedRvDefinition';
  public calculation_ErrorMissingRvDefinition: string = '::Calculation:ErrorMissingRvDefinition';
  public static Calculation_ErrorMissingRvDefinition: string = '::Calculation:ErrorMissingRvDefinition';
  public calculation_ErrorMissingRvObject: string = '::Calculation:ErrorMissingRvObject';
  public static Calculation_ErrorMissingRvObject: string = '::Calculation:ErrorMissingRvObject';
  public calculation_ErrorMissingRvObjectPrice: string = '::Calculation:ErrorMissingRvObjectPrice';
  public static Calculation_ErrorMissingRvObjectPrice: string = '::Calculation:ErrorMissingRvObjectPrice';
  public calculation_ErrorValueTooBig: string = '::Calculation:ErrorValueTooBig';
  public static Calculation_ErrorValueTooBig: string = '::Calculation:ErrorValueTooBig';
  public calculation_ErrorValueTooSmall: string = '::Calculation:ErrorValueTooSmall';
  public static Calculation_ErrorValueTooSmall: string = '::Calculation:ErrorValueTooSmall';
  public contracts_ActivationDate: string = '::Contracts:ActivationDate';
  public static Contracts_ActivationDate: string = '::Contracts:ActivationDate';
  public contracts_ActivityStatus: string = '::Contracts:ActivityStatus';
  public static Contracts_ActivityStatus: string = '::Contracts:ActivityStatus';
  public contracts_ActualInterestRate: string = '::Contracts:ActualInterestRate';
  public static Contracts_ActualInterestRate: string = '::Contracts:ActualInterestRate';
  public contracts_Balance: string = '::Contracts:Balance';
  public static Contracts_Balance: string = '::Contracts:Balance';
  public contracts_ClaimType: string = '::Contracts:ClaimType';
  public static Contracts_ClaimType: string = '::Contracts:ClaimType';
  public contracts_ConclusionDate: string = '::Contracts:ConclusionDate';
  public static Contracts_ConclusionDate: string = '::Contracts:ConclusionDate';
  public contracts_ConclusionDateShort: string = '::Contracts:ConclusionDateShort';
  public static Contracts_ConclusionDateShort: string = '::Contracts:ConclusionDateShort';
  public contracts_Credit: string = '::Contracts:Credit';
  public static Contracts_Credit: string = '::Contracts:Credit';
  public contracts_DbStatus: string = '::Contracts:DbStatus';
  public static Contracts_DbStatus: string = '::Contracts:DbStatus';
  public contracts_Debit: string = '::Contracts:Debit';
  public static Contracts_Debit: string = '::Contracts:Debit';
  public contracts_DueDate: string = '::Contracts:DueDate';
  public static Contracts_DueDate: string = '::Contracts:DueDate';
  public contracts_DueDateShort: string = '::Contracts:DueDateShort';
  public static Contracts_DueDateShort: string = '::Contracts:DueDateShort';
  public contracts_EffectiveIr: string = '::Contracts:EffectiveIr';
  public static Contracts_EffectiveIr: string = '::Contracts:EffectiveIr';
  public contracts_FinancedValue: string = '::Contracts:FinancedValue';
  public static Contracts_FinancedValue: string = '::Contracts:FinancedValue';
  public contracts_FinancedValueShort: string = '::Contracts:FinancedValueShort';
  public static Contracts_FinancedValueShort: string = '::Contracts:FinancedValueShort';
  public contracts_FutureCapital: string = '::Contracts:FutureCapital';
  public static Contracts_FutureCapital: string = '::Contracts:FutureCapital';
  public contracts_GuarantorEmployer: string = '::Contracts:GuarantorEmployer';
  public static Contracts_GuarantorEmployer: string = '::Contracts:GuarantorEmployer';
  public contracts_GuarantorId: string = '::Contracts:GuarantorId';
  public static Contracts_GuarantorId: string = '::Contracts:GuarantorId';
  public contracts_GuarantorName: string = '::Contracts:GuarantorName';
  public static Contracts_GuarantorName: string = '::Contracts:GuarantorName';
  public contracts_GuarantorRole: string = '::Contracts:GuarantorRole';
  public static Contracts_GuarantorRole: string = '::Contracts:GuarantorRole';
  public contracts_GuarantorTitle: string = '::Contracts:GuarantorTitle';
  public static Contracts_GuarantorTitle: string = '::Contracts:GuarantorTitle';
  public contracts_HeaderBalance: string = '::Contracts:HeaderBalance';
  public static Contracts_HeaderBalance: string = '::Contracts:HeaderBalance';
  public contracts_HeaderBasicData: string = '::Contracts:HeaderBasicData';
  public static Contracts_HeaderBasicData: string = '::Contracts:HeaderBasicData';
  public contracts_HeaderDetails: string = '::Contracts:HeaderDetails';
  public static Contracts_HeaderDetails: string = '::Contracts:HeaderDetails';
  public contracts_HeaderInterestRate: string = '::Contracts:HeaderInterestRate';
  public static Contracts_HeaderInterestRate: string = '::Contracts:HeaderInterestRate';
  public contracts_IdPog: string = '::Contracts:IdPog';
  public static Contracts_IdPog: string = '::Contracts:IdPog';
  public contracts_IdPogShort: string = '::Contracts:IdPogShort';
  public static Contracts_IdPogShort: string = '::Contracts:IdPogShort';
  public contracts_InstallmentNumber: string = '::Contracts:InstallmentNumber';
  public static Contracts_InstallmentNumber: string = '::Contracts:InstallmentNumber';
  public contracts_InterestRate: string = '::Contracts:InterestRate';
  public static Contracts_InterestRate: string = '::Contracts:InterestRate';
  public contracts_InterestRateCalculationMode: string = '::Contracts:InterestRateCalculationMode';
  public static Contracts_InterestRateCalculationMode: string = '::Contracts:InterestRateCalculationMode';
  public contracts_InterestRateConformably: string = '::Contracts:InterestRateConformably';
  public static Contracts_InterestRateConformably: string = '::Contracts:InterestRateConformably';
  public contracts_InterestRateLinear: string = '::Contracts:InterestRateLinear';
  public static Contracts_InterestRateLinear: string = '::Contracts:InterestRateLinear';
  public contracts_InterestRateMargin: string = '::Contracts:InterestRateMargin';
  public static Contracts_InterestRateMargin: string = '::Contracts:InterestRateMargin';
  public contracts_InterestRateTolerance: string = '::Contracts:InterestRateTolerance';
  public static Contracts_InterestRateTolerance: string = '::Contracts:InterestRateTolerance';
  public contracts_IrIndex: string = '::Contracts:IrIndex';
  public static Contracts_IrIndex: string = '::Contracts:IrIndex';
  public contracts_LastIr: string = '::Contracts:LastIr';
  public static Contracts_LastIr: string = '::Contracts:LastIr';
  public contracts_LastIrChange: string = '::Contracts:LastIrChange';
  public static Contracts_LastIrChange: string = '::Contracts:LastIrChange';
  public contracts_LeaseObject: string = '::Contracts:LeaseObject';
  public static Contracts_LeaseObject: string = '::Contracts:LeaseObject';
  public contracts_LeaseType: string = '::Contracts:LeaseType';
  public static Contracts_LeaseType: string = '::Contracts:LeaseType';
  public contracts_LegalType: string = '::Contracts:LegalType';
  public static Contracts_LegalType: string = '::Contracts:LegalType';
  public contracts_LesseeTitle: string = '::Contracts:LesseeTitle';
  public static Contracts_LesseeTitle: string = '::Contracts:LesseeTitle';
  public contracts_Net: string = '::Contracts:Net';
  public static Contracts_Net: string = '::Contracts:Net';
  public contracts_NextIrChange: string = '::Contracts:NextIrChange';
  public static Contracts_NextIrChange: string = '::Contracts:NextIrChange';
  public contracts_NumFutureInstallments: string = '::Contracts:NumFutureInstallments';
  public static Contracts_NumFutureInstallments: string = '::Contracts:NumFutureInstallments';
  public contracts_OpenDebt: string = '::Contracts:OpenDebt';
  public static Contracts_OpenDebt: string = '::Contracts:OpenDebt';
  public contracts_Other: string = '::Contracts:Other';
  public static Contracts_Other: string = '::Contracts:Other';
  public contracts_Overdue: string = '::Contracts:Overdue';
  public static Contracts_Overdue: string = '::Contracts:Overdue';
  public contracts_OverdueContractNumber: string = '::Contracts:OverdueContractNumber';
  public static Contracts_OverdueContractNumber: string = '::Contracts:OverdueContractNumber';
  public contracts_OverduePaymentsTitle: string = '::Contracts:OverduePaymentsTitle';
  public static Contracts_OverduePaymentsTitle: string = '::Contracts:OverduePaymentsTitle';
  public contracts_PaymentsPerYear: string = '::Contracts:PaymentsPerYear';
  public static Contracts_PaymentsPerYear: string = '::Contracts:PaymentsPerYear';
  public contracts_PlanpTitle: string = '::Contracts:PlanpTitle';
  public static Contracts_PlanpTitle: string = '::Contracts:PlanpTitle';
  public contracts_PresentValue: string = '::Contracts:PresentValue';
  public static Contracts_PresentValue: string = '::Contracts:PresentValue';
  public contracts_ReferenceNumber: string = '::Contracts:ReferenceNumber';
  public static Contracts_ReferenceNumber: string = '::Contracts:ReferenceNumber';
  public contracts_RegistrationNumber: string = '::Contracts:RegistrationNumber';
  public static Contracts_RegistrationNumber: string = '::Contracts:RegistrationNumber';
  public contracts_RoleGuarantorToolTip: string = '::Contracts:RoleGuarantorToolTip';
  public static Contracts_RoleGuarantorToolTip: string = '::Contracts:RoleGuarantorToolTip';
  public contracts_RoleLesseeToolTip: string = '::Contracts:RoleLesseeToolTip';
  public static Contracts_RoleLesseeToolTip: string = '::Contracts:RoleLesseeToolTip';
  public contracts_ShowPaidClaims: string = '::Contracts:ShowPaidClaims';
  public static Contracts_ShowPaidClaims: string = '::Contracts:ShowPaidClaims';
  public contracts_StatusActive: string = '::Contracts:StatusActive';
  public static Contracts_StatusActive: string = '::Contracts:StatusActive';
  public contracts_StatusClosed: string = '::Contracts:StatusClosed';
  public static Contracts_StatusClosed: string = '::Contracts:StatusClosed';
  public contracts_StatusInactive: string = '::Contracts:StatusInactive';
  public static Contracts_StatusInactive: string = '::Contracts:StatusInactive';
  public contracts_StatusPartiallyActive: string = '::Contracts:StatusPartiallyActive';
  public static Contracts_StatusPartiallyActive: string = '::Contracts:StatusPartiallyActive';
  public contracts_SumExDueClaimsCount: string = '::Contracts:SumExDueClaimsCount';
  public static Contracts_SumExDueClaimsCount: string = '::Contracts:SumExDueClaimsCount';
  public contracts_SumExFutureDebt: string = '::Contracts:SumExFutureDebt';
  public static Contracts_SumExFutureDebt: string = '::Contracts:SumExFutureDebt';
  public contracts_SumExFutureInstallments: string = '::Contracts:SumExFutureInstallments';
  public static Contracts_SumExFutureInstallments: string = '::Contracts:SumExFutureInstallments';
  public contracts_Tax: string = '::Contracts:Tax';
  public static Contracts_Tax: string = '::Contracts:Tax';
  public contracts_Title: string = '::Contracts:Title';
  public static Contracts_Title: string = '::Contracts:Title';
  public contracts_TotalDebt: string = '::Contracts:TotalDebt';
  public static Contracts_TotalDebt: string = '::Contracts:TotalDebt';
  public contracts_VinSerial: string = '::Contracts:VinSerial';
  public static Contracts_VinSerial: string = '::Contracts:VinSerial';
  public cookie_FirstPage: string = '::Cookie:FirstPage';
  public static Cookie_FirstPage: string = '::Cookie:FirstPage';
  public cookie_InfoPage1: string = '::Cookie:InfoPage1';
  public static Cookie_InfoPage1: string = '::Cookie:InfoPage1';
  public cookie_InfoPage2: string = '::Cookie:InfoPage2';
  public static Cookie_InfoPage2: string = '::Cookie:InfoPage2';
  public cookie_InfoPage3: string = '::Cookie:InfoPage3';
  public static Cookie_InfoPage3: string = '::Cookie:InfoPage3';
  public cookie_InfoPage4: string = '::Cookie:InfoPage4';
  public static Cookie_InfoPage4: string = '::Cookie:InfoPage4';
  public cookie_Information: string = '::Cookie:Information';
  public static Cookie_Information: string = '::Cookie:Information';
  public cookie_LearnMore: string = '::Cookie:LearnMore';
  public static Cookie_LearnMore: string = '::Cookie:LearnMore';
  public cookie_Message: string = '::Cookie:Message';
  public static Cookie_Message: string = '::Cookie:Message';
  public cookie_Understand: string = '::Cookie:Understand';
  public static Cookie_Understand: string = '::Cookie:Understand';
  public dashboard_AllContracts: string = '::Dashboard:AllContracts';
  public static Dashboard_AllContracts: string = '::Dashboard:AllContracts';
  public dashboard_ContractList: string = '::Dashboard:ContractList';
  public static Dashboard_ContractList: string = '::Dashboard:ContractList';
  public dashboard_ContractListLastThree: string = '::Dashboard:ContractListLastThree';
  public static Dashboard_ContractListLastThree: string = '::Dashboard:ContractListLastThree';
  public dashboard_LastPayment: string = '::Dashboard:LastPayment';
  public static Dashboard_LastPayment: string = '::Dashboard:LastPayment';
  public dashboard_LesseeProgressGraph: string = '::Dashboard:LesseeProgressGraph';
  public static Dashboard_LesseeProgressGraph: string = '::Dashboard:LesseeProgressGraph';
  public dashboard_LesseeProgressGraphToolTip: string = '::Dashboard:LesseeProgressGraphToolTip';
  public static Dashboard_LesseeProgressGraphToolTip: string = '::Dashboard:LesseeProgressGraphToolTip';
  public dashboard_MorePayments: string = '::Dashboard:MorePayments';
  public static Dashboard_MorePayments: string = '::Dashboard:MorePayments';
  public dashboard_PartnerBalance: string = '::Dashboard:PartnerBalance';
  public static Dashboard_PartnerBalance: string = '::Dashboard:PartnerBalance';
  public datatableActionDropdownDefaultText: string = '::DatatableActionDropdownDefaultText';
  public static DatatableActionDropdownDefaultText: string = '::DatatableActionDropdownDefaultText';
  public day_Friday: string = '::Day:Friday';
  public static Day_Friday: string = '::Day:Friday';
  public day_FridayShort: string = '::Day:FridayShort';
  public static Day_FridayShort: string = '::Day:FridayShort';
  public day_Monday: string = '::Day:Monday';
  public static Day_Monday: string = '::Day:Monday';
  public day_MondayShort: string = '::Day:MondayShort';
  public static Day_MondayShort: string = '::Day:MondayShort';
  public day_Saturday: string = '::Day:Saturday';
  public static Day_Saturday: string = '::Day:Saturday';
  public day_SaturdayShort: string = '::Day:SaturdayShort';
  public static Day_SaturdayShort: string = '::Day:SaturdayShort';
  public day_Sunday: string = '::Day:Sunday';
  public static Day_Sunday: string = '::Day:Sunday';
  public day_SundayShort: string = '::Day:SundayShort';
  public static Day_SundayShort: string = '::Day:SundayShort';
  public day_Thursday: string = '::Day:Thursday';
  public static Day_Thursday: string = '::Day:Thursday';
  public day_ThursdayShort: string = '::Day:ThursdayShort';
  public static Day_ThursdayShort: string = '::Day:ThursdayShort';
  public day_Tuesday: string = '::Day:Tuesday';
  public static Day_Tuesday: string = '::Day:Tuesday';
  public day_TuesdayShort: string = '::Day:TuesdayShort';
  public static Day_TuesdayShort: string = '::Day:TuesdayShort';
  public day_Wednesday: string = '::Day:Wednesday';
  public static Day_Wednesday: string = '::Day:Wednesday';
  public day_WednesdayShort: string = '::Day:WednesdayShort';
  public static Day_WednesdayShort: string = '::Day:WednesdayShort';
  public eaDocuments_ArchiveError: string = '::EaDocuments:ArchiveError';
  public static EaDocuments_ArchiveError: string = '::EaDocuments:ArchiveError';
  public eaDocuments_DeletedInArchive: string = '::EaDocuments:DeletedInArchive';
  public static EaDocuments_DeletedInArchive: string = '::EaDocuments:DeletedInArchive';
  public eaDocuments_DocumentDate: string = '::EaDocuments:DocumentDate';
  public static EaDocuments_DocumentDate: string = '::EaDocuments:DocumentDate';
  public eaDocuments_DocumentDateRead: string = '::EaDocuments:DocumentDateRead';
  public static EaDocuments_DocumentDateRead: string = '::EaDocuments:DocumentDateRead';
  public eaDocuments_DocumentDateShort: string = '::EaDocuments:DocumentDateShort';
  public static EaDocuments_DocumentDateShort: string = '::EaDocuments:DocumentDateShort';
  public eaDocuments_DocumentDeleted: string = '::EaDocuments:DocumentDeleted';
  public static EaDocuments_DocumentDeleted: string = '::EaDocuments:DocumentDeleted';
  public eaDocuments_LoadingFromArchive: string = '::EaDocuments:LoadingFromArchive';
  public static EaDocuments_LoadingFromArchive: string = '::EaDocuments:LoadingFromArchive';
  public eaDocuments_OpenDocumentError: string = '::EaDocuments:OpenDocumentError';
  public static EaDocuments_OpenDocumentError: string = '::EaDocuments:OpenDocumentError';
  public eaDocuments_Title: string = '::EaDocuments:Title';
  public static EaDocuments_Title: string = '::EaDocuments:Title';
  public eaDocuments_UnreadOnly: string = '::EaDocuments:UnreadOnly';
  public static EaDocuments_UnreadOnly: string = '::EaDocuments:UnreadOnly';
  public enum_GmiPersonalizationBlock_1: string = '::Enum:GmiPersonalizationBlock.1';
  public static Enum_GmiPersonalizationBlock_1: string = '::Enum:GmiPersonalizationBlock.1';
  public enum_GmiPersonalizationBlock_2: string = '::Enum:GmiPersonalizationBlock.2';
  public static Enum_GmiPersonalizationBlock_2: string = '::Enum:GmiPersonalizationBlock.2';
  public enum_GmiPersonalizationType_1: string = '::Enum:GmiPersonalizationType.1';
  public static Enum_GmiPersonalizationType_1: string = '::Enum:GmiPersonalizationType.1';
  public enum_GmiPersonalizationType_2: string = '::Enum:GmiPersonalizationType.2';
  public static Enum_GmiPersonalizationType_2: string = '::Enum:GmiPersonalizationType.2';
  public enum_GmiPersonalizationType_3: string = '::Enum:GmiPersonalizationType.3';
  public static Enum_GmiPersonalizationType_3: string = '::Enum:GmiPersonalizationType.3';
  public enum_GmiPersonalizationType_4: string = '::Enum:GmiPersonalizationType.4';
  public static Enum_GmiPersonalizationType_4: string = '::Enum:GmiPersonalizationType.4';
  public enum_GmiPersonalizationType_5: string = '::Enum:GmiPersonalizationType.5';
  public static Enum_GmiPersonalizationType_5: string = '::Enum:GmiPersonalizationType.5';
  public enum_GmiTwoFactorType_0: string = '::Enum:GmiTwoFactorType.0';
  public static Enum_GmiTwoFactorType_0: string = '::Enum:GmiTwoFactorType.0';
  public enum_GmiTwoFactorType_1: string = '::Enum:GmiTwoFactorType.1';
  public static Enum_GmiTwoFactorType_1: string = '::Enum:GmiTwoFactorType.1';
  public enum_GmiTwoFactorType_10: string = '::Enum:GmiTwoFactorType.10';
  public static Enum_GmiTwoFactorType_10: string = '::Enum:GmiTwoFactorType.10';
  public enum_GmiTwoFactorType_2: string = '::Enum:GmiTwoFactorType.2';
  public static Enum_GmiTwoFactorType_2: string = '::Enum:GmiTwoFactorType.2';
  public eVloga_EmailConfirmed: string = '::EVloga:EmailConfirmed';
  public static EVloga_EmailConfirmed: string = '::EVloga:EmailConfirmed';
  public eVloga_EmailConfirmedTitle: string = '::EVloga:EmailConfirmedTitle';
  public static EVloga_EmailConfirmedTitle: string = '::EVloga:EmailConfirmedTitle';
  public eVloga_EmailNotConfirmed: string = '::EVloga:EmailNotConfirmed';
  public static EVloga_EmailNotConfirmed: string = '::EVloga:EmailNotConfirmed';
  public eVloga_LinkNotValid: string = '::EVloga:LinkNotValid';
  public static EVloga_LinkNotValid: string = '::EVloga:LinkNotValid';
  public externalFunction_Insert: string = '::ExternalFunction:Insert';
  public static ExternalFunction_Insert: string = '::ExternalFunction:Insert';
  public externalFunctions_ChangeComment: string = '::ExternalFunctions:ChangeComment';
  public static ExternalFunctions_ChangeComment: string = '::ExternalFunctions:ChangeComment';
  public externalFunctions_ChangeId: string = '::ExternalFunctions:ChangeId';
  public static ExternalFunctions_ChangeId: string = '::ExternalFunctions:ChangeId';
  public externalFunctions_LastChangeComment: string = '::ExternalFunctions:LastChangeComment';
  public static ExternalFunctions_LastChangeComment: string = '::ExternalFunctions:LastChangeComment';
  public externalFunctions_LastChangeDate: string = '::ExternalFunctions:LastChangeDate';
  public static ExternalFunctions_LastChangeDate: string = '::ExternalFunctions:LastChangeDate';
  public externalFunctions_LastChangeId: string = '::ExternalFunctions:LastChangeId';
  public static ExternalFunctions_LastChangeId: string = '::ExternalFunctions:LastChangeId';
  public externalFunctions_PreviousChange: string = '::ExternalFunctions:PreviousChange';
  public static ExternalFunctions_PreviousChange: string = '::ExternalFunctions:PreviousChange';
  public externalFunctions_StockFundingSetFields: string = '::ExternalFunctions:StockFundingSetFields';
  public static ExternalFunctions_StockFundingSetFields: string = '::ExternalFunctions:StockFundingSetFields';
  public externalFunctions_StockFundingsFilterData: string = '::ExternalFunctions:StockFundingsFilterData';
  public static ExternalFunctions_StockFundingsFilterData: string = '::ExternalFunctions:StockFundingsFilterData';
  public gDPRFieldNames1: string = '::GDPRFieldNames1';
  public static GDPRFieldNames1: string = '::GDPRFieldNames1';
  public gDPRFieldNames2: string = '::GDPRFieldNames2';
  public static GDPRFieldNames2: string = '::GDPRFieldNames2';
  public gDPRFieldNames3: string = '::GDPRFieldNames3';
  public static GDPRFieldNames3: string = '::GDPRFieldNames3';
  public gDPRFieldNames4: string = '::GDPRFieldNames4';
  public static GDPRFieldNames4: string = '::GDPRFieldNames4';
  public gDPRFieldNames5: string = '::GDPRFieldNames5';
  public static GDPRFieldNames5: string = '::GDPRFieldNames5';
  public gDPRFieldNames6: string = '::GDPRFieldNames6';
  public static GDPRFieldNames6: string = '::GDPRFieldNames6';
  public gDPRLabelDashboard: string = '::GDPRLabelDashboard';
  public static GDPRLabelDashboard: string = '::GDPRLabelDashboard';
  public gDPRLabelMapaPartnerja: string = '::GDPRLabelMapaPartnerja';
  public static GDPRLabelMapaPartnerja: string = '::GDPRLabelMapaPartnerja';
  public gDPRLabelMaskaPartnerja: string = '::GDPRLabelMaskaPartnerja';
  public static GDPRLabelMaskaPartnerja: string = '::GDPRLabelMaskaPartnerja';
  public gDPRLabelMaskaUporabnika: string = '::GDPRLabelMaskaUporabnika';
  public static GDPRLabelMaskaUporabnika: string = '::GDPRLabelMaskaUporabnika';
  public gDPRLabelPregledPartnerjev: string = '::GDPRLabelPregledPartnerjev';
  public static GDPRLabelPregledPartnerjev: string = '::GDPRLabelPregledPartnerjev';
  public gDPRLabelPregledUporabnikov: string = '::GDPRLabelPregledUporabnikov';
  public static GDPRLabelPregledUporabnikov: string = '::GDPRLabelPregledUporabnikov';
  public general_ButtonActions: string = '::General:ButtonActions';
  public static General_ButtonActions: string = '::General:ButtonActions';
  public general_ButtonActivate: string = '::General:ButtonActivate';
  public static General_ButtonActivate: string = '::General:ButtonActivate';
  public general_ButtonBackToLogin: string = '::General:ButtonBackToLogin';
  public static General_ButtonBackToLogin: string = '::General:ButtonBackToLogin';
  public general_ButtonCancel: string = '::General:ButtonCancel';
  public static General_ButtonCancel: string = '::General:ButtonCancel';
  public general_ButtonChangePassword: string = '::General:ButtonChangePassword';
  public static General_ButtonChangePassword: string = '::General:ButtonChangePassword';
  public general_ButtonCheck: string = '::General:ButtonCheck';
  public static General_ButtonCheck: string = '::General:ButtonCheck';
  public general_ButtonClearCache: string = '::General:ButtonClearCache';
  public static General_ButtonClearCache: string = '::General:ButtonClearCache';
  public general_ButtonClose: string = '::General:ButtonClose';
  public static General_ButtonClose: string = '::General:ButtonClose';
  public general_ButtonDeactivate: string = '::General:ButtonDeactivate';
  public static General_ButtonDeactivate: string = '::General:ButtonDeactivate';
  public general_ButtonDelete: string = '::General:ButtonDelete';
  public static General_ButtonDelete: string = '::General:ButtonDelete';
  public general_ButtonEdit: string = '::General:ButtonEdit';
  public static General_ButtonEdit: string = '::General:ButtonEdit';
  public general_ButtonMore: string = '::General:ButtonMore';
  public static General_ButtonMore: string = '::General:ButtonMore';
  public general_ButtonNo: string = '::General:ButtonNo';
  public static General_ButtonNo: string = '::General:ButtonNo';
  public general_ButtonOk: string = '::General:ButtonOk';
  public static General_ButtonOk: string = '::General:ButtonOk';
  public general_ButtonReEntry: string = '::General:ButtonReEntry';
  public static General_ButtonReEntry: string = '::General:ButtonReEntry';
  public general_ButtonSave: string = '::General:ButtonSave';
  public static General_ButtonSave: string = '::General:ButtonSave';
  public general_ButtonSearch: string = '::General:ButtonSearch';
  public static General_ButtonSearch: string = '::General:ButtonSearch';
  public general_ButtonSearchReset: string = '::General:ButtonSearchReset';
  public static General_ButtonSearchReset: string = '::General:ButtonSearchReset';
  public general_ButtonYes: string = '::General:ButtonYes';
  public static General_ButtonYes: string = '::General:ButtonYes';
  public general_ConfirmTwoFactorReset: string = '::General:ConfirmTwoFactorReset';
  public static General_ConfirmTwoFactorReset: string = '::General:ConfirmTwoFactorReset';
  public general_EmailAdress: string = '::General:EmailAdress';
  public static General_EmailAdress: string = '::General:EmailAdress';
  public general_ErrFromToDate: string = '::General:ErrFromToDate';
  public static General_ErrFromToDate: string = '::General:ErrFromToDate';
  public general_ErrorDateFromTo: string = '::General:ErrorDateFromTo';
  public static General_ErrorDateFromTo: string = '::General:ErrorDateFromTo';
  public general_ErrorDoesNotExists: string = '::General:ErrorDoesNotExists';
  public static General_ErrorDoesNotExists: string = '::General:ErrorDoesNotExists';
  public general_ErrorIncorrectPassword: string = '::General:ErrorIncorrectPassword';
  public static General_ErrorIncorrectPassword: string = '::General:ErrorIncorrectPassword';
  public general_ErrorInvalidCss: string = '::General:ErrorInvalidCss';
  public static General_ErrorInvalidCss: string = '::General:ErrorInvalidCss';
  public general_ErrorInvalidEmail: string = '::General:ErrorInvalidEmail';
  public static General_ErrorInvalidEmail: string = '::General:ErrorInvalidEmail';
  public general_ErrorInvalidId: string = '::General:ErrorInvalidId';
  public static General_ErrorInvalidId: string = '::General:ErrorInvalidId';
  public general_ErrorInvalidJson: string = '::General:ErrorInvalidJson';
  public static General_ErrorInvalidJson: string = '::General:ErrorInvalidJson';
  public general_ErrorPageMessage: string = '::General:ErrorPageMessage';
  public static General_ErrorPageMessage: string = '::General:ErrorPageMessage';
  public general_ErrorPasswordsDontMatch: string = '::General:ErrorPasswordsDontMatch';
  public static General_ErrorPasswordsDontMatch: string = '::General:ErrorPasswordsDontMatch';
  public general_ErrorSendingMail: string = '::General:ErrorSendingMail';
  public static General_ErrorSendingMail: string = '::General:ErrorSendingMail';
  public general_ErrorSqlSyntax: string = '::General:ErrorSqlSyntax';
  public static General_ErrorSqlSyntax: string = '::General:ErrorSqlSyntax';
  public general_ErrorSqlToLongExecution: string = '::General:ErrorSqlToLongExecution';
  public static General_ErrorSqlToLongExecution: string = '::General:ErrorSqlToLongExecution';
  public general_ErrorUserWithEmailExists: string = '::General:ErrorUserWithEmailExists';
  public static General_ErrorUserWithEmailExists: string = '::General:ErrorUserWithEmailExists';
  public general_ErrorValidFromTo: string = '::General:ErrorValidFromTo';
  public static General_ErrorValidFromTo: string = '::General:ErrorValidFromTo';
  public general_Field_IsDefault: string = '::General:Field.IsDefault';
  public static General_Field_IsDefault: string = '::General:Field.IsDefault';
  public general_Field_IsInactive: string = '::General:Field.IsInactive';
  public static General_Field_IsInactive: string = '::General:Field.IsInactive';
  public general_FooterCopyright: string = '::General:FooterCopyright';
  public static General_FooterCopyright: string = '::General:FooterCopyright';
  public general_FooterRights: string = '::General:FooterRights';
  public static General_FooterRights: string = '::General:FooterRights';
  public general_GridNoData: string = '::General:GridNoData';
  public static General_GridNoData: string = '::General:GridNoData';
  public general_GridPageData: string = '::General:GridPageData';
  public static General_GridPageData: string = '::General:GridPageData';
  public general_GridPageDataNoPage: string = '::General:GridPageDataNoPage';
  public static General_GridPageDataNoPage: string = '::General:GridPageDataNoPage';
  public general_Label_City: string = '::General:Label:City';
  public static General_Label_City: string = '::General:Label:City';
  public general_LabelAddress: string = '::General:LabelAddress';
  public static General_LabelAddress: string = '::General:LabelAddress';
  public general_LabelAttachements: string = '::General:LabelAttachements';
  public static General_LabelAttachements: string = '::General:LabelAttachements';
  public general_LabelCategory: string = '::General:LabelCategory';
  public static General_LabelCategory: string = '::General:LabelCategory';
  public general_LabelComment: string = '::General:LabelComment';
  public static General_LabelComment: string = '::General:LabelComment';
  public general_LabelConfirmSaveChanges: string = '::General:LabelConfirmSaveChanges';
  public static General_LabelConfirmSaveChanges: string = '::General:LabelConfirmSaveChanges';
  public general_LabelCurrency: string = '::General:LabelCurrency';
  public static General_LabelCurrency: string = '::General:LabelCurrency';
  public general_LabelDataDelay: string = '::General:LabelDataDelay';
  public static General_LabelDataDelay: string = '::General:LabelDataDelay';
  public general_LabelDateFrom: string = '::General:LabelDateFrom';
  public static General_LabelDateFrom: string = '::General:LabelDateFrom';
  public general_LabelDateFromShort: string = '::General:LabelDateFromShort';
  public static General_LabelDateFromShort: string = '::General:LabelDateFromShort';
  public general_LabelDateInserted: string = '::General:LabelDateInserted';
  public static General_LabelDateInserted: string = '::General:LabelDateInserted';
  public general_LabelDateTo: string = '::General:LabelDateTo';
  public static General_LabelDateTo: string = '::General:LabelDateTo';
  public general_LabelDateToShort: string = '::General:LabelDateToShort';
  public static General_LabelDateToShort: string = '::General:LabelDateToShort';
  public general_LabelDateValidFrom: string = '::General:LabelDateValidFrom';
  public static General_LabelDateValidFrom: string = '::General:LabelDateValidFrom';
  public general_LabelDateValidFromShort: string = '::General:LabelDateValidFromShort';
  public static General_LabelDateValidFromShort: string = '::General:LabelDateValidFromShort';
  public general_LabelDateValidTo: string = '::General:LabelDateValidTo';
  public static General_LabelDateValidTo: string = '::General:LabelDateValidTo';
  public general_LabelDateValidToShort: string = '::General:LabelDateValidToShort';
  public static General_LabelDateValidToShort: string = '::General:LabelDateValidToShort';
  public general_LabelDescription: string = '::General:LabelDescription';
  public static General_LabelDescription: string = '::General:LabelDescription';
  public general_LabelDocumentDescription: string = '::General:LabelDocumentDescription';
  public static General_LabelDocumentDescription: string = '::General:LabelDocumentDescription';
  public general_LabelDocumentDescriptionShort: string = '::General:LabelDocumentDescriptionShort';
  public static General_LabelDocumentDescriptionShort: string = '::General:LabelDocumentDescriptionShort';
  public general_LabelDocumentNumber: string = '::General:LabelDocumentNumber';
  public static General_LabelDocumentNumber: string = '::General:LabelDocumentNumber';
  public general_LabelDocumentNumberShort: string = '::General:LabelDocumentNumberShort';
  public static General_LabelDocumentNumberShort: string = '::General:LabelDocumentNumberShort';
  public general_LabelDownloadAll: string = '::General:LabelDownloadAll';
  public static General_LabelDownloadAll: string = '::General:LabelDownloadAll';
  public general_LabelEmail: string = '::General:LabelEmail';
  public static General_LabelEmail: string = '::General:LabelEmail';
  public general_LabelExchangeRate: string = '::General:LabelExchangeRate';
  public static General_LabelExchangeRate: string = '::General:LabelExchangeRate';
  public general_LabelExchangeRateShort: string = '::General:LabelExchangeRateShort';
  public static General_LabelExchangeRateShort: string = '::General:LabelExchangeRateShort';
  public general_LabelFax: string = '::General:LabelFax';
  public static General_LabelFax: string = '::General:LabelFax';
  public general_LabelFrom: string = '::General:LabelFrom';
  public static General_LabelFrom: string = '::General:LabelFrom';
  public general_LabelInactive: string = '::General:LabelInactive';
  public static General_LabelInactive: string = '::General:LabelInactive';
  public general_LabelLastChange: string = '::General:LabelLastChange';
  public static General_LabelLastChange: string = '::General:LabelLastChange';
  public general_LabelMobileNumber: string = '::General:LabelMobileNumber';
  public static General_LabelMobileNumber: string = '::General:LabelMobileNumber';
  public general_LabelNewPassword: string = '::General:LabelNewPassword';
  public static General_LabelNewPassword: string = '::General:LabelNewPassword';
  public general_LabelPage: string = '::General:LabelPage';
  public static General_LabelPage: string = '::General:LabelPage';
  public general_LabelPhoneNumber: string = '::General:LabelPhoneNumber';
  public static General_LabelPhoneNumber: string = '::General:LabelPhoneNumber';
  public general_LabelPost: string = '::General:LabelPost';
  public static General_LabelPost: string = '::General:LabelPost';
  public general_LabelProgress: string = '::General:LabelProgress';
  public static General_LabelProgress: string = '::General:LabelProgress';
  public general_LabelrepeatNewPassword: string = '::General:LabelrepeatNewPassword';
  public static General_LabelrepeatNewPassword: string = '::General:LabelrepeatNewPassword';
  public general_LabelSaveSuccessfull: string = '::General:LabelSaveSuccessfull';
  public static General_LabelSaveSuccessfull: string = '::General:LabelSaveSuccessfull';
  public general_LabelShowInactive: string = '::General:LabelShowInactive';
  public static General_LabelShowInactive: string = '::General:LabelShowInactive';
  public general_LabelSortOrder: string = '::General:LabelSortOrder';
  public static General_LabelSortOrder: string = '::General:LabelSortOrder';
  public general_LabelSql: string = '::General:LabelSql';
  public static General_LabelSql: string = '::General:LabelSql';
  public general_LabelSqlCondition: string = '::General:LabelSqlCondition';
  public static General_LabelSqlCondition: string = '::General:LabelSqlCondition';
  public general_LabelStatus: string = '::General:LabelStatus';
  public static General_LabelStatus: string = '::General:LabelStatus';
  public general_LabelStreet: string = '::General:LabelStreet';
  public static General_LabelStreet: string = '::General:LabelStreet';
  public general_LabelText: string = '::General:LabelText';
  public static General_LabelText: string = '::General:LabelText';
  public general_LabelTitle: string = '::General:LabelTitle';
  public static General_LabelTitle: string = '::General:LabelTitle';
  public general_LabelTo: string = '::General:LabelTo';
  public static General_LabelTo: string = '::General:LabelTo';
  public general_LabelType: string = '::General:LabelType';
  public static General_LabelType: string = '::General:LabelType';
  public general_Loading: string = '::General:Loading';
  public static General_Loading: string = '::General:Loading';
  public general_MailAttachements: string = '::General:MailAttachements';
  public static General_MailAttachements: string = '::General:MailAttachements';
  public general_MailBody: string = '::General:MailBody';
  public static General_MailBody: string = '::General:MailBody';
  public general_MailCC: string = '::General:MailCC';
  public static General_MailCC: string = '::General:MailCC';
  public general_MailFrom: string = '::General:MailFrom';
  public static General_MailFrom: string = '::General:MailFrom';
  public general_MailSend: string = '::General:MailSend';
  public static General_MailSend: string = '::General:MailSend';
  public general_MailSubject: string = '::General:MailSubject';
  public static General_MailSubject: string = '::General:MailSubject';
  public general_MailTo: string = '::General:MailTo';
  public static General_MailTo: string = '::General:MailTo';
  public general_ModalAreYouSure: string = '::General:ModalAreYouSure';
  public static General_ModalAreYouSure: string = '::General:ModalAreYouSure';
  public general_ModalChangesSaved: string = '::General:ModalChangesSaved';
  public static General_ModalChangesSaved: string = '::General:ModalChangesSaved';
  public general_ModalError: string = '::General:ModalError';
  public static General_ModalError: string = '::General:ModalError';
  public general_ModalInserted: string = '::General:ModalInserted';
  public static General_ModalInserted: string = '::General:ModalInserted';
  public general_ModalSuccess: string = '::General:ModalSuccess';
  public static General_ModalSuccess: string = '::General:ModalSuccess';
  public general_NewQuery: string = '::General:NewQuery';
  public static General_NewQuery: string = '::General:NewQuery';
  public general_Redirecting: string = '::General:Redirecting';
  public static General_Redirecting: string = '::General:Redirecting';
  public general_RemainingPrincipal: string = '::General:RemainingPrincipal';
  public static General_RemainingPrincipal: string = '::General:RemainingPrincipal';
  public general_RequeredField: string = '::General:RequeredField';
  public static General_RequeredField: string = '::General:RequeredField';
  public general_SelectNoSelection: string = '::General:SelectNoSelection';
  public static General_SelectNoSelection: string = '::General:SelectNoSelection';
  public general_TwoFactorAuthentication: string = '::General:TwoFactorAuthentication';
  public static General_TwoFactorAuthentication: string = '::General:TwoFactorAuthentication';
  public general_TwoFactorAuthenticationType: string = '::General:TwoFactorAuthenticationType';
  public static General_TwoFactorAuthenticationType: string = '::General:TwoFactorAuthenticationType';
  public general_TwoFactorSetOnRole: string = '::General:TwoFactorSetOnRole';
  public static General_TwoFactorSetOnRole: string = '::General:TwoFactorSetOnRole';
  public general_ValidationPhoneIllegalCharacters: string = '::General:ValidationPhoneIllegalCharacters';
  public static General_ValidationPhoneIllegalCharacters: string = '::General:ValidationPhoneIllegalCharacters';
  public general_ValidationPhoneNumberInvalidMobileNumber: string = '::General:ValidationPhoneNumberInvalidMobileNumber';
  public static General_ValidationPhoneNumberInvalidMobileNumber: string = '::General:ValidationPhoneNumberInvalidMobileNumber';
  public general_ValidationPhoneWrongDialCode: string = '::General:ValidationPhoneWrongDialCode';
  public static General_ValidationPhoneWrongDialCode: string = '::General:ValidationPhoneWrongDialCode';
  public general_ValidationPhoneWrongNumberSi: string = '::General:ValidationPhoneWrongNumberSi';
  public static General_ValidationPhoneWrongNumberSi: string = '::General:ValidationPhoneWrongNumberSi';
  public gmiAdministration_partnerAlreadyExists: string = '::GmiAdministration:partnerAlreadyExists';
  public static GmiAdministration_partnerAlreadyExists: string = '::GmiAdministration:partnerAlreadyExists';
  public gmiAdministration_UserInsertError: string = '::GmiAdministration:UserInsertError';
  public static GmiAdministration_UserInsertError: string = '::GmiAdministration:UserInsertError';
  public gmiAdministration_UserInsertErrorRoles: string = '::GmiAdministration:UserInsertErrorRoles';
  public static GmiAdministration_UserInsertErrorRoles: string = '::GmiAdministration:UserInsertErrorRoles';
  public gmiAdministration_UserUpdateError: string = '::GmiAdministration:UserUpdateError';
  public static GmiAdministration_UserUpdateError: string = '::GmiAdministration:UserUpdateError';
  public gmiAdministration_UserUpdateErrorRoles: string = '::GmiAdministration:UserUpdateErrorRoles';
  public static GmiAdministration_UserUpdateErrorRoles: string = '::GmiAdministration:UserUpdateErrorRoles';
  public gmiBackGroundWorkerJob_ButtonJobHistory: string = '::GmiBackGroundWorkerJob:ButtonJobHistory';
  public static GmiBackGroundWorkerJob_ButtonJobHistory: string = '::GmiBackGroundWorkerJob:ButtonJobHistory';
  public gmiBackGroundWorkerJob_EADocumentsArchivingWorker: string = '::GmiBackGroundWorkerJob:EADocumentsArchivingWorker';
  public static GmiBackGroundWorkerJob_EADocumentsArchivingWorker: string = '::GmiBackGroundWorkerJob:EADocumentsArchivingWorker';
  public gmiBackGroundWorkerJob_GmiImpersonationCleanupWorker: string = '::GmiBackGroundWorkerJob:GmiImpersonationCleanupWorker';
  public static GmiBackGroundWorkerJob_GmiImpersonationCleanupWorker: string = '::GmiBackGroundWorkerJob:GmiImpersonationCleanupWorker';
  public gmiBackGroundWorkerJob_LogRetentionAdmin: string = '::GmiBackGroundWorkerJob:LogRetentionAdmin';
  public static GmiBackGroundWorkerJob_LogRetentionAdmin: string = '::GmiBackGroundWorkerJob:LogRetentionAdmin';
  public gmiBackGroundWorkerJob_LogRetentionPublic: string = '::GmiBackGroundWorkerJob:LogRetentionPublic';
  public static GmiBackGroundWorkerJob_LogRetentionPublic: string = '::GmiBackGroundWorkerJob:LogRetentionPublic';
  public gmiBackGroundWorkerJob_TokenCleanupBackgroundWorker: string = '::GmiBackGroundWorkerJob:TokenCleanupBackgroundWorker';
  public static GmiBackGroundWorkerJob_TokenCleanupBackgroundWorker: string = '::GmiBackGroundWorkerJob:TokenCleanupBackgroundWorker';
  public gmiBlocks_application: string = '::GmiBlocks:application';
  public static GmiBlocks_application: string = '::GmiBlocks:application';
  public gmiBlocks_contract: string = '::GmiBlocks:contract';
  public static GmiBlocks_contract: string = '::GmiBlocks:contract';
  public gmiBlocks_contracts: string = '::GmiBlocks:contracts';
  public static GmiBlocks_contracts: string = '::GmiBlocks:contracts';
  public gmiBlocks_contractsRecovery: string = '::GmiBlocks:contractsRecovery';
  public static GmiBlocks_contractsRecovery: string = '::GmiBlocks:contractsRecovery';
  public gmiBlocks_dashboard: string = '::GmiBlocks:dashboard';
  public static GmiBlocks_dashboard: string = '::GmiBlocks:dashboard';
  public gmiBlocks_dashboardContracts: string = '::GmiBlocks:dashboardContracts';
  public static GmiBlocks_dashboardContracts: string = '::GmiBlocks:dashboardContracts';
  public gmiBlocks_hideLesseeContracts: string = '::GmiBlocks:hideLesseeContracts';
  public static GmiBlocks_hideLesseeContracts: string = '::GmiBlocks:hideLesseeContracts';
  public gmiBlocks_login: string = '::GmiBlocks:login';
  public static GmiBlocks_login: string = '::GmiBlocks:login';
  public gmiBlocks_partner: string = '::GmiBlocks:partner';
  public static GmiBlocks_partner: string = '::GmiBlocks:partner';
  public gmiBlocks_partnersRecovery: string = '::GmiBlocks:partnersRecovery';
  public static GmiBlocks_partnersRecovery: string = '::GmiBlocks:partnersRecovery';
  public gmiBlocks_registerData: string = '::GmiBlocks:registerData';
  public static GmiBlocks_registerData: string = '::GmiBlocks:registerData';
  public gmiNotifications_AddedFiles: string = '::GmiNotifications:AddedFiles';
  public static GmiNotifications_AddedFiles: string = '::GmiNotifications:AddedFiles';
  public gmiNotifications_allNotificationtypes: string = '::GmiNotifications:allNotificationtypes';
  public static GmiNotifications_allNotificationtypes: string = '::GmiNotifications:allNotificationtypes';
  public gmiNotifications_AlreadyRead: string = '::GmiNotifications:AlreadyRead';
  public static GmiNotifications_AlreadyRead: string = '::GmiNotifications:AlreadyRead';
  public gmiNotifications_DateRead: string = '::GmiNotifications:DateRead';
  public static GmiNotifications_DateRead: string = '::GmiNotifications:DateRead';
  public gmiNotifications_ErrorCannotDelete: string = '::GmiNotifications:ErrorCannotDelete';
  public static GmiNotifications_ErrorCannotDelete: string = '::GmiNotifications:ErrorCannotDelete';
  public gmiNotifications_ErrorInvalidCommercial: string = '::GmiNotifications:ErrorInvalidCommercial';
  public static GmiNotifications_ErrorInvalidCommercial: string = '::GmiNotifications:ErrorInvalidCommercial';
  public gmiNotifications_HasAttachements: string = '::GmiNotifications:HasAttachements';
  public static GmiNotifications_HasAttachements: string = '::GmiNotifications:HasAttachements';
  public gmiNotifications_InsertNew: string = '::GmiNotifications:InsertNew';
  public static GmiNotifications_InsertNew: string = '::GmiNotifications:InsertNew';
  public gmiNotifications_MarkAsRead: string = '::GmiNotifications:MarkAsRead';
  public static GmiNotifications_MarkAsRead: string = '::GmiNotifications:MarkAsRead';
  public gmiNotifications_MarkedAsRead: string = '::GmiNotifications:MarkedAsRead';
  public static GmiNotifications_MarkedAsRead: string = '::GmiNotifications:MarkedAsRead';
  public gmiNotifications_NoPartnersFound: string = '::GmiNotifications:NoPartnersFound';
  public static GmiNotifications_NoPartnersFound: string = '::GmiNotifications:NoPartnersFound';
  public gmiNotifications_NotificationDate: string = '::GmiNotifications:NotificationDate';
  public static GmiNotifications_NotificationDate: string = '::GmiNotifications:NotificationDate';
  public gmiNotifications_RecipientsGmiPartner: string = '::GmiNotifications:RecipientsGmiPartner';
  public static GmiNotifications_RecipientsGmiPartner: string = '::GmiNotifications:RecipientsGmiPartner';
  public gmiNotifications_RecipientsRole: string = '::GmiNotifications:RecipientsRole';
  public static GmiNotifications_RecipientsRole: string = '::GmiNotifications:RecipientsRole';
  public gmiNotifications_SearcgPartnersPlaceholder: string = '::GmiNotifications:SearcgPartnersPlaceholder';
  public static GmiNotifications_SearcgPartnersPlaceholder: string = '::GmiNotifications:SearcgPartnersPlaceholder';
  public gmiNotifications_TextsAreAllEmpty: string = '::GmiNotifications:TextsAreAllEmpty';
  public static GmiNotifications_TextsAreAllEmpty: string = '::GmiNotifications:TextsAreAllEmpty';
  public gmiNotifications_Title: string = '::GmiNotifications:Title';
  public static GmiNotifications_Title: string = '::GmiNotifications:Title';
  public gmiNotifications_Type: string = '::GmiNotifications:Type';
  public static GmiNotifications_Type: string = '::GmiNotifications:Type';
  public gmiNotificationsTypes_Commercial: string = '::GmiNotificationsTypes:Commercial';
  public static GmiNotificationsTypes_Commercial: string = '::GmiNotificationsTypes:Commercial';
  public gmiNotificationsTypes_Confirmation: string = '::GmiNotificationsTypes:Confirmation';
  public static GmiNotificationsTypes_Confirmation: string = '::GmiNotificationsTypes:Confirmation';
  public gmiNotificationsTypes_Help: string = '::GmiNotificationsTypes:Help';
  public static GmiNotificationsTypes_Help: string = '::GmiNotificationsTypes:Help';
  public gmiNotificationsTypes_Login: string = '::GmiNotificationsTypes:Login';
  public static GmiNotificationsTypes_Login: string = '::GmiNotificationsTypes:Login';
  public gmiNotificationsTypes_Normal: string = '::GmiNotificationsTypes:Normal';
  public static GmiNotificationsTypes_Normal: string = '::GmiNotificationsTypes:Normal';
  public gmiPartners_ButtonEdit: string = '::GmiPartners:ButtonEdit';
  public static GmiPartners_ButtonEdit: string = '::GmiPartners:ButtonEdit';
  public gmiPartners_ButtonInsert: string = '::GmiPartners:ButtonInsert';
  public static GmiPartners_ButtonInsert: string = '::GmiPartners:ButtonInsert';
  public gmiPartners_ButtonShowGmiUsers: string = '::GmiPartners:ButtonShowGmiUsers';
  public static GmiPartners_ButtonShowGmiUsers: string = '::GmiPartners:ButtonShowGmiUsers';
  public gmiPartners_ConfirmActivation: string = '::GmiPartners:ConfirmActivation';
  public static GmiPartners_ConfirmActivation: string = '::GmiPartners:ConfirmActivation';
  public gmiPartners_ConfirmDeactivation: string = '::GmiPartners:ConfirmDeactivation';
  public static GmiPartners_ConfirmDeactivation: string = '::GmiPartners:ConfirmDeactivation';
  public gmiPartners_DateOfBirth: string = '::GmiPartners:DateOfBirth';
  public static GmiPartners_DateOfBirth: string = '::GmiPartners:DateOfBirth';
  public gmiPartners_DocumentDeliveryChannel: string = '::GmiPartners:DocumentDeliveryChannel';
  public static GmiPartners_DocumentDeliveryChannel: string = '::GmiPartners:DocumentDeliveryChannel';
  public gmiPartners_DocumentDeliveryCustomMsg: string = '::GmiPartners:DocumentDeliveryCustomMsg';
  public static GmiPartners_DocumentDeliveryCustomMsg: string = '::GmiPartners:DocumentDeliveryCustomMsg';
  public gmiPartners_DocumentDeliveryEmail: string = '::GmiPartners:DocumentDeliveryEmail';
  public static GmiPartners_DocumentDeliveryEmail: string = '::GmiPartners:DocumentDeliveryEmail';
  public gmiPartners_DocumentDeliveryMailRequred: string = '::GmiPartners:DocumentDeliveryMailRequred';
  public static GmiPartners_DocumentDeliveryMailRequred: string = '::GmiPartners:DocumentDeliveryMailRequred';
  public gmiPartners_DocumentDeliveryNotify: string = '::GmiPartners:DocumentDeliveryNotify';
  public static GmiPartners_DocumentDeliveryNotify: string = '::GmiPartners:DocumentDeliveryNotify';
  public gmiPartners_email: string = '::GmiPartners:email';
  public static GmiPartners_email: string = '::GmiPartners:email';
  public gmiPartners_GSM: string = '::GmiPartners:GSM';
  public static GmiPartners_GSM: string = '::GmiPartners:GSM';
  public gmiPartners_IdBank: string = '::GmiPartners:IdBank';
  public static GmiPartners_IdBank: string = '::GmiPartners:IdBank';
  public gmiPartners_IdInsurance: string = '::GmiPartners:IdInsurance';
  public static GmiPartners_IdInsurance: string = '::GmiPartners:IdInsurance';
  public gmiPartners_IdKupca: string = '::GmiPartners:IdKupca';
  public static GmiPartners_IdKupca: string = '::GmiPartners:IdKupca';
  public gmiPartners_IdKupcaK: string = '::GmiPartners:IdKupcaK';
  public static GmiPartners_IdKupcaK: string = '::GmiPartners:IdKupcaK';
  public gmiPartners_IdKupcaTrr: string = '::GmiPartners:IdKupcaTrr';
  public static GmiPartners_IdKupcaTrr: string = '::GmiPartners:IdKupcaTrr';
  public gmiPartners_IdPKontakt: string = '::GmiPartners:IdPKontakt';
  public static GmiPartners_IdPKontakt: string = '::GmiPartners:IdPKontakt';
  public gmiPartners_IdRecoveryAgency: string = '::GmiPartners:IdRecoveryAgency';
  public static GmiPartners_IdRecoveryAgency: string = '::GmiPartners:IdRecoveryAgency';
  public gmiPartners_idStyle: string = '::GmiPartners:idStyle';
  public static GmiPartners_idStyle: string = '::GmiPartners:idStyle';
  public gmiPartners_IsInsurance: string = '::GmiPartners:IsInsurance';
  public static GmiPartners_IsInsurance: string = '::GmiPartners:IsInsurance';
  public gmiPartners_IsInsuranceShort: string = '::GmiPartners:IsInsuranceShort';
  public static GmiPartners_IsInsuranceShort: string = '::GmiPartners:IsInsuranceShort';
  public gmiPartners_IsLessee: string = '::GmiPartners:IsLessee';
  public static GmiPartners_IsLessee: string = '::GmiPartners:IsLessee';
  public gmiPartners_IsLesseeShort: string = '::GmiPartners:IsLesseeShort';
  public static GmiPartners_IsLesseeShort: string = '::GmiPartners:IsLesseeShort';
  public gmiPartners_IsRecoveryAgency: string = '::GmiPartners:IsRecoveryAgency';
  public static GmiPartners_IsRecoveryAgency: string = '::GmiPartners:IsRecoveryAgency';
  public gmiPartners_IsRecoveryAgencyShort: string = '::GmiPartners:IsRecoveryAgencyShort';
  public static GmiPartners_IsRecoveryAgencyShort: string = '::GmiPartners:IsRecoveryAgencyShort';
  public gmiPartners_IsStockFunding: string = '::GmiPartners:IsStockFunding';
  public static GmiPartners_IsStockFunding: string = '::GmiPartners:IsStockFunding';
  public gmiPartners_IsStockFundingShort: string = '::GmiPartners:IsStockFundingShort';
  public static GmiPartners_IsStockFundingShort: string = '::GmiPartners:IsStockFundingShort';
  public gmiPartners_IsVendor: string = '::GmiPartners:IsVendor';
  public static GmiPartners_IsVendor: string = '::GmiPartners:IsVendor';
  public gmiPartners_IsVendorPseudo: string = '::GmiPartners:IsVendorPseudo';
  public static GmiPartners_IsVendorPseudo: string = '::GmiPartners:IsVendorPseudo';
  public gmiPartners_IsVendorPseudoShort: string = '::GmiPartners:IsVendorPseudoShort';
  public static GmiPartners_IsVendorPseudoShort: string = '::GmiPartners:IsVendorPseudoShort';
  public gmiPartners_IsVendorShort: string = '::GmiPartners:IsVendorShort';
  public static GmiPartners_IsVendorShort: string = '::GmiPartners:IsVendorShort';
  public gmiPartners_Kontakt: string = '::GmiPartners:Kontakt';
  public static GmiPartners_Kontakt: string = '::GmiPartners:Kontakt';
  public gmiPartners_Naslov: string = '::GmiPartners:Naslov';
  public static GmiPartners_Naslov: string = '::GmiPartners:Naslov';
  public gmiPartners_Naziv: string = '::GmiPartners:Naziv';
  public static GmiPartners_Naziv: string = '::GmiPartners:Naziv';
  public gmiPartners_Naziv1: string = '::GmiPartners:Naziv1';
  public static GmiPartners_Naziv1: string = '::GmiPartners:Naziv1';
  public gmiPartners_NewPartner: string = '::GmiPartners:NewPartner';
  public static GmiPartners_NewPartner: string = '::GmiPartners:NewPartner';
  public gmiPartners_Opis: string = '::GmiPartners:Opis';
  public static GmiPartners_Opis: string = '::GmiPartners:Opis';
  public gmiPartners_OpisVloga: string = '::GmiPartners:OpisVloga';
  public static GmiPartners_OpisVloga: string = '::GmiPartners:OpisVloga';
  public gmiPartners_Partner: string = '::GmiPartners:Partner';
  public static GmiPartners_Partner: string = '::GmiPartners:Partner';
  public gmiPartners_PartnerActivated: string = '::GmiPartners:PartnerActivated';
  public static GmiPartners_PartnerActivated: string = '::GmiPartners:PartnerActivated';
  public gmiPartners_PartnerDeactivated: string = '::GmiPartners:PartnerDeactivated';
  public static GmiPartners_PartnerDeactivated: string = '::GmiPartners:PartnerDeactivated';
  public gmiPartners_ReadOnly: string = '::GmiPartners:ReadOnly';
  public static GmiPartners_ReadOnly: string = '::GmiPartners:ReadOnly';
  public gmiPartners_Swift: string = '::GmiPartners:Swift';
  public static GmiPartners_Swift: string = '::GmiPartners:Swift';
  public gmiPartners_SyncArchiveDocuments: string = '::GmiPartners:SyncArchiveDocuments';
  public static GmiPartners_SyncArchiveDocuments: string = '::GmiPartners:SyncArchiveDocuments';
  public gmiPartners_Telefon: string = '::GmiPartners:Telefon';
  public static GmiPartners_Telefon: string = '::GmiPartners:Telefon';
  public gmiPartners_Trr: string = '::GmiPartners:Trr';
  public static GmiPartners_Trr: string = '::GmiPartners:Trr';
  public gmiPartners_Unit: string = '::GmiPartners:Unit';
  public static GmiPartners_Unit: string = '::GmiPartners:Unit';
  public gmiRequests_BpmGroup: string = '::GmiRequests:BpmGroup';
  public static GmiRequests_BpmGroup: string = '::GmiRequests:BpmGroup';
  public gmiRequests_Definition: string = '::GmiRequests:Definition';
  public static GmiRequests_Definition: string = '::GmiRequests:Definition';
  public gmiRequests_DefinitionEdit: string = '::GmiRequests:DefinitionEdit';
  public static GmiRequests_DefinitionEdit: string = '::GmiRequests:DefinitionEdit';
  public gmiRequests_DefinitionNew: string = '::GmiRequests:DefinitionNew';
  public static GmiRequests_DefinitionNew: string = '::GmiRequests:DefinitionNew';
  public gmiRequests_Destination: string = '::GmiRequests:Destination';
  public static GmiRequests_Destination: string = '::GmiRequests:Destination';
  public gmiRequests_DisplayedOn: string = '::GmiRequests:DisplayedOn';
  public static GmiRequests_DisplayedOn: string = '::GmiRequests:DisplayedOn';
  public gmiRequests_DocBrowse: string = '::GmiRequests:DocBrowse';
  public static GmiRequests_DocBrowse: string = '::GmiRequests:DocBrowse';
  public gmiRequests_DocOneTimeQRCode: string = '::GmiRequests:DocOneTimeQRCode';
  public static GmiRequests_DocOneTimeQRCode: string = '::GmiRequests:DocOneTimeQRCode';
  public gmiRequests_DocOR: string = '::GmiRequests:DocOR';
  public static GmiRequests_DocOR: string = '::GmiRequests:DocOR';
  public gmiRequests_DocScanQR: string = '::GmiRequests:DocScanQR';
  public static GmiRequests_DocScanQR: string = '::GmiRequests:DocScanQR';
  public gmiRequests_DocTransfer: string = '::GmiRequests:DocTransfer';
  public static GmiRequests_DocTransfer: string = '::GmiRequests:DocTransfer';
  public gmiRequests_Documents: string = '::GmiRequests:Documents';
  public static GmiRequests_Documents: string = '::GmiRequests:Documents';
  public gmiRequests_DocumentsQR: string = '::GmiRequests:DocumentsQR';
  public static GmiRequests_DocumentsQR: string = '::GmiRequests:DocumentsQR';
  public gmiRequests_DocumentsSearch: string = '::GmiRequests:DocumentsSearch';
  public static GmiRequests_DocumentsSearch: string = '::GmiRequests:DocumentsSearch';
  public gmiRequests_ErrSending: string = '::GmiRequests:ErrSending';
  public static GmiRequests_ErrSending: string = '::GmiRequests:ErrSending';
  public gmiRequests_Format: string = '::GmiRequests:Format';
  public static GmiRequests_Format: string = '::GmiRequests:Format';
  public gmiRequests_Or: string = '::GmiRequests:Or';
  public static GmiRequests_Or: string = '::GmiRequests:Or';
  public gmiRequests_ProblemSending: string = '::GmiRequests:ProblemSending';
  public static GmiRequests_ProblemSending: string = '::GmiRequests:ProblemSending';
  public gmiRequests_QRCodeAddDocuments: string = '::GmiRequests:QRCodeAddDocuments';
  public static GmiRequests_QRCodeAddDocuments: string = '::GmiRequests:QRCodeAddDocuments';
  public gmiRequests_RequestSent: string = '::GmiRequests:RequestSent';
  public static GmiRequests_RequestSent: string = '::GmiRequests:RequestSent';
  public gmiRequests_SaveRequest: string = '::GmiRequests:SaveRequest';
  public static GmiRequests_SaveRequest: string = '::GmiRequests:SaveRequest';
  public gmiRequests_Search: string = '::GmiRequests:Search';
  public static GmiRequests_Search: string = '::GmiRequests:Search';
  public gmiRequests_SendRequest: string = '::GmiRequests:SendRequest';
  public static GmiRequests_SendRequest: string = '::GmiRequests:SendRequest';
  public gmiRequests_SendRequestAddDocuments: string = '::GmiRequests:SendRequestAddDocuments';
  public static GmiRequests_SendRequestAddDocuments: string = '::GmiRequests:SendRequestAddDocuments';
  public gmiRequests_ShowDynamicModal: string = '::GmiRequests:ShowDynamicModal';
  public static GmiRequests_ShowDynamicModal: string = '::GmiRequests:ShowDynamicModal';
  public gmiRequests_ShowHistory: string = '::GmiRequests:ShowHistory';
  public static GmiRequests_ShowHistory: string = '::GmiRequests:ShowHistory';
  public gmiUsers_Activated: string = '::GmiUsers:Activated';
  public static GmiUsers_Activated: string = '::GmiUsers:Activated';
  public gmiUsers_AlreadyImpersonating: string = '::GmiUsers:AlreadyImpersonating';
  public static GmiUsers_AlreadyImpersonating: string = '::GmiUsers:AlreadyImpersonating';
  public gmiUsers_ButtonInsert: string = '::GmiUsers:ButtonInsert';
  public static GmiUsers_ButtonInsert: string = '::GmiUsers:ButtonInsert';
  public gmiUsers_ConfirmActivation: string = '::GmiUsers:ConfirmActivation';
  public static GmiUsers_ConfirmActivation: string = '::GmiUsers:ConfirmActivation';
  public gmiUsers_ConfirmDeactivation: string = '::GmiUsers:ConfirmDeactivation';
  public static GmiUsers_ConfirmDeactivation: string = '::GmiUsers:ConfirmDeactivation';
  public gmiUsers_ConfirmEmailConfirm: string = '::GmiUsers:ConfirmEmailConfirm';
  public static GmiUsers_ConfirmEmailConfirm: string = '::GmiUsers:ConfirmEmailConfirm';
  public gmiUsers_ConfirmEmailUnconfirm: string = '::GmiUsers:ConfirmEmailUnconfirm';
  public static GmiUsers_ConfirmEmailUnconfirm: string = '::GmiUsers:ConfirmEmailUnconfirm';
  public gmiUsers_EmailConfirmed: string = '::GmiUsers:EmailConfirmed';
  public static GmiUsers_EmailConfirmed: string = '::GmiUsers:EmailConfirmed';
  public gmiUsers_EmailConfirmSuccessful: string = '::GmiUsers:EmailConfirmSuccessful';
  public static GmiUsers_EmailConfirmSuccessful: string = '::GmiUsers:EmailConfirmSuccessful';
  public gmiUsers_EmailUnconfirmSuccessful: string = '::GmiUsers:EmailUnconfirmSuccessful';
  public static GmiUsers_EmailUnconfirmSuccessful: string = '::GmiUsers:EmailUnconfirmSuccessful';
  public gmiUsers_ErrorEditingModalLabel: string = '::GmiUsers:ErrorEditingModalLabel';
  public static GmiUsers_ErrorEditingModalLabel: string = '::GmiUsers:ErrorEditingModalLabel';
  public gmiUsers_ErrorUserHasDummyEmail: string = '::GmiUsers:ErrorUserHasDummyEmail';
  public static GmiUsers_ErrorUserHasDummyEmail: string = '::GmiUsers:ErrorUserHasDummyEmail';
  public gmiUsers_IdKupca: string = '::GmiUsers:IdKupca';
  public static GmiUsers_IdKupca: string = '::GmiUsers:IdKupca';
  public gmiUsers_IdProd: string = '::GmiUsers:IdProd';
  public static GmiUsers_IdProd: string = '::GmiUsers:IdProd';
  public gmiUsers_Impersonate: string = '::GmiUsers:Impersonate';
  public static GmiUsers_Impersonate: string = '::GmiUsers:Impersonate';
  public gmiUsers_ImpersonatioNotAllowed: string = '::GmiUsers:ImpersonatioNotAllowed';
  public static GmiUsers_ImpersonatioNotAllowed: string = '::GmiUsers:ImpersonatioNotAllowed';
  public gmiUsers_InsertNewRole: string = '::GmiUsers:InsertNewRole';
  public static GmiUsers_InsertNewRole: string = '::GmiUsers:InsertNewRole';
  public gmiUsers_NewUser: string = '::GmiUsers:NewUser';
  public static GmiUsers_NewUser: string = '::GmiUsers:NewUser';
  public gmiUsers_PartnerTitle: string = '::GmiUsers:PartnerTitle';
  public static GmiUsers_PartnerTitle: string = '::GmiUsers:PartnerTitle';
  public gmiUsers_Password: string = '::GmiUsers:Password';
  public static GmiUsers_Password: string = '::GmiUsers:Password';
  public gmiUsers_PasswordChangeSuccessful: string = '::GmiUsers:PasswordChangeSuccessful';
  public static GmiUsers_PasswordChangeSuccessful: string = '::GmiUsers:PasswordChangeSuccessful';
  public gmiUsers_PasswordChangeTitle: string = '::GmiUsers:PasswordChangeTitle';
  public static GmiUsers_PasswordChangeTitle: string = '::GmiUsers:PasswordChangeTitle';
  public gmiUsers_PasswordConfirm: string = '::GmiUsers:PasswordConfirm';
  public static GmiUsers_PasswordConfirm: string = '::GmiUsers:PasswordConfirm';
  public gmiUsers_PasswordNextChange: string = '::GmiUsers:PasswordNextChange';
  public static GmiUsers_PasswordNextChange: string = '::GmiUsers:PasswordNextChange';
  public gmiUsers_PasswordOld: string = '::GmiUsers:PasswordOld';
  public static GmiUsers_PasswordOld: string = '::GmiUsers:PasswordOld';
  public gmiUsers_PlaceholderBeginTyping: string = '::GmiUsers:PlaceholderBeginTyping';
  public static GmiUsers_PlaceholderBeginTyping: string = '::GmiUsers:PlaceholderBeginTyping';
  public gmiUsers_ShowingPartnerUser: string = '::GmiUsers:ShowingPartnerUser';
  public static GmiUsers_ShowingPartnerUser: string = '::GmiUsers:ShowingPartnerUser';
  public gmiUsers_ShowUsersInRole: string = '::GmiUsers:ShowUsersInRole';
  public static GmiUsers_ShowUsersInRole: string = '::GmiUsers:ShowUsersInRole';
  public gmiUsers_Title: string = '::GmiUsers:Title';
  public static GmiUsers_Title: string = '::GmiUsers:Title';
  public gmiUsers_UnconfirmEmail: string = '::GmiUsers:UnconfirmEmail';
  public static GmiUsers_UnconfirmEmail: string = '::GmiUsers:UnconfirmEmail';
  public gmiUsers_UserActivated: string = '::GmiUsers:UserActivated';
  public static GmiUsers_UserActivated: string = '::GmiUsers:UserActivated';
  public gmiUsers_UserDataTitle: string = '::GmiUsers:UserDataTitle';
  public static GmiUsers_UserDataTitle: string = '::GmiUsers:UserDataTitle';
  public gmiUsers_UserDeactivated: string = '::GmiUsers:UserDeactivated';
  public static GmiUsers_UserDeactivated: string = '::GmiUsers:UserDeactivated';
  public gmiUsers_Username: string = '::GmiUsers:Username';
  public static GmiUsers_Username: string = '::GmiUsers:Username';
  public gmiUsers_UserRolesTitle: string = '::GmiUsers:UserRolesTitle';
  public static GmiUsers_UserRolesTitle: string = '::GmiUsers:UserRolesTitle';
  public gmiUsers_UserTitle: string = '::GmiUsers:UserTitle';
  public static GmiUsers_UserTitle: string = '::GmiUsers:UserTitle';
  public gmiUsersHist_Action: string = '::GmiUsersHist:Action';
  public static GmiUsersHist_Action: string = '::GmiUsersHist:Action';
  public gmiUsersHist_CreationTime: string = '::GmiUsersHist:CreationTime';
  public static GmiUsersHist_CreationTime: string = '::GmiUsersHist:CreationTime';
  public gmiUsersHist_LoginHist: string = '::GmiUsersHist:LoginHist';
  public static GmiUsersHist_LoginHist: string = '::GmiUsersHist:LoginHist';
  public help_Title: string = '::Help:Title';
  public static Help_Title: string = '::Help:Title';
  public impersonation_AlreadyImpersonatingUser: string = '::Impersonation:AlreadyImpersonatingUser';
  public static Impersonation_AlreadyImpersonatingUser: string = '::Impersonation:AlreadyImpersonatingUser';
  public impersonation_LabelSetSuccessfuly: string = '::Impersonation:LabelSetSuccessfuly';
  public static Impersonation_LabelSetSuccessfuly: string = '::Impersonation:LabelSetSuccessfuly';
  public impersonation_LabelStoppedSuccessfuly: string = '::Impersonation:LabelStoppedSuccessfuly';
  public static Impersonation_LabelStoppedSuccessfuly: string = '::Impersonation:LabelStoppedSuccessfuly';
  public impersonation_StopAndImpersonateNew: string = '::Impersonation:StopAndImpersonateNew';
  public static Impersonation_StopAndImpersonateNew: string = '::Impersonation:StopAndImpersonateNew';
  public impersonation_StopImpersonation: string = '::Impersonation:StopImpersonation';
  public static Impersonation_StopImpersonation: string = '::Impersonation:StopImpersonation';
  public insuranceClaims_ChassisNumber: string = '::InsuranceClaims:ChassisNumber';
  public static InsuranceClaims_ChassisNumber: string = '::InsuranceClaims:ChassisNumber';
  public insuranceClaims_CoreId: string = '::InsuranceClaims:CoreId';
  public static InsuranceClaims_CoreId: string = '::InsuranceClaims:CoreId';
  public insuranceClaims_CritInsuranceCase: string = '::InsuranceClaims:CritInsuranceCase';
  public static InsuranceClaims_CritInsuranceCase: string = '::InsuranceClaims:CritInsuranceCase';
  public insuranceClaims_DamageDate: string = '::InsuranceClaims:DamageDate';
  public static InsuranceClaims_DamageDate: string = '::InsuranceClaims:DamageDate';
  public insuranceClaims_DateInserted: string = '::InsuranceClaims:DateInserted';
  public static InsuranceClaims_DateInserted: string = '::InsuranceClaims:DateInserted';
  public insuranceClaims_DateProcessed: string = '::InsuranceClaims:DateProcessed';
  public static InsuranceClaims_DateProcessed: string = '::InsuranceClaims:DateProcessed';
  public insuranceClaims_Finished: string = '::InsuranceClaims:Finished';
  public static InsuranceClaims_Finished: string = '::InsuranceClaims:Finished';
  public insuranceClaims_GridInsuranceCase: string = '::InsuranceClaims:GridInsuranceCase';
  public static InsuranceClaims_GridInsuranceCase: string = '::InsuranceClaims:GridInsuranceCase';
  public insuranceClaims_InsuranceAmount: string = '::InsuranceClaims:InsuranceAmount';
  public static InsuranceClaims_InsuranceAmount: string = '::InsuranceClaims:InsuranceAmount';
  public insuranceClaims_InsuranceCase: string = '::InsuranceClaims:InsuranceCase';
  public static InsuranceClaims_InsuranceCase: string = '::InsuranceClaims:InsuranceCase';
  public insuranceClaims_InsuranceCompany: string = '::InsuranceClaims:InsuranceCompany';
  public static InsuranceClaims_InsuranceCompany: string = '::InsuranceClaims:InsuranceCompany';
  public insuranceClaims_InsuranceDate: string = '::InsuranceClaims:InsuranceDate';
  public static InsuranceClaims_InsuranceDate: string = '::InsuranceClaims:InsuranceDate';
  public insuranceClaims_InsuranceId: string = '::InsuranceClaims:InsuranceId';
  public static InsuranceClaims_InsuranceId: string = '::InsuranceClaims:InsuranceId';
  public insuranceClaims_InsuranceStatusApproved: string = '::InsuranceClaims:InsuranceStatusApproved';
  public static InsuranceClaims_InsuranceStatusApproved: string = '::InsuranceClaims:InsuranceStatusApproved';
  public insuranceClaims_InsuranceStatusError: string = '::InsuranceClaims:InsuranceStatusError';
  public static InsuranceClaims_InsuranceStatusError: string = '::InsuranceClaims:InsuranceStatusError';
  public insuranceClaims_InsuranceStatusInserted: string = '::InsuranceClaims:InsuranceStatusInserted';
  public static InsuranceClaims_InsuranceStatusInserted: string = '::InsuranceClaims:InsuranceStatusInserted';
  public insuranceClaims_InsuranceStatusRejected: string = '::InsuranceClaims:InsuranceStatusRejected';
  public static InsuranceClaims_InsuranceStatusRejected: string = '::InsuranceClaims:InsuranceStatusRejected';
  public insuranceClaims_New: string = '::InsuranceClaims:New';
  public static InsuranceClaims_New: string = '::InsuranceClaims:New';
  public insuranceClaims_NumberOfInsuranceCase: string = '::InsuranceClaims:NumberOfInsuranceCase';
  public static InsuranceClaims_NumberOfInsuranceCase: string = '::InsuranceClaims:NumberOfInsuranceCase';
  public insuranceClaims_PdfNotAvailableMessage: string = '::InsuranceClaims:PdfNotAvailableMessage';
  public static InsuranceClaims_PdfNotAvailableMessage: string = '::InsuranceClaims:PdfNotAvailableMessage';
  public insuranceClaims_Print: string = '::InsuranceClaims:Print';
  public static InsuranceClaims_Print: string = '::InsuranceClaims:Print';
  public insuranceClaims_PrintClaim: string = '::InsuranceClaims:PrintClaim';
  public static InsuranceClaims_PrintClaim: string = '::InsuranceClaims:PrintClaim';
  public insuranceClaims_ProcessStatus: string = '::InsuranceClaims:ProcessStatus';
  public static InsuranceClaims_ProcessStatus: string = '::InsuranceClaims:ProcessStatus';
  public insuranceClaims_Required: string = '::InsuranceClaims:Required';
  public static InsuranceClaims_Required: string = '::InsuranceClaims:Required';
  public insuranceClaims_Save: string = '::InsuranceClaims:Save';
  public static InsuranceClaims_Save: string = '::InsuranceClaims:Save';
  public insuranceClaims_Status: string = '::InsuranceClaims:Status';
  public static InsuranceClaims_Status: string = '::InsuranceClaims:Status';
  public insuranceClaims_Title: string = '::InsuranceClaims:Title';
  public static InsuranceClaims_Title: string = '::InsuranceClaims:Title';
  public insuranceClaims_Waiting: string = '::InsuranceClaims:Waiting';
  public static InsuranceClaims_Waiting: string = '::InsuranceClaims:Waiting';
  public insuranceClaimsStatus_Approved: string = '::InsuranceClaimsStatus:Approved';
  public static InsuranceClaimsStatus_Approved: string = '::InsuranceClaimsStatus:Approved';
  public insuranceClaimsStatus_Error: string = '::InsuranceClaimsStatus:Error';
  public static InsuranceClaimsStatus_Error: string = '::InsuranceClaimsStatus:Error';
  public insuranceClaimsStatus_New: string = '::InsuranceClaimsStatus:New';
  public static InsuranceClaimsStatus_New: string = '::InsuranceClaimsStatus:New';
  public insuranceClaimsStatus_Proces: string = '::InsuranceClaimsStatus:Proces';
  public static InsuranceClaimsStatus_Proces: string = '::InsuranceClaimsStatus:Proces';
  public insuranceClaimsStatus_Rejected: string = '::InsuranceClaimsStatus:Rejected';
  public static InsuranceClaimsStatus_Rejected: string = '::InsuranceClaimsStatus:Rejected';
  public insuranceClaimsStatus_Workflow: string = '::InsuranceClaimsStatus:Workflow';
  public static InsuranceClaimsStatus_Workflow: string = '::InsuranceClaimsStatus:Workflow';
  public invalidUserNameOrPassword: string = '::InvalidUserNameOrPassword';
  public static InvalidUserNameOrPassword: string = '::InvalidUserNameOrPassword';
  public jobHistory_EndTime: string = '::JobHistory:EndTime';
  public static JobHistory_EndTime: string = '::JobHistory:EndTime';
  public jobHistory_Error: string = '::JobHistory:Error';
  public static JobHistory_Error: string = '::JobHistory:Error';
  public jobHistory_Id: string = '::JobHistory:Id';
  public static JobHistory_Id: string = '::JobHistory:Id';
  public jobHistory_JobHistory: string = '::JobHistory:JobHistory';
  public static JobHistory_JobHistory: string = '::JobHistory:JobHistory';
  public jobHistory_JobHistoryId: string = '::JobHistory:JobHistoryId';
  public static JobHistory_JobHistoryId: string = '::JobHistory:JobHistoryId';
  public jobHistory_StartTime: string = '::JobHistory:StartTime';
  public static JobHistory_StartTime: string = '::JobHistory:StartTime';
  public jobHistory_Title: string = '::JobHistory:Title';
  public static JobHistory_Title: string = '::JobHistory:Title';
  public jobs_ChangeComment: string = '::Jobs:ChangeComment';
  public static Jobs_ChangeComment: string = '::Jobs:ChangeComment';
  public jobs_ChangeId: string = '::Jobs:ChangeId';
  public static Jobs_ChangeId: string = '::Jobs:ChangeId';
  public jobs_ErrFromToTime: string = '::Jobs:ErrFromToTime';
  public static Jobs_ErrFromToTime: string = '::Jobs:ErrFromToTime';
  public jobs_Error: string = '::Jobs:Error';
  public static Jobs_Error: string = '::Jobs:Error';
  public jobs_FromTime: string = '::Jobs:FromTime';
  public static Jobs_FromTime: string = '::Jobs:FromTime';
  public jobs_FromTimeHours: string = '::Jobs:FromTimeHours';
  public static Jobs_FromTimeHours: string = '::Jobs:FromTimeHours';
  public jobs_FromTimeMinutes: string = '::Jobs:FromTimeMinutes';
  public static Jobs_FromTimeMinutes: string = '::Jobs:FromTimeMinutes';
  public jobs_Inactive: string = '::Jobs:Inactive';
  public static Jobs_Inactive: string = '::Jobs:Inactive';
  public jobs_Insert: string = '::Jobs:Insert';
  public static Jobs_Insert: string = '::Jobs:Insert';
  public jobs_LastChangeComment: string = '::Jobs:LastChangeComment';
  public static Jobs_LastChangeComment: string = '::Jobs:LastChangeComment';
  public jobs_LastChangeDate: string = '::Jobs:LastChangeDate';
  public static Jobs_LastChangeDate: string = '::Jobs:LastChangeDate';
  public jobs_LastChangeId: string = '::Jobs:LastChangeId';
  public static Jobs_LastChangeId: string = '::Jobs:LastChangeId';
  public jobs_NextRunTime: string = '::Jobs:NextRunTime';
  public static Jobs_NextRunTime: string = '::Jobs:NextRunTime';
  public jobs_PreviousChange: string = '::Jobs:PreviousChange';
  public static Jobs_PreviousChange: string = '::Jobs:PreviousChange';
  public jobs_StatusEdit: string = '::Jobs:StatusEdit';
  public static Jobs_StatusEdit: string = '::Jobs:StatusEdit';
  public jobs_StatusNew: string = '::Jobs:StatusNew';
  public static Jobs_StatusNew: string = '::Jobs:StatusNew';
  public jobs_TimeToNextruntime: string = '::Jobs:TimeToNextruntime';
  public static Jobs_TimeToNextruntime: string = '::Jobs:TimeToNextruntime';
  public jobs_ToTime: string = '::Jobs:ToTime';
  public static Jobs_ToTime: string = '::Jobs:ToTime';
  public jobs_ToTimeHours: string = '::Jobs:ToTimeHours';
  public static Jobs_ToTimeHours: string = '::Jobs:ToTimeHours';
  public jobs_ToTimeMinutes: string = '::Jobs:ToTimeMinutes';
  public static Jobs_ToTimeMinutes: string = '::Jobs:ToTimeMinutes';
  public language_Croatian: string = '::Language:Croatian';
  public static Language_Croatian: string = '::Language:Croatian';
  public language_English: string = '::Language:English';
  public static Language_English: string = '::Language:English';
  public language_Macedonian: string = '::Language:Macedonian';
  public static Language_Macedonian: string = '::Language:Macedonian';
  public language_SerbianCyrillic: string = '::Language:SerbianCyrillic';
  public static Language_SerbianCyrillic: string = '::Language:SerbianCyrillic';
  public language_SerbianLatin: string = '::Language:SerbianLatin';
  public static Language_SerbianLatin: string = '::Language:SerbianLatin';
  public language_Slovenian: string = '::Language:Slovenian';
  public static Language_Slovenian: string = '::Language:Slovenian';
  public license_ErrorContractQuotaError: string = '::License:ErrorContractQuotaError';
  public static License_ErrorContractQuotaError: string = '::License:ErrorContractQuotaError';
  public license_ErrorContractQuotaWarning: string = '::License:ErrorContractQuotaWarning';
  public static License_ErrorContractQuotaWarning: string = '::License:ErrorContractQuotaWarning';
  public license_ErrorGeneratingLesseeRole: string = '::License:ErrorGeneratingLesseeRole';
  public static License_ErrorGeneratingLesseeRole: string = '::License:ErrorGeneratingLesseeRole';
  public license_ErrorInvalidLicenseFile: string = '::License:ErrorInvalidLicenseFile';
  public static License_ErrorInvalidLicenseFile: string = '::License:ErrorInvalidLicenseFile';
  public license_ErrorLesseeRoleNotSet: string = '::License:ErrorLesseeRoleNotSet';
  public static License_ErrorLesseeRoleNotSet: string = '::License:ErrorLesseeRoleNotSet';
  public license_ErrorLicenseError: string = '::License:ErrorLicenseError';
  public static License_ErrorLicenseError: string = '::License:ErrorLicenseError';
  public license_ErrorLicenseExpired: string = '::License:ErrorLicenseExpired';
  public static License_ErrorLicenseExpired: string = '::License:ErrorLicenseExpired';
  public license_ErrorLicenseExpiresWarning: string = '::License:ErrorLicenseExpiresWarning';
  public static License_ErrorLicenseExpiresWarning: string = '::License:ErrorLicenseExpiresWarning';
  public license_ErrorLicenseInvalidIdKupca: string = '::License:ErrorLicenseInvalidIdKupca';
  public static License_ErrorLicenseInvalidIdKupca: string = '::License:ErrorLicenseInvalidIdKupca';
  public license_ErrorLicenseSubjectWarning: string = '::License:ErrorLicenseSubjectWarning';
  public static License_ErrorLicenseSubjectWarning: string = '::License:ErrorLicenseSubjectWarning';
  public license_ErrorLicenseWarningInfo: string = '::License:ErrorLicenseWarningInfo';
  public static License_ErrorLicenseWarningInfo: string = '::License:ErrorLicenseWarningInfo';
  public license_ErrorMissingDatabaseSettings: string = '::License:ErrorMissingDatabaseSettings';
  public static License_ErrorMissingDatabaseSettings: string = '::License:ErrorMissingDatabaseSettings';
  public license_ErrorMissingSupportIdKupca: string = '::License:ErrorMissingSupportIdKupca';
  public static License_ErrorMissingSupportIdKupca: string = '::License:ErrorMissingSupportIdKupca';
  public license_ErrorModuleNotEnabled: string = '::License:ErrorModuleNotEnabled';
  public static License_ErrorModuleNotEnabled: string = '::License:ErrorModuleNotEnabled';
  public license_ErrorOfferQuotaError: string = '::License:ErrorOfferQuotaError';
  public static License_ErrorOfferQuotaError: string = '::License:ErrorOfferQuotaError';
  public license_ErrorOfferQuotaWarning: string = '::License:ErrorOfferQuotaWarning';
  public static License_ErrorOfferQuotaWarning: string = '::License:ErrorOfferQuotaWarning';
  public license_ErrorToManyDatabaseSettings: string = '::License:ErrorToManyDatabaseSettings';
  public static License_ErrorToManyDatabaseSettings: string = '::License:ErrorToManyDatabaseSettings';
  public license_ErrorWrongCompany: string = '::License:ErrorWrongCompany';
  public static License_ErrorWrongCompany: string = '::License:ErrorWrongCompany';
  public license_Title: string = '::License:Title';
  public static License_Title: string = '::License:Title';
  public login_ChangeEmailWarning: string = '::Login:ChangeEmailWarning';
  public static Login_ChangeEmailWarning: string = '::Login:ChangeEmailWarning';
  public login_ConfirmedMailTitle: string = '::Login:ConfirmedMailTitle';
  public static Login_ConfirmedMailTitle: string = '::Login:ConfirmedMailTitle';
  public login_ConfirmEmail: string = '::Login:ConfirmEmail';
  public static Login_ConfirmEmail: string = '::Login:ConfirmEmail';
  public login_ConfirmEmailMessage: string = '::Login:ConfirmEmailMessage';
  public static Login_ConfirmEmailMessage: string = '::Login:ConfirmEmailMessage';
  public login_ConfirmEmailNotification: string = '::Login:ConfirmEmailNotification';
  public static Login_ConfirmEmailNotification: string = '::Login:ConfirmEmailNotification';
  public login_ConfirmMailTitle: string = '::Login:ConfirmMailTitle';
  public static Login_ConfirmMailTitle: string = '::Login:ConfirmMailTitle';
  public login_EmailConfirmationSent: string = '::Login:EmailConfirmationSent';
  public static Login_EmailConfirmationSent: string = '::Login:EmailConfirmationSent';
  public login_EmailConfirmationSuccess: string = '::Login:EmailConfirmationSuccess';
  public static Login_EmailConfirmationSuccess: string = '::Login:EmailConfirmationSuccess';
  public login_ForgotPassword: string = '::Login:ForgotPassword';
  public static Login_ForgotPassword: string = '::Login:ForgotPassword';
  public login_ForgotPasswordMessage: string = '::Login:ForgotPasswordMessage';
  public static Login_ForgotPasswordMessage: string = '::Login:ForgotPasswordMessage';
  public login_ForgotPasswordNotification: string = '::Login:ForgotPasswordNotification';
  public static Login_ForgotPasswordNotification: string = '::Login:ForgotPasswordNotification';
  public login_ForgotPasswordTitle: string = '::Login:ForgotPasswordTitle';
  public static Login_ForgotPasswordTitle: string = '::Login:ForgotPasswordTitle';
  public login_IncorrectLoginInformation: string = '::Login:IncorrectLoginInformation';
  public static Login_IncorrectLoginInformation: string = '::Login:IncorrectLoginInformation';
  public login_LoginButton: string = '::Login:LoginButton';
  public static Login_LoginButton: string = '::Login:LoginButton';
  public login_LoginFailed: string = '::Login:LoginFailed';
  public static Login_LoginFailed: string = '::Login:LoginFailed';
  public login_Message: string = '::Login:Message';
  public static Login_Message: string = '::Login:Message';
  public login_Password: string = '::Login:Password';
  public static Login_Password: string = '::Login:Password';
  public login_PasswordResetExtraDataRequired: string = '::Login:PasswordResetExtraDataRequired';
  public static Login_PasswordResetExtraDataRequired: string = '::Login:PasswordResetExtraDataRequired';
  public login_PasswordResetMessage: string = '::Login:PasswordResetMessage';
  public static Login_PasswordResetMessage: string = '::Login:PasswordResetMessage';
  public login_PasswordResetTitle: string = '::Login:PasswordResetTitle';
  public static Login_PasswordResetTitle: string = '::Login:PasswordResetTitle';
  public login_RegistrationMessage: string = '::Login:RegistrationMessage';
  public static Login_RegistrationMessage: string = '::Login:RegistrationMessage';
  public login_RegistrationSuccess: string = '::Login:RegistrationSuccess';
  public static Login_RegistrationSuccess: string = '::Login:RegistrationSuccess';
  public login_RegistrationTitle: string = '::Login:RegistrationTitle';
  public static Login_RegistrationTitle: string = '::Login:RegistrationTitle';
  public login_RememberMe: string = '::Login:RememberMe';
  public static Login_RememberMe: string = '::Login:RememberMe';
  public login_ResendEmail: string = '::Login:ResendEmail';
  public static Login_ResendEmail: string = '::Login:ResendEmail';
  public login_Title: string = '::Login:Title';
  public static Login_Title: string = '::Login:Title';
  public login_TwoFactorAuthenticatorMessage: string = '::Login:TwoFactorAuthenticatorMessage';
  public static Login_TwoFactorAuthenticatorMessage: string = '::Login:TwoFactorAuthenticatorMessage';
  public login_TwoFactorChoiceMessage: string = '::Login:TwoFactorChoiceMessage';
  public static Login_TwoFactorChoiceMessage: string = '::Login:TwoFactorChoiceMessage';
  public login_TwoFactorEmailMessage: string = '::Login:TwoFactorEmailMessage';
  public static Login_TwoFactorEmailMessage: string = '::Login:TwoFactorEmailMessage';
  public login_TwoFactorFirstError: string = '::Login:TwoFactorFirstError';
  public static Login_TwoFactorFirstError: string = '::Login:TwoFactorFirstError';
  public login_TwoFactorFirstOk: string = '::Login:TwoFactorFirstOk';
  public static Login_TwoFactorFirstOk: string = '::Login:TwoFactorFirstOk';
  public login_TwoFactorGenerateQrCode: string = '::Login:TwoFactorGenerateQrCode';
  public static Login_TwoFactorGenerateQrCode: string = '::Login:TwoFactorGenerateQrCode';
  public login_TwoFactorIncorrect: string = '::Login:TwoFactorIncorrect';
  public static Login_TwoFactorIncorrect: string = '::Login:TwoFactorIncorrect';
  public login_TwoFactorSendCode: string = '::Login:TwoFactorSendCode';
  public static Login_TwoFactorSendCode: string = '::Login:TwoFactorSendCode';
  public login_TwoFactorSentToEmail: string = '::Login:TwoFactorSentToEmail';
  public static Login_TwoFactorSentToEmail: string = '::Login:TwoFactorSentToEmail';
  public login_TwoFactorTitle: string = '::Login:TwoFactorTitle';
  public static Login_TwoFactorTitle: string = '::Login:TwoFactorTitle';
  public login_TwoFactorVerifiyCode: string = '::Login:TwoFactorVerifiyCode';
  public static Login_TwoFactorVerifiyCode: string = '::Login:TwoFactorVerifiyCode';
  public login_UnconfirmedEmail: string = '::Login:UnconfirmedEmail';
  public static Login_UnconfirmedEmail: string = '::Login:UnconfirmedEmail';
  public login_Username: string = '::Login:Username';
  public static Login_Username: string = '::Login:Username';
  public loginErrorCodes_AbpUserInactive: string = '::LoginErrorCodes:AbpUserInactive';
  public static LoginErrorCodes_AbpUserInactive: string = '::LoginErrorCodes:AbpUserInactive';
  public loginErrorCodes_AbpUserNotExists: string = '::LoginErrorCodes:AbpUserNotExists';
  public static LoginErrorCodes_AbpUserNotExists: string = '::LoginErrorCodes:AbpUserNotExists';
  public loginErrorCodes_AbpUserWithEmailNotExists: string = '::LoginErrorCodes:AbpUserWithEmailNotExists';
  public static LoginErrorCodes_AbpUserWithEmailNotExists: string = '::LoginErrorCodes:AbpUserWithEmailNotExists';
  public loginErrorCodes_AbpUserWithUsernameNotExists: string = '::LoginErrorCodes:AbpUserWithUsernameNotExists';
  public static LoginErrorCodes_AbpUserWithUsernameNotExists: string = '::LoginErrorCodes:AbpUserWithUsernameNotExists';
  public loginErrorCodes_ApbUnconfirmedEMail: string = '::LoginErrorCodes:ApbUnconfirmedEMail';
  public static LoginErrorCodes_ApbUnconfirmedEMail: string = '::LoginErrorCodes:ApbUnconfirmedEMail';
  public loginErrorCodes_ErrorConfirmingEmail: string = '::LoginErrorCodes:ErrorConfirmingEmail';
  public static LoginErrorCodes_ErrorConfirmingEmail: string = '::LoginErrorCodes:ErrorConfirmingEmail';
  public loginErrorCodes_ErrorLoginCheck: string = '::LoginErrorCodes:ErrorLoginCheck';
  public static LoginErrorCodes_ErrorLoginCheck: string = '::LoginErrorCodes:ErrorLoginCheck';
  public loginErrorCodes_ErrorSendingEmail: string = '::LoginErrorCodes:ErrorSendingEmail';
  public static LoginErrorCodes_ErrorSendingEmail: string = '::LoginErrorCodes:ErrorSendingEmail';
  public loginErrorCodes_ErrorUserInNoRoles: string = '::LoginErrorCodes:ErrorUserInNoRoles';
  public static LoginErrorCodes_ErrorUserInNoRoles: string = '::LoginErrorCodes:ErrorUserInNoRoles';
  public loginErrorCodes_GmiContractNotExists: string = '::LoginErrorCodes:GmiContractNotExists';
  public static LoginErrorCodes_GmiContractNotExists: string = '::LoginErrorCodes:GmiContractNotExists';
  public loginErrorCodes_GmiPartnerInactive: string = '::LoginErrorCodes:GmiPartnerInactive';
  public static LoginErrorCodes_GmiPartnerInactive: string = '::LoginErrorCodes:GmiPartnerInactive';
  public loginErrorCodes_GmiPartnerNotExists: string = '::LoginErrorCodes:GmiPartnerNotExists';
  public static LoginErrorCodes_GmiPartnerNotExists: string = '::LoginErrorCodes:GmiPartnerNotExists';
  public loginErrorCodes_GmiUserNotExists: string = '::LoginErrorCodes:GmiUserNotExists';
  public static LoginErrorCodes_GmiUserNotExists: string = '::LoginErrorCodes:GmiUserNotExists';
  public loginErrorCodes_GmiUserWithEmailAlreadyExists: string = '::LoginErrorCodes:GmiUserWithEmailAlreadyExists';
  public static LoginErrorCodes_GmiUserWithEmailAlreadyExists: string = '::LoginErrorCodes:GmiUserWithEmailAlreadyExists';
  public loginErrorCodes_IncorrectPassword: string = '::LoginErrorCodes:IncorrectPassword';
  public static LoginErrorCodes_IncorrectPassword: string = '::LoginErrorCodes:IncorrectPassword';
  public loginErrorCodes_ToManyIncorrectEntries: string = '::LoginErrorCodes:ToManyIncorrectEntries';
  public static LoginErrorCodes_ToManyIncorrectEntries: string = '::LoginErrorCodes:ToManyIncorrectEntries';
  public loginIsNotAllowed: string = '::LoginIsNotAllowed';
  public static LoginIsNotAllowed: string = '::LoginIsNotAllowed';
  public mail_Attachment: string = '::Mail:Attachment';
  public static Mail_Attachment: string = '::Mail:Attachment';
  public mail_AttachmentName: string = '::Mail:AttachmentName';
  public static Mail_AttachmentName: string = '::Mail:AttachmentName';
  public mail_Attachments: string = '::Mail:Attachments';
  public static Mail_Attachments: string = '::Mail:Attachments';
  public mail_Content: string = '::Mail:Content';
  public static Mail_Content: string = '::Mail:Content';
  public mail_DateError: string = '::Mail:DateError';
  public static Mail_DateError: string = '::Mail:DateError';
  public mail_DateInserted: string = '::Mail:DateInserted';
  public static Mail_DateInserted: string = '::Mail:DateInserted';
  public mail_DateSent: string = '::Mail:DateSent';
  public static Mail_DateSent: string = '::Mail:DateSent';
  public mail_Details: string = '::Mail:Details';
  public static Mail_Details: string = '::Mail:Details';
  public mail_Error: string = '::Mail:Error';
  public static Mail_Error: string = '::Mail:Error';
  public mail_SendTo: string = '::Mail:SendTo';
  public static Mail_SendTo: string = '::Mail:SendTo';
  public mail_SentBcc: string = '::Mail:SentBcc';
  public static Mail_SentBcc: string = '::Mail:SentBcc';
  public mail_SentCc: string = '::Mail:SentCc';
  public static Mail_SentCc: string = '::Mail:SentCc';
  public mail_SentFrom: string = '::Mail:SentFrom';
  public static Mail_SentFrom: string = '::Mail:SentFrom';
  public mail_Status: string = '::Mail:Status';
  public static Mail_Status: string = '::Mail:Status';
  public mail_Subject: string = '::Mail:Subject';
  public static Mail_Subject: string = '::Mail:Subject';
  public mail_WasSuccessfullySent: string = '::Mail:WasSuccessfullySent';
  public static Mail_WasSuccessfullySent: string = '::Mail:WasSuccessfullySent';
  public maintenance_Admin: string = '::Maintenance:Admin';
  public static Maintenance_Admin: string = '::Maintenance:Admin';
  public maintenance_Public: string = '::Maintenance:Public';
  public static Maintenance_Public: string = '::Maintenance:Public';
  public maintenance_RestartApplication: string = '::Maintenance:RestartApplication';
  public static Maintenance_RestartApplication: string = '::Maintenance:RestartApplication';
  public menu_Administration: string = '::Menu:Administration';
  public static Menu_Administration: string = '::Menu:Administration';
  public menu_Contracts: string = '::Menu:Contracts';
  public static Menu_Contracts: string = '::Menu:Contracts';
  public menu_Dashboard: string = '::Menu:Dashboard';
  public static Menu_Dashboard: string = '::Menu:Dashboard';
  public menu_EaDocuments: string = '::Menu:EaDocuments';
  public static Menu_EaDocuments: string = '::Menu:EaDocuments';
  public menu_ExternalFunctions: string = '::Menu:ExternalFunctions';
  public static Menu_ExternalFunctions: string = '::Menu:ExternalFunctions';
  public menu_FinSettings: string = '::Menu:FinSettings';
  public static Menu_FinSettings: string = '::Menu:FinSettings';
  public menu_GmiPartners: string = '::Menu:GmiPartners';
  public static Menu_GmiPartners: string = '::Menu:GmiPartners';
  public menu_GmiUsers: string = '::Menu:GmiUsers';
  public static Menu_GmiUsers: string = '::Menu:GmiUsers';
  public menu_Help: string = '::Menu:Help';
  public static Menu_Help: string = '::Menu:Help';
  public menu_Home: string = '::Menu:Home';
  public static Menu_Home: string = '::Menu:Home';
  public menu_Insurance: string = '::Menu:Insurance';
  public static Menu_Insurance: string = '::Menu:Insurance';
  public menu_InsuranceClaims: string = '::Menu:InsuranceClaims';
  public static Menu_InsuranceClaims: string = '::Menu:InsuranceClaims';
  public menu_Jobs: string = '::Menu:Jobs';
  public static Menu_Jobs: string = '::Menu:Jobs';
  public menu_Lessee: string = '::Menu:Lessee';
  public static Menu_Lessee: string = '::Menu:Lessee';
  public menu_License: string = '::Menu:License';
  public static Menu_License: string = '::Menu:License';
  public menu_Mail: string = '::Menu:Mail';
  public static Menu_Mail: string = '::Menu:Mail';
  public menu_Maintenance: string = '::Menu:Maintenance';
  public static Menu_Maintenance: string = '::Menu:Maintenance';
  public menu_Messages: string = '::Menu:Messages';
  public static Menu_Messages: string = '::Menu:Messages';
  public menu_MissingDocumentation: string = '::Menu:MissingDocumentation';
  public static Menu_MissingDocumentation: string = '::Menu:MissingDocumentation';
  public menu_Notifications: string = '::Menu:Notifications';
  public static Menu_Notifications: string = '::Menu:Notifications';
  public menu_OverduePayments: string = '::Menu:OverduePayments';
  public static Menu_OverduePayments: string = '::Menu:OverduePayments';
  public menu_Partner: string = '::Menu:Partner';
  public static Menu_Partner: string = '::Menu:Partner';
  public menu_Payments: string = '::Menu:Payments';
  public static Menu_Payments: string = '::Menu:Payments';
  public menu_Personalizations: string = '::Menu:Personalizations';
  public static Menu_Personalizations: string = '::Menu:Personalizations';
  public menu_Recovery: string = '::Menu:Recovery';
  public static Menu_Recovery: string = '::Menu:Recovery';
  public menu_Reports: string = '::Menu:Reports';
  public static Menu_Reports: string = '::Menu:Reports';
  public menu_RequestDefinitions: string = '::Menu:RequestDefinitions';
  public static Menu_RequestDefinitions: string = '::Menu:RequestDefinitions';
  public menu_Requests: string = '::Menu:Requests';
  public static Menu_Requests: string = '::Menu:Requests';
  public menu_RequestUserRequests: string = '::Menu:RequestUserRequests';
  public static Menu_RequestUserRequests: string = '::Menu:RequestUserRequests';
  public menu_Roles: string = '::Menu:Roles';
  public static Menu_Roles: string = '::Menu:Roles';
  public menu_Settings: string = '::Menu:Settings';
  public static Menu_Settings: string = '::Menu:Settings';
  public menu_StockFunding: string = '::Menu:StockFunding';
  public static Menu_StockFunding: string = '::Menu:StockFunding';
  public menu_Styles: string = '::Menu:Styles';
  public static Menu_Styles: string = '::Menu:Styles';
  public menu_SystemSetings: string = '::Menu:SystemSetings';
  public static Menu_SystemSetings: string = '::Menu:SystemSetings';
  public menu_WfProcesses: string = '::Menu:WfProcesses';
  public static Menu_WfProcesses: string = '::Menu:WfProcesses';
  public menu_WfStatuses: string = '::Menu:WfStatuses';
  public static Menu_WfStatuses: string = '::Menu:WfStatuses';
  public messages_LastMessage: string = '::Messages:LastMessage';
  public static Messages_LastMessage: string = '::Messages:LastMessage';
  public messages_Message: string = '::Messages:Message';
  public static Messages_Message: string = '::Messages:Message';
  public messages_NewConversation: string = '::Messages:NewConversation';
  public static Messages_NewConversation: string = '::Messages:NewConversation';
  public messages_Recipients: string = '::Messages:Recipients';
  public static Messages_Recipients: string = '::Messages:Recipients';
  public messages_Sender: string = '::Messages:Sender';
  public static Messages_Sender: string = '::Messages:Sender';
  public messages_SentAt: string = '::Messages:SentAt';
  public static Messages_SentAt: string = '::Messages:SentAt';
  public messages_Subject: string = '::Messages:Subject';
  public static Messages_Subject: string = '::Messages:Subject';
  public messages_Title: string = '::Messages:Title';
  public static Messages_Title: string = '::Messages:Title';
  public missingDoc_DocumentDate: string = '::MissingDoc:DocumentDate';
  public static MissingDoc_DocumentDate: string = '::MissingDoc:DocumentDate';
  public missingDoc_Title: string = '::MissingDoc:Title';
  public static MissingDoc_Title: string = '::MissingDoc:Title';
  public month_April: string = '::Month:April';
  public static Month_April: string = '::Month:April';
  public month_August: string = '::Month:August';
  public static Month_August: string = '::Month:August';
  public month_December: string = '::Month:December';
  public static Month_December: string = '::Month:December';
  public month_February: string = '::Month:February';
  public static Month_February: string = '::Month:February';
  public month_January: string = '::Month:January';
  public static Month_January: string = '::Month:January';
  public month_July: string = '::Month:July';
  public static Month_July: string = '::Month:July';
  public month_June: string = '::Month:June';
  public static Month_June: string = '::Month:June';
  public month_March: string = '::Month:March';
  public static Month_March: string = '::Month:March';
  public month_May: string = '::Month:May';
  public static Month_May: string = '::Month:May';
  public month_November: string = '::Month:November';
  public static Month_November: string = '::Month:November';
  public month_October: string = '::Month:October';
  public static Month_October: string = '::Month:October';
  public month_September: string = '::Month:September';
  public static Month_September: string = '::Month:September';
  public navbar_EaDocumentToolTip: string = '::Navbar:EaDocumentToolTip';
  public static Navbar_EaDocumentToolTip: string = '::Navbar:EaDocumentToolTip';
  public navbar_Logout: string = '::Navbar:Logout';
  public static Navbar_Logout: string = '::Navbar:Logout';
  public navbar_MessagesToolTip: string = '::Navbar:MessagesToolTip';
  public static Navbar_MessagesToolTip: string = '::Navbar:MessagesToolTip';
  public navbar_MissingDocsToolTip: string = '::Navbar:MissingDocsToolTip';
  public static Navbar_MissingDocsToolTip: string = '::Navbar:MissingDocsToolTip';
  public navbar_NotificationsToolTip: string = '::Navbar:NotificationsToolTip';
  public static Navbar_NotificationsToolTip: string = '::Navbar:NotificationsToolTip';
  public navbar_Profile: string = '::Navbar:Profile';
  public static Navbar_Profile: string = '::Navbar:Profile';
  public oTPVerification_Error: string = '::OTPVerification:Error';
  public static OTPVerification_Error: string = '::OTPVerification:Error';
  public partners_DashboardTitle: string = '::Partners:DashboardTitle';
  public static Partners_DashboardTitle: string = '::Partners:DashboardTitle';
  public partners_DateOfBirth: string = '::Partners:DateOfBirth';
  public static Partners_DateOfBirth: string = '::Partners:DateOfBirth';
  public partners_DavStev: string = '::Partners:DavStev';
  public static Partners_DavStev: string = '::Partners:DavStev';
  public partners_DavStevId: string = '::Partners:DavStevId';
  public static Partners_DavStevId: string = '::Partners:DavStevId';
  public partners_DocDeliveryTitle: string = '::Partners:DocDeliveryTitle';
  public static Partners_DocDeliveryTitle: string = '::Partners:DocDeliveryTitle';
  public partners_Emso: string = '::Partners:Emso';
  public static Partners_Emso: string = '::Partners:Emso';
  public partners_IdKupca: string = '::Partners:IdKupca';
  public static Partners_IdKupca: string = '::Partners:IdKupca';
  public partners_NazKrKup: string = '::Partners:NazKrKup';
  public static Partners_NazKrKup: string = '::Partners:NazKrKup';
  public partners_ProfileContactData: string = '::Partners:ProfileContactData';
  public static Partners_ProfileContactData: string = '::Partners:ProfileContactData';
  public partners_ProfileTitle: string = '::Partners:ProfileTitle';
  public static Partners_ProfileTitle: string = '::Partners:ProfileTitle';
  public partners_Ulica: string = '::Partners:Ulica';
  public static Partners_Ulica: string = '::Partners:Ulica';
  public partners_UlicaSed: string = '::Partners:UlicaSed';
  public static Partners_UlicaSed: string = '::Partners:UlicaSed';
  public partners_UserProfileData: string = '::Partners:UserProfileData';
  public static Partners_UserProfileData: string = '::Partners:UserProfileData';
  public password_MustHaveDigit: string = '::Password:MustHaveDigit';
  public static Password_MustHaveDigit: string = '::Password:MustHaveDigit';
  public password_MustHaveLowerCase: string = '::Password:MustHaveLowerCase';
  public static Password_MustHaveLowerCase: string = '::Password:MustHaveLowerCase';
  public password_MusthaveNonAlphaNum: string = '::Password:MusthaveNonAlphaNum';
  public static Password_MusthaveNonAlphaNum: string = '::Password:MusthaveNonAlphaNum';
  public password_MusthaveUpperCase: string = '::Password:MusthaveUpperCase';
  public static Password_MusthaveUpperCase: string = '::Password:MusthaveUpperCase';
  public password_PasswordMustMatch: string = '::Password:PasswordMustMatch';
  public static Password_PasswordMustMatch: string = '::Password:PasswordMustMatch';
  public password_ResetSuccessfull: string = '::Password:ResetSuccessfull';
  public static Password_ResetSuccessfull: string = '::Password:ResetSuccessfull';
  public password_ToShort: string = '::Password:ToShort';
  public static Password_ToShort: string = '::Password:ToShort';
  public payments_AdvanceAmount: string = '::Payments:AdvanceAmount';
  public static Payments_AdvanceAmount: string = '::Payments:AdvanceAmount';
  public payments_Amount: string = '::Payments:Amount';
  public static Payments_Amount: string = '::Payments:Amount';
  public payments_BookingDate: string = '::Payments:BookingDate';
  public static Payments_BookingDate: string = '::Payments:BookingDate';
  public payments_CreditForeignAmount: string = '::Payments:CreditForeignAmount';
  public static Payments_CreditForeignAmount: string = '::Payments:CreditForeignAmount';
  public payments_CreditHomeAmount: string = '::Payments:CreditHomeAmount';
  public static Payments_CreditHomeAmount: string = '::Payments:CreditHomeAmount';
  public payments_Date: string = '::Payments:Date';
  public static Payments_Date: string = '::Payments:Date';
  public payments_Details: string = '::Payments:Details';
  public static Payments_Details: string = '::Payments:Details';
  public payments_DistributionTitle: string = '::Payments:DistributionTitle';
  public static Payments_DistributionTitle: string = '::Payments:DistributionTitle';
  public payments_IdPlac: string = '::Payments:IdPlac';
  public static Payments_IdPlac: string = '::Payments:IdPlac';
  public payments_Title: string = '::Payments:Title';
  public static Payments_Title: string = '::Payments:Title';
  public permission_AdminRequestsEdit: string = '::Permission:AdminRequestsEdit';
  public static Permission_AdminRequestsEdit: string = '::Permission:AdminRequestsEdit';
  public permission_AdminRequestsInsert: string = '::Permission:AdminRequestsInsert';
  public static Permission_AdminRequestsInsert: string = '::Permission:AdminRequestsInsert';
  public permission_CanSeeOthersStockFunding: string = '::Permission:CanSeeOthersStockFunding';
  public static Permission_CanSeeOthersStockFunding: string = '::Permission:CanSeeOthersStockFunding';
  public permission_Contracts: string = '::Permission:Contracts';
  public static Permission_Contracts: string = '::Permission:Contracts';
  public permission_EADocuments: string = '::Permission:EADocuments';
  public static Permission_EADocuments: string = '::Permission:EADocuments';
  public permission_ExternalFunctionsEdit: string = '::Permission:ExternalFunctionsEdit';
  public static Permission_ExternalFunctionsEdit: string = '::Permission:ExternalFunctionsEdit';
  public permission_GmiPartnersEdit: string = '::Permission:GmiPartnersEdit';
  public static Permission_GmiPartnersEdit: string = '::Permission:GmiPartnersEdit';
  public permission_GmiPartnersInsert: string = '::Permission:GmiPartnersInsert';
  public static Permission_GmiPartnersInsert: string = '::Permission:GmiPartnersInsert';
  public permission_GmiUsersEdit: string = '::Permission:GmiUsersEdit';
  public static Permission_GmiUsersEdit: string = '::Permission:GmiUsersEdit';
  public permission_GmiUsersImpersonate: string = '::Permission:GmiUsersImpersonate';
  public static Permission_GmiUsersImpersonate: string = '::Permission:GmiUsersImpersonate';
  public permission_GmiUsersInsert: string = '::Permission:GmiUsersInsert';
  public static Permission_GmiUsersInsert: string = '::Permission:GmiUsersInsert';
  public permission_GmiUsersMail: string = '::Permission:GmiUsersMail';
  public static Permission_GmiUsersMail: string = '::Permission:GmiUsersMail';
  public permission_GmiUsersResetPassword: string = '::Permission:GmiUsersResetPassword';
  public static Permission_GmiUsersResetPassword: string = '::Permission:GmiUsersResetPassword';
  public permission_InsuranceClaims: string = '::Permission:InsuranceClaims';
  public static Permission_InsuranceClaims: string = '::Permission:InsuranceClaims';
  public permission_JobsEdit: string = '::Permission:JobsEdit';
  public static Permission_JobsEdit: string = '::Permission:JobsEdit';
  public permission_MissingDocumentation: string = '::Permission:MissingDocumentation';
  public static Permission_MissingDocumentation: string = '::Permission:MissingDocumentation';
  public permission_NotificationsEdit: string = '::Permission:NotificationsEdit';
  public static Permission_NotificationsEdit: string = '::Permission:NotificationsEdit';
  public permission_NotificationsInsert: string = '::Permission:NotificationsInsert';
  public static Permission_NotificationsInsert: string = '::Permission:NotificationsInsert';
  public permission_OverduePayments: string = '::Permission:OverduePayments';
  public static Permission_OverduePayments: string = '::Permission:OverduePayments';
  public permission_Partner: string = '::Permission:Partner';
  public static Permission_Partner: string = '::Permission:Partner';
  public permission_PartnerContact: string = '::Permission:PartnerContact';
  public static Permission_PartnerContact: string = '::Permission:PartnerContact';
  public permission_PartnerTrr: string = '::Permission:PartnerTrr';
  public static Permission_PartnerTrr: string = '::Permission:PartnerTrr';
  public permission_Payments: string = '::Permission:Payments';
  public static Permission_Payments: string = '::Permission:Payments';
  public permission_PersonalizationsEdit: string = '::Permission:PersonalizationsEdit';
  public static Permission_PersonalizationsEdit: string = '::Permission:PersonalizationsEdit';
  public permission_PersonalizationsInsert: string = '::Permission:PersonalizationsInsert';
  public static Permission_PersonalizationsInsert: string = '::Permission:PersonalizationsInsert';
  public permission_PublicRequestsInsert: string = '::Permission:PublicRequestsInsert';
  public static Permission_PublicRequestsInsert: string = '::Permission:PublicRequestsInsert';
  public permission_PublicRequestsView: string = '::Permission:PublicRequestsView';
  public static Permission_PublicRequestsView: string = '::Permission:PublicRequestsView';
  public permission_PublicStockFundingCanSeeOthersStockFunding: string = '::Permission:PublicStockFundingCanSeeOthersStockFunding';
  public static Permission_PublicStockFundingCanSeeOthersStockFunding: string = '::Permission:PublicStockFundingCanSeeOthersStockFunding';
  public permission_PublicStockFundingCanSeeStockFunding: string = '::Permission:PublicStockFundingCanSeeStockFunding';
  public static Permission_PublicStockFundingCanSeeStockFunding: string = '::Permission:PublicStockFundingCanSeeStockFunding';
  public permission_PublicStockFundingCanSeeStockFundingContract: string = '::Permission:PublicStockFundingCanSeeStockFundingContract';
  public static Permission_PublicStockFundingCanSeeStockFundingContract: string = '::Permission:PublicStockFundingCanSeeStockFundingContract';
  public permission_PublicStockFundingInsert: string = '::Permission:PublicStockFundingInsert';
  public static Permission_PublicStockFundingInsert: string = '::Permission:PublicStockFundingInsert';
  public permission_ReportsEdit: string = '::Permission:ReportsEdit';
  public static Permission_ReportsEdit: string = '::Permission:ReportsEdit';
  public permission_ReportsInsert: string = '::Permission:ReportsInsert';
  public static Permission_ReportsInsert: string = '::Permission:ReportsInsert';
  public permission_RolesChangePermissions: string = '::Permission:RolesChangePermissions';
  public static Permission_RolesChangePermissions: string = '::Permission:RolesChangePermissions';
  public permission_RolesEdit: string = '::Permission:RolesEdit';
  public static Permission_RolesEdit: string = '::Permission:RolesEdit';
  public permission_RolesInsert: string = '::Permission:RolesInsert';
  public static Permission_RolesInsert: string = '::Permission:RolesInsert';
  public permission_SettingsEdit: string = '::Permission:SettingsEdit';
  public static Permission_SettingsEdit: string = '::Permission:SettingsEdit';
  public permission_SettingsView: string = '::Permission:SettingsView';
  public static Permission_SettingsView: string = '::Permission:SettingsView';
  public permission_StockFunding: string = '::Permission:StockFunding';
  public static Permission_StockFunding: string = '::Permission:StockFunding';
  public permission_StylesEdit: string = '::Permission:StylesEdit';
  public static Permission_StylesEdit: string = '::Permission:StylesEdit';
  public permission_TwoFactorAuthCanChange: string = '::Permission:TwoFactorAuthCanChange';
  public static Permission_TwoFactorAuthCanChange: string = '::Permission:TwoFactorAuthCanChange';
  public permission_TwoFactorAuthCanReset: string = '::Permission:TwoFactorAuthCanReset';
  public static Permission_TwoFactorAuthCanReset: string = '::Permission:TwoFactorAuthCanReset';
  public permission_WorkflowsEdit: string = '::Permission:WorkflowsEdit';
  public static Permission_WorkflowsEdit: string = '::Permission:WorkflowsEdit';
  public permission_WorkflowsInsert: string = '::Permission:WorkflowsInsert';
  public static Permission_WorkflowsInsert: string = '::Permission:WorkflowsInsert';
  public permissionGroup_AdminMenu: string = '::PermissionGroup:AdminMenu';
  public static PermissionGroup_AdminMenu: string = '::PermissionGroup:AdminMenu';
  public permissionGroup_AdminRequests: string = '::PermissionGroup:AdminRequests';
  public static PermissionGroup_AdminRequests: string = '::PermissionGroup:AdminRequests';
  public permissionGroup_AdminSettings: string = '::PermissionGroup:AdminSettings';
  public static PermissionGroup_AdminSettings: string = '::PermissionGroup:AdminSettings';
  public permissionGroup_ExternalFunctions: string = '::PermissionGroup:ExternalFunctions';
  public static PermissionGroup_ExternalFunctions: string = '::PermissionGroup:ExternalFunctions';
  public permissionGroup_FinancingSetting: string = '::PermissionGroup:FinancingSetting';
  public static PermissionGroup_FinancingSetting: string = '::PermissionGroup:FinancingSetting';
  public permissionGroup_GmiPartners: string = '::PermissionGroup:GmiPartners';
  public static PermissionGroup_GmiPartners: string = '::PermissionGroup:GmiPartners';
  public permissionGroup_GmiUsers: string = '::PermissionGroup:GmiUsers';
  public static PermissionGroup_GmiUsers: string = '::PermissionGroup:GmiUsers';
  public permissionGroup_Jobs: string = '::PermissionGroup:Jobs';
  public static PermissionGroup_Jobs: string = '::PermissionGroup:Jobs';
  public permissionGroup_License: string = '::PermissionGroup:License';
  public static PermissionGroup_License: string = '::PermissionGroup:License';
  public permissionGroup_Maintenace: string = '::PermissionGroup:Maintenace';
  public static PermissionGroup_Maintenace: string = '::PermissionGroup:Maintenace';
  public permissionGroup_Notifications: string = '::PermissionGroup:Notifications';
  public static PermissionGroup_Notifications: string = '::PermissionGroup:Notifications';
  public permissionGroup_Personalizations: string = '::PermissionGroup:Personalizations';
  public static PermissionGroup_Personalizations: string = '::PermissionGroup:Personalizations';
  public permissionGroup_PublicLessee: string = '::PermissionGroup:PublicLessee';
  public static PermissionGroup_PublicLessee: string = '::PermissionGroup:PublicLessee';
  public permissionGroup_PublicReports: string = '::PermissionGroup:PublicReports';
  public static PermissionGroup_PublicReports: string = '::PermissionGroup:PublicReports';
  public permissionGroup_PublicRequests: string = '::PermissionGroup:PublicRequests';
  public static PermissionGroup_PublicRequests: string = '::PermissionGroup:PublicRequests';
  public permissionGroup_PublicStockFunding: string = '::PermissionGroup:PublicStockFunding';
  public static PermissionGroup_PublicStockFunding: string = '::PermissionGroup:PublicStockFunding';
  public permissionGroup_Reports: string = '::PermissionGroup:Reports';
  public static PermissionGroup_Reports: string = '::PermissionGroup:Reports';
  public permissionGroup_Roles: string = '::PermissionGroup:Roles';
  public static PermissionGroup_Roles: string = '::PermissionGroup:Roles';
  public permissionGroup_Settings: string = '::PermissionGroup:Settings';
  public static PermissionGroup_Settings: string = '::PermissionGroup:Settings';
  public permissionGroup_Styles: string = '::PermissionGroup:Styles';
  public static PermissionGroup_Styles: string = '::PermissionGroup:Styles';
  public permissionGroup_SystemSettingsMenu: string = '::PermissionGroup:SystemSettingsMenu';
  public static PermissionGroup_SystemSettingsMenu: string = '::PermissionGroup:SystemSettingsMenu';
  public permissionGroup_TwoFactorAuth: string = '::PermissionGroup:TwoFactorAuth';
  public static PermissionGroup_TwoFactorAuth: string = '::PermissionGroup:TwoFactorAuth';
  public permissionGroup_Workflows: string = '::PermissionGroup:Workflows';
  public static PermissionGroup_Workflows: string = '::PermissionGroup:Workflows';
  public permissionMenu_AdminCalculationGroup: string = '::PermissionMenu:AdminCalculationGroup';
  public static PermissionMenu_AdminCalculationGroup: string = '::PermissionMenu:AdminCalculationGroup';
  public permissionMenu_AdministrationGroup: string = '::PermissionMenu:AdministrationGroup';
  public static PermissionMenu_AdministrationGroup: string = '::PermissionMenu:AdministrationGroup';
  public permissionMenu_AdminMailsGroup: string = '::PermissionMenu:AdminMailsGroup';
  public static PermissionMenu_AdminMailsGroup: string = '::PermissionMenu:AdminMailsGroup';
  public permissionMenu_AdminRequestsGroup: string = '::PermissionMenu:AdminRequestsGroup';
  public static PermissionMenu_AdminRequestsGroup: string = '::PermissionMenu:AdminRequestsGroup';
  public permissionMenu_ExternalFunctionsGroup: string = '::PermissionMenu:ExternalFunctionsGroup';
  public static PermissionMenu_ExternalFunctionsGroup: string = '::PermissionMenu:ExternalFunctionsGroup';
  public permissionMenu_LicenseGroup: string = '::PermissionMenu:LicenseGroup';
  public static PermissionMenu_LicenseGroup: string = '::PermissionMenu:LicenseGroup';
  public permissionMenu_MaintenaceGroup: string = '::PermissionMenu:MaintenaceGroup';
  public static PermissionMenu_MaintenaceGroup: string = '::PermissionMenu:MaintenaceGroup';
  public permissionMenu_NotificationsGroup: string = '::PermissionMenu:NotificationsGroup';
  public static PermissionMenu_NotificationsGroup: string = '::PermissionMenu:NotificationsGroup';
  public permissionMenu_PersonalizationsGroup: string = '::PermissionMenu:PersonalizationsGroup';
  public static PermissionMenu_PersonalizationsGroup: string = '::PermissionMenu:PersonalizationsGroup';
  public permissionMenu_PublicLesseeGroup: string = '::PermissionMenu:PublicLesseeGroup';
  public static PermissionMenu_PublicLesseeGroup: string = '::PermissionMenu:PublicLesseeGroup';
  public permissionMenu_PublicReportsGroup: string = '::PermissionMenu:PublicReportsGroup';
  public static PermissionMenu_PublicReportsGroup: string = '::PermissionMenu:PublicReportsGroup';
  public permissionMenu_PublicRequestsGroup: string = '::PermissionMenu:PublicRequestsGroup';
  public static PermissionMenu_PublicRequestsGroup: string = '::PermissionMenu:PublicRequestsGroup';
  public permissionMenu_PublicStockFundingGroup: string = '::PermissionMenu:PublicStockFundingGroup';
  public static PermissionMenu_PublicStockFundingGroup: string = '::PermissionMenu:PublicStockFundingGroup';
  public permissionMenu_PublicTwoFAGroup: string = '::PermissionMenu:PublicTwoFAGroup';
  public static PermissionMenu_PublicTwoFAGroup: string = '::PermissionMenu:PublicTwoFAGroup';
  public permissionMenu_ReportsGroup: string = '::PermissionMenu:ReportsGroup';
  public static PermissionMenu_ReportsGroup: string = '::PermissionMenu:ReportsGroup';
  public permissionMenu_SettingsGroup: string = '::PermissionMenu:SettingsGroup';
  public static PermissionMenu_SettingsGroup: string = '::PermissionMenu:SettingsGroup';
  public permissionMenu_StylesGroup: string = '::PermissionMenu:StylesGroup';
  public static PermissionMenu_StylesGroup: string = '::PermissionMenu:StylesGroup';
  public permissionMenu_SystemSettingsGroup: string = '::PermissionMenu:SystemSettingsGroup';
  public static PermissionMenu_SystemSettingsGroup: string = '::PermissionMenu:SystemSettingsGroup';
  public permissionMenu_WorkflowsGroup: string = '::PermissionMenu:WorkflowsGroup';
  public static PermissionMenu_WorkflowsGroup: string = '::PermissionMenu:WorkflowsGroup';
  public personalization_DefinitionCSS: string = '::Personalization:DefinitionCSS';
  public static Personalization_DefinitionCSS: string = '::Personalization:DefinitionCSS';
  public personalization_DefinitionJSON: string = '::Personalization:DefinitionJSON';
  public static Personalization_DefinitionJSON: string = '::Personalization:DefinitionJSON';
  public personalization_Edit: string = '::Personalization:Edit';
  public static Personalization_Edit: string = '::Personalization:Edit';
  public personalization_LabelBlock: string = '::Personalization:LabelBlock';
  public static Personalization_LabelBlock: string = '::Personalization:LabelBlock';
  public personalization_LabelDefinition: string = '::Personalization:LabelDefinition';
  public static Personalization_LabelDefinition: string = '::Personalization:LabelDefinition';
  public personalization_New: string = '::Personalization:New';
  public static Personalization_New: string = '::Personalization:New';
  public qrFileUpload_BtnAdd: string = '::QrFileUpload:BtnAdd';
  public static QrFileUpload_BtnAdd: string = '::QrFileUpload:BtnAdd';
  public qrFileUpload_BtnConfirm: string = '::QrFileUpload:BtnConfirm';
  public static QrFileUpload_BtnConfirm: string = '::QrFileUpload:BtnConfirm';
  public qrFileUpload_Error: string = '::QrFileUpload:Error';
  public static QrFileUpload_Error: string = '::QrFileUpload:Error';
  public qrFileUpload_MaliciousContentFound: string = '::QrFileUpload:MaliciousContentFound';
  public static QrFileUpload_MaliciousContentFound: string = '::QrFileUpload:MaliciousContentFound';
  public qrFileUpload_Manual: string = '::QrFileUpload:Manual';
  public static QrFileUpload_Manual: string = '::QrFileUpload:Manual';
  public qrFileUpload_MaximumAllowedNumber: string = '::QrFileUpload:MaximumAllowedNumber';
  public static QrFileUpload_MaximumAllowedNumber: string = '::QrFileUpload:MaximumAllowedNumber';
  public qrFileUpload_MaxSizeFize: string = '::QrFileUpload:MaxSizeFize';
  public static QrFileUpload_MaxSizeFize: string = '::QrFileUpload:MaxSizeFize';
  public qrFileUpload_NoError: string = '::QrFileUpload:NoError';
  public static QrFileUpload_NoError: string = '::QrFileUpload:NoError';
  public qrFileUpload_progress: string = '::QrFileUpload:progress';
  public static QrFileUpload_progress: string = '::QrFileUpload:progress';
  public qrFileUpload_Title: string = '::QrFileUpload:Title';
  public static QrFileUpload_Title: string = '::QrFileUpload:Title';
  public qrFileUpload_Uploaded: string = '::QrFileUpload:Uploaded';
  public static QrFileUpload_Uploaded: string = '::QrFileUpload:Uploaded';
  public reports_ArchiveTitle: string = '::Reports:ArchiveTitle';
  public static Reports_ArchiveTitle: string = '::Reports:ArchiveTitle';
  public reports_Edit: string = '::Reports:Edit';
  public static Reports_Edit: string = '::Reports:Edit';
  public reports_ErrorRendering: string = '::Reports:ErrorRendering';
  public static Reports_ErrorRendering: string = '::Reports:ErrorRendering';
  public reports_File: string = '::Reports:File';
  public static Reports_File: string = '::Reports:File';
  public reports_ForNova: string = '::Reports:ForNova';
  public static Reports_ForNova: string = '::Reports:ForNova';
  public reports_Insert: string = '::Reports:Insert';
  public static Reports_Insert: string = '::Reports:Insert';
  public reports_LabelArchive: string = '::Reports:LabelArchive';
  public static Reports_LabelArchive: string = '::Reports:LabelArchive';
  public reports_NoReportsAvailable: string = '::Reports:NoReportsAvailable';
  public static Reports_NoReportsAvailable: string = '::Reports:NoReportsAvailable';
  public reports_NovaReportKeySql: string = '::Reports:NovaReportKeySql';
  public static Reports_NovaReportKeySql: string = '::Reports:NovaReportKeySql';
  public reports_NovaReportName: string = '::Reports:NovaReportName';
  public static Reports_NovaReportName: string = '::Reports:NovaReportName';
  public reports_RenderReport: string = '::Reports:RenderReport';
  public static Reports_RenderReport: string = '::Reports:RenderReport';
  public reports_RenderType: string = '::Reports:RenderType';
  public static Reports_RenderType: string = '::Reports:RenderType';
  public reports_SendReport: string = '::Reports:SendReport';
  public static Reports_SendReport: string = '::Reports:SendReport';
  public search_More: string = '::Search:More';
  public static Search_More: string = '::Search:More';
  public search_Reset: string = '::Search:Reset';
  public static Search_Reset: string = '::Search:Reset';
  public search_Search: string = '::Search:Search';
  public static Search_Search: string = '::Search:Search';
  public search_Title: string = '::Search:Title';
  public static Search_Title: string = '::Search:Title';
  public setting_Abp_Account_EnableLocalLogin: string = '::Setting:Abp.Account.EnableLocalLogin';
  public static Setting_Abp_Account_EnableLocalLogin: string = '::Setting:Abp.Account.EnableLocalLogin';
  public setting_Abp_Account_IsSelfRegistrationEnabled: string = '::Setting:Abp.Account.IsSelfRegistrationEnabled';
  public static Setting_Abp_Account_IsSelfRegistrationEnabled: string = '::Setting:Abp.Account.IsSelfRegistrationEnabled';
  public setting_Abp_Identity_Lockout_AllowedForNewUsers: string = '::Setting:Abp.Identity.Lockout.AllowedForNewUsers';
  public static Setting_Abp_Identity_Lockout_AllowedForNewUsers: string = '::Setting:Abp.Identity.Lockout.AllowedForNewUsers';
  public setting_Abp_Identity_Lockout_LockoutDuration: string = '::Setting:Abp.Identity.Lockout.LockoutDuration';
  public static Setting_Abp_Identity_Lockout_LockoutDuration: string = '::Setting:Abp.Identity.Lockout.LockoutDuration';
  public setting_Abp_Identity_Lockout_MaxFailedAccessAttempts: string = '::Setting:Abp.Identity.Lockout.MaxFailedAccessAttempts';
  public static Setting_Abp_Identity_Lockout_MaxFailedAccessAttempts: string = '::Setting:Abp.Identity.Lockout.MaxFailedAccessAttempts';
  public setting_Abp_Identity_OrganizationUnit_MaxUserMembershipCount: string = '::Setting:Abp.Identity.OrganizationUnit.MaxUserMembershipCount';
  public static Setting_Abp_Identity_OrganizationUnit_MaxUserMembershipCount: string = '::Setting:Abp.Identity.OrganizationUnit.MaxUserMembershipCount';
  public setting_Abp_Identity_Password_RequireDigit: string = '::Setting:Abp.Identity.Password.RequireDigit';
  public static Setting_Abp_Identity_Password_RequireDigit: string = '::Setting:Abp.Identity.Password.RequireDigit';
  public setting_Abp_Identity_Password_RequiredLength: string = '::Setting:Abp.Identity.Password.RequiredLength';
  public static Setting_Abp_Identity_Password_RequiredLength: string = '::Setting:Abp.Identity.Password.RequiredLength';
  public setting_Abp_Identity_Password_RequiredUniqueChars: string = '::Setting:Abp.Identity.Password.RequiredUniqueChars';
  public static Setting_Abp_Identity_Password_RequiredUniqueChars: string = '::Setting:Abp.Identity.Password.RequiredUniqueChars';
  public setting_Abp_Identity_Password_RequireLowercase: string = '::Setting:Abp.Identity.Password.RequireLowercase';
  public static Setting_Abp_Identity_Password_RequireLowercase: string = '::Setting:Abp.Identity.Password.RequireLowercase';
  public setting_Abp_Identity_Password_RequireNonAlphanumeric: string = '::Setting:Abp.Identity.Password.RequireNonAlphanumeric';
  public static Setting_Abp_Identity_Password_RequireNonAlphanumeric: string = '::Setting:Abp.Identity.Password.RequireNonAlphanumeric';
  public setting_Abp_Identity_Password_RequireUppercase: string = '::Setting:Abp.Identity.Password.RequireUppercase';
  public static Setting_Abp_Identity_Password_RequireUppercase: string = '::Setting:Abp.Identity.Password.RequireUppercase';
  public setting_Abp_Identity_SignIn_EnablePhoneNumberConfirmation: string = '::Setting:Abp.Identity.SignIn.EnablePhoneNumberConfirmation';
  public static Setting_Abp_Identity_SignIn_EnablePhoneNumberConfirmation: string = '::Setting:Abp.Identity.SignIn.EnablePhoneNumberConfirmation';
  public setting_Abp_Identity_SignIn_RequireConfirmedEmail: string = '::Setting:Abp.Identity.SignIn.RequireConfirmedEmail';
  public static Setting_Abp_Identity_SignIn_RequireConfirmedEmail: string = '::Setting:Abp.Identity.SignIn.RequireConfirmedEmail';
  public setting_Abp_Identity_SignIn_RequireConfirmedPhoneNumber: string = '::Setting:Abp.Identity.SignIn.RequireConfirmedPhoneNumber';
  public static Setting_Abp_Identity_SignIn_RequireConfirmedPhoneNumber: string = '::Setting:Abp.Identity.SignIn.RequireConfirmedPhoneNumber';
  public setting_Abp_Identity_TwoFactor_Behaviour: string = '::Setting:Abp.Identity.TwoFactor.Behaviour';
  public static Setting_Abp_Identity_TwoFactor_Behaviour: string = '::Setting:Abp.Identity.TwoFactor.Behaviour';
  public setting_Abp_Identity_TwoFactor_UsersCanChange: string = '::Setting:Abp.Identity.TwoFactor.UsersCanChange';
  public static Setting_Abp_Identity_TwoFactor_UsersCanChange: string = '::Setting:Abp.Identity.TwoFactor.UsersCanChange';
  public setting_Abp_Identity_User_IsEmailUpdateEnabled: string = '::Setting:Abp.Identity.User.IsEmailUpdateEnabled';
  public static Setting_Abp_Identity_User_IsEmailUpdateEnabled: string = '::Setting:Abp.Identity.User.IsEmailUpdateEnabled';
  public setting_Abp_Identity_User_IsUserNameUpdateEnabled: string = '::Setting:Abp.Identity.User.IsUserNameUpdateEnabled';
  public static Setting_Abp_Identity_User_IsUserNameUpdateEnabled: string = '::Setting:Abp.Identity.User.IsUserNameUpdateEnabled';
  public setting_Abp_Mailing_DefaultFromAddress: string = '::Setting:Abp.Mailing.DefaultFromAddress';
  public static Setting_Abp_Mailing_DefaultFromAddress: string = '::Setting:Abp.Mailing.DefaultFromAddress';
  public setting_Abp_Mailing_DefaultFromDisplayName: string = '::Setting:Abp.Mailing.DefaultFromDisplayName';
  public static Setting_Abp_Mailing_DefaultFromDisplayName: string = '::Setting:Abp.Mailing.DefaultFromDisplayName';
  public setting_Abp_Mailing_Smtp_Domain: string = '::Setting:Abp.Mailing.Smtp.Domain';
  public static Setting_Abp_Mailing_Smtp_Domain: string = '::Setting:Abp.Mailing.Smtp.Domain';
  public setting_Abp_Mailing_Smtp_EnableSsl: string = '::Setting:Abp.Mailing.Smtp.EnableSsl';
  public static Setting_Abp_Mailing_Smtp_EnableSsl: string = '::Setting:Abp.Mailing.Smtp.EnableSsl';
  public setting_Abp_Mailing_Smtp_Host: string = '::Setting:Abp.Mailing.Smtp.Host';
  public static Setting_Abp_Mailing_Smtp_Host: string = '::Setting:Abp.Mailing.Smtp.Host';
  public setting_Abp_Mailing_Smtp_Password: string = '::Setting:Abp.Mailing.Smtp.Password';
  public static Setting_Abp_Mailing_Smtp_Password: string = '::Setting:Abp.Mailing.Smtp.Password';
  public setting_Abp_Mailing_Smtp_Port: string = '::Setting:Abp.Mailing.Smtp.Port';
  public static Setting_Abp_Mailing_Smtp_Port: string = '::Setting:Abp.Mailing.Smtp.Port';
  public setting_Abp_Mailing_Smtp_UseDefaultCredentials: string = '::Setting:Abp.Mailing.Smtp.UseDefaultCredentials';
  public static Setting_Abp_Mailing_Smtp_UseDefaultCredentials: string = '::Setting:Abp.Mailing.Smtp.UseDefaultCredentials';
  public setting_Abp_Mailing_Smtp_UserName: string = '::Setting:Abp.Mailing.Smtp.UserName';
  public static Setting_Abp_Mailing_Smtp_UserName: string = '::Setting:Abp.Mailing.Smtp.UserName';
  public setting_Calculation_EomTolerance: string = '::Setting:Calculation.EomTolerance';
  public static Setting_Calculation_EomTolerance: string = '::Setting:Calculation.EomTolerance';
  public setting_Calculation_EomUseDiscountMethod2: string = '::Setting:Calculation.EomUseDiscountMethod2';
  public static Setting_Calculation_EomUseDiscountMethod2: string = '::Setting:Calculation.EomUseDiscountMethod2';
  public setting_Calculation_LogCalculationInputParameters: string = '::Setting:Calculation.LogCalculationInputParameters';
  public static Setting_Calculation_LogCalculationInputParameters: string = '::Setting:Calculation.LogCalculationInputParameters';
  public setting_Calculation_MaxMultipleCalculations: string = '::Setting:Calculation.MaxMultipleCalculations';
  public static Setting_Calculation_MaxMultipleCalculations: string = '::Setting:Calculation.MaxMultipleCalculations';
  public setting_Calculation_MinCalculationPeriod: string = '::Setting:Calculation.MinCalculationPeriod';
  public static Setting_Calculation_MinCalculationPeriod: string = '::Setting:Calculation.MinCalculationPeriod';
  public setting_Calculation_OdsMaxMultipleCalculations: string = '::Setting:Calculation.OdsMaxMultipleCalculations';
  public static Setting_Calculation_OdsMaxMultipleCalculations: string = '::Setting:Calculation.OdsMaxMultipleCalculations';
  public setting_Calculation_OdsMinCalculationPeriod: string = '::Setting:Calculation.OdsMinCalculationPeriod';
  public static Setting_Calculation_OdsMinCalculationPeriod: string = '::Setting:Calculation.OdsMinCalculationPeriod';
  public setting_Calculation_Step3CalculateRvFromObjectPrice: string = '::Setting:Calculation.Step3CalculateRvFromObjectPrice';
  public static Setting_Calculation_Step3CalculateRvFromObjectPrice: string = '::Setting:Calculation.Step3CalculateRvFromObjectPrice';
  public setting_Calculation_Step3SetBrutAmount: string = '::Setting:Calculation.Step3SetBrutAmount';
  public static Setting_Calculation_Step3SetBrutAmount: string = '::Setting:Calculation.Step3SetBrutAmount';
  public setting_Gmi_Application_CookieInfoPage: string = '::Setting:Gmi.Application.CookieInfoPage';
  public static Setting_Gmi_Application_CookieInfoPage: string = '::Setting:Gmi.Application.CookieInfoPage';
  public setting_Gmi_Application_Custom_Currency: string = '::Setting:Gmi.Application.Custom.Currency';
  public static Setting_Gmi_Application_Custom_Currency: string = '::Setting:Gmi.Application.Custom.Currency';
  public setting_Gmi_Application_GmiAdminMail: string = '::Setting:Gmi.Application.GmiAdminMail';
  public static Setting_Gmi_Application_GmiAdminMail: string = '::Setting:Gmi.Application.GmiAdminMail';
  public setting_Gmi_Application_Lessee_DummyEmailDomain: string = '::Setting:Gmi.Application.Lessee.DummyEmailDomain';
  public static Setting_Gmi_Application_Lessee_DummyEmailDomain: string = '::Setting:Gmi.Application.Lessee.DummyEmailDomain';
  public setting_Gmi_Application_Lessee_Username_Prefix: string = '::Setting:Gmi.Application.Lessee.Username.Prefix';
  public static Setting_Gmi_Application_Lessee_Username_Prefix: string = '::Setting:Gmi.Application.Lessee.Username.Prefix';
  public setting_Gmi_Application_Lessee_VisibleBuyout: string = '::Setting:Gmi.Application.Lessee.VisibleBuyout';
  public static Setting_Gmi_Application_Lessee_VisibleBuyout: string = '::Setting:Gmi.Application.Lessee.VisibleBuyout';
  public setting_Gmi_Application_Mail_Test_Allowed_Domains: string = '::Setting:Gmi.Application.Mail.Test.Allowed.Domains';
  public static Setting_Gmi_Application_Mail_Test_Allowed_Domains: string = '::Setting:Gmi.Application.Mail.Test.Allowed.Domains';
  public setting_Gmi_CustomNamespace: string = '::Setting:Gmi.CustomNamespace';
  public static Setting_Gmi_CustomNamespace: string = '::Setting:Gmi.CustomNamespace';
  public setting_Gmi_EADocs_ArchiveIntervalDays: string = '::Setting:Gmi.EADocs.ArchiveIntervalDays';
  public static Setting_Gmi_EADocs_ArchiveIntervalDays: string = '::Setting:Gmi.EADocs.ArchiveIntervalDays';
  public setting_Gmi_Esb_BpmUrl: string = '::Setting:Gmi.Esb.BpmUrl';
  public static Setting_Gmi_Esb_BpmUrl: string = '::Setting:Gmi.Esb.BpmUrl';
  public setting_Gmi_Esb_LogCalls: string = '::Setting:Gmi.Esb.LogCalls';
  public static Setting_Gmi_Esb_LogCalls: string = '::Setting:Gmi.Esb.LogCalls';
  public setting_Gmi_Esb_NovaUrl: string = '::Setting:Gmi.Esb.NovaUrl';
  public static Setting_Gmi_Esb_NovaUrl: string = '::Setting:Gmi.Esb.NovaUrl';
  public setting_Gmi_Esb_UseUTF8_BpmUrl: string = '::Setting:Gmi.Esb.UseUTF8.BpmUrl';
  public static Setting_Gmi_Esb_UseUTF8_BpmUrl: string = '::Setting:Gmi.Esb.UseUTF8.BpmUrl';
  public setting_Gmi_Esb_UseUTF8_NovaUrl: string = '::Setting:Gmi.Esb.UseUTF8.NovaUrl';
  public static Setting_Gmi_Esb_UseUTF8_NovaUrl: string = '::Setting:Gmi.Esb.UseUTF8.NovaUrl';
  public setting_Gmi_GDPR_PseudonimisationExceptions: string = '::Setting:Gmi.GDPR.PseudonimisationExceptions';
  public static Setting_Gmi_GDPR_PseudonimisationExceptions: string = '::Setting:Gmi.GDPR.PseudonimisationExceptions';
  public setting_Gmi_GmiUser_Password_ValidForDays: string = '::Setting:Gmi.GmiUser.Password.ValidForDays';
  public static Setting_Gmi_GmiUser_Password_ValidForDays: string = '::Setting:Gmi.GmiUser.Password.ValidForDays';
  public setting_Gmi_InsuranceAuthErrorText: string = '::Setting:Gmi.InsuranceAuthErrorText';
  public static Setting_Gmi_InsuranceAuthErrorText: string = '::Setting:Gmi.InsuranceAuthErrorText';
  public setting_Gmi_InsuranceAuthPdfDownloadDelayDays: string = '::Setting:Gmi.InsuranceAuthPdfDownloadDelayDays';
  public static Setting_Gmi_InsuranceAuthPdfDownloadDelayDays: string = '::Setting:Gmi.InsuranceAuthPdfDownloadDelayDays';
  public setting_Gmi_InsuranceAuthPdfNotAvailableMessage: string = '::Setting:Gmi.InsuranceAuthPdfNotAvailableMessage';
  public static Setting_Gmi_InsuranceAuthPdfNotAvailableMessage: string = '::Setting:Gmi.InsuranceAuthPdfNotAvailableMessage';
  public setting_Gmi_InsuranceAuthWaitingLblGSettings_Gmi_InsuranceAuthWaitingLblText: string = '::Setting:Gmi.InsuranceAuthWaitingLblGSettings:Gmi.InsuranceAuthWaitingLblText';
  public static Setting_Gmi_InsuranceAuthWaitingLblGSettings_Gmi_InsuranceAuthWaitingLblText: string = '::Setting:Gmi.InsuranceAuthWaitingLblGSettings:Gmi.InsuranceAuthWaitingLblText';
  public setting_Gmi_Licence_LastChecked: string = '::Setting:Gmi.Licence.LastChecked';
  public static Setting_Gmi_Licence_LastChecked: string = '::Setting:Gmi.Licence.LastChecked';
  public setting_Gmi_Licence_LastExpiredSendMail: string = '::Setting:Gmi.Licence.LastExpiredSendMail';
  public static Setting_Gmi_Licence_LastExpiredSendMail: string = '::Setting:Gmi.Licence.LastExpiredSendMail';
  public setting_Gmi_Licence_LocalIdKupca: string = '::Setting:Gmi.Licence.LocalIdKupca';
  public static Setting_Gmi_Licence_LocalIdKupca: string = '::Setting:Gmi.Licence.LocalIdKupca';
  public setting_Gmi_Licence_QuotaTolerance_Contract: string = '::Setting:Gmi.Licence.QuotaTolerance.Contract';
  public static Setting_Gmi_Licence_QuotaTolerance_Contract: string = '::Setting:Gmi.Licence.QuotaTolerance.Contract';
  public setting_Gmi_Licence_QuotaTolerance_Offer: string = '::Setting:Gmi.Licence.QuotaTolerance.Offer';
  public static Setting_Gmi_Licence_QuotaTolerance_Offer: string = '::Setting:Gmi.Licence.QuotaTolerance.Offer';
  public setting_Gmi_Licence_SendMailTo: string = '::Setting:Gmi.Licence.SendMailTo';
  public static Setting_Gmi_Licence_SendMailTo: string = '::Setting:Gmi.Licence.SendMailTo';
  public setting_Gmi_Login_Error_Message: string = '::Setting:Gmi.Login.Error.Message';
  public static Setting_Gmi_Login_Error_Message: string = '::Setting:Gmi.Login.Error.Message';
  public setting_Gmi_Login_Error_Message_Extra_Additional_Info: string = '::Setting:Gmi.Login.Error.Message.Extra.Additional.Info';
  public static Setting_Gmi_Login_Error_Message_Extra_Additional_Info: string = '::Setting:Gmi.Login.Error.Message.Extra.Additional.Info';
  public setting_Gmi_Mail_ConfirmationMailBody: string = '::Setting:Gmi.Mail.ConfirmationMailBody';
  public static Setting_Gmi_Mail_ConfirmationMailBody: string = '::Setting:Gmi.Mail.ConfirmationMailBody';
  public setting_Gmi_Mail_ConfirmationMailSubject: string = '::Setting:Gmi.Mail.ConfirmationMailSubject';
  public static Setting_Gmi_Mail_ConfirmationMailSubject: string = '::Setting:Gmi.Mail.ConfirmationMailSubject';
  public setting_Gmi_Mail_PasswordResetMailBody: string = '::Setting:Gmi.Mail.PasswordResetMailBody';
  public static Setting_Gmi_Mail_PasswordResetMailBody: string = '::Setting:Gmi.Mail.PasswordResetMailBody';
  public setting_Gmi_Mail_PasswordResetMailSubject: string = '::Setting:Gmi.Mail.PasswordResetMailSubject';
  public static Setting_Gmi_Mail_PasswordResetMailSubject: string = '::Setting:Gmi.Mail.PasswordResetMailSubject';
  public setting_Gmi_Mail_TwoFactorMailBody: string = '::Setting:Gmi.Mail.TwoFactorMailBody';
  public static Setting_Gmi_Mail_TwoFactorMailBody: string = '::Setting:Gmi.Mail.TwoFactorMailBody';
  public setting_Gmi_Mail_TwoFactorMailSubject: string = '::Setting:Gmi.Mail.TwoFactorMailSubject';
  public static Setting_Gmi_Mail_TwoFactorMailSubject: string = '::Setting:Gmi.Mail.TwoFactorMailSubject';
  public setting_Gmi_Mailing_Domain_Admin: string = '::Setting:Gmi.Mailing.Domain.Admin';
  public static Setting_Gmi_Mailing_Domain_Admin: string = '::Setting:Gmi.Mailing.Domain.Admin';
  public setting_Gmi_Mailing_Domain_Public: string = '::Setting:Gmi.Mailing.Domain.Public';
  public static Setting_Gmi_Mailing_Domain_Public: string = '::Setting:Gmi.Mailing.Domain.Public';
  public setting_Gmi_Mailing_Password_Admin: string = '::Setting:Gmi.Mailing.Password.Admin';
  public static Setting_Gmi_Mailing_Password_Admin: string = '::Setting:Gmi.Mailing.Password.Admin';
  public setting_Gmi_Mailing_Password_Public: string = '::Setting:Gmi.Mailing.Password.Public';
  public static Setting_Gmi_Mailing_Password_Public: string = '::Setting:Gmi.Mailing.Password.Public';
  public setting_Gmi_Mailing_Port_Admin: string = '::Setting:Gmi.Mailing.Port.Admin';
  public static Setting_Gmi_Mailing_Port_Admin: string = '::Setting:Gmi.Mailing.Port.Admin';
  public setting_Gmi_Mailing_Port_Public: string = '::Setting:Gmi.Mailing.Port.Public';
  public static Setting_Gmi_Mailing_Port_Public: string = '::Setting:Gmi.Mailing.Port.Public';
  public setting_Gmi_Mailing_Server_Admin: string = '::Setting:Gmi.Mailing.Server.Admin';
  public static Setting_Gmi_Mailing_Server_Admin: string = '::Setting:Gmi.Mailing.Server.Admin';
  public setting_Gmi_Mailing_Server_Public: string = '::Setting:Gmi.Mailing.Server.Public';
  public static Setting_Gmi_Mailing_Server_Public: string = '::Setting:Gmi.Mailing.Server.Public';
  public setting_Gmi_Mailing_Username_Admin: string = '::Setting:Gmi.Mailing.Username.Admin';
  public static Setting_Gmi_Mailing_Username_Admin: string = '::Setting:Gmi.Mailing.Username.Admin';
  public setting_Gmi_Mailing_Username_Public: string = '::Setting:Gmi.Mailing.Username.Public';
  public static Setting_Gmi_Mailing_Username_Public: string = '::Setting:Gmi.Mailing.Username.Public';
  public setting_Gmi_Mailing_UseSsl_Admin: string = '::Setting:Gmi.Mailing.UseSsl.Admin';
  public static Setting_Gmi_Mailing_UseSsl_Admin: string = '::Setting:Gmi.Mailing.UseSsl.Admin';
  public setting_Gmi_Mailing_UseSsl_Public: string = '::Setting:Gmi.Mailing.UseSsl.Public';
  public static Setting_Gmi_Mailing_UseSsl_Public: string = '::Setting:Gmi.Mailing.UseSsl.Public';
  public setting_Gmi_Sync_DiffSyncCompleted: string = '::Setting:Gmi.Sync.DiffSyncCompleted';
  public static Setting_Gmi_Sync_DiffSyncCompleted: string = '::Setting:Gmi.Sync.DiffSyncCompleted';
  public setting_Gmi_Sync_DiffSyncStarted: string = '::Setting:Gmi.Sync.DiffSyncStarted';
  public static Setting_Gmi_Sync_DiffSyncStarted: string = '::Setting:Gmi.Sync.DiffSyncStarted';
  public setting_Gmi_Sync_FullSyncCompleted: string = '::Setting:Gmi.Sync.FullSyncCompleted';
  public static Setting_Gmi_Sync_FullSyncCompleted: string = '::Setting:Gmi.Sync.FullSyncCompleted';
  public setting_Gmi_Sync_FullSyncStarted: string = '::Setting:Gmi.Sync.FullSyncStarted';
  public static Setting_Gmi_Sync_FullSyncStarted: string = '::Setting:Gmi.Sync.FullSyncStarted';
  public setting_Gmi_Sync_RegisterSyncCompleted: string = '::Setting:Gmi.Sync.RegisterSyncCompleted';
  public static Setting_Gmi_Sync_RegisterSyncCompleted: string = '::Setting:Gmi.Sync.RegisterSyncCompleted';
  public setting_Gmi_Sync_RegisterSyncStarted: string = '::Setting:Gmi.Sync.RegisterSyncStarted';
  public static Setting_Gmi_Sync_RegisterSyncStarted: string = '::Setting:Gmi.Sync.RegisterSyncStarted';
  public setting_GmiInsuranceDataCompanyText: string = '::Setting:GmiInsuranceDataCompanyText';
  public static Setting_GmiInsuranceDataCompanyText: string = '::Setting:GmiInsuranceDataCompanyText';
  public setting_Nova_CheckEOMFunctType_Is_ZPOTK_2010: string = '::Setting:Nova.CheckEOMFunctType_Is_ZPOTK_2010';
  public static Setting_Nova_CheckEOMFunctType_Is_ZPOTK_2010: string = '::Setting:Nova.CheckEOMFunctType_Is_ZPOTK_2010';
  public setting_Nova_GDPR_ListOfCustomerTypesForAccessLog: string = '::Setting:Nova.GDPR.ListOfCustomerTypesForAccessLog';
  public static Setting_Nova_GDPR_ListOfCustomerTypesForAccessLog: string = '::Setting:Nova.GDPR.ListOfCustomerTypesForAccessLog';
  public setting_Nova_GDPR_ListOfCustomerTypesForPseudonimisation: string = '::Setting:Nova.GDPR.ListOfCustomerTypesForPseudonimisation';
  public static Setting_Nova_GDPR_ListOfCustomerTypesForPseudonimisation: string = '::Setting:Nova.GDPR.ListOfCustomerTypesForPseudonimisation';
  public setting_Nova_LE_ClaimPaymentsRep_UseExchangeRateDiffSetting: string = '::Setting:Nova.LE.ClaimPaymentsRep.UseExchangeRateDiffSetting';
  public static Setting_Nova_LE_ClaimPaymentsRep_UseExchangeRateDiffSetting: string = '::Setting:Nova.LE.ClaimPaymentsRep.UseExchangeRateDiffSetting';
  public setting_Nova_LE_CommisionIRMode: string = '::Setting:Nova.LE.CommisionIRMode';
  public static Setting_Nova_LE_CommisionIRMode: string = '::Setting:Nova.LE.CommisionIRMode';
  public setting_Nova_LE_NewCommissionsModule_AllowZeroCommissionsInsert: string = '::Setting:Nova.LE.NewCommissionsModule.AllowZeroCommissionsInsert';
  public static Setting_Nova_LE_NewCommissionsModule_AllowZeroCommissionsInsert: string = '::Setting:Nova.LE.NewCommissionsModule.AllowZeroCommissionsInsert';
  public setting_Nova_LE_NewCommissionsModule_Enabled: string = '::Setting:Nova.LE.NewCommissionsModule.Enabled';
  public static Setting_Nova_LE_NewCommissionsModule_Enabled: string = '::Setting:Nova.LE.NewCommissionsModule.Enabled';
  public setting_Nova_LE_NewCommissionsModule_PosrednikJeKoncesija: string = '::Setting:Nova.LE.NewCommissionsModule.PosrednikJeKoncesija';
  public static Setting_Nova_LE_NewCommissionsModule_PosrednikJeKoncesija: string = '::Setting:Nova.LE.NewCommissionsModule.PosrednikJeKoncesija';
  public setting_Nova_LE_NewCommissionsModule_UseOldCommisions: string = '::Setting:Nova.LE.NewCommissionsModule.UseOldCommisions';
  public static Setting_Nova_LE_NewCommissionsModule_UseOldCommisions: string = '::Setting:Nova.LE.NewCommissionsModule.UseOldCommisions';
  public setting_Nova_LE_Partner_PhoneCheck: string = '::Setting:Nova.LE.Partner.PhoneCheck';
  public static Setting_Nova_LE_Partner_PhoneCheck: string = '::Setting:Nova.LE.Partner.PhoneCheck';
  public setting_Nova_LE_PartnerEmailCheck: string = '::Setting:Nova.LE.PartnerEmailCheck';
  public static Setting_Nova_LE_PartnerEmailCheck: string = '::Setting:Nova.LE.PartnerEmailCheck';
  public setting_Nova_NeverSkipHolidays: string = '::Setting:Nova.NeverSkipHolidays';
  public static Setting_Nova_NeverSkipHolidays: string = '::Setting:Nova.NeverSkipHolidays';
  public settings_ErrorDoesNotExist: string = '::Settings:ErrorDoesNotExist';
  public static Settings_ErrorDoesNotExist: string = '::Settings:ErrorDoesNotExist';
  public settings_FromNova: string = '::Settings:FromNova';
  public static Settings_FromNova: string = '::Settings:FromNova';
  public settings_Gmi_Maintenance_Users_DeactivateUsersTimeframe: string = '::Settings:Gmi.Maintenance.Users.DeactivateUsersTimeframe';
  public static Settings_Gmi_Maintenance_Users_DeactivateUsersTimeframe: string = '::Settings:Gmi.Maintenance.Users.DeactivateUsersTimeframe';
  public settings_IllegalCharacters: string = '::Settings:IllegalCharacters';
  public static Settings_IllegalCharacters: string = '::Settings:IllegalCharacters';
  public settings_Name: string = '::Settings:Name';
  public static Settings_Name: string = '::Settings:Name';
  public settings_Title: string = '::Settings:Title';
  public static Settings_Title: string = '::Settings:Title';
  public settings_Value: string = '::Settings:Value';
  public static Settings_Value: string = '::Settings:Value';
  public stockFunding_ChassisNumb: string = '::StockFunding:ChassisNumb';
  public static StockFunding_ChassisNumb: string = '::StockFunding:ChassisNumb';
  public stockFunding_DateBegin: string = '::StockFunding:DateBegin';
  public static StockFunding_DateBegin: string = '::StockFunding:DateBegin';
  public stockFunding_DateEnd: string = '::StockFunding:DateEnd';
  public static StockFunding_DateEnd: string = '::StockFunding:DateEnd';
  public stockFunding_DateInserted: string = '::StockFunding:DateInserted';
  public static StockFunding_DateInserted: string = '::StockFunding:DateInserted';
  public stockFunding_Error: string = '::StockFunding:Error';
  public static StockFunding_Error: string = '::StockFunding:Error';
  public stockFunding_IdStockFunding: string = '::StockFunding:IdStockFunding';
  public static StockFunding_IdStockFunding: string = '::StockFunding:IdStockFunding';
  public stockFunding_Inserted: string = '::StockFunding:Inserted';
  public static StockFunding_Inserted: string = '::StockFunding:Inserted';
  public stockFunding_Make: string = '::StockFunding:Make';
  public static StockFunding_Make: string = '::StockFunding:Make';
  public stockFunding_Message: string = '::StockFunding:Message';
  public static StockFunding_Message: string = '::StockFunding:Message';
  public stockFunding_Model: string = '::StockFunding:Model';
  public static StockFunding_Model: string = '::StockFunding:Model';
  public stockFunding_New: string = '::StockFunding:New';
  public static StockFunding_New: string = '::StockFunding:New';
  public stockFunding_NumberOfKilometers: string = '::StockFunding:NumberOfKilometers';
  public static StockFunding_NumberOfKilometers: string = '::StockFunding:NumberOfKilometers';
  public stockFunding_Price: string = '::StockFunding:Price';
  public static StockFunding_Price: string = '::StockFunding:Price';
  public stockFunding_PriceBrut: string = '::StockFunding:PriceBrut';
  public static StockFunding_PriceBrut: string = '::StockFunding:PriceBrut';
  public stockFunding_PriceNet: string = '::StockFunding:PriceNet';
  public static StockFunding_PriceNet: string = '::StockFunding:PriceNet';
  public stockFunding_Status: string = '::StockFunding:Status';
  public static StockFunding_Status: string = '::StockFunding:Status';
  public stockFunding_StockFundingStatusApproved: string = '::StockFunding:StockFundingStatusApproved';
  public static StockFunding_StockFundingStatusApproved: string = '::StockFunding:StockFundingStatusApproved';
  public stockFunding_StockFundingStatusError: string = '::StockFunding:StockFundingStatusError';
  public static StockFunding_StockFundingStatusError: string = '::StockFunding:StockFundingStatusError';
  public stockFunding_StockFundingStatusInserted: string = '::StockFunding:StockFundingStatusInserted';
  public static StockFunding_StockFundingStatusInserted: string = '::StockFunding:StockFundingStatusInserted';
  public stockFunding_StockFundTemplate: string = '::StockFunding:StockFundTemplate';
  public static StockFunding_StockFundTemplate: string = '::StockFunding:StockFundTemplate';
  public stockFunding_VehicalType: string = '::StockFunding:VehicalType';
  public static StockFunding_VehicalType: string = '::StockFunding:VehicalType';
  public stockFunding_Vendor: string = '::StockFunding:Vendor';
  public static StockFunding_Vendor: string = '::StockFunding:Vendor';
  public stockFunding_VendorUser: string = '::StockFunding:VendorUser';
  public static StockFunding_VendorUser: string = '::StockFunding:VendorUser';
  public stockFunding_YearManufactured: string = '::StockFunding:YearManufactured';
  public static StockFunding_YearManufactured: string = '::StockFunding:YearManufactured';
  public stockFundingStatus_Approved: string = '::StockFundingStatus:Approved';
  public static StockFundingStatus_Approved: string = '::StockFundingStatus:Approved';
  public stockFundingStatus_Error: string = '::StockFundingStatus:Error';
  public static StockFundingStatus_Error: string = '::StockFundingStatus:Error';
  public stockFundingStatus_New: string = '::StockFundingStatus:New';
  public static StockFundingStatus_New: string = '::StockFundingStatus:New';
  public stockFundingStatus_Proces: string = '::StockFundingStatus:Proces';
  public static StockFundingStatus_Proces: string = '::StockFundingStatus:Proces';
  public stockFundingStatus_Workflow: string = '::StockFundingStatus:Workflow';
  public static StockFundingStatus_Workflow: string = '::StockFundingStatus:Workflow';
  public styles_ButtonInsert: string = '::Styles:ButtonInsert';
  public static Styles_ButtonInsert: string = '::Styles:ButtonInsert';
  public styles_ErrorDeactivateDefault: string = '::Styles:ErrorDeactivateDefault';
  public static Styles_ErrorDeactivateDefault: string = '::Styles:ErrorDeactivateDefault';
  public styles_ErrorNoDefault: string = '::Styles:ErrorNoDefault';
  public static Styles_ErrorNoDefault: string = '::Styles:ErrorNoDefault';
  public styles_Label_AppTitle: string = '::Styles:Label.AppTitle';
  public static Styles_Label_AppTitle: string = '::Styles:Label.AppTitle';
  public styles_Label_ColourDanger: string = '::Styles:Label.ColourDanger';
  public static Styles_Label_ColourDanger: string = '::Styles:Label.ColourDanger';
  public styles_Label_ColourGradient1: string = '::Styles:Label.ColourGradient1';
  public static Styles_Label_ColourGradient1: string = '::Styles:Label.ColourGradient1';
  public styles_Label_ColourGradient2: string = '::Styles:Label.ColourGradient2';
  public static Styles_Label_ColourGradient2: string = '::Styles:Label.ColourGradient2';
  public styles_Label_ColourInfo: string = '::Styles:Label.ColourInfo';
  public static Styles_Label_ColourInfo: string = '::Styles:Label.ColourInfo';
  public styles_Label_ColourLight: string = '::Styles:Label.ColourLight';
  public static Styles_Label_ColourLight: string = '::Styles:Label.ColourLight';
  public styles_Label_ColourMandatory: string = '::Styles:Label.ColourMandatory';
  public static Styles_Label_ColourMandatory: string = '::Styles:Label.ColourMandatory';
  public styles_Label_ColourPrimary: string = '::Styles:Label.ColourPrimary';
  public static Styles_Label_ColourPrimary: string = '::Styles:Label.ColourPrimary';
  public styles_Label_ColourSuccess: string = '::Styles:Label.ColourSuccess';
  public static Styles_Label_ColourSuccess: string = '::Styles:Label.ColourSuccess';
  public styles_Label_ColourWarning: string = '::Styles:Label.ColourWarning';
  public static Styles_Label_ColourWarning: string = '::Styles:Label.ColourWarning';
  public styles_Label_Favicon: string = '::Styles:Label.Favicon';
  public static Styles_Label_Favicon: string = '::Styles:Label.Favicon';
  public styles_Label_LoginHorizontal: string = '::Styles:Label.LoginHorizontal';
  public static Styles_Label_LoginHorizontal: string = '::Styles:Label.LoginHorizontal';
  public styles_Label_LoginVertical: string = '::Styles:Label.LoginVertical';
  public static Styles_Label_LoginVertical: string = '::Styles:Label.LoginVertical';
  public styles_Label_Logo: string = '::Styles:Label.Logo';
  public static Styles_Label_Logo: string = '::Styles:Label.Logo';
  public styles_Label_NoInputShading: string = '::Styles:Label.NoInputShading';
  public static Styles_Label_NoInputShading: string = '::Styles:Label.NoInputShading';
  public styles_Label_Title: string = '::Styles:Label.Title';
  public static Styles_Label_Title: string = '::Styles:Label.Title';
  public styles_LabelTextMenu: string = '::Styles:LabelTextMenu';
  public static Styles_LabelTextMenu: string = '::Styles:LabelTextMenu';
  public styles_LabelTextNavbar: string = '::Styles:LabelTextNavbar';
  public static Styles_LabelTextNavbar: string = '::Styles:LabelTextNavbar';
  public styles_Title: string = '::Styles:Title';
  public static Styles_Title: string = '::Styles:Title';
  public sync_EaDocumentNotInArchive: string = '::Sync:EaDocumentNotInArchive';
  public static Sync_EaDocumentNotInArchive: string = '::Sync:EaDocumentNotInArchive';
  public sync_EmailAlreadyExists: string = '::Sync:EmailAlreadyExists';
  public static Sync_EmailAlreadyExists: string = '::Sync:EmailAlreadyExists';
  public sync_LeseeRoleDoesntExist: string = '::Sync:LeseeRoleDoesntExist';
  public static Sync_LeseeRoleDoesntExist: string = '::Sync:LeseeRoleDoesntExist';
  public sync_LesseeDifferentUsernameEmail: string = '::Sync:LesseeDifferentUsernameEmail';
  public static Sync_LesseeDifferentUsernameEmail: string = '::Sync:LesseeDifferentUsernameEmail';
  public sync_LesseeUsernameOnDifferentPartner: string = '::Sync:LesseeUsernameOnDifferentPartner';
  public static Sync_LesseeUsernameOnDifferentPartner: string = '::Sync:LesseeUsernameOnDifferentPartner';
  public sync_LesseeUsernameOnDifferentUser: string = '::Sync:LesseeUsernameOnDifferentUser';
  public static Sync_LesseeUsernameOnDifferentUser: string = '::Sync:LesseeUsernameOnDifferentUser';
  public sync_UsernameAlreadyExists: string = '::Sync:UsernameAlreadyExists';
  public static Sync_UsernameAlreadyExists: string = '::Sync:UsernameAlreadyExists';
  public tooltip_NextMonth: string = '::Tooltip:NextMonth';
  public static Tooltip_NextMonth: string = '::Tooltip:NextMonth';
  public tooltip_PreviousMonth: string = '::Tooltip:PreviousMonth';
  public static Tooltip_PreviousMonth: string = '::Tooltip:PreviousMonth';
  public tooltip_SelectMonth: string = '::Tooltip:SelectMonth';
  public static Tooltip_SelectMonth: string = '::Tooltip:SelectMonth';
  public tooltip_SelectYear: string = '::Tooltip:SelectYear';
  public static Tooltip_SelectYear: string = '::Tooltip:SelectYear';
  public trr_StatusActive: string = '::Trr:StatusActive';
  public static Trr_StatusActive: string = '::Trr:StatusActive';
  public trr_StatusBlocked: string = '::Trr:StatusBlocked';
  public static Trr_StatusBlocked: string = '::Trr:StatusBlocked';
  public trr_StatusClosed: string = '::Trr:StatusClosed';
  public static Trr_StatusClosed: string = '::Trr:StatusClosed';
  public volo_Abp_Identity_DuplicateEmail: string = '::Volo.Abp.Identity:DuplicateEmail';
  public static Volo_Abp_Identity_DuplicateEmail: string = '::Volo.Abp.Identity:DuplicateEmail';
  public wfDocument_DocumentEdit: string = '::WfDocument:DocumentEdit';
  public static WfDocument_DocumentEdit: string = '::WfDocument:DocumentEdit';
  public wfDocument_DocumentNotExists: string = '::WfDocument:DocumentNotExists';
  public static WfDocument_DocumentNotExists: string = '::WfDocument:DocumentNotExists';
  public wfDocument_ProcessCode: string = '::WfDocument:ProcessCode';
  public static WfDocument_ProcessCode: string = '::WfDocument:ProcessCode';
  public wfDocument_ProcessCodeExists: string = '::WfDocument:ProcessCodeExists';
  public static WfDocument_ProcessCodeExists: string = '::WfDocument:ProcessCodeExists';
  public wfDocument_ProcessEdit: string = '::WfDocument:ProcessEdit';
  public static WfDocument_ProcessEdit: string = '::WfDocument:ProcessEdit';
  public wfDocument_ProcessInactive: string = '::WfDocument:ProcessInactive';
  public static WfDocument_ProcessInactive: string = '::WfDocument:ProcessInactive';
  public wfDocument_ProcessInvalidStatus: string = '::WfDocument:ProcessInvalidStatus';
  public static WfDocument_ProcessInvalidStatus: string = '::WfDocument:ProcessInvalidStatus';
  public wfDocument_ProcessNew: string = '::WfDocument:ProcessNew';
  public static WfDocument_ProcessNew: string = '::WfDocument:ProcessNew';
  public wfDocument_ProcessNotExists: string = '::WfDocument:ProcessNotExists';
  public static WfDocument_ProcessNotExists: string = '::WfDocument:ProcessNotExists';
  public wfDocument_ProcessTitle: string = '::WfDocument:ProcessTitle';
  public static WfDocument_ProcessTitle: string = '::WfDocument:ProcessTitle';
  public wfDocument_StartStatusTitle: string = '::WfDocument:StartStatusTitle';
  public static WfDocument_StartStatusTitle: string = '::WfDocument:StartStatusTitle';
  public wfDocument_StatusBackgroundColor: string = '::WfDocument:StatusBackgroundColor';
  public static WfDocument_StatusBackgroundColor: string = '::WfDocument:StatusBackgroundColor';
  public wfDocument_StatusCode: string = '::WfDocument:StatusCode';
  public static WfDocument_StatusCode: string = '::WfDocument:StatusCode';
  public wfDocument_StatusCodeExists: string = '::WfDocument:StatusCodeExists';
  public static WfDocument_StatusCodeExists: string = '::WfDocument:StatusCodeExists';
  public wfDocument_StatusCodeNotExists: string = '::WfDocument:StatusCodeNotExists';
  public static WfDocument_StatusCodeNotExists: string = '::WfDocument:StatusCodeNotExists';
  public wfDocument_StatusEdit: string = '::WfDocument:StatusEdit';
  public static WfDocument_StatusEdit: string = '::WfDocument:StatusEdit';
  public wfDocument_StatusInactive: string = '::WfDocument:StatusInactive';
  public static WfDocument_StatusInactive: string = '::WfDocument:StatusInactive';
  public wfDocument_StatusIsEnd: string = '::WfDocument:StatusIsEnd';
  public static WfDocument_StatusIsEnd: string = '::WfDocument:StatusIsEnd';
  public wfDocument_StatusIsStart: string = '::WfDocument:StatusIsStart';
  public static WfDocument_StatusIsStart: string = '::WfDocument:StatusIsStart';
  public wfDocument_StatusNew: string = '::WfDocument:StatusNew';
  public static WfDocument_StatusNew: string = '::WfDocument:StatusNew';
  public wfDocument_StatusNewTitle: string = '::WfDocument:StatusNewTitle';
  public static WfDocument_StatusNewTitle: string = '::WfDocument:StatusNewTitle';
  public wfDocument_StatusNotExists: string = '::WfDocument:StatusNotExists';
  public static WfDocument_StatusNotExists: string = '::WfDocument:StatusNotExists';
  public wfDocument_StatusNotReachable: string = '::WfDocument:StatusNotReachable';
  public static WfDocument_StatusNotReachable: string = '::WfDocument:StatusNotReachable';
  public wfDocument_StatusOldTitle: string = '::WfDocument:StatusOldTitle';
  public static WfDocument_StatusOldTitle: string = '::WfDocument:StatusOldTitle';
  public wfDocument_StatusTitle: string = '::WfDocument:StatusTitle';
  public static WfDocument_StatusTitle: string = '::WfDocument:StatusTitle';
  public wfDocument_StatusTransitionExists: string = '::WfDocument:StatusTransitionExists';
  public static WfDocument_StatusTransitionExists: string = '::WfDocument:StatusTransitionExists';
  public wfDocument_StatusTransitionNotExists: string = '::WfDocument:StatusTransitionNotExists';
  public static WfDocument_StatusTransitionNotExists: string = '::WfDocument:StatusTransitionNotExists';
  public wfDocument_StatusTranslation: string = '::WfDocument:StatusTranslation';
  public static WfDocument_StatusTranslation: string = '::WfDocument:StatusTranslation';
  public wfDocument_StatusVisibleToUser: string = '::WfDocument:StatusVisibleToUser';
  public static WfDocument_StatusVisibleToUser: string = '::WfDocument:StatusVisibleToUser';
  public wfDocument_TransitionNew: string = '::WfDocument:TransitionNew';
  public static WfDocument_TransitionNew: string = '::WfDocument:TransitionNew';
  public wfDocument_TransitionTitle: string = '::WfDocument:TransitionTitle';
  public static WfDocument_TransitionTitle: string = '::WfDocument:TransitionTitle';
}
