import type { GmiTwoFactorType } from './gmi-two-factor-type.enum';
import type { DefaultReturnData, GmiForgotPasswordDto, GmiForgotPasswordResponseDto, GmiLoginDto, GmiLoginReturnDto, GmiRegisterDataDto, GmiRegistrationDto, GmiValidateOTPDto, PasswordResetDto, QrCodeGeneratorDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiVerificationsService {
  apiName = 'Default';
  

  getGenerateAuthenticatorQrCodeById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, QrCodeGeneratorDto>({
      method: 'GET',
      url: `/api/app/gmi-verifications/${id}/generate-authenticator-qr-code`,
    },
    { apiName: this.apiName,...config });
  

  getUserRegistrationDataByUsername = (username: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiRegisterDataDto>({
      method: 'GET',
      url: '/api/app/gmi-verifications/user-registration-data',
      params: { username },
    },
    { apiName: this.apiName,...config });
  

  postChangeTwoFactorSettingsByTwoFactorType = (twoFactorType: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/change-two-factor-settings',
      params: { twoFactorType },
    },
    { apiName: this.apiName,...config });
  

  postGmiLoginCheckByInput = (input: GmiLoginDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiLoginReturnDto>({
      method: 'POST',
      url: '/api/app/gmi-verifications/gmi-login-check',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postPasswordRecoveryByUsernameEmail = (usernameEmail: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/password-recovery',
      params: { usernameEmail },
    },
    { apiName: this.apiName,...config });
  

  postPwdRecoveryBasicByUsername = (username: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/pwd-recovery-basic',
      params: { username },
    },
    { apiName: this.apiName,...config });
  

  postPwdRecoveryByInput = (input: GmiForgotPasswordDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiForgotPasswordResponseDto>({
      method: 'POST',
      url: '/api/app/gmi-verifications/pwd-recovery',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postRegisterUserByInput = (input: GmiRegistrationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/register-user',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postResetTwoFactor = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/reset-two-factor',
    },
    { apiName: this.apiName,...config });
  

  postSendEmailVerificationByUsername = (username: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/send-email-verification',
      params: { username },
    },
    { apiName: this.apiName,...config });
  

  postSendPhoneOTPByIdAndPhoneNumber = (id: string, phoneNumber: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-verifications/${id}/send-phone-oTP`,
      params: { phoneNumber },
    },
    { apiName: this.apiName,...config });
  

  postSendTwoFactorCodeByIdAndTwoFactorType = (id: string, twoFactorType: GmiTwoFactorType, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-verifications/${id}/send-two-factor-code`,
      params: { twoFactorType },
    },
    { apiName: this.apiName,...config });
  

  postValidatePhoneOTPByGmiOTPCode = (gmiOTPCode: GmiValidateOTPDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/gmi-verifications/validate-phone-oTP',
      body: gmiOTPCode,
    },
    { apiName: this.apiName,...config });
  

  postVerifyEmailVerificationByEmailAndCode = (email: string, code: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/verify-email-verification',
      params: { email, code },
    },
    { apiName: this.apiName,...config });
  

  postVerifyFirstTwoFactorCodeByIdAndTwoFactorTypeAndCodeAndCode2 = (id: string, twoFactorType: GmiTwoFactorType, code: string, code2: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-verifications/${id}/verify-first-two-factor-code`,
      params: { twoFactorType, code, code2 },
    },
    { apiName: this.apiName,...config });
  

  postVerifyPasswordRecoveryByInput = (input: PasswordResetDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-verifications/verify-password-recovery',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postVerifyTwoFactorCodeByIdAndTwoFactorTypeAndCode = (id: string, twoFactorType: GmiTwoFactorType, code: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-verifications/${id}/verify-two-factor-code`,
      params: { twoFactorType, code },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
