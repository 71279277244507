export class OnlineConstants {
  public gmiLicenceModules: string = 'GmiModules';
  public static GmiLicenceModules: string = 'GmiModules';
  public gmiLicenceModules_ContractsSync: string = 'Nova.Online.ContractsSync';
  public static GmiLicenceModules_ContractsSync: string = 'Nova.Online.ContractsSync';
  public gmiLicenceModules_RecoveryAgencies: string = 'Nova.Online.RecoveryAgencies';
  public static GmiLicenceModules_RecoveryAgencies: string = 'Nova.Online.RecoveryAgencies';
  public gmiLicenceModules_StockFunding: string = 'Nova.Online.StockFunding';
  public static GmiLicenceModules_StockFunding: string = 'Nova.Online.StockFunding';
  public gmiLicenceModules_DamageClaims: string = 'Nova.Online.DamageClaims';
  public static GmiLicenceModules_DamageClaims: string = 'Nova.Online.DamageClaims';
  public calculationFields_brutAmount: string = 'brutAmount';
  public static CalculationFields_brutAmount: string = 'brutAmount';
  public calculationFields_netAmount: string = 'netAmount';
  public static CalculationFields_netAmount: string = 'netAmount';
  public calculationFields_loanAmount: string = 'loanAmount';
  public static CalculationFields_loanAmount: string = 'loanAmount';
  public calculationFields_calculationAmount: string = 'calculationAmount';
  public static CalculationFields_calculationAmount: string = 'calculationAmount';
  public calculationFields_financingAmount: string = 'financingAmount';
  public static CalculationFields_financingAmount: string = 'financingAmount';
  public calculationFields_rInterestAmount: string = 'rInterestAmount';
  public static CalculationFields_rInterestAmount: string = 'rInterestAmount';
  public calculationFields_equipmentTaxRate: string = 'equipmentTaxRate';
  public static CalculationFields_equipmentTaxRate: string = 'equipmentTaxRate';
  public calculationFields_leasingTaxRate: string = 'leasingTaxRate';
  public static CalculationFields_leasingTaxRate: string = 'leasingTaxRate';
  public calculationFields_newPurchaseValue: string = 'newPurchaseValue';
  public static CalculationFields_newPurchaseValue: string = 'newPurchaseValue';
  public calculationFields_premiumRateFactor: string = 'premiumRateFactor';
  public static CalculationFields_premiumRateFactor: string = 'premiumRateFactor';
  public calculationFields_exchangeRate: string = 'exchangeRate';
  public static CalculationFields_exchangeRate: string = 'exchangeRate';
  public calculationFields_financingPeriod: string = 'financingPeriod';
  public static CalculationFields_financingPeriod: string = 'financingPeriod';
  public calculationFields_paymentDynamic: string = 'paymentDynamic';
  public static CalculationFields_paymentDynamic: string = 'paymentDynamic';
  public calculationFields_subsidy: string = 'subsidy';
  public static CalculationFields_subsidy: string = 'subsidy';
  public calculationFields_paymentAtStart: string = 'paymentAtStart';
  public static CalculationFields_paymentAtStart: string = 'paymentAtStart';
  public calculationFields_bail: string = 'bail';
  public static CalculationFields_bail: string = 'bail';
  public calculationFields_downpayment: string = 'downpayment';
  public static CalculationFields_downpayment: string = 'downpayment';
  public calculationFields_yearlyKmAmount: string = 'yearlyKmAmount';
  public static CalculationFields_yearlyKmAmount: string = 'yearlyKmAmount';
  public calculationFields_residualValue: string = 'residualValue';
  public static CalculationFields_residualValue: string = 'residualValue';
  public calculationFields_interestRate: string = 'interestRate';
  public static CalculationFields_interestRate: string = 'interestRate';
  public calculationFields_desiredEOM: string = 'desiredEOM';
  public static CalculationFields_desiredEOM: string = 'desiredEOM';
  public calculationFields_beginEnd: string = 'beginEnd';
  public static CalculationFields_beginEnd: string = 'beginEnd';
  public calculationFields_additionalServicesAmount: string = 'additionalServicesAmount';
  public static CalculationFields_additionalServicesAmount: string = 'additionalServicesAmount';
  public calculationFields_manipulativeCosts: string = 'manipulativeCosts';
  public static CalculationFields_manipulativeCosts: string = 'manipulativeCosts';
  public calculationFields_claimInsuranceCosts: string = 'claimInsuranceCosts';
  public static CalculationFields_claimInsuranceCosts: string = 'claimInsuranceCosts';
  public calculationFields_propertyInsuranceCosts: string = 'propertyInsuranceCosts';
  public static CalculationFields_propertyInsuranceCosts: string = 'propertyInsuranceCosts';
  public calculationFields_financeInsuranceCosts: string = 'financeInsuranceCosts';
  public static CalculationFields_financeInsuranceCosts: string = 'financeInsuranceCosts';
  public calculationFields_extraCosts: string = 'extraCosts';
  public static CalculationFields_extraCosts: string = 'extraCosts';
  public calculationFields_moratorium: string = 'moratorium';
  public static CalculationFields_moratorium: string = 'moratorium';
  public calculationFields_fullPrice: string = 'fullPrice';
  public static CalculationFields_fullPrice: string = 'fullPrice';
  public calculationFields_engineCapacity: string = 'engineCapacity';
  public static CalculationFields_engineCapacity: string = 'engineCapacity';
  public calculationFields_currentKm: string = 'currentKm';
  public static CalculationFields_currentKm: string = 'currentKm';
  public calculationFields_buyoutOnLastInstallment: string = 'buyoutOnLastInstallment';
  public static CalculationFields_buyoutOnLastInstallment: string = 'buyoutOnLastInstallment';
  public calculationFields_docDateCreateType: string = 'docDateCreateType';
  public static CalculationFields_docDateCreateType: string = 'docDateCreateType';
  public calculationFields_docDate: string = 'docDate';
  public static CalculationFields_docDate: string = 'docDate';
}
