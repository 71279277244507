import type { DefaultReturnData, GmiStyleDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminStylesService {
  apiName = 'Default';
  

  clearStyleCacheOnPublic = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/gmi-admin-styles/clear-style-cache-on-public',
    },
    { apiName: this.apiName,...config });
  

  getStyleById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiStyleDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-styles/${id}/style`,
    },
    { apiName: this.apiName,...config });
  

  getStyles = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiStyleDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-styles/styles',
    },
    { apiName: this.apiName,...config });
  

  setStyleByInput = (input: GmiStyleDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-styles/set-style',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
