import type { DefaultReturnData, GmiPersonalizationBlockAndTypeDto, GmiPersonalizationDto, GmiPersonalizationSearchDto, GmiPersonalizationType, GmiPersonalizationsSearchDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminPersonalizationsService {
  apiName = 'Default';
  

  getPersonalizationById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiPersonalizationDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-personalizations/${id}/personalization`,
    },
    { apiName: this.apiName,...config });
  

  getPersonalizationType = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiPersonalizationType[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-personalizations/personalization-type',
    },
    { apiName: this.apiName,...config });
  

  getPersonalizationsSearchByInputAndPage = (input: GmiPersonalizationsSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiPersonalizationSearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-personalizations/personalizations-search',
      params: { idType: input.idType, searchCriteria: input.searchCriteria, blockId: input.blockId, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getPersonalizationsTypeAndBlock = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiPersonalizationBlockAndTypeDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-personalizations/personalizations-type-and-block',
    },
    { apiName: this.apiName,...config });
  

  postInsertPersonalizationByInput = (input: GmiPersonalizationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-personalizations/insert-personalization',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdatePersonalizationByInput = (input: GmiPersonalizationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-personalizations/update-personalization',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
