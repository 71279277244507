import type { DefaultReturnData, GmiSettingUpdateDto, GmiSettingsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminSettingsService {
  apiName = 'Default';
  

  getSettingByName = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiSettingsDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-settings/setting',
      params: { name },
    },
    { apiName: this.apiName,...config });
  

  getSettings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiSettingsDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-settings/settings',
    },
    { apiName: this.apiName,...config });
  

  putUpdateSettingByInput = (input: GmiSettingUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-settings/update-setting',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
