import type { DefaultReturnData, FileContentsDto, GmiMessageConversationDataDto, GmiMessageConversationsDto, GmiMessageDataFile, GmiMessageTextsDto, GmiSearchDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiMessagesService {
  apiName = 'Default';
  

  getConversationFileByIdNotification = (idNotification: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-messages/conversation-file',
      params: { idNotification },
    },
    { apiName: this.apiName,...config });
  

  getMessageConversationDataById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiMessageConversationsDto>({
      method: 'GET',
      url: `/api/app/gmi-messages/${id}/message-conversation-data`,
    },
    { apiName: this.apiName,...config });
  

  getMessageFileByIdConversaionAndIdText = (idConversaion: number, idText: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FileContentsDto>({
      method: 'GET',
      url: '/api/app/gmi-messages/message-file',
      params: { idConversaion, idText },
    },
    { apiName: this.apiName,...config });
  

  getUserMessageConversationsByPageAndInput = (page: PagedResultRequestDto, input: GmiSearchDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiMessageConversationDataDto>>({
      method: 'GET',
      url: '/api/app/gmi-messages/user-message-conversations',
      params: { skipCount: page.skipCount, maxResultCount: page.maxResultCount, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  postMessageNewConversationByInput = (input: GmiMessageConversationsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-messages/message-new-conversation',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postMessageTextByInput = (input: GmiMessageTextsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-messages/message-text',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUploadNotificationDocumentsByInput = (input: GmiMessageDataFile, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-messages/upload-notification-documents',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putMessageMarkStatusAsReadByIdText = (idText: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-messages/message-mark-status-as-read',
      params: { idText },
    },
    { apiName: this.apiName,...config });
  

  putMessegeMarkConversationAsDeletedByIdConversation = (idConversation: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-messages/messege-mark-conversation-as-deleted',
      params: { idConversation },
    },
    { apiName: this.apiName,...config });
  

  putMessegeMarkTextAsDeletedByIdText = (idText: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-messages/messege-mark-text-as-deleted',
      params: { idText },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
