<ng-template #requestModalContent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ WL.menu_Requests | abpLocalization }}
    </h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div>
      <form [formGroup]="requestOptionsForm">
        <select class="form-select" formControlName="selectedRequest">
          <option *ngFor="let item of allRequestsToSelect" [ngValue]="item.id">{{ item.title }}</option>
        </select>
      </form>
    </div>
  </div>
</ng-template>