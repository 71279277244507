import type { DefaultReturnData, GmiJobHistSearchParamsDto, GmiSearchDto, JobHistoryDto, JobHistorySearchDto, JobsDto, JobsSearchDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminJobsService {
  apiName = 'Default';
  

  getJobById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, JobsDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-jobs/${id}/job`,
    },
    { apiName: this.apiName,...config });
  

  getJobHistorySearchByIdBackGroundWorkerAndInputAndPage = (idBackGroundWorker: string, input: GmiJobHistSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<JobHistorySearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-jobs/job-history-search',
      params: { idBackGroundWorker, from: input.from, to: input.to, searchCriteria: input.searchCriteria, orderBy: input.orderBy, orderAscending: input.orderAscending, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getJobsSearchByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<JobsSearchDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-jobs/jobs-search',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertJobHistoryByInput = (input: JobHistoryDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-jobs/insert-job-history',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUpdateJobByInput = (input: JobsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-jobs/update-job',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUpdateNextRunTimeById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/gmi-admin-jobs/${id}/update-next-run-time`,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
