import { Environment } from '@abp/ng.core';

export const environment = {
  production: false,
  apis: {
    default: {
      url: 'https://localhost:44300',
      rootNamespace: 'Gmi.Nova.Online'
    },
  },
  remoteEnv: {
    url: 'appsettings.json',
    method: 'GET',
    mergeStrategy: 'deepmerge',
    headers: {}
  }
} as Environment;
