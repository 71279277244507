import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { WL } from 'src/app/const/definitions';
import { GmiDateYearValidation } from 'src/app/gmiUtils/validators';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-gmi-simple-grid-criteria',
	templateUrl: './gmi-simple-grid-criteria.component.html',
	styleUrls: ['./gmi-simple-grid-criteria.component.scss'],
})
export class GmiSimpleGridCriteriaComponent implements OnInit {
	// output parameters
	@Output() changeCriteria: EventEmitter<{
		searchCriteria: string;
		dateFrom: string;
		dateTo: string;
		checkBoxValue: boolean;
		searchExpanded: boolean;
	}> = new EventEmitter();

	// input parameters
	@Input() searchCriteria: string;
	@Input() searchDateFromText: string;
	@Input() searchDateFromNgb: string;
	@Input() searchDateToText: string;
	@Input() searchDateToNgb: string;
	@Input() searchCheckBoxDefault: boolean;
	@Input() searchCheckBoxText: string;
	@Input() searchCheckBoxValue: boolean;
	@Input() searchCheckBoxVisible: boolean;
	@Input() searchExpanded = false;

	WL: WL;
	formSimpleGridCriteria: FormGroup;

	// custom parameters
	searchDateFromPlaceholderText = '';
	searchDateToPlaceholderText = '';
	checkBoxVisible = false;
	checkBoxValue = false;
	checkBoxText = '';

	constructor(private localizationService: LocalizationService, private formBuilder: FormBuilder) {
		this.WL = new WL();
	}

	ngOnInit(): void {
		this.searchDateFromPlaceholderText = !!this.searchDateFromText ? this.searchDateFromText : this.localizationService.instant(WL.General_LabelDateFrom);
		this.searchDateToPlaceholderText = !!this.searchDateToText ? this.searchDateToText : this.localizationService.instant(WL.General_LabelDateTo);
		this.checkBoxVisible = !!this.searchCheckBoxVisible;
		this.checkBoxText = this.searchCheckBoxText;

		this.buildForm();
	}

	buildForm() {
		this.formSimpleGridCriteria = this.formBuilder.group({
			formSearchCriteria: new FormControl(this.searchCriteria || null),
			formSearchDateFromNgb: new FormControl(this.searchDateFromNgb || null, [GmiDateYearValidation()]),
			formSearchDateToNgb: new FormControl(this.searchDateToNgb || null, [GmiDateYearValidation()]),
			formCheckBoxValue: new FormControl(this.searchCheckBoxValue || false),
		});
	}

	criteriaChange() {
		if (this.formSimpleGridCriteria.controls.formSearchDateFromNgb.value > this.formSimpleGridCriteria.controls.formSearchDateToNgb.value) {
			Swal.fire({
				html: '<b>' + this.localizationService.instant(WL.General_ErrFromToDate) + '</b>',
				showCancelButton: false,
				reverseButtons: true,
				confirmButtonText: this.localizationService.instant(WL.General_ButtonOk),
				customClass: {
					confirmButton: 'gmi-swal-btn-confirm',
				},
			});
			return;
		}
		this.changeCriteria.emit({
			searchCriteria: this.formSimpleGridCriteria.controls.formSearchCriteria.value,
			dateFrom: this.formSimpleGridCriteria.controls.formSearchDateFromNgb.value,
			dateTo: this.formSimpleGridCriteria.controls.formSearchDateToNgb.value,
			checkBoxValue: this.formSimpleGridCriteria.controls.formCheckBoxValue.value,
			searchExpanded: this.searchExpanded,
		});
	}

	criteriaReset() {
		this.searchDateFromNgb = null;
		this.searchDateToNgb = null;
		this.searchCriteria = '';
		this.checkBoxValue = this.searchCheckBoxValue = this.searchCheckBoxDefault;
		this.buildForm();
		this.changeCriteria.emit({
			searchCriteria: this.searchCriteria,
			dateFrom: this.searchDateFromNgb,
			dateTo: this.searchDateToNgb,
			checkBoxValue: this.searchCheckBoxDefault,
			searchExpanded: this.searchExpanded,
		});
	}

	runSearch(searchData: any, columnsToSearch: any, dateColumnName: string): any {
		// this is only for client side search
		const returnData = searchData.items.filter((item) => {
			const dateColumnValue = item[dateColumnName]?.substring(0, 10);
			let isAfter = moment(dateColumnValue).isSameOrAfter(this.searchDateFromNgb);
			let isBefore = moment(dateColumnValue).isSameOrBefore(this.searchDateToNgb);

			for (let i = 0; i < columnsToSearch.length; i++) {
				const colValue = item[columnsToSearch[i]];
				if (
					(this.searchDateFromNgb === null || isAfter) &&
					(this.searchDateToNgb === null || isBefore) &&
					(!!!this.searchCriteria || (!!colValue && colValue.toString().toLowerCase().indexOf(this.searchCriteria.toLowerCase()) > -1))
				) {
					return true;
				}
			}
		});
		return returnData;
	}

	get formSearchCriteria() {
		return this.formSimpleGridCriteria.get('formSearchCriteria')!;
	}

	get formSearchDateFromNgb() {
		return this.formSimpleGridCriteria.get('formSearchDateFromNgb')!;
	}

	get formSearchDateToNgb() {
		return this.formSimpleGridCriteria.get('formSearchDateToNgb')!;
	}

	get formCheckBoxValue() {
		return this.formSimpleGridCriteria.get('formCheckBoxValue')!;
	}
}
