import type { ActionResult, ContentResult } from './microsoft/asp-net-core/mvc/models';
import type { GmiStyleDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiStylesService {
  apiName = 'Default';
  

  clearStyleCacheByToken = (token: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'GET',
      url: '/api/app/gmi-styles/clear-style-cache',
      params: { token },
    },
    { apiName: this.apiName,...config });
  

  getUserCss = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ContentResult>({
      method: 'GET',
      url: '/api/app/gmi-styles/user-css',
    },
    { apiName: this.apiName,...config });
  

  getUserFavicon = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionResult>({
      method: 'GET',
      url: '/api/app/gmi-styles/user-favicon',
    },
    { apiName: this.apiName,...config });
  

  getUserStyle = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiStyleDto>({
      method: 'GET',
      url: '/api/app/gmi-styles/user-style',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
