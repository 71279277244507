import { ConfigStateService, isNumber, LocalizationService, PermissionService } from '@abp/ng.core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WL } from '../const/definitions';
import { toInteger } from './ngbDateParser';
import { GmiStringFormat } from './pipes';
import { GmiGridLocalization, Page } from './objects';
import { GmiPersonalizationsService, IdTitleDtoString } from '@proxy';
import { GmiPolicyService } from '../shared/services/gmiPolicy.service';
import { Observable, ReplaySubject } from 'rxjs';
import { GmiLocalizationService } from '../shared/services/gmiLocalization.service';
import Swal from 'sweetalert2';

export function GmiCheckEmail(email: string): boolean {
	const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
	return re.test(String(email).toLowerCase());
}
export function GmiPadNumber(num: number, size: number) {
	let numStr = num.toString();
	while (numStr.length < size) numStr = '0' + numStr;
	return numStr;
}

export function GmiDateToString(date: NgbDateStruct) {
	if (!!date) {
		let day = GmiPadNumber(date.day, 2);
		let month = GmiPadNumber(date.month, 2);
		let res = `${date.year}-${month}-${day}`;
		return res;
	} else {
		return null;
	}
}

export function GmiBase64ToBlob(dataURI, mimeType) {
	const byteString = window.atob(dataURI);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const int8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteString.length; i++) {
		int8Array[i] = byteString.charCodeAt(i);
	}
	const blob = new Blob([int8Array], { type: mimeType });
	return blob;
}

export function GmiPrepareGridLocalization(localizationService: LocalizationService, page: Page): GmiGridLocalization {
	let start = page.pageNumber * page.pageSize;
	let end = start + page.pageSize > page.totalElements ? page.totalElements : start + page.pageSize;
	return {
		totalMessage: new GmiStringFormat().transform(localizationService.instant(WL.General_GridPageData), end == 0 ? 0 : start + 1, end),
		emptyMessage: localizationService.instant(WL.General_GridNoData),
	};
}

export function GmiDateStringToNgbDate(date: string): NgbDateStruct {
	if (!!date) {
		let datePart = date.trim().split('T');
		if (datePart.length === 2) {
			let splitedDate = datePart[0].split('-');
			if (splitedDate.length === 3 && isNumber(splitedDate[0]) && isNumber(splitedDate[1]) && isNumber(splitedDate[2])) {
				return {
					year: toInteger(splitedDate[0]),
					month: toInteger(splitedDate[1]),
					day: toInteger(splitedDate[2]),
				};
			}
		}
	} else {
		return null;
	}
}

export function GmiCheckMenuPermissions(
	permissionService: PermissionService,
	gmiPolicyService: GmiPolicyService,
	configStateService: ConfigStateService,
	element
): boolean {
	// module check
	if (!!element.gmiModulePolicy && element.gmiModulePolicy?.length > 0) {
		for (let i = 0; i < element.gmiModulePolicy?.length; i++) {
			if (!GetBoolValue(configStateService.getFeature(element.gmiModulePolicy[0]))) {
				return false;
			}
		}
	}

	// permission check
	if (!!element.requiredPolicy) {
		if (!permissionService.getGrantedPolicy(element.requiredPolicy)) {
			return false;
		}
	}

	// policy check
	if (!!element.gmiMenuPolicy && element.gmiMenuPolicy.length > 0) {
		const policySettingsOk = gmiPolicyService.checkGmiPolicySetting(element.gmiMenuPolicy);
		return policySettingsOk;
	}

	return true;
}

export function GmiGetBlockFromPath(path: string): string {
	return path.slice(path.lastIndexOf('/') + 1);
}

export function GmiFilterColumns(columnArray: any, hiddenFields: any): any {
	return columnArray.filter((x) => !!!hiddenFields || !hiddenFields.includes(x.prop));
}

export function convertFile(file: any): Observable<string> {
	const result = new ReplaySubject<string>(1);
	const reader = new FileReader();
	reader.readAsBinaryString(file);
	reader.onload = (event) => result.next(btoa(event.target.result.toString()));
	return result;
}

export function CheckDatesFromTo(localizationService: LocalizationService, fromDateTime: string, toDateTime: string) {
	if (fromDateTime != null || fromDateTime != null) {
		if (new Date(fromDateTime) > new Date(toDateTime)) {
			Swal.fire({
				icon: 'error',
				title: '',
				text: localizationService.instant(WL.General_ErrFromToDate),
				confirmButtonText: localizationService.instant(WL.General_ButtonYes),
				showCancelButton: false,
			});
			return true;
		}
	}
	return false;
}

export function GmiLocalizeObject(gmiLocalizationService: GmiLocalizationService, obj: any): any {
	gmiLocalizationService.getUserCustomLocalization().subscribe((response) => {
		Object.keys(obj).forEach((element) => {
			if (!Number(obj[element])) {
				for (let i = 0; i < response?.length; i++) {
					if (response[i].id == obj[element]) {
						obj[element] = response[i].title;
						break;
					}
				}
			}
		});
	});
	return obj;
}

export function GmiFormatMobileNumber(mobileNumber: string): string {
	let newVal = mobileNumber.replace(/\D/g, '');

	let plusStart = mobileNumber[0] == '+';

	if (plusStart) {
		if (newVal.length <= 3) {
			newVal = newVal.replace(/^(\d{0,3})/, '+$1');
		} else if (newVal.length <= 5) {
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, '+$1 $2');
		} else if (newVal.length <= 10) {
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})/, '+$1 $2 $3 $4');
		} else {
			newVal = newVal.substring(0, 11);
			newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})/, '+$1 $2 $3 $4');
		}
	} else {
		if (newVal.length <= 5) {
			newVal = newVal.replace(/^(\d{0,5})/, '$1');
		} else if (newVal.length <= 7) {
			newVal = newVal.replace(/^(\d{0,5})(\d{0,2})/, '$1 $2');
		} else if (newVal.length <= 12) {
			newVal = newVal.replace(/^(\d{0,5})(\d{0,2})(\d{0,3})(\d{0,3})/, '$1 $2 $3 $4');
		} else {
			newVal = newVal.substring(0, 13);
			newVal = newVal.replace(/^(\d{0,5})(\d{0,2})(\d{0,3})(\d{0,3})/, '$1 $2 $3 $4');
		}
	}
	return newVal;
}

export function GetBoolValue(value) {
	switch (value) {
		case true:
		case 'true':
		case 1:
		case '1':
		case 'on':
		case 'yes':
			return true;
		default:
			return false;
	}
}
