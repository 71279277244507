<div class="gmi-cookie flex-shrink" *ngIf="!this.ishidden">
	<div class="row">
		<div class="col-10 col-md-5 col-lg-6 col-xl-7 gmi-align-grid-cell-vertical gmi-cookie-div">
			<i class="icon-info font-medium-3 pointer gmi-align-icon gmi-cookie-icon"></i>
			{{ cookie_Message }}
		</div>
		<div class="gmi-display-flex col-11 col-md-4 col-lg-3 col-xl-3 gmi-cookie-div2">
			<button type="button" (click)="iUnderstand()" class="btn btn-primary grid-right-align gmi-cookie-button">
				{{ cookie_Understand }}
			</button>
			<button type="button" (click)="learnMore(content)" class="btn btn-primary grid-right-align gmi-cookie-button">
				{{ cookie_LearnMore }}
			</button>
		</div>
	</div>
	<div class="col-1"></div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header flex-wrap">
		<div>
			<h3>{{ WL.cookie_Information | gmiCustomLocalization | async }}</h3>
		</div>
	</div>
	<div class="modal-body">
		<div class="gmi-flex-break"></div>
		<div>
			<div class="fw-bold">{{ WL.cookie_InfoPage1 | gmiCustomLocalization | async }}</div>
			<div>{{ WL.cookie_InfoPage2 | gmiCustomLocalization | async }}</div>
			<div class="fw-bold">{{ WL.cookie_InfoPage3 | gmiCustomLocalization | async }}</div>
			<div>{{ WL.cookie_InfoPage4 | gmiCustomLocalization | async }}</div>
		</div>
	</div>
	<div class="modal-footer gmi-display-flex gmi-grid-space-between">
		<button type="button" (click)="iUnderstand(); c('Close click')" class="btn btn-primary">
			{{ WL.cookie_Understand | gmiCustomLocalization | async }}
		</button>
		<button type="button" (click)="c('Close click')" class="btn btn-primary">
			{{ WL.general_ButtonClose | gmiCustomLocalization | async }}
		</button>
	</div>
</ng-template>
