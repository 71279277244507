import { Component, HostBinding } from '@angular/core';
// GMI code
import { LocalizationService } from '@abp/ng.core';
import { WL } from 'src/app/const/definitions';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    //Variables
    currentDate : Date = new Date();

    // GMI code
    WL: WL;
    locCopyright = this.localizationService.instant(WL.General_FooterCopyright);
    locRights = this.localizationService.instant(WL.General_FooterRights);

    constructor(
        private localizationService: LocalizationService,
    ){
        this.WL = new WL();
    }
}
