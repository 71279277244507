import type { DefaultReturnData, GmiRequestDefinitionDto, GmiRequestDefinitionsDto, GmiRequestExecutionDto, GmiRequestsSentDto, GmiSearchWithIntIdDto, grp_AdminWfDocumentHistoryTable0 } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminRequestsService {
  apiName = 'Default';
  

  getRequestDefinitionById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiRequestDefinitionDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-requests/${id}/request-definition`,
    },
    { apiName: this.apiName,...config });
  

  getRequestDefinitionsByInputAndPage = (input: GmiSearchWithIntIdDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiRequestDefinitionsDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-requests/request-definitions',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getRequestHistoryById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, grp_AdminWfDocumentHistoryTable0[]>({
      method: 'GET',
      url: `/api/app/gmi-admin-requests/${id}/request-history`,
    },
    { apiName: this.apiName,...config });
  

  getRequestsSentByInputAndPage = (input: GmiSearchWithIntIdDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiRequestsSentDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-requests/requests-sent',
      params: { id: input.id, searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertRequestDefinitionByInput = (input: GmiRequestDefinitionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-requests/insert-request-definition',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postTryDynamicInputByInput = (input: GmiRequestExecutionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-requests/try-dynamic-input',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUpdateRequestDefinitionByInput = (input: GmiRequestDefinitionDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-requests/update-request-definition',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
