import { Injectable, Output } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { GmiStyleDto, GmiStylesService, UploadFileDto } from '@proxy';

@Injectable({
  providedIn: "root"
})
export class GmiTitlesAndStyles {
  done: boolean = false;
  @Output() gmiStyle: GmiStyleDto;
  @Output() title: string;
  @Output() favicon: string;
  @Output() logo: string;
  @Output() loginVertical: string;
  @Output() loginHorizontal: string;

  constructor(
    private titleService: Title,
    private gmiStylesService: GmiStylesService,
  ) {
    this.title = '';
    const blank = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    this.logo = blank;
    this.loginVertical = blank;
    this.loginHorizontal = blank;

    this.getUserStyle();
  }

  getUserStyle() {
    this.gmiStylesService.getUserStyle().subscribe((response) => {
      if (response == undefined) return;

      this.gmiStyle = response;
      if (response.appTitle) {
        this.title = response.appTitle; 
        this.titleService.setTitle(this.title);
      }  
      else {
        var defaultTitle = "NOVA Online";
        this.titleService.setTitle(defaultTitle);
      }

      this.favicon = this.getImage(response.favicon);
      this.logo = this.getImage(response.logo);
      this.loginVertical = this.getImage(response.loginVertical);
      this.loginHorizontal = this.getImage(response.loginHorizontal);

      if (!!response.noInputShading) {
        document.documentElement.style.setProperty('--input-shadow-warning', 'none');
        document.documentElement.style.setProperty('--input-shadow-info', 'none');
      }

      (document.getElementById('gmi-styles-user-css') as HTMLStyleElement).innerHTML = response.cssStyle;
      this.done = true;
    });
  }

  getImage(image: UploadFileDto) {
    if (image?.content) {
      return `data:${image.mimeType};base64,${image.content}`;
    } else {
      return null;
    }
  }
}
