import type { DefaultReturnData, GetStockFundingDropDownDataDto, GmiExternalFunctionResultsDto, GmiGetStockFundingDto, GmiSearchDto, GmiStockFundingFormDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiStockFundingService {
  apiName = 'Default';
  

  getHiddenMandatoryFields = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiExternalFunctionResultsDto[]>({
      method: 'GET',
      url: '/api/app/gmi-stock-funding/hidden-mandatory-fields',
    },
    { apiName: this.apiName,...config });
  

  getStockFundingByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiGetStockFundingDto>>({
      method: 'GET',
      url: '/api/app/gmi-stock-funding/stock-funding',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getStockFundingDropDownDataByIdStockFunding = (idStockFunding: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GetStockFundingDropDownDataDto>({
      method: 'GET',
      url: '/api/app/gmi-stock-funding/stock-funding-drop-down-data',
      params: { idStockFunding },
    },
    { apiName: this.apiName,...config });
  

  getStockFundingFormById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiStockFundingFormDto>({
      method: 'GET',
      url: `/api/app/gmi-stock-funding/${id}/stock-funding-form`,
    },
    { apiName: this.apiName,...config });
  

  postInsertStockFundingByInput = (input: GmiStockFundingFormDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-stock-funding/insert-stock-funding',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
