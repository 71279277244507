import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class NgbDateGmiParserFormatter extends NgbDateParserFormatter {
	getToday(): NgbDateStruct {
		return this.parseMoment(moment());
	}

	parse(value: string): NgbDateStruct {
		if (value) {
			const dateParts = value.trim().split('.');
			if (dateParts.length === 1 && isNumber(dateParts[0])) {
				return { day: toInteger(dateParts[0]), month: null, year: null };
			} else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
				return {
					day: toInteger(dateParts[0]),
					month: toInteger(dateParts[1]),
					year: null,
				};
			} else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
				return {
					day: toInteger(dateParts[0]),
					month: toInteger(dateParts[1]),
					year: toInteger(dateParts[2]),
				};
			}
		}
		return null;
	}

	parseXml(value: string): NgbDateStruct {
		if (value) {
			const onlyDate = value.trim().split('T');
			const dateParts = onlyDate[0].trim().split('-');
			if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
				return {
					day: toInteger(dateParts[2]),
					month: toInteger(dateParts[1]),
					year: toInteger(dateParts[0]),
				};
			}
		}
		return null;
	}

	parseDate(value: Date): NgbDateStruct {
		if (value) {
			return this.parseXml(value.toISOString());
		} else {
			return null;
		}
	}

	parseMoment(value: moment.Moment): NgbDateStruct {
		if (value) {
			return this.parseXml(value.format());
		} else {
			return null;
		}
	}

	format(date: NgbDateStruct): string {
		return date ? `${isNumber(date.day) ? padNumber(date.day) : ''}. ${isNumber(date.month) ? padNumber(date.month) : ''}. ${date.year}` : '';
	}
}

@Injectable()
export class GmiNgbDateAdapter extends NgbDateAdapter<string> {
	readonly DELIMITER = '-';

	toModel(date: NgbDateStruct | null): string | null {
		if (!!date) {
			return date.year + this.DELIMITER + padNumber(date.month) + this.DELIMITER + padNumber(date.day);
		} else {
			return null;
		}
	}

	fromModel(value: string | null): NgbDateStruct | null {
		if (value) {
			const onlyDate = value.trim().split('T');
			const dateParts = onlyDate[0].trim().split('-');
			const yearPart = dateParts[0];
			if (dateParts.length === 3 && isNumber(dateParts[0]) && yearPart.length == 4 && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
				return {
					day: toInteger(dateParts[2]),
					month: toInteger(dateParts[1]),
					year: toInteger(dateParts[0]),
				};
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
}

export function toInteger(value: any): number {
	return parseInt(`${value}`, 10);
}

export function isNumber(value: any): value is number {
	return !isNaN(toInteger(value));
}

export function padNumber(value: number) {
	if (isNumber(value)) {
		return `0${value}`.slice(-2);
	} else {
		return '';
	}
}

export function generateDateForDynamicForm(val: string): NgbDateStruct | null {
	if (!!val) {
		let dateIndex = val.indexOf('GETDATE');
		if (dateIndex >= 0) {
			if (val.indexOf('-') > 0 || val.indexOf('+') > 0) {
				let sign = val.indexOf('-');
				if (sign <= 0) {
					sign = val.indexOf('+');
					if (sign <= 0) {
						return null;
					}
				}
				let days = val.substring(sign);
				if (isNumber(days)) {
					let newDate = moment().add(days, 'days');
					return new NgbDateGmiParserFormatter().parseMoment(newDate);
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return new NgbDateGmiParserFormatter().parseXml(val);
		}
	}
	return null;
}

export function calculateDateWithExtraDays(days: number): string {
	return moment().add(days, 'day').format();
}

export function calculateDateWithExtraDaysNgb(days: number): NgbDateStruct {
	return new NgbDateGmiParserFormatter().parseXml(moment().add(days, 'day').format());
}

export function calculateDateWithExtraMonths(months: number): string {
	return moment().add(months, 'month').format();
}

export function calculateDateWithExtraMonthsNgb(months: number): NgbDateStruct {
	return new NgbDateGmiParserFormatter().parseXml(moment().add(months, 'month').format());
}
