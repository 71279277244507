import { LocalizationService } from "@abp/ng.core";
import { Directive, NgModule } from "@angular/core";
import { NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { WL } from "src/app/const/definitions";

@Directive({
    selector: '[gmiDatepickerTooltip]',
  })
  export class GmiDatepickerTooltipDirective {

    WL = new WL();
  
    constructor(
      private datepicker: NgbInputDatepicker,
      private localizationService: LocalizationService
    ) {
      const previousToggle = this.datepicker.toggle;
      this.datepicker.toggle = () => {
        previousToggle.bind(this.datepicker)();
        if (this.datepicker.isOpen()) {
          this.swapTitles();
        }
      };
    }
  
    swapTitles(): void {
      // @ts-ignore
      const selectMonth = this.datepicker._cRef.location.nativeElement.querySelector('select[title="Select month"]');
      selectMonth.setAttribute('title', this.localizationService.instant(WL.Tooltip_SelectMonth));
      selectMonth.setAttribute('aria-label', this.localizationService.instant(WL.Tooltip_SelectMonth));
  
      // @ts-ignore
      const selectYear = this.datepicker._cRef.location.nativeElement.querySelector('select[title="Select year"]');
      selectYear.setAttribute('title', this.localizationService.instant(WL.Tooltip_SelectYear));
      selectYear.setAttribute('aria-label', this.localizationService.instant(WL.Tooltip_SelectYear));

      // @ts-ignore
      const nextMonth = this.datepicker._cRef.location.nativeElement.querySelector('button[title="Next month"]');
      nextMonth.setAttribute('title', this.localizationService.instant(WL.Tooltip_NextMonth));
      nextMonth.setAttribute('aria-label', this.localizationService.instant(WL.Tooltip_NextMonth));

      // @ts-ignore
      const previousMonth = this.datepicker._cRef.location.nativeElement.querySelector('button[title="Previous month"]');
      previousMonth.setAttribute('title', this.localizationService.instant(WL.Tooltip_PreviousMonth));
      previousMonth.setAttribute('aria-label', this.localizationService.instant(WL.Tooltip_PreviousMonth));
    }
  }

  @NgModule({
    declarations: [GmiDatepickerTooltipDirective],
    exports: [GmiDatepickerTooltipDirective]
})

export class GmiDatepickerTooltipModule { }