<div class="gmi-display-flex grid-right-align">
	<i
		class="ft-send gmi-selectable gmi-icon-size"
		(click)="openRequestsModal()"
		*ngIf="!!requestsSelect && requestsSelect.length > 0"
		ngbTooltip="{{ WL.menu_Requests | gmiCustomLocalization | async }}"
		placement="bottom"></i>
	<i
		class="ft-printer gmi-selectable gmi-icon-size"
		[ngClass]="{ 'ps-3': !!requestsSelect && requestsSelect.length > 0 }"
		(click)="openReportsModal()"
		*ngIf="!!reportsSelect && reportsSelect.length > 0"
		ngbTooltip="{{ WL.menu_Reports | gmiCustomLocalization | async }}"
		placement="bottom"></i>
</div>

<gmi-reports-modal #modalReports [reportsSelect]="reportsSelect" [id]="this.id"></gmi-reports-modal>

<gmi-requests-modal #modalRequests [requestsSelect]="requestsSelect" [id]="this.id" [subTitle]="this.customSubtitle"></gmi-requests-modal>
