import type { DefaultReturnData, GmiCheckClaimsInputDto, GmiGetInsuranceClaimsDto, GmiInsuranceClaimsFormDto, GmiInsuranceClaimsSettingsDto, GmiSearchDto, IdTitleDtoString } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiInsuranceClaimsService {
  apiName = 'Default';
  

  getChassisForLessee = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoString[]>({
      method: 'GET',
      url: '/api/app/gmi-insurance-claims/chassis-for-lessee',
    },
    { apiName: this.apiName,...config });
  

  getCoreInsuranceCompanies = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoString[]>({
      method: 'GET',
      url: '/api/app/gmi-insurance-claims/core-insurance-companies',
    },
    { apiName: this.apiName,...config });
  

  getDefaultCoreInsuranceCompanies = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/gmi-insurance-claims/default-core-insurance-companies',
    },
    { apiName: this.apiName,...config });
  

  getInsuranceClaimsByInputAndPage = (input: GmiSearchDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiGetInsuranceClaimsDto>>({
      method: 'GET',
      url: '/api/app/gmi-insurance-claims/insurance-claims',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getInsuranceClaimsFormById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiInsuranceClaimsFormDto>({
      method: 'GET',
      url: `/api/app/gmi-insurance-claims/${id}/insurance-claims-form`,
    },
    { apiName: this.apiName,...config });
  

  getInsuranceClaimsSettings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiInsuranceClaimsSettingsDto>({
      method: 'GET',
      url: '/api/app/gmi-insurance-claims/insurance-claims-settings',
    },
    { apiName: this.apiName,...config });
  

  postCheckClaimsByInput = (input: GmiCheckClaimsInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-insurance-claims/check-claims',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postInsertInsuranceClaimsByInput = (input: GmiInsuranceClaimsFormDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiInsuranceClaimsFormDto>({
      method: 'POST',
      url: '/api/app/gmi-insurance-claims/insert-insurance-claims',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
