import { RouteInfo } from './vertical-menu.metadata';
// GMI code
import { WL, WP, WC } from '../../const/definitions';

// Dashboard
export const ROUTES_HOME : RouteInfo[] = [
	{ path: 'dashboard', title: WL.Menu_Home, icon: 'icon-home', class: '', isExternalLink: false, submenu: [] },
	{ path: 'calculation', title: "Hitra kalkulacija", icon: 'icon-calculator', class: '', isExternalLink: false, submenu: [], },
];

// Lessee menus
export const ROUTES_LESSEE : RouteInfo[] = [
	{ path: '', title: WL.Menu_Lessee, icon: '', class: 'has-sub', isExternalLink: false,
		gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [
		{ path: 'contracts', title: WL.Menu_Contracts, icon: 'ft-file', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Contracts,
			gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [] },
		{ path: 'payments', title: WL.Menu_Payments, icon: 'fa fa-euro', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Payments,
			gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [] },
		{ path: 'eaDocuments', title: WL.Menu_EaDocuments, icon: 'ft-file-text', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_EADocuments,
			gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [] },
		{ path: 'missingDocuments', title: WL.Menu_MissingDocumentation, icon: 'icon-docs', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_MissingDocumentations,
			gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [], },
		{ path: 'overduePayments', title: WL.Menu_OverduePayments, icon: 'icon-wallet', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_OverduePayments,
			gmiMenuPolicy: ["isLessee"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [] },
		]
	}];


// Stock funding menus
export const ROUTES_STOCKFUNDING : RouteInfo[] = [
	{ path: '', title: WL.Menu_StockFunding, icon: '', class: 'has-sub', isExternalLink: false,
		requiredPolicy: WP.PublicStockFundingGroup_PublicStockFunding,
		gmiMenuPolicy: ["isStockFunding"], 
		gmiModulePolicy: [WC.GmiLicenceModules_StockFunding], submenu: [
			{ path: 'stockFunding', title: WL.Menu_StockFunding, icon: 'ft-package', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicStockFundingGroup_PublicStockFunding,
			gmiMenuPolicy: ["isStockFunding"], 
			gmiModulePolicy: [WC.GmiLicenceModules_StockFunding], submenu: [] },
		]
	}];

// Insurance menus
export const ROUTES_INSURANCE : RouteInfo[] = [
	{ path: '', title: WL.Menu_Insurance, icon: '', class: 'has-sub', isExternalLink: false,
		requiredPolicy: WP.PublicLesseeGroup_PublicLessee_InsuranceClaims,
		gmiMenuPolicy: ["isLessee", "showInsuranceClaimsMenu"], 
		gmiModulePolicy: [WC.GmiLicenceModules_DamageClaims], submenu: [
		{ path: 'insuranceClaims', title: WL.Menu_InsuranceClaims, icon: 'icon-shield', class: '', isExternalLink: false,
			requiredPolicy: WP.PublicLesseeGroup_PublicLessee_InsuranceClaims,
			gmiMenuPolicy: ["isLessee", "showInsuranceClaimsMenu"], 
			gmiModulePolicy: [WC.GmiLicenceModules_DamageClaims], submenu: [] }]
	}];

// Menus for all
export const ROUTES_ALL: RouteInfo[] = [
	{ path: 'partner', title: WL.Menu_Partner, icon: 'ft-user', class: '', isExternalLink: false,
	 requiredPolicy: WP.PublicLesseeGroup_PublicLessee_Partner,
	  gmiMenuPolicy: ["isLessee", "isVendor"], gmiModulePolicy: [WC.GmiLicenceModules_ContractsSync], submenu: [] },
	{ path: 'requests', title: WL.Menu_Requests, icon: 'ft-send', class: '', isExternalLink: false,
	  requiredPolicy: WP.PublicRequestsGroup_PublicRequests, submenu: [], gmiMenuPolicy: ['showUserRequests'] },
	{ path: 'notification', title: WL.Menu_Notifications, icon: 'ft-bell', class: '', isExternalLink: false,
	  submenu: [] },
	{ path: 'help', title: WL.Menu_Help, icon: 'ft-help-circle', class: '', isExternalLink: false,
	  gmiMenuPolicy: ["showHelpMenu"], submenu: [] }
];
