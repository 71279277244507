import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[gmiFormatDate]',
})
export class GmiFormatDateDirective {
	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		const input = event.target as HTMLInputElement;
		if (input.value != input.value.trim()) {
			input.value = input.value.substring(0, input.value.length - 1);
		}
	}

	@HostListener('input', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;

		let trimmed = input.value.replace(/\s+/g, '');
		let numbers = [];

		let periodCnt = trimmed.split('.');
		if (periodCnt.length == 1) {
			numbers.push(periodCnt[0].substring(0, 2));
			if (periodCnt[0].length == 3) {
				numbers.push(periodCnt[0].substring(2));
			}
		} else if (periodCnt.length == 2) {
			numbers.push(periodCnt[0].substring(0, 2));
			numbers.push(periodCnt[1].substring(0, 2));
			if (periodCnt[1].length > 2) {
				numbers.push(periodCnt[1].substring(2));
			}
		} else if (periodCnt.length == 3) {
			numbers.push(periodCnt[0]);
			numbers.push(periodCnt[1]);
			numbers.push(periodCnt[2]);
		}

		input.value = numbers.join('. ');
	}
}
