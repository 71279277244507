import { ConfigStateService, SessionStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: "root"
})
export class GmiLanguageService {
	constructor(
		private configStateService: ConfigStateService,
		private sessionStateService: SessionStateService,
	) {}

	setDefaultLanguage(): boolean {
		const defaultLanguageSet = localStorage.getItem('defaultLanguageSet');

		// check if language currentCulture is even a possibility (in defaultCultures)
		const currentCulture: any = this.configStateService.getDeep('localization.currentCulture').cultureName;
		const defaultCultures: any[] = this.configStateService.getDeep('localization.languages').map(x => x.cultureName);

		if (defaultLanguageSet == 'true' && defaultCultures.includes(currentCulture)) return false;

		localStorage.setItem('defaultLanguageSet', 'true');
		const defaultCulture = defaultCultures[0];
		if (defaultCulture == currentCulture) return false;

		this.sessionStateService.setLanguage(defaultCulture);
		this.configStateService.refreshAppState();
		return true;
	}
}
