import type { DefaultReturnData, GmiSearchDto, GmiWfDocumentChangeDto, GmiWfDocumentDto, GmiWfProcessDto, GmiWfProcessTransitionDataDto, GmiWfStatusDto, IdTitleDtoInt } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminWorkflowsService {
  apiName = 'Default';
  

  getAllProcessesByInput = (input: GmiSearchDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiWfProcessDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/processes',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  getEndStatuses = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/end-statuses',
    },
    { apiName: this.apiName,...config });
  

  getProcessById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiWfProcessDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-workflows/${id}/process`,
    },
    { apiName: this.apiName,...config });
  

  getStartStatuses = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/start-statuses',
    },
    { apiName: this.apiName,...config });
  

  getStatusById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiWfStatusDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-workflows/${id}/status`,
    },
    { apiName: this.apiName,...config });
  

  getStatusTransitionsByIdProcess = (idProcess: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiWfProcessTransitionDataDto>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/status-transitions',
      params: { idProcess },
    },
    { apiName: this.apiName,...config });
  

  getStatuses = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdTitleDtoInt[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/statuses',
    },
    { apiName: this.apiName,...config });
  

  getStatusesSearchByInput = (input: GmiSearchDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiWfStatusDto[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/statuses-search',
      params: { searchCriteria: input.searchCriteria, dateFrom: input.dateFrom, dateTo: input.dateTo, checkBoxValue: input.checkBoxValue, orderBy: input.orderBy, orderAscending: input.orderAscending, showInactive: input.showInactive },
    },
    { apiName: this.apiName,...config });
  

  getUsedStatusNames = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/gmi-admin-workflows/used-status-names',
    },
    { apiName: this.apiName,...config });
  

  postChangeDocumentWfStatusByInput = (input: GmiWfDocumentChangeDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-workflows/change-document-wf-status',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postInsertProcessByInput = (input: GmiWfProcessDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-workflows/insert-process',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postInsertStatusByInput = (input: GmiWfStatusDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-workflows/insert-status',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postInsertWfDocumentByInput = (input: GmiWfDocumentDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-workflows/insert-wf-document',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postUpdateStatusTransitionByInput = (input: GmiWfProcessTransitionDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-workflows/update-status-transition',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdateProcessByInput = (input: GmiWfProcessDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-workflows/update-process',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdateStatusByInput = (input: GmiWfStatusDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-workflows/update-status',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
