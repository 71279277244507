import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { GmiFormatMobileNumber } from './functions';

@Directive({ selector: '[gmiTrim]' })
export class GmiInputTrimDirective {
	constructor(private el: ElementRef, private ngControl: NgControl) {}
	@HostListener('input', ['$event']) onEvent($event) {
		let val = this.el.nativeElement.value.trim();
		this.ngControl.control.setValue(val);
	}
}

@Directive({ selector: '[gmiMobilePhoneInput]' })
export class GmiMobilePhoneInputDirective {
	constructor(private ngControl: NgControl) {}
	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		const input = event.target as HTMLInputElement;
		if (input.value != input.value.trim()) {
			input.value = input.value.substring(0, input.value.length - 1);
		}
	}

	@HostListener('input', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;
		let newVal = GmiFormatMobileNumber(input.value);
		this.ngControl.control.patchValue(newVal);
	}
}

@Directive({ selector: '[gmiIbanInput]' })
export class GmiIbanInputDirective {
	constructor(private ngControl: NgControl) {}

	@HostListener('keydown.backspace', ['$event'])
	keydownBackspace(event) {
		const input = event.target as HTMLInputElement;
		if (input.value != input.value.trim()) {
			input.value = input.value.substring(0, input.value.length - 1);
		}
	}

	@HostListener('input', ['$event'])
	onKeyDown(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement;
		let newVal = input.value.replace(/\s/g, '');
		newVal = newVal.replace(/[^a-zA-Z0-9]/, '');

		if (newVal.length <= 4) {
			newVal = newVal;
		} else {
			if (newVal.startsWith('SI56')) {
				if (newVal.length <= 8) {
					newVal = `${newVal.slice(0, 4)} ${newVal.slice(4)}`;
				} else if (newVal.length <= 12) {
					newVal = `${newVal.slice(0, 4)} ${newVal.slice(4, 8)} ${newVal.slice(8)}`;
				} else if (newVal.length <= 16) {
					newVal = `${newVal.slice(0, 4)} ${newVal.slice(4, 8)} ${newVal.slice(8, 12)} ${newVal.slice(12)}`;
				} else {
					newVal = `${newVal.slice(0, 4)} ${newVal.slice(4, 8)} ${newVal.slice(8, 12)} ${newVal.slice(12, 16)} ${newVal.slice(16, 19)}`;
				}
			} else {
				newVal = newVal.length > 33 ? newVal.slice(0, 33) : newVal;
				let tempVal = newVal.match(/.{1,4}/g);
				newVal = '';
				tempVal.forEach((x) => {
					newVal += x + ' ';
				});
			}
		}
		this.ngControl.control.patchValue(newVal);
	}
}

@Directive({ selector: '[gmiToUpperCase]' })
export class GmiInputToUpperCaseDirective {
	constructor(private el: ElementRef, private ngControl: NgControl) {}
	@HostListener('input', ['$event']) onEvent($event) {
		let val = this.el.nativeElement.value.toUpperCase();
		this.ngControl.control.setValue(val);
	}
}
