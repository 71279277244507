import type { DefaultReturnData, GmiCustomTranslation, GmiCustomTranslationsSearchParamsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminCustomTranslationsService {
  apiName = 'Default';
  

  getCustomTranslationsByInputAndPage = (input: GmiCustomTranslationsSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiCustomTranslation>>({
      method: 'GET',
      url: '/api/app/gmi-admin-custom-translations/custom-translations',
      params: { searchCriteria: input.searchCriteria, showInactive: input.showInactive, orderBy: input.orderBy, orderAscending: input.orderAscending, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertNewCustomTranslationByInput = (input: GmiCustomTranslation, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-custom-translations/insert-new-custom-translation',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdateCustomTranslationByInput = (input: GmiCustomTranslation, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-custom-translations/update-custom-translation',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
