import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GmiRequestExecutionDto, GmiRequestsService, IdTitleDtoString, PublicRequestDto, UploadFileDto } from '@proxy';
import { WL } from 'src/app/const/definitions';
import { convertFile } from 'src/app/gmiUtils/functions';
import { generateDateForDynamicForm, isNumber, toInteger } from 'src/app/gmiUtils/ngbDateParser';
import Swal from 'sweetalert2';

interface JsonFormValidators {
	min?: number;
	max?: number;
	required?: boolean;
	email?: boolean;
	pattern?: string;
}

interface JsonFormControls {
	name: string;
	label: string;
	value: string;
	type: string;
	errorMsg: string;
	minDate?: string;
	maxDate?: boolean;
	tooltip: string;
	validators: JsonFormValidators;
	selectOptions: Array<IdTitleDtoString>;
	localization: Array<IdTitleDtoString>;
}

export interface JsonFormData {
	controls: JsonFormControls[];
}

@Component({
	selector: 'gmi-json-form',
	templateUrl: './gmi-json-form.component.html',
	styleUrls: ['./gmi-json-form.component.scss'],
})
export class GmiJsonFormComponent implements OnInit, OnChanges {
	@Input() selectedRequest: PublicRequestDto;
	@Input() idCont: number;
	@Input() subTitle: string;

	public myForm: FormGroup = this.fb.group({});
	formDataParsed = false;
	WL: WL;
	dynamicModalFormData: GmiRequestExecutionDto;
	formControls: any;
	currentLanguage: string;
	selectedFiles: Array<File>;
	filesToUpload: Array<UploadFileDto>;

	constructor(
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private localizationService: LocalizationService,
		private ngbModal: NgbModal,
		private gmiRequestsService: GmiRequestsService,
		private configStateService: ConfigStateService
	) {
		this.WL = new WL();
	}

	ngOnInit() {
		this.currentLanguage = this.configStateService.getDeep('localization.currentCulture').name;
	}

	ngAfterViewInit(): void {
		this.formControls = JSON.parse(this.selectedRequest.requestDefinition);
		this.createForm(this.formControls.controls);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.jsonFormData.firstChange) {
			console.log(this.selectedRequest.requestDefinition);
		}
	}

	createForm(controls: JsonFormControls[]) {
		this.selectedFiles = [];
		if (!!controls) {
			for (const control of controls) {
				const validatorsToAdd = [];

				if (!!control.validators) {
					for (const [key, value] of Object.entries(control.validators)) {
						switch (key) {
							case 'min':
								if (control.type == 'text') {
									validatorsToAdd.push(Validators.minLength(value));
								} else if (control.type == 'number') {
									validatorsToAdd.push(Validators.min(value));
								}
								break;
							case 'max':
								if (control.type == 'text') {
									validatorsToAdd.push(Validators.maxLength(value));
								} else if (control.type == 'number') {
									validatorsToAdd.push(Validators.max(value));
								}
								break;
							case 'required':
								if (value) {
									validatorsToAdd.push(Validators.required);
								}
								break;
							case 'email':
								if (value) {
									validatorsToAdd.push(Validators.email);
								}
								break;
							case 'pattern':
								validatorsToAdd.push(Validators.pattern(value));
								break;
							default:
								break;
						}
					}
				}

				if (!!control.selectOptions && control.selectOptions.length > 0) {
					let unselected: Array<IdTitleDtoString> = [{ id: '0', title: this.localizationService.instant(WL.General_SelectNoSelection) }];
					control.selectOptions = [...unselected, ...control.selectOptions];
				}

				if (!!control.localization) {
					let localLabel = control.localization[this.currentLanguage];
					if (!!localLabel) {
						control.label = localLabel;
					}
				}

				this.myForm.addControl(control.name, this.fb.control(control.value, validatorsToAdd));

				if (Object.keys(this.myForm.controls).length == controls.length) {
					this.formDataParsed = true;
					this.cdr.detectChanges();
				}
			}
		} else {
			this.formDataParsed = true;
			this.cdr.detectChanges();
		}
	}

	closeDynamicModal() {
		this.ngbModal.dismissAll();
	}

	loadit() {
		this.formDataParsed = true;
	}

	submitForm() {
		if (!this.myForm.valid) {
			return;
		}

		this.dynamicModalFormData = {} as GmiRequestExecutionDto;

		if (!!this.selectedFiles && this.selectedFiles.length > 0) {
			this.filesToUpload = [];

			var fileUplaodFinished = new Promise<void>((resolve, reject) => {
				this.selectedFiles.forEach((element) => {
					convertFile(element).subscribe((response) => {
						var file = { fileName: element.name, content: response, mimeType: element.type } as UploadFileDto;
						this.filesToUpload.push(file);

						if (this.selectedFiles.length == this.filesToUpload.length) {
							resolve();
						}
					});
				});
			});

			fileUplaodFinished.then(() => {
				this.dynamicModalFormData.documents = this.filesToUpload;
				this.sendRequest();
			});
		} else {
			this.sendRequest();
		}
	}

	sendRequest() {
		this.dynamicModalFormData.formData = this.myForm.value;
		this.dynamicModalFormData.id = this.selectedRequest.id;
		this.dynamicModalFormData.idCont = this.idCont;

		this.gmiRequestsService.postInsertRequestByInput(this.dynamicModalFormData).subscribe((response) => {
			let msg = 'Request sent.';
			if (response.isError) {
				msg = 'There was a problem sending the request.';
			}

			Swal.fire({
				html: '<b>' + msg + '</b>',
				showCancelButton: false,
				reverseButtons: true,
				confirmButtonText: this.localizationService.instant(WL.General_ButtonOk),
				customClass: {
					confirmButton: 'gmi-swal-btn-confirm',
				},
			});

			this.ngbModal.dismissAll();
		});
	}

	uploadFile(event) {
		if (!!event?.target?.files) {
			for (var i = 0; i < event.target.files.length; i++) {
				this.selectedFiles.push(event.target.files[i]);
			}
		}
	}

	removeFile(fileName) {
		if (!!this.selectedFiles) {
			let newList = this.selectedFiles.filter((item) => {
				if (item.name != fileName) {
					return true;
				}
			});
			this.selectedFiles = newList;
		}
	}

	calcDate(date: string): NgbDateStruct | null {
		return generateDateForDynamicForm(date);
	}
}
