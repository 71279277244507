import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit, } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { WL } from '../../../const/definitions';

@Component({
  selector: 'gmi-spinner',
  templateUrl: './gmi-spinner.component.html',
  styleUrls: ['./gmi-spinner.component.scss']
})

export class GmiSpinner implements OnInit {
  @Input() spinnerLoadingMessage = this.localizationService.instant(WL.General_Loading);
  WL: WL;
  constructor(
    private localizationService: LocalizationService,
    private spinner: NgxSpinnerService,
  ) { 
    this.WL = new WL();
  }

  ngOnInit(): void {
  }

  showSpinner(){
    this.spinner.show();
  }

  hideSpinner(){
    this.spinner.hide();
  }
}
