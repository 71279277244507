import type { DefaultReturnData, GmiNotificationAdminDto, GmiNotificationAllDto, GmiNotificationsSearchParamsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto, PagedResultRequestDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiAdminNotificationsService {
  apiName = 'Default';
  

  deleteNotificationById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'DELETE',
      url: `/api/app/gmi-admin-notifications/${id}/notification`,
    },
    { apiName: this.apiName,...config });
  

  getNotificationSingleById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiNotificationAdminDto>({
      method: 'GET',
      url: `/api/app/gmi-admin-notifications/${id}/notification-single`,
    },
    { apiName: this.apiName,...config });
  

  getNotificationsSearchByInputAndPage = (input: GmiNotificationsSearchParamsDto, page: PagedResultRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<GmiNotificationAllDto>>({
      method: 'GET',
      url: '/api/app/gmi-admin-notifications/notifications-search',
      params: { searchCriteria: input.searchCriteria, typeId: input.typeId, showInactive: input.showInactive, orderBy: input.orderBy, orderAscending: input.orderAscending, skipCount: page.skipCount, maxResultCount: page.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  postInsertNewNotificationByInput = (input: GmiNotificationAdminDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-admin-notifications/insert-new-notification',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  putUpdateNotificationByInput = (input: GmiNotificationAdminDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'PUT',
      url: '/api/app/gmi-admin-notifications/update-notification',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
