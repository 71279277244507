import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[gmiRestrictDateInput]'
})
export class GmiRestrictDateInputDirective {

  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    this.restrictInput(event);
  }

  restrictInput(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 
      'Slash', 'Dash', 'Period' 
    ];

    if (
      !allowedKeys.includes(event.key) && 
      !(event.key >= '0' && event.key <= '9') 
    ) {
      event.preventDefault(); 
    }
  }
}
