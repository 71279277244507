import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FileContentsDto, GmiReportsService, IdTitleDtoInt } from '@proxy';
import { WL } from 'src/app/const/definitions';
import { GmiBase64ToBlob } from 'src/app/gmiUtils/functions';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { LocalizationService } from '@abp/ng.core';

@Component({
	selector: 'gmi-reports-modal',
	templateUrl: './gmi-reports-modal.component.html',
	styleUrls: ['./gmi-reports-modal.component.scss'],
})
export class GmiReportsModalComponent implements OnInit {
	@Input() id: any;
	@Input() reportsSelect: Array<IdTitleDtoInt>;
	@ViewChild('reportModalContent') templateRef: TemplateRef<any>;

	WL: WL;
	showRenderDiv = false;
	showSendDiv = false;
	renderedReport: FileContentsDto;
	formReportMail: FormGroup;

	selectReportOptionsForm: FormGroup;
	allReportsToSelect: Array<IdTitleDtoInt>;

	constructor(
		private gmiReportsService: GmiReportsService,
		private formBuilder: FormBuilder,
		private ngbModal: NgbModal,
		private spinner: NgxSpinnerService,
		private localizationService: LocalizationService
	) {
		this.WL = new WL();
	}

	ngOnInit() {}

	openModal() {
		this.showRenderDiv = false;
		this.showSendDiv = false;
		this.buildForm();
		this.ngbModal.open(this.templateRef, { size: 'lg', backdrop: 'static' });
	}

	closeModal() {
		this.ngbModal.dismissAll();
	}

	buildForm() {
		this.allReportsToSelect = [{ id: 0, title: this.localizationService.instant(WL.General_SelectNoSelection) }, ...this.reportsSelect];

		this.selectReportOptionsForm = this.formBuilder.group({
			selectedReport: [0],
		});

		this.formReportMail = this.formBuilder.group({
			reportId: [this.selectReportOptionsForm.value.selectedReport],
			id: [null],
			to: ['', Validators.required],
			cc: [''],
			subject: ['', Validators.required],
			body: ['', Validators.required],
		});
	}

	selectDelivery(printOnly: boolean) {
		if (printOnly) {
			this.showRenderDiv = true;
			this.showSendDiv = false;
		} else {
			this.showRenderDiv = false;
			this.showSendDiv = true;
		}
	}

	isReportSelected() {
		return !!this.selectReportOptionsForm.value?.selectedReport;
	}

	showReport() {
		this.spinner.show();
		this.gmiReportsService.getRenderStimulsoftReportByReportIdAndId(this.selectReportOptionsForm.value.selectedReport, this.id).subscribe((response) => {
			this.spinner.hide();
			if (!!response.content) {
				const imageBlob = GmiBase64ToBlob(response.content, response.mimeType);
				const imageFile = new File([imageBlob], response.fileName, { type: response.mimeType });
				saveAs(imageFile);
			} else {
				Swal.fire(this.localizationService.instant(WL.Reports_ErrorRendering));
			}
		});
	}

	sendMail() {
		this.formReportMail.controls['reportId'].setValue(this.selectReportOptionsForm.value.selectedReport);
		this.formReportMail.controls['id'].setValue(this.id);
		this.spinner.show();
		this.gmiReportsService.postSendReportByEmailByInput(this.formReportMail.value).subscribe((response) => {
			this.spinner.hide();
			if (response.isError) {
				Swal.fire(this.localizationService.instant(WL.Reports_ErrorRendering));
			} else {
				Swal.fire(this.localizationService.instant(WL.General_ModalSuccess));
				this.ngbModal.dismissAll();
			}
		});
	}

	get to() {
		return this.formReportMail.get('to')!;
	}

	get cc() {
		return this.formReportMail.get('cc')!;
	}

	get subject() {
		return this.formReportMail.get('subject')!;
	}

	get body() {
		return this.formReportMail.get('body')!;
	}
}
