import type { DefaultReturnData, GmiPasswordChangeDto, GmiProfileDataDto, GmiProfileDocumentDeliveryDto, GmiProfileDto, GmiTwoFactorSettingsDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmiProfilesService {
  apiName = 'Default';
  

  getProfileData = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiProfileDataDto>({
      method: 'GET',
      url: '/api/app/gmi-profiles/profile-data',
    },
    { apiName: this.apiName,...config });
  

  getUserTwoFactorSettings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, GmiTwoFactorSettingsDto>({
      method: 'GET',
      url: '/api/app/gmi-profiles/user-two-factor-settings',
    },
    { apiName: this.apiName,...config });
  

  postChangeDocumentDeliveryDataByInput = (input: GmiProfileDocumentDeliveryDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-profiles/change-document-delivery-data',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postChangeOwnEmailByNewEmailAndPassword = (newEmail: string, password: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-profiles/change-own-email',
      params: { newEmail, password },
    },
    { apiName: this.apiName,...config });
  

  postChangePasswordByInput = (input: GmiPasswordChangeDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-profiles/change-password',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postChangeProfileDataByInput = (input: GmiProfileDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/gmi-profiles/change-profile-data',
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
