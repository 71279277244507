<ng-template #reportModalContent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ WL.menu_Reports | abpLocalization }}
    </h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <!-- spinner for the modal window-->
    <gmi-spinner></gmi-spinner>
    <form [formGroup]="selectReportOptionsForm">
      <div>
        <select formControlName="selectedReport" class="form-select">
          <option *ngFor="let item of allReportsToSelect" [ngValue]="item.id">{{ item.title }}</option>
        </select>
      </div>
    </form>
    <div class="gmi-display-flex gmi-grid-space-evenly pt-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"
          (change)="selectDelivery(true)" />
        <label class="form-check-label" for="inlineRadio1">{{ WL.reports_RenderReport | abpLocalization }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"
          (change)="selectDelivery(false)" />
        <label class="form-check-label" for="inlineRadio2">{{ WL.reports_SendReport | abpLocalization }}</label>
      </div>
    </div>

    <div *ngIf="showRenderDiv" class="gmi-display-flex gmi-grid-space-evenly pt-4">
      <button class="btn btn-primary" [disabled]="!isReportSelected()" (click)="showReport()">
        {{ WL.reports_RenderReport | abpLocalization }}
      </button>
    </div>
    <form [formGroup]="formReportMail" *ngIf="showSendDiv">
      <hr />
      <div class="mb-2">
        <label for="to">{{ WL.general_MailTo | gmiCustomLocalization | async }}</label>
        <input type="text" id="to" formControlName="to" class="form-control" />
      </div>
      <div class="mb-2">
        <label for="cc">{{ WL.general_MailCC | gmiCustomLocalization | async }}</label>
        <input type="text" id="cc" formControlName="cc" class="form-control" />
      </div>
      <div class="mb-2">
        <label for="subject">{{ WL.general_MailSubject | gmiCustomLocalization | async }}</label>
        <input type="text" id="subject" formControlName="subject" class="form-control" />
      </div>
      <div class="mb-2">
        <label for="body">{{ WL.general_MailBody | gmiCustomLocalization | async }}</label>
        <textarea type="text" name="body" class="form-control" id="body" formControlName="body"> </textarea>
      </div>
      <div class="gmi-display-flex gmi-grid-space-evenly">
        <button class="btn btn-primary" [disabled]="!formReportMail.valid || !isReportSelected()" (click)="sendMail()">
          {{ WL.general_MailSend | abpLocalization }}
        </button>
      </div>
    </form>
  </div>
</ng-template>