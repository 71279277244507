import type { DefaultReturnData, SisumApplicationPortalInitialDataDto, SisumApplicationSavedDataDto, SisumApplicationStep0Dto, SisumApplicationStep1Dto, SisumApplicationStep2Dto, SisumApplicationStep3Dto, SisumApplicationStep4Dto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomSisumService {
  apiName = 'Default';
  

  postApplicationCancelByApplicationId = (applicationId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/custom-sisum/application-cancel/${applicationId}`,
    },
    { apiName: this.apiName,...config });
  

  postApplicationInitialCheckByInput = (input: SisumApplicationPortalInitialDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SisumApplicationSavedDataDto>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-initial-check',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationInitialCheckClientByInput = (input: SisumApplicationPortalInitialDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SisumApplicationSavedDataDto>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-initial-check-client',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationSaveStep0ByInput = (input: SisumApplicationStep0Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-save-step0',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationSaveStep1ByInput = (input: SisumApplicationStep1Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-save-step1',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationSaveStep2ByInput = (input: SisumApplicationStep2Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-save-step2',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationSaveStep3ByInput = (input: SisumApplicationStep3Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-save-step3',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postApplicationSaveStep4ByInput = (input: SisumApplicationStep4Dto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: '/api/app/custom-sisum/application-save-step4',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  postSendConfirmationEmailByIdAndEmail = (id: string, email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DefaultReturnData>({
      method: 'POST',
      url: `/api/app/custom-sisum/${id}/send-confirmation-email`,
      params: { email },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
