export class OnlinePermissions {
  public administrationGroup_AdminMenu: string = 'AdminMenu';
  public static AdministrationGroup_AdminMenu: string = 'AdminMenu';
  public administrationGroup_GmiPartners: string = 'GmiPartners';
  public static AdministrationGroup_GmiPartners: string = 'GmiPartners';
  public administrationGroup_GmiPartners_Insert: string = 'GmiPartners.Insert';
  public static AdministrationGroup_GmiPartners_Insert: string = 'GmiPartners.Insert';
  public administrationGroup_GmiPartners_Edit: string = 'GmiPartners.Edit';
  public static AdministrationGroup_GmiPartners_Edit: string = 'GmiPartners.Edit';
  public administrationGroup_GmiUsers: string = 'GmiUsers';
  public static AdministrationGroup_GmiUsers: string = 'GmiUsers';
  public administrationGroup_GmiUsers_Insert: string = 'GmiUsers.Insert';
  public static AdministrationGroup_GmiUsers_Insert: string = 'GmiUsers.Insert';
  public administrationGroup_GmiUsers_Edit: string = 'GmiUsers.Edit';
  public static AdministrationGroup_GmiUsers_Edit: string = 'GmiUsers.Edit';
  public administrationGroup_GmiUsers_ResetPassword: string = 'GmiUsers.ResetPassword';
  public static AdministrationGroup_GmiUsers_ResetPassword: string = 'GmiUsers.ResetPassword';
  public administrationGroup_GmiUsers_Impersonate: string = 'GmiUsers.Impersonate';
  public static AdministrationGroup_GmiUsers_Impersonate: string = 'GmiUsers.Impersonate';
  public administrationGroup_GmiUsers_Mails: string = 'GmiUsers.Mail';
  public static AdministrationGroup_GmiUsers_Mails: string = 'GmiUsers.Mail';
  public administrationGroup_Roles: string = 'Roles';
  public static AdministrationGroup_Roles: string = 'Roles';
  public administrationGroup_Roles_Insert: string = 'Roles.Insert';
  public static AdministrationGroup_Roles_Insert: string = 'Roles.Insert';
  public administrationGroup_Roles_Edit: string = 'Roles.Edit';
  public static AdministrationGroup_Roles_Edit: string = 'Roles.Edit';
  public administrationGroup_Roles_ChangePermissions: string = 'Roles.ChangePermissions';
  public static AdministrationGroup_Roles_ChangePermissions: string = 'Roles.ChangePermissions';
  public stylesGroup_Styles: string = 'Styles';
  public static StylesGroup_Styles: string = 'Styles';
  public stylesGroup_Styles_Edit: string = 'Styles.Edit';
  public static StylesGroup_Styles_Edit: string = 'Styles.Edit';
  public notificationsGroup_Notifications: string = 'Notifications';
  public static NotificationsGroup_Notifications: string = 'Notifications';
  public notificationsGroup_Notifications_Insert: string = 'Notifications.Insert';
  public static NotificationsGroup_Notifications_Insert: string = 'Notifications.Insert';
  public notificationsGroup_Notifications_Edit: string = 'Notifications.Edit';
  public static NotificationsGroup_Notifications_Edit: string = 'Notifications.Edit';
  public publicLesseeGroup_PublicLessee: string = 'PublicLessee';
  public static PublicLesseeGroup_PublicLessee: string = 'PublicLessee';
  public publicLesseeGroup_PublicLessee_Contracts: string = 'Contracts';
  public static PublicLesseeGroup_PublicLessee_Contracts: string = 'Contracts';
  public publicLesseeGroup_PublicLessee_Payments: string = 'Payments';
  public static PublicLesseeGroup_PublicLessee_Payments: string = 'Payments';
  public publicLesseeGroup_PublicLessee_EADocuments: string = 'EADocuments';
  public static PublicLesseeGroup_PublicLessee_EADocuments: string = 'EADocuments';
  public publicLesseeGroup_PublicLessee_MissingDocumentations: string = 'MissingDocumentation';
  public static PublicLesseeGroup_PublicLessee_MissingDocumentations: string = 'MissingDocumentation';
  public publicLesseeGroup_PublicLessee_OverduePayments: string = 'OverduePayments';
  public static PublicLesseeGroup_PublicLessee_OverduePayments: string = 'OverduePayments';
  public publicLesseeGroup_PublicLessee_InsuranceClaims: string = 'InsuranceClaims';
  public static PublicLesseeGroup_PublicLessee_InsuranceClaims: string = 'InsuranceClaims';
  public publicLesseeGroup_PublicLessee_Partner: string = 'Partner';
  public static PublicLesseeGroup_PublicLessee_Partner: string = 'Partner';
  public publicLesseeGroup_PublicLessee_PartnerTrr: string = 'PartnerTrr';
  public static PublicLesseeGroup_PublicLessee_PartnerTrr: string = 'PartnerTrr';
  public publicLesseeGroup_PublicLessee_PartnerContact: string = 'PartnerContact';
  public static PublicLesseeGroup_PublicLessee_PartnerContact: string = 'PartnerContact';
  public publicStockFundingGroup_PublicStockFunding: string = 'PublicStockFunding';
  public static PublicStockFundingGroup_PublicStockFunding: string = 'PublicStockFunding';
  public publicStockFundingGroup_PublicStockFunding_Insert: string = 'PublicStockFunding.Insert';
  public static PublicStockFundingGroup_PublicStockFunding_Insert: string = 'PublicStockFunding.Insert';
  public publicStockFundingGroup_PublicStockFunding_CanSeeOthersStockFunding: string = 'PublicStockFunding.CanSeeOthersStockFunding';
  public static PublicStockFundingGroup_PublicStockFunding_CanSeeOthersStockFunding: string = 'PublicStockFunding.CanSeeOthersStockFunding';
  public publicStockFundingGroup_PublicStockFunding_CanSeeStockFunding: string = 'PublicStockFunding.CanSeeStockFunding';
  public static PublicStockFundingGroup_PublicStockFunding_CanSeeStockFunding: string = 'PublicStockFunding.CanSeeStockFunding';
  public publicStockFundingGroup_PublicStockFunding_CanSeeStockFundingContract: string = 'PublicStockFunding.CanSeeStockFundingContract';
  public static PublicStockFundingGroup_PublicStockFunding_CanSeeStockFundingContract: string = 'PublicStockFunding.CanSeeStockFundingContract';
  public systemSettingsGroup_SystemSettingsMenu: string = 'SystemSettingsMenu';
  public static SystemSettingsGroup_SystemSettingsMenu: string = 'SystemSettingsMenu';
  public systemSettingsGroup_Workflows: string = 'Workflows';
  public static SystemSettingsGroup_Workflows: string = 'Workflows';
  public systemSettingsGroup_Workflows_Insert: string = 'Workflows.Insert';
  public static SystemSettingsGroup_Workflows_Insert: string = 'Workflows.Insert';
  public systemSettingsGroup_Workflows_Edit: string = 'Workflows.Edit';
  public static SystemSettingsGroup_Workflows_Edit: string = 'Workflows.Edit';
  public systemSettingsGroup_AdminRequests: string = 'AdminRequests';
  public static SystemSettingsGroup_AdminRequests: string = 'AdminRequests';
  public systemSettingsGroup_AdminRequests_Insert: string = 'AdminRequests.Insert';
  public static SystemSettingsGroup_AdminRequests_Insert: string = 'AdminRequests.Insert';
  public systemSettingsGroup_AdminRequests_Edit: string = 'AdminRequests.Edit';
  public static SystemSettingsGroup_AdminRequests_Edit: string = 'AdminRequests.Edit';
  public systemSettingsGroup_ExternalFunctions: string = 'ExternalFunctions';
  public static SystemSettingsGroup_ExternalFunctions: string = 'ExternalFunctions';
  public systemSettingsGroup_ExternalFunctions_Edit: string = 'ExternalFunctions.Edit';
  public static SystemSettingsGroup_ExternalFunctions_Edit: string = 'ExternalFunctions.Edit';
  public systemSettingsGroup_Jobs: string = 'Jobs';
  public static SystemSettingsGroup_Jobs: string = 'Jobs';
  public systemSettingsGroup_Jobs_Edit: string = 'Jobs.Edit';
  public static SystemSettingsGroup_Jobs_Edit: string = 'Jobs.Edit';
  public systemSettingsGroup_Personalizations: string = 'Personalizations';
  public static SystemSettingsGroup_Personalizations: string = 'Personalizations';
  public systemSettingsGroup_Personalizations_Insert: string = 'Personalizations.Insert';
  public static SystemSettingsGroup_Personalizations_Insert: string = 'Personalizations.Insert';
  public systemSettingsGroup_Personalizations_Edit: string = 'Personalizations.Edit';
  public static SystemSettingsGroup_Personalizations_Edit: string = 'Personalizations.Edit';
  public systemSettingsGroup_Reports: string = 'Reports';
  public static SystemSettingsGroup_Reports: string = 'Reports';
  public systemSettingsGroup_Reports_Insert: string = 'Reports.Insert';
  public static SystemSettingsGroup_Reports_Insert: string = 'Reports.Insert';
  public systemSettingsGroup_Reports_Edit: string = 'Reports.Edit';
  public static SystemSettingsGroup_Reports_Edit: string = 'Reports.Edit';
  public systemSettingsGroup_Settings: string = 'Settings';
  public static SystemSettingsGroup_Settings: string = 'Settings';
  public systemSettingsGroup_Settings_Edit: string = 'Settings.Edit';
  public static SystemSettingsGroup_Settings_Edit: string = 'Settings.Edit';
  public systemSettingsGroup_License: string = 'License';
  public static SystemSettingsGroup_License: string = 'License';
  public systemSettingsGroup_Maintenace: string = 'Maintenace';
  public static SystemSettingsGroup_Maintenace: string = 'Maintenace';
  public publicRequestsGroup_PublicRequests: string = 'PublicRequests';
  public static PublicRequestsGroup_PublicRequests: string = 'PublicRequests';
  public publicRequestsGroup_PublicRequests_PublicRequestsInsert: string = 'PublicRequests.Insert';
  public static PublicRequestsGroup_PublicRequests_PublicRequestsInsert: string = 'PublicRequests.Insert';
  public publicReportsGroup_PublicReports: string = 'PublicReports';
  public static PublicReportsGroup_PublicReports: string = 'PublicReports';
  public publicCalculationGroup_Calculation: string = 'Calculation';
  public static PublicCalculationGroup_Calculation: string = 'Calculation';
  public adminCalculationGroup_FinancingSetting: string = 'FinancingSetting';
  public static AdminCalculationGroup_FinancingSetting: string = 'FinancingSetting';
  public publicTwoFAGroup_TwoFactorAuth: string = 'TwoFactorAuth';
  public static PublicTwoFAGroup_TwoFactorAuth: string = 'TwoFactorAuth';
  public publicTwoFAGroup_TwoFactorAuth_CanReset: string = 'TwoFactorAuth.CanReset';
  public static PublicTwoFAGroup_TwoFactorAuth_CanReset: string = 'TwoFactorAuth.CanReset';
  public publicTwoFAGroup_TwoFactorAuth_CanChange: string = 'TwoFactorAuth.CanChange';
  public static PublicTwoFAGroup_TwoFactorAuth_CanChange: string = 'TwoFactorAuth.CanChange';
}
