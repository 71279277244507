export class OnlineSettings {
  public abp_IdentityPasswordRequireDigit: string = 'Abp.Identity.Password.RequireDigit';
  public static Abp_IdentityPasswordRequireDigit: string = 'Abp.Identity.Password.RequireDigit';
  public abp_IdentityPasswordRequiredLength: string = 'Abp.Identity.Password.RequiredLength';
  public static Abp_IdentityPasswordRequiredLength: string = 'Abp.Identity.Password.RequiredLength';
  public abp_IdentityPasswordRequiredUniqueChars: string = 'Abp.Identity.Password.RequiredUniqueChars';
  public static Abp_IdentityPasswordRequiredUniqueChars: string = 'Abp.Identity.Password.RequiredUniqueChars';
  public abp_IdentityPasswordRequireLowercase: string = 'Abp.Identity.Password.RequireLowercase';
  public static Abp_IdentityPasswordRequireLowercase: string = 'Abp.Identity.Password.RequireLowercase';
  public abp_IdentityPasswordRequireNonAlphanumeric: string = 'Abp.Identity.Password.RequireNonAlphanumeric';
  public static Abp_IdentityPasswordRequireNonAlphanumeric: string = 'Abp.Identity.Password.RequireNonAlphanumeric';
  public abp_IdentityPasswordRequireUppercase: string = 'Abp.Identity.Password.RequireUppercase';
  public static Abp_IdentityPasswordRequireUppercase: string = 'Abp.Identity.Password.RequireUppercase';
  public applicationSettings_GmiApplicationCookieInfoPage: string = 'Gmi.Application.CookieInfoPage';
  public static ApplicationSettings_GmiApplicationCookieInfoPage: string = 'Gmi.Application.CookieInfoPage';
  public messages_LoginErrorMessage: string = 'Gmi.Login.Error.Message';
  public static Messages_LoginErrorMessage: string = 'Gmi.Login.Error.Message';
  public messages_LoginErrorMessageAdditionalInfo: string = 'Gmi.Login.Error.Message.Additional.Info';
  public static Messages_LoginErrorMessageAdditionalInfo: string = 'Gmi.Login.Error.Message.Additional.Info';
  public users_GmiUserPasswordValidForDays: string = 'Gmi.GmiUser.Password.ValidForDays';
  public static Users_GmiUserPasswordValidForDays: string = 'Gmi.GmiUser.Password.ValidForDays';
  public calculation_CheckVehicleAge: string = 'Gmi.Calc.CheckVehicleAge';
  public static Calculation_CheckVehicleAge: string = 'Gmi.Calc.CheckVehicleAge';
  public calculation_DefaultLegalType: string = 'Gmi.Calc.DefaultLegalType';
  public static Calculation_DefaultLegalType: string = 'Gmi.Calc.DefaultLegalType';
  public calculation_CopyObjectToDescription: string = 'Gmi.Calc.CopyObjectToDescription';
  public static Calculation_CopyObjectToDescription: string = 'Gmi.Calc.CopyObjectToDescription';
}
