<nav
	class="navbar navbar-expand-lg navbar-light header-navbar {{ transparentBGClass }}"
	[ngClass]="{
		'navbar-brand-center': menuPosition === 'Top',
		'navbar-static': menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
		'navbar-sticky': menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
		'fixed-top': isSmallScreen && menuPosition === 'Top'
	}">
	<div class="container-fluid navbar-wrapper fixed-top-navbar-primary gmi-navbar">
		<div class="navbar-header d-flex">
			<div
				class="navbar-toggle menu-toggle d-xl-none d-block float-start align-items-center justify-content-center"
				data-toggle="collapse"
				(click)="toggleSidebar()">
				<i class="ft-menu font-medium-5 ps-2 text-white"></i>
			</div>
			<ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)"></ul>
			<div class="navbar-brand-center">
				<div class="navbar-header">
					<ul class="navbar-nav">
						<li class="nav-item">
							<!-- LOGO -->
							<div class="logo">
								<a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
									<div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl" /></div>
									<span class="text">APEX</span>
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="navbar-container">
			<div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
				<ul class="navbar-nav">
					<!-- new notifications button -->
					<li placement="bottom-left" display="static" *ngIf="!!cntNewEaDocuments$">
						<button
							type="button"
							class="btn rounded-25 text-white mt-1 gmi-navbar-color"
							[routerLink]="'/notification'"
							ngbTooltip="{{ WL.navbar_NotificationsToolTip | gmiCustomLocalization | async }}">
							<i class="ft-bell font-medium-4"></i>
							<span class="badge rounded-pill badge-danger font-small-1">{{ cntNewEaDocuments$ }}</span>
						</button>
					</li>
					<!-- new documents button -->
					<li placement="bottom-left" display="static" *ngIf="!!cntNewNotifications$">
						<button
							type="button"
							class="btn rounded-25 text-white mt-1 gmi-navbar-color"
							(click)="redirectToEaDocs()"
							ngbTooltip="{{ WL.navbar_EaDocumentToolTip | gmiCustomLocalization | async }}">
							<i class="ft-file-text font-medium-4"></i>
							<span class="badge rounded-pill badge-danger font-small-1">{{ cntNewNotifications$ }}</span>
						</button>
					</li>
					<!-- new messages button -->
					<li placement="bottom-left" display="static" *ngIf="!!navbarData?.cntUnreadMessages">
						<button
							type="button"
							class="btn rounded-25 text-white mt-1 gmi-navbar-color"
							[routerLink]="'/message'"
							ngbTooltip="{{ WL.navbar_MessagesToolTip | gmiCustomLocalization | async }}">
							<i class="icon-bubbles font-medium-4"></i>
							<span class="badge rounded-pill badge-danger font-small-1">{{ navbarData?.cntUnreadMessages }}</span>
						</button>
					</li>
					<!-- missing docs button -->
					<li placement="bottom-left" display="static" *ngIf="!!navbarData?.cntMissingDocs">
						<button
							type="button"
							class="btn rounded-25 text-white mt-1 gmi-navbar-color"
							[routerLink]="'/missingDocuments'"
							ngbTooltip="{{ WL.navbar_MissingDocsToolTip | gmiCustomLocalization | async }}">
							<i class="icon-docs font-medium-4"></i>
							<span class="badge rounded-pill badge-danger font-small-1">{{ navbarData?.cntMissingDocs }}</span>
						</button>
					</li>
					<!-- user data -->
					<li class="dropdown nav-item me-1" placement="bottom-left" display="static" ngbDropdown>
						<a
							class="nav-link dropdown-toggle user-dropdown d-flex align-items-end mt-1"
							id="dropdownBasic2"
							href="javascript:;"
							data-toggle="dropdown"
							ngbDropdownToggle>
							<div class="user d-flex me-2 hide800">
								<b><span class="text-end font-medium-3 light gmi-navbar-color">{{ !!navbarData?.actualUserName ? navbarData?.actualUserName + ' >> ' : '' }}{{ navbarData?.userName }}</span></b>
								<span class="text-end light gmi-navbar-color">{{ navbarData?.email }}</span>
							</div>
							<div class="user d-flex hide800">
								<i class="ft-user font-large-1 text-white gmi-navbar-color"></i>							
							</div>
							<div class="user d-flex me-2 show800 hideOnFull">
								<i class="ft-user font-large-1 text-white gmi-navbar-color"></i>
							</div>
						</a>
						<div class="dropdown-menu text-start dropdown-menu-right m-0 p-1" aria-labelledby="dropdownBasic2" ngbDropdownMenu>
							<a class="dropdown-item hideOnFull show800">
								<div class="d-flex align-items-center">
									<b><span class="font-medium-3 text-wrap text-break">{{ navbarData?.userName }}</span></b>
								</div>
								<div class="d-flex align-items-center">
									<span class="text-wrap text-break">{{ navbarData?.email }}</span>
								</div>
								<div class="dropdown-divider"></div>
							</a>
							<a class="dropdown-item gmi-light-hover" routerLink="/profile">
								<div class="d-flex align-items-center">
									<i class="ft-edit me-2"></i><span>{{ WL.navbar_Profile | gmiCustomLocalization | async }}</span>
								</div>
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item gmi-current-language">
								<div class="d-flex grid-left-align ps-2 pb-2">
									<img class="langimg selected-flag" src="{{ defaultLanguage?.flagIcon }}" alt="flag" />
									<span class="ps-2">{{ defaultLanguage?.displayName }}</span>
								</div>
							</a>
							<a
								class="dropdown-item gmi-light-hover"
								*ngFor="let lang of dropdownLanguages"
								href="javascript:void(0)"
								(click)="onChangeLang(lang.cultureName)">
								<div class="d-flex grid-left-align ps-2 pb-2">
									<img class="langimg selected-flag" src="{{ lang?.flagIcon }}" alt="flag" />
									<span class="ps-2">{{ lang?.displayName }}</span>
								</div>
							</a>
							<div class="dropdown-divider"></div>
							<a class="dropdown-item gmi-light-hover" (click)="logout()">
								<div class="d-flex align-items-center">
									<i class="ft-power me-2"></i><span>{{ WL.navbar_Logout | gmiCustomLocalization | async }}</span>
								</div>
							</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>
