import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * This is the observable for the numbers in the navbar.
 * We don't need to reload the data, just set the number to lower.
 */
@Injectable({ providedIn: 'root' })
export class GmiNavbarService {
	// Observable
	private cntUnreadNotifications$: BehaviorSubject<number> = new BehaviorSubject(0);
	private cntNewEaDocuments$: BehaviorSubject<number> = new BehaviorSubject(0);

	getCntUnreadNotifications(): Observable<number> {
		return this.cntUnreadNotifications$.asObservable();
	}

	getCntNewEaDocuments(): Observable<number> {
		return this.cntNewEaDocuments$.asObservable();
	}

	setCntUnreadNotifications(value: number) {
		this.cntUnreadNotifications$.next(value);
	}

	setCntNewEaDocuments(value: number) {
		this.cntNewEaDocuments$.next(value);
	}
}
