import { ActivatedRouteSnapshot, Router, CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';
// GMI code
import { AbpOAuthService } from '@abp/ng.oauth';
import { ConfigStateService, PermissionService } from '@abp/ng.core';
import { GmiPolicyService } from '../services/gmiPolicy.service';
import { GmiUtilsService } from '@proxy';
import { Observable } from 'rxjs';
import { GmiCheckMenuPermissions } from 'src/app/gmiUtils/functions';


export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(AuthGuardService).canActivate(route);
};

@Injectable()
export class AuthGuardService {
  constructor(
    private oAuthService: AbpOAuthService,
    private router: Router,
    private permissionService: PermissionService,
    private gmiPolicyService: GmiPolicyService,
    private gmiUtilsService: GmiUtilsService,
    private configStateService: ConfigStateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    let isAuth = this.oAuthService.isAuthenticated;
    if (!isAuth) {
      this.router.navigate(['/login']);
      return false;
    } else {
      // we have to wait for this to finish, because the menu check need this
      return new Observable<boolean>((observer) => {
          this.gmiUtilsService.getUserMenuSettings().subscribe((response) => {
            this.gmiPolicyService.setPolicySettings(response);
            let check = GmiCheckMenuPermissions(this.permissionService, this.gmiPolicyService, this.configStateService, route.data);
            if (!check){
              this.router.navigate(['/dashboard']);
            }
            observer.next(check);
            observer.complete();
          });
      });
    }
  }
}
